export const APPLICATION_PAYWALL_UTMS = {
	utm_source: 'application',
	utm_medium: 'paywall',
	utm_content: 'v1',
};

export const APPLICATION_PROFILE_UTMS = {
	utm_source: 'application',
	utm_medium: 'profile',
	utm_content: 'v1',
};
