import { WORD_TRAINER_STATUES } from '../WordTrainer';

export const mapScoreToStatus = (score) => {
  if (score <= 20) {
    return WORD_TRAINER_STATUES.BAD;
  }

  if (score <= 65) {
    return WORD_TRAINER_STATUES.OK;
  }

  return WORD_TRAINER_STATUES.GOOD;
};
