import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'scene';

export const findScencesByVersionForCustomer = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/for-customer?version=${payload.version}`,
  );

  return { data: data.data };
};

export const findScenceByVersionForCustomer = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/find-one-scene-for-customer/${payload._id}?version=${payload.version}`,
  );

  return { data: data.data };
};
