import React from 'react';
import styled from 'styled-components';
import Confetti from '../images/confetti.png';
import { useNavigate } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { useLanguageData } from '@/common/hooks/useLanguageData';
const Wrapper = styled.div`
  top: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;
  z-index: 1;
`;
const Title = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
`;
const Text = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  max-width: 220px;
`;
const Button = styled.div`
  display: inline-flex;
  padding: 11px 0px 10px 0px;
  justify-content: center;
  align-items: center;
  width: 340px;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 100px;
`;
const ImageWrapper = styled.div`
  width: 50px;
  img {
    width: 100%;
    height: auto;
  }
`;
const EndedScreen = ({ category }) => {
  const translation = useLanguageData('learn.flashcardV2');
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Title>{translation.congratulation}</Title>
      <ImageWrapper>
        <img src={Confetti} />
      </ImageWrapper>
      <ConfettiExplosion zIndex={999} />
      <Text>
        {translation.category} <b>{category?.translations[0]?.translation} </b>{' '}
        {translation.textAfterCategory}
      </Text>
      <Button onClick={() => navigate('/learn/flashcards')}>
        {translation.pickNewCategory}
      </Button>
    </Wrapper>
  );
};
export default EndedScreen;
