import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import { getCurrentPriceConfig } from '@/api/query/price-config';
import { useMutation } from 'react-query';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import useQueryMemo from '@/common/hooks/urlMemo';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { registerOnePageView } from '@/api/query/order';
import { handleEmailChange } from '@/common/helpers/handleGuessEmail';
import SavePayment from '../SavePayment';
import { generateLinkToProductPage } from '@/common/functions/generateLinkToProductPage';
import { CheckoutContext } from '../../../../../common/contexts/checkoutContext';
import { createOneOffPayment } from '@/api/query/payment';
import { PAYMENT_TYPES } from '@/pages/customer/paywall/components/PlanView';
import { getUtms } from '@/common/helpers/getUtms';
import {
	Wrapper,
	LeftInfo,
	SynthiAiLogoWrapper,
	Title,
	PriceWrapper,
	PlanName,
	SaveWrapper,
	TextWrapper,
	Text,
	PriceInfoWrapper,
	Border,
	Text2,
	ActionWrapper,
	TitleDesktop,
	EmailBox,
	InputWrapper,
	Label,
	StyledInput,
	FormatMailCorrection,
	HintMail,
	LinkWrapper,
	LetMailButton,
} from './OnePagePayuPayment.styles';

const OnePagePayuPayment = () => {
	const translations = useLanguageData('checkout');
	const queryMemo = useQueryMemo();
	const common = useLanguageData('common');

	const { lang } = useContext(LanguageContext);
	const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);
	const { quizVersion, spVersion } = useContext(CheckoutContext);

	const [email, setEmail] = useState();
	const [promo, setPromo] = useState();
	const [correctionEmail, setCorrectionEmail] = useState();
	const [pricesData, setPricesData] = useState([]);
	const [selectedOption, setSelectedOption] = useState();

	const emailRef = useRef();

	const handleLoadPricesMutation = useMutation({
		mutationFn: (value) => getCurrentPriceConfig(value),
		onSuccess: ({ data }) => {
			const prices = data.prices.sort((a, b) => a.full_price - b.full_price);
			const plan = localStorage.getItem('plan');
			const selected =
				prices?.find((p) => p?.type?.value === plan) || prices[0];

			setPricesData(() => prices);
			setSelectedOption(() => selected);
		},
	});

	const handleChangeSelect = (pd) => {
		setSelectedOption(() => pd);
	};

	const handleRegisterOnePageViewMutation = useMutation({
		mutationFn: (payload) => createOneOffPayment(payload),
		onSuccess: ({ data }) => {
			localStorage.setItem('user_register', JSON.stringify(data.customerId));
			window.location.href = data.link;
		},
	});

	const handleCreateOrder = (e) => {
		e?.preventDefault();

		const payload = {};
		payload.email = emailRef?.current?.value?.toLowerCase();
		payload.paymentUtms = getUtms(queryMemo);
		payload.lang = lang;
		payload.plan = selectedOption._id;
		payload.promo = promo;
		payload.type = PAYMENT_TYPES.PAYU;

		handleFbStats('InitiateCheckout', {});
		handleFbStats('AddPaymentInfo', {});

		window?.gtag('event', 'initiate_checkout', {});
		window?.gtag('event', 'add_payment_info', {});

		window?.fbq('track', 'InitiateCheckout');
		window?.fbq('track', 'AddPaymentInfo');

		window?.uetq?.push('event', 'subscribe');

		if (promo) {
			if (selectedOption.type.value === 'PREMIUM') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}6MO_PROMO`);
			}
			if (selectedOption.type.value === 'PRO') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}3MO_PROMO`);
			}
			if (selectedOption.type.value === 'BASIC') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}1MO_PROMO`);
			}
		} else {
			if (selectedOption.type.value === 'PREMIUM') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}6MO`);
			}
			if (selectedOption.type.value === 'PRO') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}3MO`);
			}
			if (selectedOption.type.value === 'BASIC') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}1MO`);
			}
		}

		handleRegisterOnePageViewMutation.mutate(payload);
	};

	useEffect(() => {
		const payload = {
			country: lang === 'cs' ? 'cz' : lang,
		};

		handleLoadPricesMutation.mutate(payload);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setEmail(() => localStorage.getItem('quiz_email'));
			const promo = localStorage.getItem('promo');

			if (promo) {
				setPromo(() => true);
			}
		}, 100);
	}, []);

	return (
		<Wrapper>
			<LeftInfo>
				<SynthiAiLogoWrapper>
					<img src={SynthiAiLogo} />
				</SynthiAiLogoWrapper>
				<Title>{translations.leftTitle}</Title>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<div style={{ minWidth: '320px' }}>
						{[...pricesData]
							.sort((a, b) => b.full_price - a.full_price)
							.map((pd) => (
								<PriceWrapper style={{ marginBottom: '12px' }}>
									<div
										style={{
											display: 'flex',
											gap: '22px',
											width: 'fit-content',
										}}
										onClick={() => handleChangeSelect(pd)}
									>
										<input
											type='radio'
											id={pd._id}
											value={pd._id}
											name='plan'
											checked={pd._id === selectedOption?._id}
										/>
										<span
											class='custom-radio-button'
											style={{ marginTop: '5px' }}
										></span>
										<label
											htmlFor={pd._id}
											style={{
												display: 'flex',
												gap: '22px',
											}}
										>
											<PlanName
												style={{
													color:
														pd._id === selectedOption?._id
															? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
															: '#29185E',
												}}
											>
												{pd.inAppPrice.label}
											</PlanName>{' '}
											<SaveWrapper promo={promo}>
												{promo
													? pd.inAppPrice.promo_saving
													: pd.inAppPrice.saving}
											</SaveWrapper>{' '}
										</label>
									</div>
									{pd._id === selectedOption?._id && (
										<PriceInfoWrapper>
											<TextWrapper>
												<Text>{pd.inAppPrice.full_label}</Text>
												<Text>{pd.inAppPrice.orginal_full_price}</Text>
											</TextWrapper>
											<TextWrapper>
												<Text>
													{promo
														? pd.inAppPrice.promo_discount_label
														: pd.inAppPrice.discount_label}
												</Text>
												<Text
													style={{
														color: `${
															promo ? '#149069' : 'var(--pink, #E94F96)'
														}`,
													}}
												>
													{promo
														? pd.inAppPrice.promo_discount_value
														: pd.inAppPrice.discount_value}
												</Text>
											</TextWrapper>
											<Border />
											<TextWrapper>
												<Text>{translations.total}</Text>
												<Text>
													<b>
														{' '}
														{promo ? pd.promo_full_price : pd.full_price}{' '}
														{translations.currencySign}
													</b>
												</Text>
											</TextWrapper>
										</PriceInfoWrapper>
									)}
								</PriceWrapper>
							))}
					</div>
				</div>
			</LeftInfo>
			<ActionWrapper onSubmit={(e) => handleCreateOrder(e)}>
				<TitleDesktop>{translations.rightTitle}</TitleDesktop>
				<EmailBox>
					<InputWrapper style={{ zIndex: 4 }}>
						<Label>{translations.email}</Label>
						<StyledInput
							ref={emailRef}
							id='email-synthiai'
							type='email'
							required
							onChange={(e) =>
								handleEmailChange(e.target.value, lang, setCorrectionEmail)
							}
							defaultValue={email}
							placeholder={translations.placeHolderEmail}
							style={{ border: 'none', padding: '8px 0px 8px 14px' }}
						/>
					</InputWrapper>
					{correctionEmail && (
						<HintMail
							onClick={() => {
								emailRef.current.value = correctionEmail;
								setCorrectionEmail(() => null);
							}}
						>
							{translations.emailGuessText}{' '}
							<FormatMailCorrection>{correctionEmail}</FormatMailCorrection> ?
						</HintMail>
					)}
				</EmailBox>
				<LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
					<input type='checkbox' id='rules_2' required defaultChecked />
					<label
						htmlFor='rules_2'
						style={{
							cursor: 'pointer',
							display: 'flex',
							flexWrap: 'wrap',
							gap: '3px',
							maxWidth: '300px',
							padding: '0 0 0 5px ',
						}}
					>
						{translations.accept}{' '}
						<a
							href={generateLinkToProductPage(lang, common.termsLink)}
							style={{ textDecoration: 'underline' }}
						>
							{' '}
							{translations.rules}
						</a>
						{translations.space}
						<a
							href={generateLinkToProductPage(lang, common.privacyLink)}
							style={{
								textDecoration: 'underline',
							}}
						>
							{translations.tos}
						</a>
						SynthiAi
					</label>
				</LinkWrapper>
				<LetMailButton type='submit' style={{ fontSize: '18px' }}>
					{translations.pay}
				</LetMailButton>
				<SavePayment />
			</ActionWrapper>
		</Wrapper>
	);
};

export default OnePagePayuPayment;
