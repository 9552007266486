import Android1 from './images/Android_1_150_CS.png';
import Android2 from './images/Android_Dodaj_150_CS.png';
import Ios1 from './images/IOS_1_150_CS.png';
import Ios2 from './images/IOS_Dodaj_150_CS.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

import Micro from './images/wordTrainerTutorial/icon-microphone.png';
import Voice from './images/wordTrainerTutorial/volume-up.svg';
import Person from './images/wordTrainerTutorial/user.svg';

export default {
	passwordRestart: {
		title: 'Obnovit heslo',
		password: 'Heslo',
		repeatPassword: 'Zopakovat heslo',
		restartPawssord: 'Obnovit heslo',
	},

	paywall: {
		title: 'Vyberte si plán pro pokračování v učení',
		buy: 'Koupit',
		savePayment: 'Bezpečné platby zajišťuje',
		skip: 'Přeskočit',
		pickPlan: 'Vyberte plán',
		endPlanFirstPart: 'Váš přístup skončí za',
		endPlanSecondPart: 'dnů.',
		endPlanAditionalPart: 'den.',
		paymentTitle: 'Shrnutí',
		product: 'Produkt:',
		plan: 'SynthiAi Balíček',
		forLong: 'Přístup na',
		data: 'údaje:',
		nameAndSurname: 'Jméno a příjmení:',
		paymentForm: 'Způsob platby:',
		toPay: 'K úhradě dnes:',
		accept: 'Souhlasím',
		rules: 'podmínky',
		payingAccept: 'Placení znamená souhlas s',
		paymentRules: '"Platebními podmínkami PayU"',
		buyAndPay: 'KOUPIT A ZAPLATIT',
	},
	paymentStatus: {
		vrification: 'Platba se stále zpracovává...',
		title: 'Platba dokončena',
		success: 'úspěšná!',
		failure: 'neúspěšná!',
		subtitle: 'Brzy budete přesměrováni na přihlašovací stránku.',
		textInfo: `Vaše platba byla úspěšně zpracována. Nyní můžete nastavit své heslo na naší registrační stránce. Během několika minut obdržíte e-mail s odkazem, který vám umožní nastavit heslo.

Zkontrolujte prosím svou e-mailovou schránku, včetně složky spam. Pro usnadnění vyhledávání zadejte do pole pro hledání slovo "synthiai". V případě jakýchkoli problémů nás prosím kontaktujte na adrese: contact@synthiaiapp.com`,
		login: 'Přihlásit se',
	},
	register: {
		steps: {
			register: 'Registrace',
			package: 'Balíček',
			summarize: 'Shrnutí',
			step: 'Krok ',
		},
		stepOne: {
			password: 'Heslo',
			errorGoogle: 'Přihlaste se pomocí svého e-mailu',
			title: 'Registrace',
			nameAndSurname: 'Jméno a příjmení',
			placeholderNameAndSurname: 'např. Adam Novák',
			email: 'E-mail',
			placeholderEmail: 'např. priklad@synthiaiapp.com',
			password: 'Heslo',
			start: 'VYTVOŘIT ÚČET',
			subtitle:
				'Heslo musí mít alespoň 8 znaků a obsahovat písmena, velká písmena a číslice.',
			passwordError1: 'Heslo musí obsahovat alespoň 8 znaků.',
			passwordError2: 'Heslo musí obsahovat alespoň 1 velké písmeno.',
			passwordError3: 'Heslo musí obsahovat alespoň 1 malé písmeno.',
			passwordError4: 'Heslo musí obsahovat alespoň 1 číslici.',
			passwordError5: 'Hesla musí být stejná.',
		},
		stepTwo: {
			title: 'Vyberte si plán',
			month: 'Měsíc',
			months: 'Měsíce',
			months2: 'Měsíce',
			value: '€/Měsíc',
			pick: 'vybrat',
			nowPay: 'Zaplatit nyní',
			saveOne: 'Ušetříte',
			buy: 'Koupit',
			savePayment: 'Bezpečné platby zajišťuje',
		},
		stepThree: {
			title: 'Shrnutí',
			product: 'Produkt:',
			plan: 'Synthi Ai Balíček',
			forLong: 'Přístup na',
			data: 'Data:',
			nameAndSurname: 'Jméno a příjmení:',
			paymentForm: 'Platební metoda:',
			toPay: 'K zaplacení dnes:',
			accept: 'Souhlasím s',
			rules: 'podmínky',
			payingAccept: 'Platbou souhlasíte s',
			paymentRules: '"Platební pravidla PayU"',
			buyAndPay: 'KOUPIT A ZAPLATIT',
		},
	},
	firstLogin: {
		createPasswordForAcoount: 'Vytvořte si heslo pro svůj účet',
		typeEmail: 'Zadejte e-mailovou adresu',
		useSameEmail: 'Použijte stejnou adresu, kterou jste zadali při nákupu',
		typePassword: 'Zadejte heslo',
		confirmPassword: 'Potvrďte heslo',
		createPassword: 'Vytvořit heslo',
		errorLogin: 'Zkontrolujte, zda je vaše heslo nebo e-mail správné',
		createdPassword: 'Už jste vytvořili heslo?',
		clickToLogin: 'Klikněte zde pro přihlášení',
	},
	login: {
		dontHavePassword: 'Ještě nemáte heslo?',
		getPassword: 'Klikněte zde pro přístup',
		title: 'Přihlášení',
		email: 'E-mail',
		password: 'Heslo',
		textSub: 'Zapomněli jste heslo? Obnovte ho',
		restart: 'zde',
		restartPasswordText:
			'Zadejte svůj e-mail, abyste obnovili heslo. Heslo bude odesláno na váš e-mail.',
		placeholder: 'Zadejte svůj e-mail...',
		resetPassword: 'Obnovit heslo',
		buttonText: 'Přihlásit se',
		errorLogin: 'Zkontrolujte, zda je vaše heslo nebo e-mailová adresa správná',
		passwordResterted: 'Vaše heslo bylo obnoveno, zkontrolujte svůj e-mail.',
		passwordError: 'Bohužel se nám nepodařilo najít váš e-mail.',
		mobileAppBannerText: 'Objevte naši aplikaci!',
	},
	navBar: {
		home: 'Start',
		learn: 'Učení',
		profile: 'Profil',
	},
	home: {
		hello: 'Ahoj',
		question: 'O čem si dnes chcete povídat?',
		answer: 'Začněte konverzaci',
		forYou: 'Doporučeno pro vás',
		popularNow: 'Nyní populární',
		doYouKnow: 'Věděli jste, že...?',
		funFact: 'Potěšující fakt dne',
		new: 'Nové',
		check: 'Zkontroluj',
		yourFavorite: 'Vaše oblíbené',
		newTitle: 'Trenér slovní zásoby',
		newSubtitle: 'Procvičujte výslovnost slov',
		talkWith: 'Mluv o čemkoli s',
	},
	learn: {
		wordTrainer: {
			title: 'Trenér slovní zásoby',
			subtitle: 'Procvičujte výslovnost slov',
			new: 'Novinka',
		},
		flashcardV2: {
			new: 'Nové',
			sport: 'Sport',
			continue: 'pokračovat',
			nice: 'Skvělé!',
			congratulation: 'Gratulace!',
			category: 'Kategorie',
			textAfterCategory: 'pro tebe není tajemstvím!',
			pickNewCategory: 'Vyber novou kategorii',
			showResult: 'Zobrazit řešení',
			createWordWithLetters: 'Slož slovo z rozházených písmen',
			know: 'Znám',
			dontKnow: 'Neznám',
			metchWords: 'Spoj do dvojic',
			addNewCategory: 'Přidat novou kategorii',
			personalizeLearning: 'Přizpůsobte si učení vytvořením vlastní kategorie.',
			typeNewCategory: 'Zadejte název své kategorie',
			save: 'Uložit',
			weAreCreatingCategoryForYou: 'Vytváříme pro vás novou kategorii:',
			start: 'ZAČÍT',
			howToUseFlashcard: 'Jak používat kartičky?',
			knowFlashcard:
				'Klikněte na „Neznám“ nebo „Znám“, abyste ohodnotili své znalosti.',
			dontKnowFlashcard: 'Kartičky označené jako „Znám“ nebudou opakovány.',
			clickToHear: 'Klikněte, abyste slyšeli slovo.',
			clickForDefinition: 'Klikněte pro zobrazení definice',
			clickForTranslation: 'Klikněte pro zobrazení překladu a příkladů',
			close: 'Zavřít',
			clickCategoryToStart: 'Klikněte na vybranou kategorii a začněte se učit.',
			lookAsProgressBar:
				'Postupová lišta pod kategorií ukazuje, kolik slovíček jste již zvládli.',
			clickToSetupFavaroiteFlashcard:
				'Klikněte pro označení kategorie jako oblíbené.',
			clickToCreateOwnCategory:
				'Klikněte pro vytvoření vlastní kategorie kartiček.',
			howLearningLookLike: 'Jak vypadá učení?',
			restText:
				' Učení spočívá v procházení kartiček a hodnocení svých znalostí. Navíc budete řešit jednoduché úkoly.',
		},
		chat: {
			title: 'Chat',
			subtitle: 'Mluvte o jakémkoliv tématu.',
		},
		fotoMode: {
			title: 'Foto-hádanka',
			subtitle: 'Popište obrázek co nejpřesněji.',
		},
		scene: {
			title: 'Scény',
			subtitle: 'Přehrávejte roli s AI lektorem.',
		},
		word: {
			title: 'Slova',
			subtitle: 'Rozšiřte si svou slovní zásobu.',
		},
		flashcard: {
			title: 'Kartičky',
			subtitle: 'Opakujte a zapamatujte si slova.',
		},
		seeMore: 'Zobrazit vše',
	},
	common: {
		description:
			'Synthi AI - Váš inteligentní asistent pro učení angličtiny. Naučte se plynule mluvit anglicky s pomocí umělé inteligence. Objevte nový, efektivní přístup k výuce jazyků. Začněte ještě dnes!',
		deletedFromFavorite: 'Odstraněno z oblíbených',
		addedToFavorite: 'Přidáno do oblíbených',
		flashcards: 'Kartičky',
		flashcards_v2: 'Kartičky',
		scenes: 'Scény',
		words: 'Slova',
		or: 'nebo',
		continueWithFB: 'Pokračovat přes Facebook',
		privacyLink: 'privacy',
		termsLink: 'tos',
	},
	profile: {
		days: ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'],
		top: {
			profile: 'Profil',
			accessTo: 'Přístup k:',
			profileImage: 'Profilový obrázek',
			nameAndSurname: 'Jméno a příjmení',
			password: 'Heslo',
			change: 'Změnit',
			changeProfileImage: 'Změnit profilový obrázek',
			changeNameAndSurname: 'Změnit jméno a příjmení',
			changeNameAndSurnamePlaceHolder: 'Zadejte své jméno a příjmení',
			save: 'Uložit',
			changePassword: 'Změnit heslo',
			passwordInfo:
				'Heslo musí mít alespoň 8 znaků a obsahovat písmena, velká písmena a číslice.',
			repeatPassword: 'Zopakovat heslo',
			passwordChanged: 'Heslo změněno',
			error: 'Ups, něco se pokazilo',
			nameChanged: 'Jméno změněno',
			profileChanged: 'Profil změněn',
		},
		plan: {
			subInfo:
				'pro zrušení předplatného pošlete e-mail na contact@synthiaiapp.com',
			yourPlan: 'Váš plán',
			extend: 'Prodlužte svůj plán',
			short_extend: 'Prodlužte',
			currentPlan: 'Aktuální plán:',
			activeToPlan: 'Aktivní do:',
			currentPlanLong: 'Váš aktuální plán',
		},
		middle: {
			yourMedals: 'Vaše medaile',
			oneDay: 'Den',
			twoDays: 'Dny',
			words: 'Slova',
			learningTime: 'Doba učení',
			streak: 'Streak',
			streakRecord: 'Rekord v po sobě jdoucích dnech',
			yourRecord: 'Váš rekord:',
			currentRecord: 'Aktuální stav:',
			words: 'Slova',
			wordsInWeek: 'Počet slov za týden:',
		},
		bottom: {
			yourTutor: 'Váš AI lektor',
			change: 'Změnit',
			logout: 'Odhlásit se',
			FAQ: 'FAQ',
			shareOpinion: 'Podělte se o svůj názor',
			yourPlan: 'Váš plán',
			hobby: 'Zájmy',
			learningSetting: 'Nastavení učení',
			back: 'Zpět',
			dayTarget: 'Denní cíl',
			languageLevel: 'Úroveň jazyka',
			changeYourLangLevel: 'Změňte svou jazykovou úroveň.',
			pickNewTutor: 'Vyberte nového lektora',
			yourDecision: 'Vaše rozhodnutí ovlivní styl a atmosféru lekcí.',
			tutroChanged: 'Lektor změněn',
			error: 'Ups, něco se pokazilo',
			englishLevelChanged: 'Vaše úroveň byla změněna',
			levelsOptions: [
				{
					name: 'Začátečník A1',
					description: 'Začněte své dobrodružství s anglickým jazykem',
					value: 'basic',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Začátečník A2',
					description: 'Chápete základní výrazy v jednoduchých konverzacích.',
					borderRadius: '0',
					value: 'beginner',
				},
				{
					name: 'Pokročilý B1',
					description:
						'Vede jednoduché, ale srozumitelné konverzace na známá témata.',
					borderRadius: '0',
					value: 'intermediate',
				},
				{
					name: 'Pokročilý B2',
					description: 'Svobodně komunikujete ve většině situací',
					borderRadius: '0',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description: 'Efektivně ovládáte jazyk ve složitých kontextech.',
					value: 'expert',
					borderRadius: '0 0 8px 8px ',
				},
			],
			changeDailyTarget: 'Změňte svůj denní cíl učení.',
			deletedFromFavorite: 'Odstraněno z oblíbených',
			addedToFavorite: 'Přidáno do oblíbených',
			lessonTime: [
				{
					name: 'Rychlá lekce',
					dsecription: 'Perfektní volba pro rychlé učení',
					time: '5 minut',
					value: 'five_minutes',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Standardní relace',
					dsecription: 'Skvělá volba pro vyvážené učení',
					time: '10 minut',
					value: 'ten_minutes',
					borderRadius: '0',
				},
				{
					name: 'Intenzivní trénink',
					dsecription: 'Nejlepší volba pro nadšence',
					time: '15 minut',
					value: 'fifteen_minutes',
					borderRadius: '0 0 8px 8px ',
				},
			],
			dailyTargetChanged: 'Denní cíl změněn!',
			pickIntresets: 'Vyberte si alespoň 3 zájmy.',
			activeTo: 'Aktivní plán do:',
			daysLeft: 'Zbývající dny do konce plánu:',
			opinionSubtitle:
				'Neustále vylepšujeme naši aplikaci. Podělte se s námi o svůj názor na to, co bychom měli v aplikaci změnit nebo co se vám líbí nejvíce.',
			startTyping: 'Napište svůj názor',
			faq: 'FAQ',
			send: 'Odeslat',
			withProblems: 'V případě dotazů/problémů nás kontaktujte na:',
			contactMail: 'contact@synthiaiapp.com',
			faqConfig: [
				{
					id: 1,
					question: 'Zvuk mi nefunguje.',
					answer: `Pokud máte problémy se zvukem, věnujte prosím pozornost následujícím tipům: 
      
              Zkontrolujte oprávnění zvuku v prohlížeči.
      
              Ujistěte se, že je hlasitost vašeho zařízení nastavena přiměřeně.
      
              Zkontrolujte správné připojení reproduktorů/sluchátek.
      
              Zkontrolujte nastavení zvuku v systému, aby aplikace nebyly ztlumeny.
      
              Vyzkoušejte jiné zařízení nebo kontaktujte technickou podporu.
          `,
				},
				{
					id: 2,
					question: 'Nemůžu nahrát svůj hlas.',
					answer: `Pokud máte problémy se záznamem zvuku, věnujte prosím pozornost následujícím tipům: 
              
              Zkontrolujte oprávnění mikrofonu v prohlížeči.
      
              Ujistěte se, že mobilní prohlížeč má přístup k mikrofonu.
      
              Zkontrolujte správné připojení mikrofonu.
      
              Vyzkoušejte jiné zařízení nebo kontaktujte technickou podporu.
          `,
				},
				{
					id: 3,
					question: 'Mohu aplikaci používat na více zařízeních?',
					answer: `Samozřejmě, přihlaste se jednoduše na každém kompatibilním zařízení, abyste si užili plynulé vzdělávání.`,
				},
				{
					id: 4,
					question: 'Mohu používat Synthi Ai offline?',
					answer: `Ne, Synthi Ai vyžaduje webový prohlížeč s přístupem k internetu.`,
				},
				{
					id: 5,
					question: 'Jak změním AI lektora?',
					answer: `Přejděte na Tutor" v nastavení Synthi Ai. Objevte a vyberte svého preferovaného lektora."`,
				},
				{
					id: 6,
					question: 'Jak prodloužím přístup k aplikaci po vypršení?',
					answer: `Svůj plán můžete obnovit po vypršení přihlášením.`,
				},
				{
					id: 7,
					question: 'Mohu získat fakturu?',
					answer:
						'Kontaktujte nás na contact@synthiaiapp.com a poskytněte své DIČ a údaje o společnosti.',
				},
				{
					id: 8,
					question: 'Jak mohu kontaktovat technickou podporu?',
					answer:
						'Zašlete e-mail na contact@synthiaiapp.com a uveďte podrobnosti o problému, informace o zařízení a prohlížeči.',
				},
			],
		},
		rules: 'Pravidla',
		privacy: 'Zásady ochrany osobních údajů',
		span: ' a ',
	},
	welcome: {
		languagePicker: {
			title: 'Vyberte jazyk, který se chcete učit',
			next: 'Dále',
			langToLearn: 'Jazyk výuky',
			successTitle: 'Jazyk výuky byl změněn',
			config: [
				{
					label: 'Angličtinu',
					label2: 'Angličtina',
					labelInside: 'Angličtinou',
					value: 'en',
				},
				{
					label: 'Němčinu',
					label2: 'Němčina',
					labelInside: 'Němčinou',
					value: 'de',
				},
				{
					label: 'Španělštinu',
					label2: 'Španělština',
					labelInside: 'Španělštinou',
					value: 'es',
				},
				{
					label: 'Francouzštinu',
					label2: 'Francouzština',
					labelInside: 'Francouzštinou',
					value: 'fr',
				},
				{
					label: 'Italštinu',
					label2: 'Italština',
					labelInside: 'Italštinou',
					value: 'it',
				},
			],
		},
		zeroScreen: {
			welcomeInApp: 'Vítejte v aplikaci Synthi AI!',
			start: 'ZAČÍT',
			welcomeMobile: 'Vítejte',
			config: {
				chrome: {
					title: 'Nainstalujte aplikaci na svém zařízení Android.',
					text1: 'Klikněte v prohlížeči Chrome na',
					text1bold: ' Nastavení',
					image1: Android1,
					text2: 'Poté klikněte na',
					text2bold: ' Přidat na úvodní obrazovku',
					image2: Android2,
					text3: 'Hotovo! Synthi AI je nyní na vaší úvodní obrazovce.',
					image3: Last,
				},
				safari: {
					title: 'Nainstalujte aplikaci na svém zařízení iOS.',
					text1: 'Klikněte v prohlížeči Safari na tlačítko',
					text1bold: ' Sdílet',
					image1: Ios1,
					text2: 'Poté klikněte na',
					text2bold: ' Přidat na úvodní obrazovku',
					image2: Ios2,
					text3: 'Hotovo! Synthi AI je nyní na vaší úvodní obrazovce.',
					image3: Last,
				},
			},
		},
		firstScreen: {
			title: 'Vyberte si svého lektora!',
			subtitle: 'Vaše rozhodnutí ovlivní styl a atmosféru lekcí.',
			next: 'DALŠÍ',
		},
		secondScreen: {
			title: 'Jaká je vaše aktuální úroveň {language}?',
			config: [
				{
					name: 'Začátečník A1',
					description: 'Začněte své dobrodružství s {language} jazykem',
					value: 'basic',
				},
				{
					name: 'Začátečník A2',
					description:
						'Rozumíte základním informacím v jednoduchých, každodenních rozhovorech.',
					value: 'beginner',
				},
				{
					name: 'Pokročilý B1',
					description:
						'Vede jednoduché, ale srozumitelné konverzace na známá témata.',
					value: 'intermediate',
				},
				{
					name: 'Pokročilý B2',
					description: 'Svobodně komunikujete ve většině situací',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description: 'Efektivně ovládáte jazyk ve složitých kontextech.',
					value: 'expert',
				},
			],
			next: 'DALŠÍ',
		},
		thirdScreen: {
			title: 'Jaké jsou vaše zájmy?',
			subtitle: 'Vyberte si alespoň 3 zájmy.',
			next: 'DALŠÍ',
		},
		fourthScreen: {
			title: 'Kolik minut denně se chcete učit?',
			subtitle: 'Vyberte si svůj denní cíl.',
			next: 'ZAČÍT!',
			config: [
				{
					name: 'Rychlá lekce',
					dsecription: 'Perfektní volba pro rychlé učení',
					time: '5 minut',
					value: 'five_minutes',
				},
				{
					name: 'Standardní relace',
					dsecription: 'Skvělá volba pro vyvážené učení',
					time: '10 minut',
					value: 'ten_minutes',
				},
				{
					name: 'Intenzivní trénink',
					dsecription: 'Nejlepší volba pro nadšence',
					time: '15 minut',
					value: 'fifteen_minutes',
				},
			],
		},
	},
	features: {
		wordTrainer: {
			next: 'Další',
			yourSpeaking: 'Tvoje výslovnost',
			you: 'Ty',
			title: 'Trenér slovní zásoby',
			errorMessage:
				'Jejda, máme problém se zpracováním tvé nahrávky. Zkus to znovu :)))',
			endScreenTitle: 'Skvělé! 🌟🌟🌟',
			endScreenTopInfo: 'Už ovládáš všechna',
			endScreenMiddleInfo: 'slova',
			endScreenBottomInfo: 'na této úrovni.',
			endScreenText: 'Můžeš začít znovu nebo změnit úroveň v nastavení.',
			endScreenBottomText: 'Začít znovu',
			endScreenEndText: 'Ukončit',
			sesionScoreTitle: 'Skvěle ti to jde! 🎉',
			sesionScoreSubTitle: 'Zlepšuješ svou výslovnost',
			sesionScoreWord: 'Slova',
			now: 'Teď',
			sum: 'celkem',
			continue: 'POKRAČOVAT',
			areYouSureToEndLesson: 'Opravdu chceš ukončit lekci?',
			speakingScore: 'Hodnocení výslovnosti',
			GOOD_ARRAY: [
				'Bravo!',
				'Perfektní!',
				'Jsi mistr!',
				'Skvělé!',
				'Úžasné!',
				'Geniální!',
			],
			BAD_ARRAY: [
				'Nevzdávej to!',
				'Zvládneš to!',
				'Ještě chvilku!',
				'Opakuj a bude to super!',
				'Brzy se to podaří!',
				'Nevadí, zkus to znovu!',
			],
			OK_ARRAY: [
				'Jsi na správné cestě!',
				'Velmi blízko!',
				'Stále lepší!',
				'Děláš pokroky!',
				'Bravo za snahu!',
			],
			hold: 'Podrž',
			holdText: 'mikrofon a vyslov:',
			tutorial: {
				title: 'Jak používat Trenéra slovní zásoby?',
				options: [
					{
						label: 'Klikni pro poslech správné výslovnosti',
						icon: Micro,
					},
					{
						label: 'Podrž a vyslov slovo',
						icon: Voice,
					},
					{
						label:
							'Klikni a poslechni si svou nahrávku pro dokonalé zvládnutí slova!',
						icon: Person,
					},
				],
				bottomText:
					'Pravidelné cvičení v Trenéru slovní zásoby ti pomůže rychleji ovládnout jazyk!',
				close: 'ZAVŘÍT',
			},
		},
		topbar: {
			back: 'Zpět',
		},
		fotoMode: {
			welcome: {
				title: 'Foto-hádanka',
				subtitle:
					'Popište, co vidíte na obrázku, co nejpodrobněji k vybranému tématu.',
				start: 'Začít!',
			},
			info: {
				startTyping: 'Pište...',
				newTask: 'Nový úkol',
				send: 'Odeslat',
			},
		},
		flashcards: {
			title: 'Kartičky',
			subtitleTop:
				'Učte se a pamatujte si nová slova díky interaktivním metodám učení s kartičkami.',
			addCategory: 'Přidejte svou kategorii',
			subtitle: 'Zadejte název své kategorie',
			type: 'zadejte kategorii...',
			add: 'Přidat',
			previous: 'Předchozí karta',
			task: 'Úkol',
			changeLevel: 'Změňte úroveň',
			dontKnow: 'Nevím',
			know: 'Vím',
			pickPairs: 'Vytvořit dvojice',
			goodJob: 'Dobrá práce!',
			titleEnd: 'Vaše odhodlání je působivé',
			subtitleEnd: 'Jste připraveni na další výzvy?',
			repeat: 'Opakovat kategorii',
			other: 'Vyberte jinou kategorii',
			level: {
				title: 'Změňte úroveň karet',
				beginner: 'Začátečník A1-A2',
				intermediate: 'Pokročilý B1-B2',
				advance: 'Expert C1-C2',
				change: 'Změnit',
			},
		},
		scene: {
			title: 'Scény',
			subtitle:
				'Vžijte se do role a přehrajte scénu s AI lektorem na vybrané téma.',
			suggest: 'Navrhnout novou scénu',
			talk: 'Mluvit',
			deletedFromFavorite: 'Odstraněno z oblíbených',
			addedToFavorite: 'Přidáno do oblíbených',
		},
		word: {
			title: 'Slova',
			subtitle:
				'Rozšiřte si slovní zásobu v klíčových oblastech konverzace s AI lektorem.',
			suggest: 'Navrhnout nové téma slov',
			deletedFromFavorite: 'Odstraněno z oblíbených',
			addedToFavorite: 'Přidáno do oblíbených',
		},
	},
	cookie: {
		title: 'Upozornění na cookies',
		subtitle:
			'Tento web používá cookies, aby vám poskytl lepší uživatelský zážitek a poskytl personalizovaný obsah. Chcete-li se dozvědět více, přečtěte si naše ',
		link: 'Zásady ochrany osobních údajů',
		accept: 'PŘIJMOUT',
		denied: 'ODMÍTNOUT',
	},
	talkingBar: {
		allowMicrofon: 'Povolte, prosím, přístup k mikrofonu',
		typeMessage: 'Napište zprávu...',
		record: 'Nahrát',
		recording: 'Nahrávání',
	},
	hands: {
		giveUsInfo: 'Podělte se s námi o svůj názor',
		thanksForGrading: 'Děkujeme za vaše hodnocení!',
		subtitle: 'Váš názor je pro nás důležitý!',
		subtitle2: 'Řekněte nám, co si o této lekci myslíte:',
		skip: 'PŘESKOČIT',
		send: 'ODESLAT',
	},
	suggestConfig: {
		profile: {
			title: 'Podělte se s námi o svůj názor!',
			subtitle:
				'Neustále vylepšujeme naši aplikaci. Podělte se s námi o to, co bychom měli v aplikaci změnit nebo co se vám líbí nejvíce.',
		},
		word: {
			title: 'Navrhnout nové téma slov',
			subtitle: '',
		},
		scene: {
			title: 'Navrhnout novou scénu',
			subtitle: '',
		},
		startTyping: 'Začněte psát',
		send: 'ODESLAT',
		thx: 'Názor přidán! Děkujeme!',
		error: 'Ups, něco se pokazilo',
	},
	checkout: {
		leftTitle: 'Vyberte si plán pro sebe',
		rightTitle: 'Doplňte údaje',
		email: 'Email',
		placeHolderEmail: 'Zadejte email',
		pay: 'KUPUJI A PLATÍM',
		accept: 'Souhlasím s',
		rules: 'pravidla',
		space: 'a',
		tos: 'zásadami ochrany osobních údajů ',
		payingAccept: 'Platbou souhlasíte s',
		paymentRules: '"Pravidla platby PayU"',
		savePayments: 'Bezpečné platby zajišťuje',
		total: 'Celkem',
		currency: 'czk',
		currencySign: 'Kč',
		emailGuessText: 'Mysleli jste',
		subscribe: 'Předplatit',
	},
	dailyV2: {
		conversation: {
			doYouWantContinueConversation:
				'Chcete pokračovat v konverzaci nebo začít novou?',
			continueConversation: 'Pokračovat v konverzaci',
			newConversation: 'Nová konverzace',
		},
		hint: {
			title: 'Nápověda',
			pick: 'Vyber',
		},
		feedback: {
			yourMessage: 'Vaše zpráva',
			goodMessage: 'Správná zpráva',
			explanation: 'Vysvětlení',
		},
		tutorial: {
			title: 'Jak používat chat?',
			skip: 'Přeskočit',
			next: 'DALŠÍ',
			start: 'Začít',
			screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
			config: [
				[
					{ title: 'Klikněte pro ztlumení zvuku Tutora', image: VolumeUp },
					{ title: 'Klikněte pro sdílení zpětné vazby', image: HandsUp },
					{ title: 'Klikněte pro otevření návodu', image: Tutorial },
				],
				[
					{ title: 'Klikněte pro opětovné přehrání', image: VolumeUpBlank },
					{
						title: 'Klikněte pro zobrazení překladu',
						image: TranslationBlank,
					},
					{ title: 'Klikněte pro zobrazení nápovědy', image: HintBlank },
					{ title: 'Klikněte pro nahlášení problému', image: InfoBlank },
				],
				[
					{ title: 'Vaše odpověď je správná', image: GreenCircle },
					{
						title: 'Klikněte pro zobrazení opravené odpovědi a vysvětlení',
						image: YellowCircle,
					},
				],
				[
					{ title: 'Klikněte do textového pole pro napsání zprávy ' },
					{ title: 'Klikněte pro nahrání zprávy', image: Microphone },
				],
			],
		},
	},
	problem: {
		title: 'Nahlásit problém',
		subtitle: 'Popište problém týkající se AI zprávy',
		send: 'Odeslat',
		messageSend: 'Zpráva odeslána',
		error: 'Něco se pokazilo, napište nám :)',
	},
};
