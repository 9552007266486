import { MIME_TYPES } from '../constants/mimeTypes';

export const getMimeType = () => {
  let allowedMimeType = MIME_TYPES.find((mimeTypeToCheck) =>
    MediaRecorder.isTypeSupported(mimeTypeToCheck),
  );

  if (!allowedMimeType) {
    alert('Please try to use other broswer');
    throw Error('Please try to use other broswer / Mime Type not allowed');
  }

  return allowedMimeType;
};
