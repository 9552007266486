import { getMimeType } from '@/common/functions/getMimeType';
import { Howl, Howler } from 'howler';
import { validatePath } from './validatePath';

export const voiceRunner = async (
  voice,
  name = '_INIT_',
  setVoiceTalking,
  pathToCheck,
  shouldReturnAudio = false,
) => {
  if (!validatePath(pathToCheck)) {
    return;
  }

  const blob = new Blob([new Uint8Array(voice)], { type: 'audio/mp3' });

  Howler.unload();

  const url = URL.createObjectURL(blob);
  const audio = new Howl({
    html5: true,
    src: [url],
    onpause: () => setVoiceTalking(false),
    onstop: () => setVoiceTalking(false),
    onmute: () => setVoiceTalking(false),
    onfade: () => setVoiceTalking(false),
    onend: () => setVoiceTalking(false),
    onunload: () => setVoiceTalking(false),
  });

  if (shouldReturnAudio) {
    return audio;
  }

  setVoiceTalking(name);

  if (validatePath(pathToCheck)) {
    audio.play();
  }
};
