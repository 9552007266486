import Api from '../api';
const { axios: api } = Api;

const PREFIX = 'feature';

export const loadConversationFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/load-conversation`, payload);
  return { data: data.data };
};

export const clearConversationFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/clear-conversation`, payload);
  return { data: data.data };
};

export const translateMessageFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/translate`, payload);
  return { data: data.data };
};

export const transcirpitMessageFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/transcript`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return { data: data.data };
};

export const getFeedbackFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/get-feedback`, payload);
  return { data: data.data };
};

export const checkTaskFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/check-task`, payload);
  return { data: data.data };
};

export const getHintFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/get-hint`, payload);
  return { data: data.data };
};

export const generateVoiceFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-voice`, payload);
  return { data: data.data };
};

export const conversationWorkflowFeature = async (payload) => {
  const data = await api.post(`/${PREFIX}/conversation-wrokflow`, payload);
  return { data: data.data };
};
