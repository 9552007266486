import { askChatGptProductWord, transcript } from '@/api/query/workflow';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import { TALKING_ROLES } from './TalkiMessage';
import Animation1 from '@/common/images/animation-1.gif';
import { typeWriter } from '@/common/functions/typing';
import { createConversationWord } from '@/api/query/lead-history';
import { useParams } from 'react-router-dom';
import IconCloseHints from '@/common/icons/icon-circle-chevron-down.svg';
import {
  FlexWrapper,
  HintIcon,
  HintIconWrapper,
  HintsWrapper,
  IconWrapper,
  WrapperHint,
} from './TalkingMicrofon.styles';
import { FROM_SOURCE_TYPES } from '@/common/constants/types';
import MessageBar from '@/common/components/chat/MessageBar';
import AudioRecordingContextProvider from '@/common/contexts/AudioRecordingContext';
import { voiceRunner } from '@/common/functions/voiceRunner';
import { VALID_PATHS } from '@/common/constants/validPaths';

const TalkingMicrofon = ({ setCurrent, setConversationWithChatGPT }) => {
  const { id } = useParams();
  const [showHints, setShowHints] = useState(true);
  const [conversation, setConversation] = useState();
  const [speechLasts, setSpeechLasts] = useState();
  const [hints, setHints] = useState([]);
  const { showLoading } = useContext(DemoConversationContext);

  const presenceChecker = useRef();

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcript(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;

      setConversationWithChatGPT((prev) => [
        ...prev,
        { role: TALKING_ROLES.USER, message: data },
      ]);

      const payload = {};
      payload.message = data;
      payload._id = conversation._id;
      payload.lessonId = id;
      payload.source = FROM_SOURCE_TYPES.SPEAK;

      getResponseForChatGptMutation.mutate(payload);
    },
  });

  const createLeadMutationXD = useMutation({
    mutationFn: (payload) => createConversationWord(payload),
    onSuccess: (data) => {
      setConversation(() => data);

      const messages =
        data?.customerMessages
          ?.filter((cm) => cm.role !== 'system')
          .map((cm, i) => ({
            index: i,
            role:
              cm.role === 'assistant' ? TALKING_ROLES.BOT : TALKING_ROLES.USER,
            message: cm.content,
          })) || [];

      if (messages?.length > 0) {
        setConversationWithChatGPT(() => messages);
      }

      const payload = {};
      payload._id = data._id;
      payload.lessonId = id;

      if (
        messages.length === 0 ||
        messages[messages.length - 1].role === TALKING_ROLES.USER
      ) {
        getResponseForChatGptMutation.mutate(payload);
      }
    },
  });

  useEffect(() => {
    const payload = {};
    payload.lessonId = id;
    createLeadMutationXD.mutate(payload);
  }, []);

  const getResponseForChatGptMutation = useMutation({
    mutationFn: (value) => askChatGptProductWord(value),
    onSuccess: async ({ data }) => {
      setHints(() => data.hints);
      await voiceRunner(
        data.speaking.data,
        null,
        setSpeechLasts,
        VALID_PATHS.WORD,
      );

      typeWriter({
        text: data.text,
        setText: setCurrent,
        typing: 45,
        onEnd: () => {
          setCurrent(null);
          setConversationWithChatGPT((prev, i) => [
            ...prev,
            { index: i, role: TALKING_ROLES.BOT, message: data.text },
          ]);
          setSpeechLasts(() => false);
        },
      });
    },
  });

  const handleSendMessage = (messageInput, hint = false) => {
    if (!messageInput) return;
    setHints([]);

    const message = messageInput;

    setConversationWithChatGPT((prev) => [
      ...prev,
      { role: TALKING_ROLES.USER, message: message },
    ]);

    const payload = {};
    payload.message = message;
    payload._id = conversation._id;
    payload.lessonId = id;
    payload.source = hint ? FROM_SOURCE_TYPES.HINT : FROM_SOURCE_TYPES.WRTIE;

    getResponseForChatGptMutation.mutate(payload);
  };

  const isResponding = useMemo(
    () =>
      speechLasts ||
      getResponseForChatGptMutation.isLoading ||
      transcriptMutation.isLoading,
    [
      getResponseForChatGptMutation.isLoading,
      speechLasts,
      transcriptMutation.isLoading,
    ],
  );

  const handleHints = () => {
    if (showHints) {
      return setShowHints(false);
    }

    setShowHints(true);
  };

  useEffect(() => {
    const element = document.getElementById(`bottom-info`);
    element?.scrollIntoView({ behavior: 'smooth' });
  }, [transcriptMutation.isLoading, getResponseForChatGptMutation.isLoading]);

  return (
    <>
      {transcriptMutation.isLoading && (
        <div style={{ textAlign: 'right' }}>
          <img alt="" src={Animation1} style={{ width: '40px' }} />
        </div>
      )}
      {getResponseForChatGptMutation.isLoading && (
        <div style={{ textAlign: 'left' }}>
          <img alt="" src={Animation1} style={{ width: '40px' }} />
        </div>
      )}
      {showLoading && (
        <div
          style={{
            textAlign: 'center',
            marginTop: '-40px',
            marginLeft: '-80px',
          }}
        >
          <img alt="" src={Animation1} style={{ width: '120px' }} />
        </div>
      )}
      <div ref={presenceChecker} />
      <IconWrapper>
        <FlexWrapper>
          {showHints && !isResponding && (
            <HintsWrapper>
              {!!hints?.length &&
                hints?.map((h) => (
                  <WrapperHint onClick={() => handleSendMessage(h, true)}>
                    {h}
                  </WrapperHint>
                ))}
            </HintsWrapper>
          )}
          {!!hints?.length && !isResponding && (
            <HintIconWrapper>
              <HintIcon
                alt=""
                src={IconCloseHints}
                onClick={() => handleHints()}
              />
            </HintIconWrapper>
          )}
        </FlexWrapper>
        <AudioRecordingContextProvider>
          <MessageBar
            isBlock={
              getResponseForChatGptMutation.isLoading ||
              transcriptMutation.isLoading
            }
            sendText={handleSendMessage}
            sendVoice={(data) => transcriptMutation.mutate(data)}
          />
        </AudioRecordingContextProvider>
      </IconWrapper>
    </>
  );
};

export default TalkingMicrofon;
