import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IP_STATS_ENUM } from '../enums/ipStatsEnum';
import { IpStatsContext } from '../contexts/IpStatsContext';
import Icon from '../images/microfon-white.svg';
import { useLanguageData } from '../hooks/useLanguageData';
import { AudioRecordingContext } from '../contexts/AudioRecordingContext';
import { getMimeType } from '../functions/getMimeType';

const RecordingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px 8px 20px;
  border-radius: 100px;
  color: white;
  margin-bottom: 10px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 5px;
`;

const Microfon = ({ handleSend }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const { allowMicrofon, record, recording } = useLanguageData('talkingBar');

  const { isRecording, handleStartRecording, handleStopRecording } = useContext(
    AudioRecordingContext,
  );

  const { handleSendIPstats } = useContext(IpStatsContext);

  const start = () => {
    handleStartRecording();
  };

  const stop = async () => {
    handleSendIPstats(IP_STATS_ENUM.TALKING_IN_FOTO_MODE);

    const blob = await handleStopRecording();

    if (!blob) {
      return;
    }

    const file = new File([blob], 'to_translate.mp3', {
      type: getMimeType(),
      lastModified: new Date().getTime(),
    });
    const formData = new FormData();
    formData.append('file', file);
    handleSend(formData);

    window.audioStream.getAudioTracks().forEach((track) => track?.stop());
  };

  const handleRecording = () => {
    if (isRecording) {
      stop();
      return;
    } else {
      start();
    }
  };

  return (
    <RecordingButton
      style={{
        background: isRecording
          ? 'red'
          : 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)',
        cursor: 'pointer',
      }}
      type="button"
      onClick={() => handleRecording()}
    >
      {isRecording ? recording : record}
      <img src={Icon} style={{ marginLeft: '10px' }} width={'14px'} />
    </RecordingButton>
  );
};

export default Microfon;
