import { MESSAGE_DATA_REDUCER_ACTION } from './messageDataActions';

export function messageDataReducer(state, action) {
  switch (action.type) {
    case MESSAGE_DATA_REDUCER_ACTION.REMOVE_LOADING_TRANSLATIONS:
      return {
        ...state,
        ['loadingTranslations']: state['loadingTranslations'].filter(
          (p) => p != action.value,
        ),
      };
    case MESSAGE_DATA_REDUCER_ACTION.ADD_LOADING_TRANSLATIONS:
      return {
        ...state,
        ['loadingTranslations']: [
          ...state['loadingTranslations'],
          action.value,
        ],
      };
    case MESSAGE_DATA_REDUCER_ACTION.REMOVE_SHOW_MESSAGES_TRANSLATIONS:
      return {
        ...state,
        ['showMessagesTranslations']: state['showMessagesTranslations'].filter(
          (p) => p != action.value,
        ),
      };
    case MESSAGE_DATA_REDUCER_ACTION.ADD_SHOW_MESSAGES_TRANSLATIONS:
      return {
        ...state,
        ['showMessagesTranslations']: [
          ...state['showMessagesTranslations'],
          action.value,
        ],
      };
    case MESSAGE_DATA_REDUCER_ACTION.CONVERSATION_WITH_CHAT_GPT:
    case MESSAGE_DATA_REDUCER_ACTION.TRANSLATION_MESSAGES:
    case MESSAGE_DATA_REDUCER_ACTION.RECORDINGS:
    case MESSAGE_DATA_REDUCER_ACTION.MESSAGE_SCORE:
      return { ...state, [action.type]: [...state[action.type], action.value] };
    case MESSAGE_DATA_REDUCER_ACTION.CURRENT:
    case MESSAGE_DATA_REDUCER_ACTION.HANDLE_SHOW_BAD_MESSAGE:
    case MESSAGE_DATA_REDUCER_ACTION.HINT_DATA:
    case MESSAGE_DATA_REDUCER_ACTION.LOADING_VOICE:
    case MESSAGE_DATA_REDUCER_ACTION.VOICE_TALKING:
    case MESSAGE_DATA_REDUCER_ACTION.IS_HINT_LOADING:
    case MESSAGE_DATA_REDUCER_ACTION.SHOW_PROBLEM:
    case MESSAGE_DATA_REDUCER_ACTION.TASK_COMPLETED:
      return { ...state, [action.type]: action.value };
    default:
  }
}
