import React from 'react';
import styled from 'styled-components';
import FilledHeart from '../images/filled-heart.svg';
import BlankHeart from '../images/blank-heart.svg';
import ArrRight from '../images/arr-right.svg';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const Title = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  width: 360px;
  margin-bottom: -5px;
  text-decoration: none;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const BoxWrapper = styled.div`
  display: flex;
  overflow: auto;
  scrollbar-color: transparent transparent;
  gap: 14px;
  margin-top: 14px;
`;

const Box = styled(Link)`
  position: relative; /* Ustawiamy pozycję na relative */
  width: 140px;
  height: 140px;
  border-radius: 8px; /* Zaokrąglenie krawędzi */
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  width: 140px;
  height: 140px;
  position: relative; /* Ustawiamy pozycję na relative */
  overflow: hidden;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`;

const TopTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 24.7px */
  letter-spacing: 0.4px;
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    max-width: 130px;
  }
  z-index: 1;
`;

const Subtitle = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  text-decoration: none;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2vw;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.5); /* Kolor nakładki */
  border-radius: 8px; /* Zaokrąglenie krawędzi */
`;

const Heart = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0px;
  width: 25px;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

const NewOne = styled.div`
  display: inline-flex;
  padding: 1px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: #03da9a;

  color: #29185e;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
`;

const FlashcardsDesktop = ({ data = [], liked = [], handleAddToFavorite }) => {
  const trasnaltion = useLanguageData('learn');

  return (
    <Wrapper>
      <Title to={`/learn/flashcards`}>{trasnaltion.flashcard.title} </Title>
      <NavWrapper>
        <Subtitle to={`/learn/flashcards`}>
          {trasnaltion.flashcard.subtitle}
        </Subtitle>
      </NavWrapper>
      <BoxWrapper>
        {data.slice(0, 4).map((d) => (
          <Box to={`/learn/flashcards/${d._id}`} key={d._id}>
            <ImageWrapper>
              <TopTitle>{d.name}</TopTitle>
              <img src={d?.file?.base64} alt={d.name} />
              <ImageOverlay />
              <Heart
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  handleAddToFavorite(d._id, 'flashcards_v2');
                }}
              >
                <img src={liked.includes(d._id) ? FilledHeart : BlankHeart} />
              </Heart>
            </ImageWrapper>
          </Box>
        ))}
        <Box to={`/learn/flashcards`}>
          <ImageWrapper>
            <TopTitle>
              <div>{trasnaltion.seeMore}</div>
            </TopTitle>
            <ImageOverlay
              style={{
                background: '#29185E',
              }}
            />
          </ImageWrapper>
        </Box>
      </BoxWrapper>
    </Wrapper>
  );
};

export default FlashcardsDesktop;
