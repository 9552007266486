import { findCustomer } from "@/api/query/customer";
import Loading from "@/common/components/Loading";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Daily from "./Daily";
import Talking from "../talking/Talking";

const DailyWrapper = () => {
  const [user, setUser] = useState();

  const loadCustomerMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setUser(() => data);
    },
  });

  useEffect(() => {
    loadCustomerMutation.mutate();
  }, []);

  if (!user) {
    return <Loading />;
  }

  return (
    <>
      {loadCustomerMutation.isLoading && <Loading />}
      {user && user.showDailyVersionV2 ? <Daily /> : <Talking />}
    </>
  );
};

export default DailyWrapper;
