import { handleChangeName } from '@/api/query/user';
import PopUp from '@/common/components/PopUp';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, { useContext, useRef } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const Input = styled.input`
  display: flex;
  width: 290px;
  padding: 8px 20px 8px 20px;
  align-items: center;
  border-radius: 8px;
  background: #eef2f5;

  color: #05275d;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */

  border: none;

  :hover {
    outline: none;
  }
`;

const SaveButton = styled.button`
  display: flex;
  width: 189px;
  padding: 8px 0px 8px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  margin-bottom: 10px;
`;

const Title = styled.p`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  margin: 20px 0 20px 0;
`;

const ChangeName = ({ setClose }) => {
  const top = useLanguageData('profile.top');

  const nameRef = useRef();
  const { addMessage } = useContext(MessageContext);

  const handleSaveMutation = useMutation({
    mutationFn: (data) => handleChangeName(data),
    onSuccess: () => {
      addMessage(top.nameChanged, 'success');
      setClose(false);
    },
    onError: () => {
      addMessage(top.error, 'error');
    },
  });

  const handleSave = (e) => {
    e?.preventDefault();

    const payload = {
      name: nameRef.current.value,
    };

    handleSaveMutation.mutate(payload);
  };

  return (
    <PopUp setClose={setClose}>
      <Title>{top.changeNameAndSurname}</Title>
      <Form onSubmit={handleSave}>
        <Input
          ref={nameRef}
          required
          placeholder={top.changeNameAndSurnamePlaceHolder}
        />
        <SaveButton type="submit">{top.save}</SaveButton>
      </Form>
    </PopUp>
  );
};

export default ChangeName;
