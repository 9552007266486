import React, { useContext, useEffect } from 'react';
import PopUp from '../../PopUp';
import userManager from '@/api/userManager';
import { updateUserCallModePopup } from '@/api/query/customer';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import Mobile from './image/mockup_mobile_1.2.png';
import Desktop from './image/mockup_desktop_1.20.png';
import PhoneWhite from './image/phone-white.svg';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';

const Title = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
`;
const Subtitle = styled.div`
  max-width: 300px;
  height: 34px;
  flex-shrink: 0;
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  margin-top: 12px;

  @media (min-width: 600px) {
    max-width: 480px;
  }
`;

const ImageWrapper = styled.div`
  margin: 24px 0 22px 0;
  width: 320px;

  @media (max-width: 600px) {
    width: 180px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const ButtonTop = styled.div`
  display: inline-flex;
  padding: 13px 42px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  cursor: pointer;
`;
const ButtonBottom = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-top: 12px;
  cursor: pointer;
`;

const Warpper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 28px 0 32px 0;
  max-width: 320px;

  @media (min-width: 600px) {
    max-width: 1100px;
    min-width: 80vw;
  }
`;

const ButtonWrapper = styled.div`
  @media (min-width: 600px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 136px;
  }
`;

const CallModePopUp = ({ loader }) => {
  const navigate = useNavigate();
  const { handleSendIPstats } = useContext(IpStatsContext);

  const handleSaveUserPreferencesMutation = useMutation({
    mutationFn: () => updateUserCallModePopup(),
    onSuccess: ({ data }) => {
      const user = userManager.getUser();
      user.isCallModePopUpShow = false;
      userManager.setUser(user);
      loader.mutate();
    },
  });

  useEffect(() => {
    handleSendIPstats(IP_STATS_ENUM.APP_MAIN_POPUP_NEW_CALLMODE);
  }, []);

  return (
    <PopUp closeIcon={false}>
      <Warpper>
        <Title>Nowość 🎉 w aplikacji Synthi AI!</Title>
        <Subtitle>
          Od teraz możesz dzwonić i rozmawiać z Megan używając funkcji{' '}
          <b>Telefon</b>.
        </Subtitle>
        <ImageWrapper>
          <img src={isMobile ? Mobile : Desktop} />
        </ImageWrapper>
        <ButtonWrapper>
          <ButtonTop
            onClick={() => {
              handleSaveUserPreferencesMutation.mutate();
              handleSendIPstats(IP_STATS_ENUM.APP_MAIN_POPUP_NEW_CALLMODE_CALL);
              navigate('/learn/call-mode');
            }}
          >
            ZADZWOŃ <img src={PhoneWhite} />
          </ButtonTop>
          <ButtonBottom
            onClick={() => {
              handleSendIPstats(IP_STATS_ENUM.APP_MAIN_POPUP_NEW_CALLMODE_SKIP);
              handleSaveUserPreferencesMutation.mutate();
            }}
          >
            POMIŃ
          </ButtonBottom>
        </ButtonWrapper>
      </Warpper>
    </PopUp>
  );
};

export default CallModePopUp;
