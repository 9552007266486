import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { WORD_TRAINER_STATUES } from '../WordTrainer';
import { typeWriter } from '@/common/functions/typing';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100vw;
  gap: 20px;
  margin-top: 10px;

  @media (min-width: 600px) {
    justify-content: center;
    gap: 40px;
  }
`;

const ImageWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  aspect-ratio: 1;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;
const BoxWrapper = styled.div`
  border-radius: 4px;
  background: #eef2f5;
  max-width: 200px;
  width: fit-content;
  height: fit-content;
  text-align: left;
  padding: 6px 10px;

  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  line-height: 140%; /* 22.4px */

  @media (min-width: 600px) {
    max-width: 300px;
  }
`;

const Border = styled.div`
  width: 90vw;
  height: 1px;
  background: #EEF2F5;

  @media (min-width: 600px) {
    max-width: 800px;
  }
`;

const BoxBoxWrapper = styled.div`
  min-width: 280px;
`;

const TopSectionTutor = ({ tutorImage, status }) => {
  const [boldText, setBoldText] = useState();
  const [text, setText] = useState();
  const translationData = useLanguageData('features.wordTrainer');

  const handleLoadMessage = () => {
    setText(() => null);
    setBoldText(() => null);

    let message = {
      bold: translationData.hold,
      text: translationData.holdText,
    };

    const random = Math.floor(Math.random() * 6);

    if (status === WORD_TRAINER_STATUES.BAD) {
      message = { bold: translationData.BAD_ARRAY[random] };
    }

    if (status === WORD_TRAINER_STATUES.OK) {
      message = { bold: translationData.OK_ARRAY[random] };
    }

    if (status === WORD_TRAINER_STATUES.GOOD) {
      message = { bold: translationData.GOOD_ARRAY[random] };
    }

    typeWriter({
      text: message.bold,
      setText: setBoldText,
      typing: 45,
      timeout: 0,
      onEnd: () => {
        if (message.text) {
          typeWriter({
            text: message.text,
            setText: setText,
            typing: 45,
            onEnd: () => {},
          });
        }
      },
    });
  };

  useEffect(() => {
    if (translationData) {
      handleLoadMessage();
    }
  }, [status, translationData]);

  return (
    <>
      <Wrapper>
        <ImageWrapper>
          <img src={tutorImage} />
        </ImageWrapper>
        <BoxBoxWrapper>
          <BoxWrapper>
            <b style={{ marginRight: '5px' }}>{boldText}</b>
            {text}
          </BoxWrapper>
        </BoxBoxWrapper>
      </Wrapper>
      <Border />
    </>
  );
};

export default TopSectionTutor;
