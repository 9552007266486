import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, { useContext, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import useQueryMemo from '@/common/hooks/urlMemo';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { handleEmailChange } from '@/common/helpers/handleGuessEmail';
import { generateLinkToProductPage } from '@/common/functions/generateLinkToProductPage';
import { CheckoutContext } from '@/common/contexts/checkoutContext';
import { PAYMENT_TYPES } from '@/pages/customer/paywall/components/PlanView';
import { PAYMENT_OPERATORS } from '@/common/constants/paymentOperators';
import { getUtms } from '@/common/helpers/getUtms';
import {
	EmailBox,
	InputWrapper,
	Label,
	StyledInput,
	HintMail,
	FormatMailCorrection,
	LetMailButton,
	LinkWrapper,
} from './OneTimeStripeConf.styles';
import { createOneOffPayment } from '@/api/query/payment';

const OneTimeStripeConf = ({ selectedProduct, promo, email, subscribe }) => {
	const queryMemo = useQueryMemo();
	const data = useLanguageData('register.stepThree');
	const checkoutData = useLanguageData('checkout');
	const common = useLanguageData('common');
	const { quizVersion, spVersion } = useContext(CheckoutContext);

	const { lang } = useContext(LanguageContext);
	const { addMessage } = useContext(MessageContext);
	const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);

	const [correctionEmail, setCorrectionEmail] = useState();
	const emailRef = useRef();

	const handleSubmitStripePaymentMutation = useMutation({
		mutationFn: (payload) => createOneOffPayment(payload),
		onSuccess: async ({ data }) => {
			localStorage.setItem('user_register', JSON.stringify(data.customerId));
			window.location.href = data.link;
		},
		onError: () => {
			addMessage(
				'Sth went wrong. Please contact contact@synthiaiapp.com',
				'error',
			);
		},
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (handleSubmitStripePaymentMutation?.isLoading) {
			return;
		}

		const payload = {
			lang: lang,
			type: PAYMENT_OPERATORS.STRIPE,
			plan: selectedProduct._id,
			promo: promo,
			email: emailRef?.current?.value?.toLowerCase(),
			paymentUtms: getUtms(queryMemo),
		};

		handleFbStats('InitiateCheckout', {});
		handleFbStats('AddPaymentInfo', {});

		window?.gtag('event', 'initiate_checkout', {});
		window?.gtag('event', 'add_payment_info', {});

		window?.fbq('track', 'InitiateCheckout');
		window?.fbq('track', 'AddPaymentInfo');

		window?.uetq?.push('event', 'subscribe');

		handleSendIPstats(IP_STATS_ENUM.PLAN);
		handleSendIPstats(IP_STATS_ENUM.PAY);

		if (promo) {
			if (selectedProduct.type.value === 'PREMIUM') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}6MO_PROMO`);
			}
			if (selectedProduct.type.value === 'PRO') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}3MO_PROMO`);
			}
			if (selectedProduct.type.value === 'BASIC') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}1MO_PROMO`);
			}
		} else {
			if (selectedProduct.type.value === 'PREMIUM') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}6MO`);
			}
			if (selectedProduct.type.value === 'PRO') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}3MO`);
			}
			if (selectedProduct.type.value === 'BASIC') {
				handleSendIPstats(`AD_${quizVersion}_CHECKOUT_${spVersion}1MO`);
			}
		}

		handleSubmitStripePaymentMutation.mutate(payload);
	};

	return (
		<form onSubmit={handleSubmit}>
			<EmailBox>
				<InputWrapper>
					<Label>{'Email'}</Label>
					<StyledInput
						ref={emailRef}
						id='email-SynthiAi'
						type='email'
						required
						defaultValue={email}
						placeholder={checkoutData.placeHolderEmail}
						style={{ border: 'none', padding: '8px 0px 8px 14px' }}
						onChange={(e) =>
							handleEmailChange(e.target.value, lang, setCorrectionEmail)
						}
					/>
					{correctionEmail && (
						<HintMail
							onClick={() => {
								emailRef.current.value = correctionEmail;
								setCorrectionEmail(() => null);
							}}
						>
							{checkoutData.emailGuessText}{' '}
							<FormatMailCorrection>{correctionEmail}</FormatMailCorrection> ?
						</HintMail>
					)}
				</InputWrapper>
			</EmailBox>
			<LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
				<input type='checkbox' id='rules_2' required defaultChecked />
				<label
					htmlFor='rules_2'
					style={{
						cursor: 'pointer',
						display: 'flex',
						flexWrap: 'wrap',
						gap: '3px',
						maxWidth: '300px',
						padding: '0 0 0 5px ',
					}}
				>
					{checkoutData.accept}{' '}
					<a
						href={generateLinkToProductPage(lang, common.termsLink)}
						style={{ textDecoration: 'underline' }}
					>
						{' '}
						{checkoutData.rules}
					</a>
					{checkoutData.space}
					<a
						href={generateLinkToProductPage(lang, common.privacyLink)}
						style={{
							textDecoration: 'underline',
						}}
					>
						{checkoutData.tos}
					</a>
					SynthiAi
				</label>
			</LinkWrapper>
			<LetMailButton
				type='submit'
				disabled={handleSubmitStripePaymentMutation?.isLoading}
			>
				{handleSubmitStripePaymentMutation?.isLoading && (
					<FontAwesomeIcon
						icon={faSpinner}
						spin={true}
						style={{ color: 'white' }}
					/>
				)}{' '}
				{subscribe}
			</LetMailButton>
		</form>
	);
};

export default OneTimeStripeConf;
