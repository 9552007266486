import React from 'react';
import Voice from '../images/voice.svg';
import styled, { keyframes } from 'styled-components';
import { useMutation } from 'react-query';
import { handleGetVoiceForFlashcard } from '@/api/query/flashcard-v2-user-progress';
import LoadingCircle from '@/common/components/loading/LoadingCircle';
import { mapScoreToColor } from '../helper/mapScoreToColor';
import { Howl } from 'howler';

const ImageWrapper = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 30px;

  border-radius: 84px;
  background: #f4f6f8;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  margin-top: 16px;

  @media (min-width: 600px) {
    justify-content: center;
    gap: 30px;
  }
`;

const Title = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
`;
const Subtitle = styled.div`
  color: #868686;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const WordWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  @media (min-width: 600px) {
    min-width: fit-content;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const WordSectionWrapper = styled.div`
  animation: ${fadeIn} 1s ease-in forwards;
`;

const WordSection = ({ word, result = [] }) => {
  const handleGetVoiceMutation = useMutation({
    mutationFn: (value) => handleGetVoiceForFlashcard(value),
    onSuccess: ({ data }) => {
      const audio = new Howl({
        html5: true,
        src: [data.base64],
      });
      audio?.play();
    },
  });

  const handleGetRecording = () => {
    if (handleGetVoiceMutation.isLoading) {
      return;
    }

    const payload = {
      flashcardId: word.flashcardId,
    };

    handleGetVoiceMutation.mutate(payload);
  };

  return (
    <>
      <Wrapper>
        <ImageWrapper onClick={handleGetRecording}>
          {handleGetVoiceMutation.isLoading ? (
            <LoadingCircle width="18px" height="18px" />
          ) : (
            <img src={Voice} alt="Voice" />
          )}
        </ImageWrapper>
        <WordSectionWrapper>
          <WordWrapper>
            {word?.word?.split(' ').map((w, i) => {
              return (
                <div>
                  <Title
                    style={{
                      color:
                        result?.length > 0
                          ? mapScoreToColor(result[i].score)
                          : '#29185e',
                    }}
                  >
                    {w}
                  </Title>
                </div>
              );
            })}
          </WordWrapper>
          <Subtitle style={{ marginTop: result.length ? '10px' : '0px' }}>
            {word?.translation}
          </Subtitle>
        </WordSectionWrapper>
        <ImageWrapper style={{ background: 'white' }} />
      </Wrapper>
    </>
  );
};

export default WordSection;
