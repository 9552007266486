import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Correct from '../audio/correct-short.mp3';
import Wrong from '../audio/wrong.mp3';
import { Howl } from 'howler';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { PROCESS_STATUS } from '../constants/process-status';
const PairsTitle = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;
const CardsWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 40px;
`;
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const BoxWrapper = styled.div`
  display: flex;
  padding: 14px 0px 12px 0px;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 18px;
  border-radius: 8px;
  background: ${(props) => props.palete?.background};
  color: ${(props) => props.palete?.color};
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;
const Pairs = ({ data, setIndex }) => {
  const translation = useLanguageData('learn.flashcardV2');
  const [pairs, setPairs] = useState({
    left: [],
    right: [],
  });
  const [picked, setPicked] = useState({ left: null, right: null });
  const [dontDisplay, setDontDisplay] = useState([]);
  const generateUniqueNumbers = () =>
    Array.from({ length: 8 }, (_, i) => i)
      .sort(() => Math.random() - 0.5)
      .slice(0, 6);
  const generatePairs = () => {
    const numbers = generateUniqueNumbers();
    const selectedData = data.filter((_, i) => numbers.includes(i));
    const result = {
      left: [...selectedData].sort((_a, _b) => (Math.random() > 0.5 ? 1 : -1)),
      right: [...selectedData].sort((_a, _b) => (Math.random() > 0.5 ? 1 : -1)),
    };
    setPairs(() => result);
  };
  const getColorPalet = (status) => {
    if (status === PROCESS_STATUS.SUCCESS) {
      return {
        color: '#FFF',
        background: '#32951B',
        animation: 'fade-out',
      };
    }
    if (status === PROCESS_STATUS.WRONG) {
      return {
        color: '#FFF',
        background: '#E9574A',
        animation: 'shake',
      };
    }
    if (status === PROCESS_STATUS.SELECTED) {
      return {
        color: '#FFF',
        background: 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)',
        animation: '',
      };
    }
    if (status === PROCESS_STATUS.HIDE) {
      return {
        color: '#FFF',
        background: '#FFF',
        animation: '',
      };
    }
    return {
      color: '#29185E',
      background: '#F4F6F8',
      animation: '',
    };
  };
  const handleSelect = (word, side) => {
    if (picked?.left && picked?.right) {
      return;
    }
    let toSet = { ...picked, [side]: word, status: PROCESS_STATUS.SELECTED };
    if (toSet.left && toSet.right) {
      if (toSet.left._id === toSet.right._id) {
        setPicked(() => ({
          ...toSet,
          status: PROCESS_STATUS.SUCCESS,
        }));
        const audio = new Howl({
          src: [Correct],
        });
        audio?.play();
        setTimeout(() => {
          setPicked((prev) => {
            setDontDisplay((prevDont) => [...prevDont, prev.left._id]);
            return {};
          });
        }, 500);
      } else {
        setPicked(() => ({
          ...toSet,
          status: PROCESS_STATUS.WRONG,
        }));
        const audio = new Howl({
          src: [Wrong],
        });
        audio?.play();
        setTimeout(() => {
          setPicked(() => {
            return {};
          });
        }, 1000);
      }
      return;
    }
    return setPicked(() => ({
      ...toSet,
    }));
  };
  useEffect(() => {
    generatePairs();
  }, []);
  useEffect(() => {
    if (dontDisplay.length === 6) {
      setIndex((prev) => prev + 1);
    }
  }, [dontDisplay]);
  return (
    <>
      <PairsTitle>{translation.metchWords}</PairsTitle>
      <CardsWrapper>
        <SectionWrapper>
          {pairs?.left.map((l) => {
            const isCurrent = picked?.left?._id === l._id;
            const stauts = isCurrent ? picked.status : PROCESS_STATUS.NONE;
            const isInDisplayNone = dontDisplay.includes(l._id);
            if (isInDisplayNone) {
              return (
                <BoxWrapper palete={getColorPalet(PROCESS_STATUS.HIDE)}>
                  XD
                </BoxWrapper>
              );
            }
            return (
              <BoxWrapper
                className={getColorPalet(stauts)?.animation}
                palete={getColorPalet(stauts)}
                onClick={() => handleSelect(l, 'left')}
              >
                {l.flashcard[0].word}
              </BoxWrapper>
            );
          })}
        </SectionWrapper>
        <SectionWrapper>
          {pairs?.right.map((l) => {
            const isCurrent = picked?.right?._id === l._id;
            const stauts = isCurrent ? picked.status : PROCESS_STATUS.NONE;
            const isInDisplayNone = dontDisplay.includes(l._id);
            if (isInDisplayNone) {
              return (
                <BoxWrapper palete={getColorPalet(PROCESS_STATUS.HIDE)}>
                  {''}
                </BoxWrapper>
              );
            }
            return (
              <BoxWrapper
                className={getColorPalet(stauts)?.animation}
                palete={getColorPalet(stauts)}
                onClick={() => handleSelect(l, 'right')}
              >
                {l.flashcard[0]?.flashcard_v2_translation[0]?.word}
              </BoxWrapper>
            );
          })}
        </SectionWrapper>
      </CardsWrapper>
    </>
  );
};
export default Pairs;
