import { styled } from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 18px;
  background-color: white;
  height: 100vh;
  overflow-y: auto;

  @media (min-width: 600px) {
    margin: 0 auto;
  }
`;

export const Logo = styled.img`
  width: 95px;
  height: 31px;

  @media (max-width: 599px) {
    display: none;
  }
`;

export const Card = styled.form`
  width: 370px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  gap: 36px;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 0px;
  margin: 0 auto;

  @media (min-height: 530px) {
    align-items: center;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    width: unset;
  }

  @media (min-width: 600px) {
    background-color: #fff;
    width: 100%;
    flex-grow: 1;
    gap: 80px;
    max-width: 1200px;
    padding: 18px;
    box-sizing: border-box;
  }
`;

export const RobotWrapper = styled.div`
  width: 18%;
  position: absolute;
  left: -84px;
  top: 50%;
  transform: translateY(-70%);

  @media (max-width: 599px) {
    display: none;
  }
`;

export const LoginDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  position: relative;
  width: 60%;
`;

export const Title = styled.h3`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  max-width: 286px;
  margin: 10px auto;

  @media (max-width: 599px) {
    text-align: center;
  }
`;

export const Span = styled.span`
  color: #5a5a5a;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  max-width: 280px;
`;

export const Label = styled.label`
  color: #868686;

  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  gap: 8px;
  position: relative;
`;

export const Input = styled.input`
  display: inline-flex;
  padding: 8px 10px 8px 10px;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: #fff;
  color: #29185e;
  width: 260px;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  border: 1px solid #29185e;

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;

    + div {
      color: #05275d;
    }
  }
`;

export const EyeIconWrapper = styled.div`
  font-size: 16px;
  position: absolute;
  right: 14px;
  bottom: 8px;
  cursor: pointer;

  @media (min-width: 600px) {
  }
`;

export const SubmitButton = styled.button`
  display: inline-flex;
  padding: 14px 60px 14px 60px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #650675;
  justify-content: center;
  flex-shrink: 0;
  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  width: 280px;
  margin-top: 40px;

  &:hover {
    color: #650675;
    background: #fff;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin: 0;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-top: 20px;
`;

export const RegisterLink = styled.a`
  color: #650675;
  font-family: Work Sans;
  font-size: 18px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
`;
