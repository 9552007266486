import { TALKING_ROLES } from '../constants/talkingRoles';

export const formatInitMessages = (messages) => {
  return messages
    ?.filter((cm) => cm.role !== 'system')
    .map((cm, i) => ({
      index: i,
      role: cm.role === 'assistant' ? TALKING_ROLES.BOT : TALKING_ROLES.USER,
      message: cm.content,
    }));
};
