import PopUp from '@/common/components/PopUp';
import React from 'react';

const TaskPopUp = ({ close, data }) => {
  return (
    <PopUp setClose={close}>
      <h2>Super fajnie to dziala!</h2>
      <h3>
        {data.map((d) => (
          <div style={{ display: 'flex', gap: '20px' }}>
            <div>Completed Task Value:</div>
            <div>{d.task_value}</div>
          </div>
        ))}
      </h3>
    </PopUp>
  );
};

export default TaskPopUp;
