import React, { useState } from 'react';
import styled from 'styled-components';
import IconClose from '@/common/images/group.svg';
import IconRight from '@/common/images/arr-right.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import InformationBlank from '@/common/images/daily/information-blank.svg';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import ProblemView from '@/common/components/customer/ProblemView';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(41, 24, 94, 0.41);
  backdrop-filter: blur(8px);
  z-index: 90;
`;

const WhiteBox = styled.div`
  height: fit-content;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: #f4f6f8;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 12px 0 12px;
`;

const MiddleContent = styled.div`
  padding: 12px;
  margin: 20px 12px;
  text-align: left;
  background: #fff;
`;

const Title = styled.div`
  color: #868686;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
`;

const Text = styled.div`
  color: #29185e;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  margin-top: 12px;
`;

const TranslationText = styled.div`
  color: #868686;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  margin-top: 4px;
`;

const TopBox = styled.div`
  border-radius: 8px;
  background: #c4c4c4;
  width: 97px;
  height: 4px;
  flex-shrink: 0;
`;

const CloseIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: -2px;
  margin-top: 8px;
`;

const Button = styled.button`
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );

  color: #fff;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 12.35px */
  letter-spacing: 0.2px;
`;

const TopContentInside = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HintBoxMessage = ({ data, setClose, handleProccessHint }) => {
  const [messageProblem, setMessageProblem] = useState();

  const { title, pick } = useLanguageData('dailyV2.hint');

  return (
    <>
      <Wrapper>
        <div style={{ flex: 1 }} />
        <WhiteBox>
          <TopContent>
            <div style={{ width: '12px' }} />
            <div />
            <CloseIcon src={IconClose} onClick={() => setClose(() => null)} />
          </TopContent>
          <MiddleContent>
            <TopContentInside>
              <Title>{title}</Title>
              <CloseIcon
                src={InformationBlank}
                onClick={() => setMessageProblem(() => data?.hint_result)}
              />
            </TopContentInside>
            <Text>{data?.hint_result}</Text>
            <TranslationText>{data?.hint_translation}</TranslationText>
            <ButtonWrapper>
              <Button onClick={() => handleProccessHint()}>
                {pick} <img src={IconRight} />
              </Button>
            </ButtonWrapper>
          </MiddleContent>
        </WhiteBox>
      </Wrapper>
      {messageProblem && (
        <ProblemView
          chatMessage={messageProblem}
          setShowProblem={setMessageProblem}
          type={SOURCES_TYPES.DAILY_V2}
          hint={true}
        />
      )}
    </>
  );
};

export default HintBoxMessage;
