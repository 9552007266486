import { jaroWinkler } from '../algorithms/jaroWinker';
import { getPopularDomainsByLanguage } from './getPopularDomainsByLanguage';

export const handleEmailChange = (value, lang, setCorrectionEmail) => {
	const ALL_DOMAINS = getPopularDomainsByLanguage(lang);

	if (value.includes('@')) {
		const [username, userDomain] = value.split('@');

		if (userDomain.length < 2) {
			setCorrectionEmail(() => null);
			return value;
		}

		if (!ALL_DOMAINS.includes(userDomain)) {
			let bestMatchDomain = ALL_DOMAINS[0];
			let highestSimilarity = 0;

			for (const domain of ALL_DOMAINS) {
				const similarity = jaroWinkler(userDomain, domain.split('.')[0]);
				if (similarity > highestSimilarity) {
					highestSimilarity = similarity;
					bestMatchDomain = domain;
				}
			}

			const correctedEmail = `${username}@${bestMatchDomain}`;
			setCorrectionEmail(() => correctedEmail);

			return value;
		}

		setCorrectionEmail(() => null);
		return value;
	}

	setCorrectionEmail(() => null);
	return value;
};
