import Android1 from './images/Android_1_150_RO.png';
import Android2 from './images/Android_Dodaj_150_RO.png';
import Ios1 from './images/IOS_1_150_RO.png';
import Ios2 from './images/IOS_Dodaj_150_RO.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

import Micro from './images/wordTrainerTutorial/icon-microphone.png';
import Voice from './images/wordTrainerTutorial/volume-up.svg';
import Person from './images/wordTrainerTutorial/user.svg';

export default {
	passwordRestart: {
		title: 'Resetează parola',
		password: 'Parolă',
		repeatPassword: 'Repetă parola',
		restartPawssord: 'Resetează parola',
	},

	paywall: {
		title: 'Alegeți un plan pentru a continua să învățați',
		buy: 'Cumpără',
		savePayment: 'Plăți sigure gestionate de',
		skip: 'Omite',
		pickPlan: 'Alegeți un plan',
		endPlanFirstPart: 'Accesul dvs. se va încheia în',
		endPlanSecondPart: 'zile.',
		endPlanAditionalPart: 'zi.',
		paymentTitle: 'Rezumat',
		product: 'Produs:',
		plan: 'Pachet SynthiAi',
		forLong: 'Acces pentru',
		data: 'date:',
		nameAndSurname: 'Nume și Prenume:',
		paymentForm: 'Metodă de Plată:',
		toPay: 'De plătit azi:',
		accept: 'Accept',
		rules: 'termenii',
		payingAccept: 'Prin plata acceptați',
		paymentRules: '"Termenii de plată PayU"',
		buyAndPay: 'CUMPĂR ȘI PLĂTESC',
	},
	paymentStatus: {
		veryfication: 'Plata este încă în curs de procesare...',
		title: 'Plata finalizată',
		success: 'cu succes!',
		failure: 'eșuat!',
		subtitle: 'În curând veți fi redirecționat către pagina de conectare.',
		textInfo: `Plata dumneavoastră a fost procesată cu succes. Acum puteți seta parola accesând pagina noastră de înregistrare. În câteva minute, veți primi un e-mail cu un link care vă va permite să setați parola.

Vă rugăm să verificați căsuța de e-mail, inclusiv folderul de spam. Pentru a facilita căutarea, introduceți „synthiai” în câmpul de căutare. Dacă aveți probleme, vă rugăm să ne contactați la: contact@synthiaiapp.com`,
		login: 'Mergi la autentificare',
	},
	register: {
		steps: {
			register: 'Înregistrare',
			package: 'Pachet',
			summarize: 'Rezumat',
			step: 'Pasul ',
		},
		stepOne: {
			password: 'Parolă',
			errorGoogle: 'Conectați-vă cu e-mailul dvs.',
			title: 'Înregistrare',
			nameAndSurname: 'Prenume și Nume',
			placeholderNameAndSurname: 'ex. Adam Smith',
			email: 'E-mail',
			placeholderEmail: 'ex. exemplu@synthiaiapp.com',
			password: 'Parolă',
			start: 'CREAȚI CONTUL',
			subtitle:
				'Parola trebuie să aibă cel puțin 8 caractere și să conțină litere, majuscule și cifre.',
			passwordError1: 'Parola trebuie să conțină cel puțin 8 caractere.',
			passwordError2: 'Parola trebuie să conțină cel puțin 1 literă mare.',
			passwordError3: 'Parola trebuie să conțină cel puțin 1 literă mică.',
			passwordError4: 'Parola trebuie să conțină cel puțin 1 cifră.',
			passwordError5: 'Parolele trebuie să fie identice.',
		},
		stepTwo: {
			title: 'Selectați planul dvs.',
			month: 'Lună',
			months: 'Luni',
			months2: 'Luni',
			value: '€/Lună',
			pick: 'alege',
			nowPay: 'Plătiți acum',
			saveOne: 'Economisiți',
			buy: 'Cumpără',
			savePayment: 'Plăți sigure gestionate de',
		},
		stepThree: {
			title: 'Rezumat',
			product: 'Produs:',
			plan: 'Pachetul Synthi Ai',
			forLong: 'Acces pentru',
			data: 'Date:',
			nameAndSurname: 'Prenume și Nume:',
			paymentForm: 'Metoda de plată:',
			toPay: 'De plătit astăzi:',
			accept: 'Accept',
			rules: 'Regulile',
			payingAccept: 'Prin plata acceptați',
			paymentRules: '"Politicile de plată PayU"',
			buyAndPay: 'CUMPĂRĂ ȘI PLĂTEȘTE',
		},
	},
	firstLogin: {
		createPasswordForAcoount: 'Creează o parolă pentru contul tău',
		typeEmail: 'Introduceți adresa de e-mail',
		useSameEmail:
			'Utilizați aceeași adresă pe care ați furnizat-o la cumpărare',
		typePassword: 'Introduceți parola',
		confirmPassword: 'Confirmați parola',
		createPassword: 'Creează parolă',
		errorLogin: 'Verificați dacă parola sau adresa de e-mail sunt corecte',
		createdPassword: 'Ați creat deja o parolă?',
		clickToLogin: 'Faceți clic aici pentru a vă conecta',
	},
	login: {
		dontHavePassword: 'Nu ai încă o parolă?',
		getPassword: 'Click aici pentru a obține accesul',
		title: 'Autentificare',
		email: 'E-mail',
		password: 'Parolă',
		textSub: 'Ați uitat parola? Resetați-o',
		restart: 'aici',
		restartPasswordText:
			'Introduceți adresa dvs. de e-mail pentru a vă reseta parola. Parola va fi trimisă la adresa dvs. de e-mail.',
		placeholder: 'Introduceți adresa dvs. de e-mail...',
		resetPassword: 'Resetați parola',
		buttonText: 'Autentificare',
		errorLogin: 'Verificați dacă parola sau e-mailul dvs. sunt corecte',
		passwordResterted:
			'Parola dvs. a fost resetată, verificați-vă e-mailurile.',
		passwordError: 'Din păcate, nu am putut găsi adresa dvs. de e-mail.',
		mobileAppBannerText: 'Descoperă aplicația noastră!',
	},
	navBar: {
		home: 'Start',
		learn: 'Învățare',
		profile: 'Profil',
	},
	home: {
		hello: 'Bună',
		question: 'Despre ce doriți să vorbiți astăzi?',
		answer: 'Începeți o conversație',
		forYou: 'Recomandat pentru dvs.',
		popularNow: 'Popular acum',
		doYouKnow: 'Știați că...?',
		funFact: 'Faptul zilei',
		new: 'Nou',
		check: 'Verifică',
		yourFavorite: 'Favoritele dvs.',
		newTitle: 'Antrenor de cuvinte',
		newSubtitle: 'Exersează pronunția cuvintelor',
		talkWith: 'Vorbește despre orice subiect cu',
	},
	learn: {
		wordTrainer: {
			next: 'Următorul',
			yourSpeaking: 'Pronunția ta',
			you: 'Tu',
			title: 'Antrenor de cuvinte',
			errorMessage:
				'Ups! Avem o problemă cu procesarea înregistrării tale. Încearcă din nou :)))',
			endScreenTitle: 'Minunat! 🌟🌟🌟',
			endScreenTopInfo: 'Ai stăpânit toate',
			endScreenMiddleInfo: 'cuvintele',
			endScreenBottomInfo: 'la acest nivel.',
			endScreenText: 'Poți începe din nou sau schimba nivelul din setări.',
			endScreenBottomText: 'Începe din nou',
			endScreenEndText: 'Finalizează',
			sesionScoreTitle: 'Te descurci grozav! 🎉',
			sesionScoreSubTitle: 'Îți îmbunătățești pronunția',
			sesionScoreWord: 'Cuvinte',
			now: 'Acum',
			sum: 'total',
			continue: 'CONTINUĂ',
			areYouSureToEndLesson: 'Ești sigur că vrei să închei lecția?',
			speakingScore: 'Scorul pronunției',
			GOOD_ARRAY: [
				'Bravo!',
				'Perfect!',
				'Ești un campion!',
				'Excelent!',
				'Uimitor!',
				'Genial!',
			],
			BAD_ARRAY: [
				'Nu te descuraja!',
				'Poți reuși!',
				'Mai aproape!',
				'Continuă să repeți, aproape ai reușit!',
				'În curând vei reuși!',
				'Nu e nicio problemă, încearcă din nou!',
			],
			OK_ARRAY: [
				'Ești pe drumul cel bun!',
				'Foarte aproape!',
				'Din ce în ce mai bine!',
				'Progresezi!',
				'Felicitări pentru efort!',
			],
			hold: 'Ține apăsat',
			holdText: 'microfonul și spune:',
			tutorial: {
				title: 'Cum să folosești Antrenorul de cuvinte?',
				options: [
					{
						label: 'Apasă pentru a asculta pronunția corectă',
						icon: Micro,
					},
					{
						label: 'Ține apăsat și pronunță cuvântul',
						icon: Voice,
					},
					{
						label:
							'Apasă și ascultă-ți înregistrarea pentru a stăpâni perfect cuvântul!',
						icon: Person,
					},
				],
				close: 'ÎNCHIDE',
			},
		},

		wordTrainer: {
			title: 'Antrenor de cuvinte',
			subtitle: 'Exersează pronunția cuvintelor',
			new: 'Nou',
		},
		flashcardV2: {
			new: 'Nou',
			sport: 'Sport',
			continue: 'continuă',
			nice: 'Minunat!',
			congratulation: 'Felicitări!',
			category: 'Categorie',
			textAfterCategory: 'nu are secrete pentru tine!',
			pickNewCategory: 'Alege o categorie nouă',
			showResult: 'Arată soluția',
			createWordWithLetters: 'Creează un cuvânt din litere amestecate',
			know: 'Știu',
			dontKnow: 'Nu știu',
			metchWords: 'Potrivește în perechi',
			addNewCategory: 'Adaugă o categorie nouă',
			personalizeLearning:
				'Personalizează-ți învățarea creând propria categorie.',
			typeNewCategory: 'Introdu numele categoriei tale',
			save: 'Salvează',
			weAreCreatingCategoryForYou: 'Creăm o nouă categorie pentru tine:',
			start: 'START',
			howToUseFlashcard: 'Cum să folosești fișele?',
			knowFlashcard:
				'Fă clic pe „Nu știu” sau „Știu” pentru a evalua cunoștințele tale.',
			dontKnowFlashcard: 'Fișele marcate cu „Știu” nu vor fi repetate.',
			clickToHear: 'Fă clic pentru a auzi cuvântul.',
			clickForDefinition: 'Fă clic pentru a vedea definiția',
			clickForTranslation: 'Fă clic pentru a vedea traducerea și exemplele',
			close: 'Închide',
			clickCategoryToStart:
				'Fă clic pe categoria selectată pentru a începe învățarea.',
			lookAsProgressBar:
				'Bara de progres de sub categorie arată câte cuvinte ai stăpânit deja.',
			clickToSetupFavaroiteFlashcard:
				'Fă clic pentru a marca categoria ca preferată.',
			clickToCreateOwnCategory:
				'Fă clic pentru a crea propria categorie de fișe.',
			howLearningLookLike: 'Cum arată învățarea?',
			restText:
				' Învățarea constă în revizuirea fișelor și evaluarea cunoștințelor tale. În plus, vei rezolva sarcini simple.',
		},
		chat: {
			title: 'Chat',
			subtitle: 'Discutați despre orice subiect.',
		},
		fotoMode: {
			title: 'Puzzle foto',
			subtitle: 'Descrieți imaginea cât mai detaliat posibil.',
		},
		scene: {
			title: 'Scene',
			subtitle: 'Jucați un rol cu un tutor AI.',
		},
		word: {
			title: 'Cuvinte',
			subtitle: 'Extindeți-vă vocabularul.',
		},
		flashcard: {
			title: 'Fișe',
			subtitle: 'Repetați și memorați cuvinte.',
		},
		seeMore: 'Vezi tot',
	},
	common: {
		description:
			'Synthi AI - Asistentul dvs. inteligent pentru învățarea limbii engleze. Învățați să vorbiți fluent în engleză, cu ajutorul inteligenței artificiale. Descoperiți o nouă abordare eficientă a învățării limbilor străine. Începeți chiar astăzi!',
		deletedFromFavorite: 'Eliminat din favorite',
		addedToFavorite: 'Adăugat la favorite',
		flashcards: 'Fișe',
		flashcards_v2: 'Fișe',
		scenes: 'Scene',
		words: 'Cuvinte',
		or: 'sau',
		continueWithFB: 'Continuă cu Facebook',
		privacyLink: 'privacy',
		termsLink: 'tos',
	},
	profile: {
		days: ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sâ', 'Du'],
		top: {
			profile: 'Profil',
			accessTo: 'Acces la:',
			profileImage: 'Imagine profil',
			nameAndSurname: 'Prenume și Nume',
			password: 'Parolă',
			change: 'Schimbă',
			changeProfileImage: 'Schimbă imaginea de profil',
			changeNameAndSurname: 'Schimbați prenumele și numele dvs.',
			changeNameAndSurnamePlaceHolder: 'Introduceți prenumele și numele dvs.',
			save: 'Salvează',
			changePassword: 'Schimbați parola',
			passwordInfo:
				'Parola trebuie să aibă cel puțin 8 caractere și să conțină litere, majuscule și cifre.',
			repeatPassword: 'Repetați parola',
			passwordChanged: 'Parola a fost schimbată',
			error: 'Ups, ceva a mers greșit',
			nameChanged: 'Numele a fost schimbat',
			profileChanged: 'Profilul a fost schimbat',
		},
		plan: {
			subInfo:
				'pentru a anula abonamentul trimiteți un e-mail la contact@synthiaiapp.com',
			yourPlan: 'Planul dvs.',
			extend: 'Prelungiți planul dvs.',
			short_extend: 'Prelungiți',
			currentPlan: 'Planul actual:',
			activeToPlan: 'Activ până la:',
			currentPlanLong: 'Planul dvs. actual',
		},
		middle: {
			yourMedals: 'Medaliile dvs.',
			oneDay: 'Zi',
			twoDays: 'Zile',
			words: 'Cuvinte',
			learningTime: 'Timp de învățare',
			streak: 'Serie',
			streakRecord: 'Record de serie',
			yourRecord: 'Recordul dvs.:',
			currentRecord: 'Stadiul actual:',
			words: 'Cuvinte',
			wordsInWeek: 'Număr de cuvinte pe săptămână:',
		},
		bottom: {
			yourTutor: 'Tutorul dvs. AI',
			change: 'Schimbă',
			logout: 'Deconectare',
			FAQ: 'Întrebări frecvente',
			shareOpinion: 'Împărtășiți opinia dvs.',
			yourPlan: 'Planul dvs.',
			hobby: 'Interese',
			learningSetting: 'Setări de învățare',
			back: 'Înapoi',
			dayTarget: 'Obiectiv zilnic',
			languageLevel: 'Nivel de limbă',
			changeYourLangLevel: 'Schimbați nivelul dvs. de limbă.',
			pickNewTutor: 'Alegeți un nou tutor',
			yourDecision: 'Decizia dvs. va influența stilul și atmosfera lecțiilor.',
			tutroChanged: 'Tutorul a fost schimbat',
			error: 'Ups, ceva a mers greșit',
			englishLevelChanged: 'Nivelul dvs. a fost schimbat',
			levelsOptions: [
				{
					name: 'Începător A1',
					description: 'Începeți aventura dvs. cu limba engleză',
					value: 'basic',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Începător A2',
					description:
						'Puteți înțelege expresii de bază în conversații simple.',
					borderRadius: '0',
					value: 'beginner',
				},
				{
					name: 'Intermediar B1',
					description:
						'Purtați conversații simple, dar inteligibile pe teme familiare.',
					borderRadius: '0',
					value: 'intermediate',
				},
				{
					name: 'Avansat B2',
					description: 'Comunicați liber în majoritatea situațiilor',
					borderRadius: '0',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description: 'Stăpâniți limba eficient în contexte complexe.',
					value: 'expert',
					borderRadius: '0 0 8px 8px ',
				},
			],
			changeDailyTarget: 'Schimbați obiectivul dvs. zilnic de învățare.',
			deletedFromFavorite: 'Eliminat din favorite',
			addedToFavorite: 'Adăugat la favorite',
			lessonTime: [
				{
					name: 'Lecție rapidă',
					dsecription: 'Opțiune perfectă pentru învățare rapidă',
					time: '5 minute',
					value: 'five_minutes',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Sesiune standard',
					dsecription: 'Alegere excelentă pentru învățare echilibrată',
					time: '10 minute',
					value: 'ten_minutes',
					borderRadius: '0',
				},
				{
					name: 'Antrenament intens',
					dsecription: 'Cea mai bună opțiune pentru entuziaști',
					time: '15 minute',
					value: 'fifteen_minutes',
					borderRadius: '0 0 8px 8px ',
				},
			],
			dailyTargetChanged: 'Obiectiv zilnic schimbat!',
			pickIntresets: 'Alegeți cel puțin 3 interese.',
			activeTo: 'Plan activ până la:',
			daysLeft: 'Zile rămase în plan:',
			opinionSubtitle:
				'Îmbunătățim constant aplicația noastră. Spuneți-ne ce putem schimba în aplicație sau ce v-a plăcut cel mai mult.',
			startTyping: 'Scrieți opinia dvs.',
			faq: 'Întrebări frecvente',
			send: 'Trimiteți',
			withProblems: 'Pentru întrebări/probleme contactați-ne la:',
			contactMail: 'contact@synthiaiapp.com',
			faqConfig: [
				{
					id: 1,
					question: 'Sunetul meu nu funcționează.',
					answer: `Dacă aveți probleme cu sunetul, vă rugăm să urmați aceste sfaturi: 
      
              Verificați permisiunile de sunet în browser.
      
              Asigurați-vă că volumul dispozitivului dvs. este corespunzător.
      
              Verificați conexiunea corectă a difuzoarelor/căștilor.
      
              Verificați setările de sunet ale sistemului pentru a vă asigura că aplicațiile nu sunt mute.
      
              Încercați un alt dispozitiv sau contactați suportul tehnic.
          `,
				},
				{
					id: 2,
					question: 'Nu îmi pot înregistra vocea.',
					answer: `Dacă aveți probleme cu înregistrarea audio, vă rugăm să urmați aceste sfaturi: 
              
              Verificați permisiunile pentru microfon în browser.
      
              Asigurați-vă că browser-ul mobil are acces la microfon.
      
              Verificați conexiunea corectă a microfonului.
      
              Folosiți un alt dispozitiv sau contactați suportul tehnic.
          `,
				},
				{
					id: 3,
					question: 'Pot folosi aplicația pe mai multe dispozitive?',
					answer: `Desigur, autentificați-vă pe fiecare dispozitiv compatibil pentru a vă bucura de o experiență de învățare fără întreruperi.`,
				},
				{
					id: 4,
					question: 'Pot folosi Synthi Ai offline?',
					answer: `Nu, Synthi Ai necesită un browser web cu acces la internet.`,
				},
				{
					id: 5,
					question: 'Cum îmi schimb tutorul AI?',
					answer: `Mergeți la "Tutor" în setările Synthi Ai. Descoperiți și alegeți tutorul preferat.`,
				},
				{
					id: 6,
					question: 'Cum îmi pot prelungi accesul după expirare?',
					answer: `Vă puteți reînnoi planul după expirare, autentificându-vă.`,
				},
				{
					id: 7,
					question: 'Pot primi o factură?',
					answer:
						'Contactați-ne la contact@synthiaiapp.com și furnizați codul dvs. fiscal și datele companiei.',
				},
				{
					id: 8,
					question: 'Cum pot contacta suportul tehnic?',
					answer:
						'Trimiteți un e-mail la contact@synthiaiapp.com și furnizați detalii despre problemă, precum și informații despre dispozitiv și browser.',
				},
			],
		},
		rules: 'Reguli',
		privacy: 'Politica de confidențialitate',
		span: ' și ',
	},
	welcome: {
		languagePicker: {
			title: 'Alege limba pe care dorești să o înveți',
			next: 'Înainte',
			langToLearn: 'Limba de învățare',
			successTitle: 'Limba de învățare a fost schimbată',
			config: [
				{
					label: 'Engleză',
					label2: 'Engleză',
					labelInside: 'engleză',
					value: 'en',
				},
				{
					label: 'Germană',
					label2: 'Germană',
					labelInside: 'germană',
					value: 'de',
				},
				{
					label: 'Spaniolă',
					label2: 'Spaniolă',
					labelInside: 'spaniolă',
					value: 'es',
				},
				{
					label: 'Franceză',
					label2: 'Franceză',
					labelInside: 'franceză',
					value: 'fr',
				},
				{
					label: 'Italiană',
					label2: 'Italiană',
					labelInside: 'italiană',
					value: 'it',
				},
			],
		},
		zeroScreen: {
			welcomeInApp: 'Bun venit în aplicația Synthi AI!',
			start: 'SĂ ÎNCEPEM',
			welcomeMobile: 'Bun venit',
			config: {
				chrome: {
					title: 'Instalați aplicația pe dispozitivul dvs. Android.',
					text1: 'Apăsați pe butonul',
					text1bold: ' Setări',
					image1: Android1,
					text2: 'Apoi apăsați pe',
					text2bold: ' Adăugați pe ecranul de start',
					image2: Android2,
					text3: 'Gata! Synthi AI este acum pe ecranul dvs. de start.',
					image3: Last,
				},
				safari: {
					title: 'Instalați aplicația pe dispozitivul dvs. iOS.',
					text1: 'Apăsați pe butonul',
					text1bold: ' Distribuie',
					image1: Ios1,
					text2: 'Apoi apăsați pe',
					text2bold: ' Adăugați pe ecranul de start',
					image2: Ios2,
					text3: 'Gata! Synthi AI este acum pe ecranul dvs. de start.',
					image3: Last,
				},
			},
		},
		firstScreen: {
			title: 'Alegeți tutorul dvs.!',
			subtitle: 'Decizia dvs. va influența stilul și atmosfera lecțiilor.',
			next: 'CONTINUAȚI',
		},
		secondScreen: {
			title: 'Care este nivelul dvs. actual de {language}?',
			config: [
				{
					name: 'Începător A1',
					description: 'Începeți aventura dvs. cu limba {language}.',
					value: 'basic',
				},
				{
					name: 'Începător A2',
					description:
						'Puteți înțelege informații de bază în conversații simple, de zi cu zi.',
					value: 'beginner',
				},
				{
					name: 'Intermediar B1',
					description:
						'Purtați conversații simple, dar inteligibile pe teme familiare.',
					value: 'intermediate',
				},
				{
					name: 'Avansat B2',
					description: 'Comunicați liber în majoritatea situațiilor',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description: 'Stăpâniți limba eficient în contexte complexe.',
					value: 'expert',
				},
			],
			next: 'CONTINUAȚI',
		},
		thirdScreen: {
			title: 'Care sunt interesele dvs.?',
			subtitle: 'Alegeți cel puțin 3 interese.',
			next: 'CONTINUAȚI',
		},
		fourthScreen: {
			title: 'Câte minute pe zi doriți să învățați?',
			subtitle: 'Alegeți obiectivul dvs. zilnic de învățare.',
			next: 'SĂ ÎNCEPEM!',
			config: [
				{
					name: 'Lecție rapidă',
					dsecription: 'Opțiune perfectă pentru învățare rapidă',
					time: '5 minute',
					value: 'five_minutes',
				},
				{
					name: 'Sesiune standard',
					dsecription: 'Alegere excelentă pentru învățare echilibrată',
					time: '10 minute',
					value: 'ten_minutes',
				},
				{
					name: 'Antrenament intens',
					dsecription: 'Cea mai bună opțiune pentru entuziaști',
					time: '15 minute',
					value: 'fifteen_minutes',
				},
			],
		},
	},
	features: {
		topbar: {
			back: 'Înapoi',
		},
		fotoMode: {
			welcome: {
				title: 'Puzzle foto',
				subtitle:
					'Descrieți ce vedeți în imagine, cu cât mai multe detalii pe un subiect ales.',
				start: 'Începeți!',
			},
			info: {
				startTyping: 'Scrieți...',
				newTask: 'Sarcină nouă',
				send: 'Trimiteți',
			},
		},
		flashcards: {
			title: 'Fișe',
			subtitleTop:
				'Învățați și memorați cuvinte noi prin metode interactive de învățare cu fișe.',
			addCategory: 'Adăugați categoria dvs.',
			subtitle: 'Introduceți numele categoriei dvs.',
			type: 'introduceți categoria...',
			add: 'Adăugați',
			previous: 'Fișa anterioară',
			task: 'Sarcină',
			changeLevel: 'Schimbați nivelul',
			dontKnow: 'Nu știu',
			know: 'Știu',
			pickPairs: 'Formează perechi',
			goodJob: 'Bună treabă!',
			titleEnd: 'Angajamentul dvs. este impresionant',
			subtitleEnd: 'Sunteți pregătit pentru alte provocări?',
			repeat: 'Repetați categoria',
			other: 'Alegeți o altă categorie',
			level: {
				title: 'Schimbați nivelul fișelor',
				beginner: 'Începător A1-A2',
				intermediate: 'Intermediar B1-B2',
				advance: 'Expert C1-C2',
				change: 'Schimbați',
			},
		},
		scene: {
			title: 'Scene',
			subtitle:
				'Intrați în rol și jucați o scenă cu un tutor AI pe un subiect ales.',
			suggest: 'Sugerați o scenă nouă',
			talk: 'Vorbiți',
			deletedFromFavorite: 'Eliminat din favorite',
			addedToFavorite: 'Adăugat la favorite',
		},
		word: {
			title: 'Cuvinte',
			subtitle:
				'Extindeți-vă vocabularul în domenii cheie ale conversației cu un tutor AI.',
			suggest: 'Sugerați un nou subiect de cuvinte',
			deletedFromFavorite: 'Eliminat din favorite',
			addedToFavorite: 'Adăugat la favorite',
		},
	},
	cookie: {
		title: 'Notificare privind cookie-urile',
		subtitle:
			'Acest site folosește cookie-uri pentru a vă oferi o experiență mai bună și pentru a oferi conținut personalizat. Dacă doriți să aflați mai multe, citiți ',
		link: 'Politica noastră de confidențialitate',
		accept: 'ACCEPT',
		denied: 'REFUZ',
	},
	talkingBar: {
		allowMicrofon: 'Vă rugăm să permiteți accesul la microfon',
		typeMessage: 'Scrieți un mesaj...',
		record: 'Înregistrare',
		recording: 'Înregistrare în curs',
	},
	hands: {
		giveUsInfo: 'Împărtășiți-ne opinia dvs.',
		thanksForGrading: 'Vă mulțumim pentru evaluare!',
		subtitle: 'Opinia dvs. este importantă pentru noi!',
		subtitle2: 'Spuneți-ne ce părere aveți despre această lecție:',
		skip: 'OMITEȚI',
		send: 'TRIMITEȚI',
	},
	suggestConfig: {
		profile: {
			title: 'Împărtășiți opinia dvs.!',
			subtitle:
				'Îmbunătățim constant aplicația noastră. Spuneți-ne ce putem schimba în aplicație sau ce v-a plăcut cel mai mult.',
		},
		word: {
			title: 'Sugerați un nou subiect de cuvinte',
			subtitle: '',
		},
		scene: {
			title: 'Sugerați o scenă nouă',
			subtitle: '',
		},
		startTyping: 'Începeți să scrieți',
		send: 'TRIMITEȚI',
		thx: 'Opinia a fost adăugată! Mulțumim!',
		error: 'Ups, ceva a mers greșit',
	},
	checkout: {
		emailGuessText: 'Ați vrut să spuneți',
		leftTitle: 'Alegeți un plan pentru dvs.',
		rightTitle: 'Completați datele',
		email: 'Email',
		placeHolderEmail: 'Introduceți emailul',
		pay: 'CUMPĂR ȘI PLĂTESC',
		accept: 'Accept',
		rules: 'regulamentul',
		space: 'și',
		tos: 'politica de confidențialitate',
		payingAccept: 'Prin plată, acceptați',
		paymentRules: '"Regulile de plată PayU"',
		savePayments: 'Plăți sigure asigurate de',
		total: 'Total',
		currency: 'ron',
		currencySign: 'Lei',
		subscribe: 'Abona',
	},
	dailyV2: {
		conversation: {
			doYouWantContinueConversation:
				'Doriți să continuați conversația sau să începeți una nouă?',
			continueConversation: 'Continuă conversația',
			newConversation: 'Conversație nouă',
		},
		hint: {
			title: 'Sfat',
			pick: 'Alege',
		},
		feedback: {
			yourMessage: 'Mesajul tău',
			goodMessage: 'Mesaj corect',
			explanation: 'Explicație',
		},
		tutorial: {
			title: 'Cum să folosești chat-ul?',
			skip: 'Sari peste',
			next: 'URMĂTOR',
			start: 'Începe',
			screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
			config: [
				[
					{
						title: 'Apasă pentru a dezactiva sunetul Tutorului',
						image: VolumeUp,
					},
					{ title: 'Apasă pentru a oferi feedback', image: HandsUp },
					{
						title: 'Faceți clic pentru a deschide instrucțiunile',
						image: Tutorial,
					},
				],
				[
					{ title: 'Apasă pentru a reda din nou', image: VolumeUpBlank },
					{
						title: 'Apasă pentru a vedea traducerea',
						image: TranslationBlank,
					},
					{ title: 'Apasă pentru a vedea sfatul', image: HintBlank },
					{ title: 'Apasă pentru a raporta o problemă', image: InfoBlank },
				],
				[
					{ title: 'Răspunsul tău este corect', image: GreenCircle },
					{
						title: 'Apasă pentru a vedea răspunsul corectat și explicația',
						image: YellowCircle,
					},
				],
				[
					{ title: 'Apasă în câmpul de text pentru a scrie un mesaj' },
					{ title: 'Apasă pentru a înregistra un mesaj', image: Microphone },
				],
			],
		},
	},
	problem: {
		title: 'Raportați o problemă',
		subtitle: 'Descrieți problema referitoare la mesajul AI',
		send: 'Trimite',
		messageSend: 'Mesaj trimis',
		error: 'Ceva nu a mers bine, scrieți-ne :)',
	},
};
