import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ArrRightPurple from '../components/images/arr-right-p.svg';

const WrapperWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 2;

	top: 108px;
	width: 100vw;
	left: -0.25vw;
`;

const Wrapper = styled(Link)`
	height: 140px;
	text-decoration: none;
	margin-top: 20px;
	display: flex;
	align-items: center;
	width: 96%;
  max-width: 1192px;
	background: ${({ gradient }) =>
		gradient ? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)' : '#EEF2F5'};
	justify-content: center;
`;

const ImageWrapper = styled.div`
	position: relative;
	width: 80px;

	img {
		width: 100%;
		height: auto;
	}
`;

const Point = styled.div`
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	border-radius: 39px;
	background: #01b07c;
	position: absolute;
	bottom: 22px;
	right: 0px;
`;

const TopMessage = styled.div`
	width: 330px;
	height: 46px;
	flex-shrink: 0;
	position: relative;

	border-radius: 10px 10px 10px 0px;
	background: white;
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0 20px;

	color: var(--Text-color, #29185e);
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 123.5%; /* 19.76px */
	letter-spacing: 0.32px;
`;

const BottomMessage = styled(Link)`
	display: flex;
	width: 229px;
	padding: 10px 18px;
	justify-content: center;
	align-items: center;
	gap: 12px;
	text-decoration: none;
	border-radius: 27px;
	background: ${({ white }) =>
		white ? 'white' : 'linear-gradient(10deg, #48068a 10%, #4322c6 65%)'};
	color: ${({ white }) => (white ? '#29185e' : '#fff')};
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%; /* 15.4px */

	float: right;
	margin-top: 20px;
`;

const TutorName = styled.div`
	color: ${({ white }) => (white ? 'white' : '#29185e')};
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%; /* 15.4px */
`;

const HourWrapper = styled.div`
	position: absolute;
	right: 10px;
	bottom: 5px;

	color: #868686;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%; /* 13.2px */
`;

const TalkWithDesktopFixed = ({ tutor, translation }) => {
	return (
		<WrapperWrapper>
			<Wrapper to='/learn/chat' gradient>
				<ImageWrapper>
					<img src={tutor?.icon} />
					<Point />
					<TutorName white>{tutor?.name || 'Megan'}</TutorName>
				</ImageWrapper>
				<div style={{ margin: '0 20px', width: '360px' }}>
					<TopMessage>
						{translation.question}
						<HourWrapper>{moment().format('HH:mm')}</HourWrapper>
					</TopMessage>
					<BottomMessage to='/learn/chat' white>
						{translation.answer} <img src={ArrRightPurple} />
					</BottomMessage>
				</div>
			</Wrapper>
		</WrapperWrapper>
	);
};

export default TalkWithDesktopFixed;
