import React, { useContext, useEffect, useRef, useState } from 'react';
import { Wrapper } from './Home.styles';
import styled from 'styled-components';
import Loading from '@/common/components/Loading';
import useQueryMemo from '@/common/hooks/urlMemo';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import TopBar from './components/TopBar';
import TalkWith from './components/TalkWith';
import ForYouSection from './components/ForYouSection';
import News from './components/News';
import Popular from './components/Popular';
import NewSection from './components/NewSection';
import Favorite from './components/Favorite';
import TalkWithDesktop from './desktop/TalkWithDesktop';
import userManager from '@/api/userManager';
import ForYouSectionDesktop from './desktop/ForYouSectionDesktop';
import NewsDesktop from './desktop/NewsDesktop';
import NewSectionsDesktop from './desktop/NewSectionsDesktop';
import PopularDesktop from './desktop/PopularDesktop';
import FavoriteDesktop from './desktop/FavoriteDesktop';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { CustomerDataContext } from '@/common/components/layout/productLayout/context/CustomerDataContext';
import TalkWithFixed from './components/TalkWithFixed';
import TalkWithDesktopFixed from './desktop/TalkWithDesktopFixed';

const CurrentWrapper = styled(Wrapper)`
	@media (min-width: 600px) {
		gap: 0px;
		padding-top: 30px;
		padding-bottom: 30px;
		overflow-y: auto;
	}

	@media (max-width: 600px) {
		overflow-y: auto;
	}
`;

const Mobile = styled.div`
	@media (min-width: 800px) {
		display: none;
	}
`;

const Desktop = styled.div`
	@media (max-width: 800px) {
		display: none;
	}
`;

const TextWrapper = styled.div`
	color: #29185e;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 28px */
`;

const FIXED_TALKWITH_APPEAR_Y = 80;
const FIXED_TALKWITH_FULL_VISIBLE_Y = 120;

const Home = () => {
	const queryMemo = useQueryMemo();
	const translation = useLanguageData('home');
	const [fixedTalkWithOpacity, setFixedTalkWithOpacity] = useState(0);

	const { manageFavoriteMutation, user, userMutation, userData } =
		useContext(CustomerDataContext);
	const { handleSendIPstats } = useContext(IpStatsContext);

	useEffect(() => {
		if (queryMemo.get('utm_source')) {
			if (queryMemo.get('utm_source') === 'mail_lastcall') {
				handleSendIPstats(IP_STATS_ENUM.MAIL_BEFORE_EXPIRED);
			} else {
				handleSendIPstats(IP_STATS_ENUM.MAIL_UP_SALE_BACK);
			}
		}

		window.document.title = 'Synthi Ai - Home';
	}, []);

	const handleRemoveFromFavorite = (id, type) => {
		const payload = {
			_id: id,
			type,
			shouldAdd: false,
		};

		manageFavoriteMutation.mutate(payload);
	};

	const scrollContainer = useRef(null);

	useEffect(() => {
		const currentScrollContainer = scrollContainer.current;

		const handleScroll = () => {
			if (currentScrollContainer.scrollTop < FIXED_TALKWITH_APPEAR_Y) {
				setFixedTalkWithOpacity(0);
			} else if (
				currentScrollContainer.scrollTop < FIXED_TALKWITH_FULL_VISIBLE_Y
			) {
				setFixedTalkWithOpacity(
					(currentScrollContainer.scrollTop - FIXED_TALKWITH_APPEAR_Y) /
						(FIXED_TALKWITH_FULL_VISIBLE_Y - FIXED_TALKWITH_APPEAR_Y),
				);
			} else {
				setFixedTalkWithOpacity(1);
			}
		};

		currentScrollContainer.addEventListener('scroll', handleScroll);

		return () => {
			currentScrollContainer.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<>
			{userMutation.isLoading && <Loading />}
			<CurrentWrapper ref={scrollContainer} id='home'>
				<Mobile>
					{userData?.tests?.fixedTalkWith && (
						<TalkWithFixed
							opacity={fixedTalkWithOpacity}
							icon={user?.tutorialIcon}
							translation={translation}
							tutorName={userData?.speaker?.name}
						/>
					)}

					<TopBar numberTwo={user?.topStats?.words} numberOne={user?.streak} />

					<TalkWith icon={user?.tutorialIcon} translation={translation} />

					<ForYouSection
						data={user?.recomended || []}
						translation={translation}
					/>
					<NewSection translation={translation} tutor={user?.tutor} />
					<Popular data={user?.popular || []} translation={translation} />
					<News news={user?.news} translation={translation} />
					<Favorite
						data={user?.favoriteArr || []}
						handleRemoveFromFavorite={handleRemoveFromFavorite}
						translation={translation}
					/>
				</Mobile>
				<Desktop>
					<TextWrapper>
						{translation.hello} {userManager?.getUser()?.name}!
					</TextWrapper>
					{userData?.tests?.fixedTalkWith && fixedTalkWithOpacity > 0 && (
						<TalkWithDesktopFixed
							tutor={user?.tutor}
							translation={translation}
						/>
					)}
					<TalkWithDesktop tutor={user?.tutor} translation={translation} />
					<ForYouSectionDesktop
						data={user?.recomended || []}
						translation={translation}
					/>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							gap: '32px',
						}}
					>
						<NewsDesktop news={user?.news} translation={translation} />
						<NewSectionsDesktop translation={translation} tutor={user?.tutor} />
					</div>
					<PopularDesktop
						data={user?.popular || []}
						translation={translation}
					/>
					<FavoriteDesktop
						data={user?.favoriteArr || []}
						handleRemoveFromFavorite={handleRemoveFromFavorite}
						translation={translation}
					/>
				</Desktop>
			</CurrentWrapper>
		</>
	);
};

export default Home;
