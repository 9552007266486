import React, { useMemo } from "react";
import { styled } from "styled-components";
import VoiceBlank from "@/common/images/daily/volume-up-blank.svg";
import VoiceFiled from "@/common/images/daily/volume-up-fill.svg";

import TranslationBlank from "@/common/images/daily/icon-translate-blank.svg";
import TranslationFiled from "@/common/images/daily/icon-translate-filed.svg";
import IdeaBlank from "@/common/images/daily/idea-blank.svg";
import InformationBlank from "@/common/images/daily/information-blank.svg";
import LoadingCircle from "@/common/components/loading/LoadingCircle";

export const ContentTranslationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  margin-top: 10px;
`;

const InsideContantWrapper = styled.div`
  max-width: 650px;
  width: fit-content;
  border-radius: 8px 8px 8px 0px;
  background: #edeffe;
  padding: 14px;

  @media (max-width: 600px) {
    padding: 12px;
    max-width: 78vw;
  }
`;

export const MessageWrapper = styled.div`
  color: #29185e;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  white-space: pre-wrap;
`;

const IconsWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

const IconInsideWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TranslationMessageWrapper = styled.div`
  color: var(--Daily---icons-in-bubble-speach, #8477af);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  white-space: pre-wrap;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #8477af;
  margin: 8px 0;
`;

const BotMessageTalking = ({
  id,
  message,
  messageData: {
    translationMessages,
    showMessagesTranslations,
    loadingTranslations,
    loadingVoice,
    voiceTalking,
    isHintLoading,
  },
  handlers,
  handleGetTranlationForBot,
  handleGetVoice,
  handleGetHint,
  isLastMessage
}) => {
  const helper = () => showMessagesTranslations.includes(`bot-message-${id}`);
  const translationLoading = useMemo(() => {
    if (loadingTranslations?.includes(`bot-message-${id}`)) {
      return true;
    }
    return false;
  }, [loadingTranslations?.length]);

  const voiceLoading = useMemo(() => {
    if (loadingVoice === `bot-message-${id}`) {
      return true;
    }
    return false;
  }, [loadingVoice]);

  const currentVoiceTalking = useMemo(() => {
    if (voiceTalking === `bot-message-${id}`) {
      return true;
    }
    return false;
  }, [voiceTalking]);

  return (
    <>
      <ContentTranslationWrapper id={`bot-message-${id}`}>
        <InsideContantWrapper>
          <MessageWrapper>{message}</MessageWrapper>
          <IconsWrapper>
            <IconInsideWrapper>
              {voiceLoading ? (
                <LoadingCircle />
              ) : (
                <Icon
                  src={currentVoiceTalking ? VoiceFiled : VoiceBlank}
                  onClick={() => handleGetVoice(`bot-message-${id}`, message)}
                />
              )}
              {translationLoading ? (
                <LoadingCircle />
              ) : (
                <Icon
                  src={helper() ? TranslationFiled : TranslationBlank}
                  onClick={() =>
                    handleGetTranlationForBot(`bot-message-${id}`, message)
                  }
                />
              )}
              {isLastMessage ? (
                isHintLoading ? (
                  <LoadingCircle />
                ) : (
                  <Icon
                    src={IdeaBlank}
                    onClick={() => handleGetHint(`bot-message-${id}`, message)}
                  />
                )
              ) : (
                <></>
              )}
            </IconInsideWrapper>
            <IconInsideWrapper>
              <Icon
                src={InformationBlank}
                onClick={() => handlers.setShowProblem(message)}
              />
            </IconInsideWrapper>
          </IconsWrapper>
          {translationMessages.find((f) => f.name === `bot-message-${id}`)
            ?.data &&
            helper() && (
              <>
                <Border />
                <TranslationMessageWrapper className="fade__in__animation">
                  {
                    translationMessages.find(
                      (f) => f.name === `bot-message-${id}`
                    )?.data
                  }
                </TranslationMessageWrapper>
              </>
            )}
        </InsideContantWrapper>
      </ContentTranslationWrapper>
    </>
  );
};

export default BotMessageTalking;
