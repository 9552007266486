import React, { useMemo } from 'react';
import { TextInformationWrapper } from './TalkiMessage';
import styled from 'styled-components';

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => (props.isOkay ? '#32951B' : '#F5BC2F')};
`;

export const TextInfomation = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 10px;
  background: #eef2f5;
  border-radius: 8px 8px 0px 8px;
  padding: 14px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  max-width: 600px;
  color: #29185e;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  margin-right: 10px;

  @media (max-width: 600px) {
    max-width: 300px;
  }
`;

const UserMessage = ({
  message,
  userMessageId,
  messageScore,
  handleShowBadMessage,
}) => {
  const score = useMemo(() => {
    const ms = messageScore.find((f) => f.userMessageId === userMessageId);

    if (ms) {
      return ms;
    }

    return null;
  }, [messageScore]);

  const handleShowInfo = () => {
    if (score?.score) {
      return;
    }

    handleShowBadMessage(score);
  };

  return (
    <TextInformationWrapper>
      <TextInfomation onClick={handleShowInfo}>
        <div>{score && <Circle isOkay={!!score.score} />}</div>
        <div>{message}</div>
      </TextInfomation>
    </TextInformationWrapper>
  );
};

export default UserMessage;
