import React, { useContext, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import TrashIcon from '../images/icons/trash.svg';
import SendIcon from '@/common/icons/send.svg';
import MicrofonIcon from '@/common/icons/mikrofon.svg';
import { KEYS_CODES } from '@/common/constants/keyCodes';
import Timer from './Timer';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { AudioRecordingContext } from '@/common/contexts/AudioRecordingContext';
import { getMimeType } from '@/common/functions/getMimeType';

const MessageBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    align-self: center;
    width: 95%;
  }
`;

const Input = styled.input`
  height: 40px;
  box-sizing: border-box;
  z-index: 3;
  display: flex;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background: #eef2f5;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    padding: 8px 10px;
    font-size: 12px;
    height: 40px;
  }

  &::placeholder {
    font-size: 14px;
  }
`;

const Button = styled.button`
  min-width: 100px;
  height: ${(prev) => prev.dekstop};
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  animation: unset;
  display: flex;
  justify-content: center;

  :hover {
    transform: unset !important;
  }

  @media (max-width: 600px) {
    min-width: 60px;
    height: ${(prev) => prev.mobile};
  }

  img {
    width: auto;
    height: 100%;
  }
`;

const RecordingBar = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
  padding-left: 30px;
  border-radius: 8px;
  background: #EEF2F5;

  @media (max-width: 600px) {
    width: 60%;
    height: 36px;
    font-size: 12px;
  }
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: red;
  width: 10px;
  height: 10px;
  animation: ${blinkAnimation} 1s infinite;
`;

const STATES = {
  BASE: 'base',
  RECORDING: 'recording',
  TYPING: 'typing',
};

const MessageBar = ({ isBlock = false, sendVoice, sendText }) => {
  const { typeMessage } = useLanguageData('talkingBar');
  const messageRef = useRef();
  const [status, setStatus] = useState(STATES.BASE);

  const { handleStartRecording, handleStopRecording } = useContext(
    AudioRecordingContext,
  );

  const handleStart = () => {
    handleStartRecording();
    setTimeout(() => {
      setStatus(() => STATES.RECORDING);
    }, 500);
  };

  const handleStop = async (cancel = false) => {
    const blob = await handleStopRecording();
    setStatus(() => STATES.BASE);

    if (cancel || !blob) {
      return;
    }

    const file = new File([blob], 'to_translate.mp3', {
      type: getMimeType(),
      lastModified: new Date().getTime(),
    });

    window?.audioStream?.getAudioTracks()?.forEach((track) => track?.stop());
    sendVoice(file);
  };

  const handleSendMessage = () => {
    if (status === STATES.TYPING) {
      sendText(messageRef.current.value);

      messageRef.current.value = '';
    }

    if (status === STATES.RECORDING) {
      handleStop();
    }

    setStatus(() => STATES.BASE);
  };

  const handleTyping = () => {
    const text = messageRef?.current?.value || '';
    if (text.length === 0) {
      return setStatus(() => STATES.BASE);
    }
    return setStatus(() => STATES.TYPING);
  };

  const onEnterPress = (e) => {
    if (e.keyCode === KEYS_CODES.ENTER && e.shiftKey === false) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <MessageBarWrapper>
      {[STATES.RECORDING].includes(status) && (
        <Button
          onClick={() => handleStop(true)}
          mobile={'24px'}
          dekstop={'28px'}
        >
          <img src={TrashIcon} alt="send" />
        </Button>
      )}
      {[STATES.BASE, STATES.TYPING].includes(status) ? (
        <Input
          onKeyDown={onEnterPress}
          ref={messageRef}
          placeholder={typeMessage}
          onChange={handleTyping}
          disabled={isBlock}
        />
      ) : (
        <RecordingBar>
          <Circle />
          <Timer />
        </RecordingBar>
      )}
      {[STATES.RECORDING, STATES.TYPING].includes(status) ? (
        <Button onClick={handleSendMessage} mobile={'30px'} dekstop={'35px'}>
          <img src={SendIcon} alt="send" />
        </Button>
      ) : (
        <Button
          onClick={handleStart}
          disabled={isBlock}
          mobile={'25px'}
          dekstop={'28px'}
        >
          <img src={MicrofonIcon} alt="mic" style={{width: 33, height: 33}} />
        </Button>
      )}
    </MessageBarWrapper>
  );
};

export default MessageBar;
