import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Hands from '../../../../common/components/customer/Hands';
import { Link } from 'react-router-dom';
import { useLanguageData } from '../../../../common/hooks/useLanguageData';
import Tutorial from '@/assets/images/tutorial_color.svg';
import Problem from '../images/problem-icon.svg';

import NewHands from '../../../../common/components/customer/NewHands';

const TitleLessonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 98%;
  padding: 5px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const TitleLessonWrapperMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }
`;

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #868686;
  text-decoration: none;
  width: 240px;

  @media (max-width: 600px) {
    width: 100px;
    margin-left: 10px;
    color: rgba(5, 39, 93, 1);
  }
`;

const TextBack = styled.p`
  margin: 0;
  text-decoration: none;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;

  @media (max-width: 600px) {
    display: none;
  }
`;
const Text = styled.h3`
  color: #29185e;

  font-family: Work Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;
  margin: 0;

  @media (max-width: 600px) {
    color: #29185e;
    font-weight: 500;
    font-size: 20px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ImageWrapper = styled.div`
  width: 44px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
`;

const TopTitleHeaderWordTrainer = ({
  title = '',
  source = '',
  navigate = '/learn',
  background,
  setShowPopUpTutorial,
  setShowPopUpProblem,
  setSessionEnd,
  forWhat,
}) => {
  const { back } = useLanguageData('features.topbar');

  return (
    <>
      <TitleLessonWrapper>
        <BackWrapper onClick={() => setSessionEnd(true)}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '12px' }} />
          <TextBack>{back}</TextBack>
        </BackWrapper>
        <Text>{title}</Text>
        <div style={{ display: 'flex', gap: '15px', marginRight: '10px' }}>
          <Hands type={source} forWhat={forWhat} />
          <ImageWrapper
            onClick={() => {
              setShowPopUpTutorial(() => true);
            }}
            style={{ width: '20px', borderRadius: '0' }}
          >
            <img
              src={Tutorial}
              style={{
                height: '20px',
              }}
            />
          </ImageWrapper>
          <ImageWrapper
            onClick={() => {
              setShowPopUpProblem(() => true);
            }}
            style={{ width: '20px', borderRadius: '0' }}
          >
            <img
              src={Problem}
              style={{
                height: '20px',
              }}
            />
          </ImageWrapper>
        </div>
      </TitleLessonWrapper>
      <TitleLessonWrapperMobile background={background}>
        <BackWrapper
          onClick={() => setSessionEnd(true)}
          style={{ width: '20px' }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackWrapper>
        <div style={{ display: 'flex', gap: '15px', marginRight: '10px' }}>
          <NewHands type={source} forceNotBlankHands={true} forWhat={forWhat} />
          <ImageWrapper
            onClick={() => {
              setShowPopUpTutorial(() => true);
            }}
            style={{ width: '20px', borderRadius: '0' }}
          >
            <img
              src={Tutorial}
              style={{
                height: '20px',
              }}
            />
          </ImageWrapper>
          <ImageWrapper
            onClick={() => {
              setShowPopUpProblem(() => true);
            }}
            style={{ width: '20px', borderRadius: '0' }}
          >
            <img
              src={Problem}
              style={{
                height: '20px',
              }}
            />
          </ImageWrapper>
        </div>
      </TitleLessonWrapperMobile>
    </>
  );
};

export default TopTitleHeaderWordTrainer;
