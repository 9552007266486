export const mapScoreToColor = (score) => {
  if (score <= 20) {
    return '#E9574A';
  }

  if (score <= 65) {
    return '#FF8C01';
  }

  return '#32951B';
};
