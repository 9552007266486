import styled from 'styled-components';

export const Label = styled.label`
	color: #868686;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 16.8px */
`;

export const Input = styled.input`
	margin-top: 4px;
	display: flex;
	padding: 8px 10px 8px 10px;
	width: 260px;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #29185e;
	background: #fff;

	&:hover {
		outline: none;
	}
	&:focus {
		outline: none;
	}
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	height: 100vh;

	@media (min-width: 600px) {
		flex-direction: row;
		overflow: hidden;
	}
`;

export const LetMailButton = styled.button`
	display: inline-flex;
	padding: 9px 82px 10px 82px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #fff;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	margin: 5px 0;

	@media (min-width: 600px) {
		margin-top: 60px;
	}
`;

export const LeftInfo = styled.div`
	@media (min-width: 600px) {
		width: 50vw;
		margin-top: 35px;
	}
`;

export const Title = styled.h3`
	color: var(--Text-color, #29185e);
	font-family: 'Work Sans';
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-align: center;

	@media (min-width: 600px) {
		text-align: left;
		width: 320px;
		margin: 20px auto;
	}
`;

export const TitleDesktop = styled(Title)`
	display: none;

	@media (min-width: 600px) {
		display: flex;
	}
`;

export const PriceWrapper = styled.div`
	/* Hide the default radio button */
	input[type='radio'] {
		display: none;
	}

	/* Style the custom radio button */
	.custom-radio {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.custom-radio-button {
		width: 10px; /* Adjust size as needed */
		height: 10px;
		border: 1px solid #c1c1c1;
		border-radius: 50%;
		background: white; /* Background color */
		display: inline-block;
		position: relative;
		transition: background 0.3s;
	}

	input[type='radio']:checked + .custom-radio-button::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 10px; /* Inner dot size */
		height: 10px;
		border-radius: 50%;
		background: linear-gradient(
			10deg,
			#48068a 10%,
			#4322c6 65%
		); /* Color of the inner dot */
		transform: translate(-50%, -50%);
	}

	input[type='radio']:focus + .custom-radio-button,
	input[type='radio']:hover + .custom-radio-button {
		box-shadow: 0 0 0 2px rgba(115, 11, 219, 0.3);
	}
`;

export const SynthiAiLogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 16px 0 0 0;

	@media (min-width: 600px) {
		justify-content: left;
		width: 320px;
		margin: 16px auto;
	}
`;

export const PlanName = styled.div`
	color: var(
		--Highlight-text,
		linear-gradient(10deg, #48068a 10%, #4322c6 65%)
	);
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 22.4px */
`;

export const SaveWrapper = styled.div`
	color: ${(props) => (props.promo ? '#149069' : 'var(--pink, #e94f96)')};
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
`;

export const PriceInfoWrapper = styled.div`
	border-radius: 8px;
	background: #f4f6f8;
	width: 280px;
	margin: 6px 0px 16px 0;
	padding: 10px;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

export const TextWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Border = styled.div`
	width: 272px;
	height: 1px;
	background: #e0e0e0;
`;

export const Text = styled.div`
	color: var(--Text-color, #29185e);
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
`;

export const EmailBox = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background: #f4f6f8;
	border-radius: 12px;
	min-height: 80px;
	height: fit-content;
	width: 320px;
	margin: -10px auto 0 auto;

	@media (max-width: 600px) {
		min-height: 100px;
		margin-top: 20px;
	}
`;

export const ActionWrapper = styled.form`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin: 0px 0;

	@media (max-width: 600px) {
		flex-wrap: wrap;
	}

	@media (min-width: 600px) {
		padding-top: 5vh;
		flex-wrap: wrap;
		width: 50vw;
		background: #f4f6f8;
		justify-content: unset;
	}
`;

export const LinkWrapper = styled.div`
	color: #05275d;

	a {
		color: #05275d;
	}

	text-align: center;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media (min-width: 600px) {
		margin-top: 8px;
	}

	/* Hide the default checkbox */
	input[type='checkbox'] {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 15px;
		height: 15px;
		border: 2px solid #29185e;
		border-radius: 4px;
		outline: none;
		cursor: pointer;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}

	/* Style the checkbox when checked */
	input[type='checkbox']:checked {
		background-color: #29185e;
	}

	/* Create the checkmark using a pseudo-element */
	input[type='checkbox']:checked::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 4px;
		height: 8px;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	input[type='checkbox']:focus {
		box-shadow: 0 0 3px #29185e;
	}
`;

export const StyledInput = styled(Input)`
	@media (min-width: 600px) {
		border: 1px solid #29185e;
		color: #29185e;
		width: 300px;
	}
`;

export const HintMail = styled.div`
	float: right;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;

	margin-top: -5px;
	padding-top: 5px;
	padding-left: 10px;
	min-height: 30px;
	height: fit-content;
	width: 302px;
	flex-shrink: 0;
	border-radius: 0px 0px 16px 16px;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	cursor: pointer;
	gap: 5px;
	text-align: left;

	color: #fff;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: left;
	z-index: 3;

	@media (max-width: 600px) {
		width: 263px;
	}
`;

export const FormatMailCorrection = styled.div`
	color: #fff;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
`;
