import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
	height: 100vh;

	@media (min-width: 600px) {
		flex-direction: row;
		overflow: hidden;
	}
`;

export const LeftInfo = styled.div`
	@media (min-width: 600px) {
		width: 50vw;
		margin-top: 35px;
	}
`;

export const Title = styled.h3`
	color: var(--Text-color, #29185e);
	font-family: 'Work Sans';
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-align: center;

	@media (min-width: 600px) {
		text-align: left;
		width: 320px;
		margin: 20px auto;
	}
`;

export const TitleDesktop = styled(Title)`
	display: none;

	@media (min-width: 600px) {
		display: flex;
	}
`;

export const PriceWrapper = styled.div`
	input[type='radio'] {
		display: none;
	}

	.custom-radio {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.custom-radio-button {
		width: 10px;
		height: 10px;
		border: 1px solid #c1c1c1;
		border-radius: 50%;
		background: white;
		display: inline-block;
		position: relative;
		transition: background 0.3s;
	}

	input[type='radio']:checked + .custom-radio-button::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
		transform: translate(-50%, -50%);
	}

	input[type='radio']:focus + .custom-radio-button,
	input[type='radio']:hover + .custom-radio-button {
		box-shadow: 0 0 0 2px rgba(115, 11, 219, 0.3);
	}
`;

export const SynthiAiLogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 16px 0 0 0;

	@media (min-width: 600px) {
		justify-content: left;
		width: 320px;
		margin: 16px auto;
	}
`;

export const PlanName = styled.div`
	color: var(
		--Highlight-text,
		linear-gradient(10deg, #48068a 10%, #4322c6 65%)
	);
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 22.4px */
`;

export const SaveWrapper = styled.div`
	color: var(--pink, #e94f96);
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
`;

export const PriceInfoWrapper = styled.div`
	border-radius: 8px;
	background: #f4f6f8;
	width: 280px;
	margin: 6px 0px 16px 0;
	padding: 10px;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

export const TextWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Border = styled.div`
	width: 272px;
	height: 1px;
	background: #e0e0e0;
`;

export const Text = styled.div`
	color: var(--Text-color, #29185e);
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
`;

export const Text2 = styled(Text)`
	font-size: 10px;
	text-align: right;
	width: 100%;
	margin-top: -8px;
`;

export const ActionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 0px 0;
	background-color: white;

	@media (max-width: 600px) {
		flex-wrap: wrap;
	}

	@media (min-width: 600px) {
		padding-top: 5vh;
		flex-wrap: wrap;
		width: 50vw;
		background: #f4f6f8;
		justify-content: unset;
	}
`;
