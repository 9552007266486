import {
  AddToFavorite,
  AddToFavoriteWithFullResponse,
  findCustomer,
  findUserInfoForDashboard,
  findUserInfoForLearn,
} from '@/api/query/customer';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import * as Sentry from '@sentry/react';

export const CustomerDataContext = createContext();

const CustomerDataContextProvider = ({ children }) => {
  const { addMessage } = useContext(MessageContext);
  const common = useLanguageData('common');

  const [user, setUser] = useState();
  const [userData, setUserData] = useState();
  const [learnData, setLearnData] = useState();

  const favoriteArr = useMemo(
    () => user?.favoriteArr?.map((f) => f._id) || [],
    [user],
  );

  const userDataMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setUserData(data);
    },
  });

  const userMutation = useMutation({
    mutationFn: () => findUserInfoForDashboard(),
    onSuccess: ({ data }) => {
      setUser(data);
    },
  });

  const manageFavoriteMutation = useMutation({
    mutationFn: (payload) => AddToFavoriteWithFullResponse(payload),
    onSuccess: ({ data, shouldAdd }) => {
      if (shouldAdd) {
        // userMutation.mutate();
        addMessage(common.addedToFavorite, 'success');
      } else {
        addMessage(common.deletedFromFavorite, 'error');
      }

      setUser((prev) => ({ ...prev, favoriteArr: data }));
    },
  });

  const handleLoadLearnDataMutation = useMutation({
    mutationFn: () => findUserInfoForLearn(),
    onSuccess: ({ data }) => setLearnData(data),
  });

  useEffect(() => {
    if (!userData) {
      Sentry.setUser(null);
      return;
    }

    Sentry.setUser({
      id: userData._id,
      email: userData.email,
      username: userData.name,
    });
  }, [userData]);

  useLayoutEffect(() => {
    userMutation.mutate();
    handleLoadLearnDataMutation.mutate();
    userDataMutation.mutate();
  }, []);

  return (
    <CustomerDataContext.Provider
      value={{
        user,
        userData,
        setUser,
        manageFavoriteMutation,
        userMutation,
        handleLoadLearnDataMutation,
        learnData,
        setLearnData,
        favoriteArr,
      }}
    >
      {children}
    </CustomerDataContext.Provider>
  );
};

export default CustomerDataContextProvider;
