import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import {
	PaymentElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import React, { useContext, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import useQueryMemo from '@/common/hooks/urlMemo';
import { handleEmailChange } from '@/common/helpers/handleGuessEmail';
import { generateLinkToProductPage } from '@/common/functions/generateLinkToProductPage';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import {
	EmailBox,
	InputWrapper,
	Label,
	StyledInput,
	HintMail,
	FormatMailCorrection,
	LetMailButton,
	LinkWrapper,
} from './SubscribtionStripeConf.styles';
import { getUtms } from '@/common/helpers/getUtms';
import { PAYMENT_OPERATORS } from '@/common/constants/paymentOperators';
import { createSubscriptionPayment } from '@/api/query/payment';

const SubscribtionStripeConf = ({ selectedProduct, email, subscribe }) => {
	const queryMemo = useQueryMemo();
	const stripe = useStripe();
	const elements = useElements();
	const data = useLanguageData('register.stepThree');
	const common = useLanguageData('common');
	const checkoutData = useLanguageData('checkout');
	const { handleSendIPstats } = useContext(IpStatsContext);

	const [errorMessage, setErrorMessage] = useState();
	const [correctionEmail, setCorrectionEmail] = useState();

	const emailRef = useRef();

	const { lang } = useContext(LanguageContext);

	const { addMessage } = useContext(MessageContext);

	const handleSubmitStripePaymentMutation = useMutation({
		mutationFn: (payload) => createSubscriptionPayment(payload),
		onSuccess: async ({ data }) => {
			localStorage.setItem('user_register', JSON.stringify(data.customerId));
			const { type, clientSecret } = data;

			const confirmIntent =
				type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

			const { error } = await confirmIntent({
				elements,
				clientSecret,
				confirmParams: {
					return_url: `https://${window.location.host}/success-payment`,
				},
			});

			if (error) {
				handleError(error);
			} else {
			}
		},
		onError: () => {
			addMessage(
				'Sth went wrong. Please contact contact@synthiaiapp.com',
				'error',
			);
		},
	});

	const handleError = (error) => {
		setErrorMessage(error.message);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || handleSubmitStripePaymentMutation?.isLoading) {
			return;
		}
		const { error: submitError } = await elements.submit();

		if (submitError) {
			handleError(submitError);
			return;
		}

		const originType = queryMemo.get('origin') ?? 'AD_SOVAL';

		if (selectedProduct.type.value === 'PREMIUM') {
			handleSendIPstats(`${originType}_CHECKOUT_6MO`);
		}
		if (selectedProduct.type.value === 'PRO') {
			handleSendIPstats(`${originType}_CHECKOUT_3MO`);
		}
		if (selectedProduct.type.value === 'BASIC') {
			handleSendIPstats(`${originType}_CHECKOUT_1MO`);
		}

		const payload = {
			lang: lang,
			type: PAYMENT_OPERATORS.STRIPE,
			plan: selectedProduct._id,
			email: emailRef?.current?.value?.toLowerCase(),
			paymentUtms: getUtms(queryMemo),
		};

		handleSubmitStripePaymentMutation.mutate(payload);
	};

	return (
		<form onSubmit={handleSubmit}>
			<EmailBox>
				<InputWrapper>
					<Label>{'Email'}</Label>
					<StyledInput
						ref={emailRef}
						id='email-SynthiAi'
						type='email'
						required
						defaultValue={email}
						placeholder={'type email'}
						style={{ border: 'none', padding: '8px 0px 8px 14px' }}
						onChange={(e) =>
							handleEmailChange(e.target.value, lang, setCorrectionEmail)
						}
					/>
					{correctionEmail && (
						<HintMail
							onClick={() => {
								emailRef.current.value = correctionEmail;
								setCorrectionEmail(() => null);
							}}
						>
							{checkoutData.emailGuessText}{' '}
							<FormatMailCorrection>{correctionEmail}</FormatMailCorrection> ?
						</HintMail>
					)}
				</InputWrapper>
			</EmailBox>
			<PaymentElement />
			{errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
			<LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
				<input type='checkbox' id='rules_2' required defaultChecked />
				<label
					htmlFor='rules_2'
					style={{
						cursor: 'pointer',
						display: 'flex',
						gap: '3px',
						padding: '0 0 0 5px ',
					}}
				>
					{data.accept}{' '}
					<div
						onClick={() =>
							window.open(generateLinkToProductPage(lang, common.termsLink))
						}
						style={{ textDecoration: 'underline' }}
					>
						{' '}
						{data.rules}
					</div>
					SynthiAi
				</label>
			</LinkWrapper>
			<LetMailButton
				type='submit'
				disabled={handleSubmitStripePaymentMutation?.isLoading}
			>
				{handleSubmitStripePaymentMutation?.isLoading && (
					<FontAwesomeIcon
						icon={faSpinner}
						spin={true}
						style={{ color: 'white' }}
					/>
				)}{' '}
				{subscribe}
			</LetMailButton>
		</form>
	);
};

export default SubscribtionStripeConf;
