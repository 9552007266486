import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MessageContextProvider } from './common/contexts/message-context/MessageContext';
import { handleSetupSentry } from './common/helpers/setupSentry';
import CookieContextProvider from './common/contexts/CookieContext';
import LanguageContextProvider from './common/contexts/LanguageContext';
import './index.css';

const queryClient = new QueryClient();

handleSetupSentry();

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<LanguageContextProvider>
				<CookieContextProvider>
					<MessageContextProvider>
						<App />
					</MessageContextProvider>
				</CookieContextProvider>
			</LanguageContextProvider>
		</QueryClientProvider>
	</BrowserRouter>,
);
