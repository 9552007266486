import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import {
	getCurrentPriceConfig,
	getCurrentPromoPriceConfig,
} from '@/api/query/price-config';
import { useMutation } from 'react-query';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import OneTimeStripeConf from './components/OneTimeStripeConf';
import SavePayment from '../SavePayment';
import useQueryMemo from '@/common/hooks/urlMemo';
import {
	Wrapper,
	LeftInfo,
	SynthiAiLogoWrapper,
	Title,
	PriceWrapper,
	PlanName,
	SaveWrapper,
	TextWrapper,
	Text,
	PriceInfoWrapper,
	Border,
	Text2,
	ActionWrapper,
	TitleDesktop,
} from './OnePageStripeOneTimePayment.styles';
import { getPriceConfigPayload } from '@/common/helpers/getPriceConfigPayload';

const OnePageStripeOneTimePayment = () => {
	const queryMemo = useQueryMemo();
	const [email, setEmail] = useState();
	const { lang } = useContext(LanguageContext);
	const data = useLanguageData('checkout');
	const [promo, setPromo] = useState();

	const [pricesData, setPricesData] = useState([]);
	const [selectedOption, setSelectedOption] = useState();

	const handleLoadPricesMutation = useMutation({
		mutationFn: (value) => getCurrentPromoPriceConfig(value),
		onSuccess: ({ data }) => {
			const prices = data.prices.sort((a, b) => a.full_price - b.full_price);

			let plan = localStorage.getItem('plan');
			let selected = prices?.find((p) => p?.type?.value === plan) || prices[0];

			setPricesData(() => prices);
			setSelectedOption(() => selected);
		},
	});

	const handleChangeSelect = (pd) => {
		setSelectedOption(() => pd);
	};

	useEffect(() => {
		const originType = queryMemo.get('origin') ?? 'AD_SOVAL';

		const pricesConfigPayload = getPriceConfigPayload(originType, lang);

		const payload = {
			country: pricesConfigPayload.country,
			type: pricesConfigPayload.pricesConfigName,
		};

		handleLoadPricesMutation.mutate(payload);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setEmail(() => localStorage.getItem('promo_email'));
		}, 100);
	}, []);

	return (
		<Wrapper>
			<LeftInfo>
				<SynthiAiLogoWrapper>
					<img src={SynthiAiLogo} />
				</SynthiAiLogoWrapper>
				<Title>{data?.leftTitle}</Title>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<div style={{ minWidth: '320px' }}>
						{[...pricesData]
							.sort((a, b) => b.full_price - a.full_price)
							.map((pd) => (
								<PriceWrapper style={{ marginBottom: '12px' }}>
									<div
										style={{
											display: 'flex',
											gap: '22px',
											width: 'fit-content',
										}}
										onClick={() => handleChangeSelect(pd)}
									>
										<input
											type='radio'
											id={pd._id}
											value={pd._id}
											name='plan'
											checked={pd._id === selectedOption?._id}
										/>
										<span
											class='custom-radio-button'
											style={{ marginTop: '5px' }}
										></span>
										<label
											htmlFor={pd._id}
											style={{
												display: 'flex',
												gap: '22px',
											}}
										>
											<PlanName
												style={{
													color:
														pd._id === selectedOption?._id
															? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
															: '#29185E',
												}}
											>
												{pd.inAppPrice.label}
											</PlanName>{' '}
											<SaveWrapper>{pd.inAppPrice.saving}</SaveWrapper>{' '}
										</label>
									</div>
									{pd._id === selectedOption?._id && (
										<PriceInfoWrapper>
											<TextWrapper>
												<Text>{pd.inAppPrice.full_label}</Text>
												<Text>{pd.inAppPrice.orginal_full_price}</Text>
											</TextWrapper>
											<TextWrapper>
												<Text>{pd.inAppPrice.discount_label}</Text>
												<Text
													style={{
														color: `${'var(--pink, #E94F96)'}`,
													}}
												>
													{pd.inAppPrice.discount_value}
												</Text>
											</TextWrapper>
											<Border />
											<TextWrapper>
												<Text>{data?.total}</Text>
												<Text>
													<b>
														{' '}
														{pd.full_price} {data?.currencySign}
													</b>
												</Text>
											</TextWrapper>
											<TextWrapper>
												<Text2>{pd.inAppPrice.nowYouWillPay}</Text2>
											</TextWrapper>
										</PriceInfoWrapper>
									)}
								</PriceWrapper>
							))}
					</div>
				</div>
			</LeftInfo>
			<ActionWrapper>
				<TitleDesktop>{data?.rightTitle}</TitleDesktop>
				<OneTimeStripeConf
					selectedProduct={selectedOption}
					email={email}
					subscribe={data?.subscribe}
				/>
				<SavePayment />
			</ActionWrapper>
		</Wrapper>
	);
};

export default OnePageStripeOneTimePayment;
