import React, { useState } from 'react';
import styled from 'styled-components';
import { markeLanguageAsLearningLang } from '@/api/query/customer';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { CONFIG_IMAGES } from '../mobile/PickLanguage';

const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 40px;
`;

const Text = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
  max-width: 320px;
`;

const Element = styled.div`
  display: inline-flex;
  cursor: pointer;

  padding: 14px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: ${(props) => (props?.isActive ? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)' : '#EEF2F5')};
  width: 360px;
`;

const Title = styled.div`
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  color: ${(props) => (props?.isActive ? 'white' : '#29185e')} !important;
`;

const Description = styled.div`
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: ${(props) => (props?.isActive ? 'white' : '#29185e')};
`;

const Left = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 60px;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  width: 192px;
  padding: 14px 0px 14px 0px;
  justify-content: center;
  align-items: center;
  color: white;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);
  margin-top: 44px;

  &:disabled {
    background: #d9d9d9 !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PickLanguage = ({ data, setPage, setData }) => {
  const [selected, setSelected] = useState();
  const { title, next, config } = useLanguageData('welcome.languagePicker');

  const markLanguageAsLearningLangMutation = useMutation({
    mutationFn: (values) => markeLanguageAsLearningLang(values),
    onSuccess: ({ data }) => {
      setPage((prev) => prev + 1);
    },
  });

  const handleSendRequest = (lang) => {
    const payload = {
      lang,
    };

    markLanguageAsLearningLangMutation.mutate(payload);
  };

  return (
    <>
      {markLanguageAsLearningLangMutation.isLoading && <Loading />}
      <TopWrapper>
        <Text>{title}</Text>
      </TopWrapper>
      <ElementsWrapper>
        {config.map((c) => (
          <Element
            isActive={selected === c.value}
            onClick={() => setSelected(c.value)}
          >
            <Left>
              <img src={CONFIG_IMAGES[c.value]} />
            </Left>
            <Title isActive={selected === c.value}>{c.label}</Title>
          </Element>
        ))}{' '}
      </ElementsWrapper>
      <ButtonWrapper>
        <SubmitButton
          onClick={() => {
            handleSendRequest(selected);
            setData((prev) => ({
              ...prev,
              learningLang: selected,
            }));
          }}
          disabled={!selected}
        >
          {next}
        </SubmitButton>
      </ButtonWrapper>
    </>
  );
};

export default PickLanguage;
