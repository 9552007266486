import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import userManager from '@/api/userManager';
import api from '@/api/api';
import bookBlank from '@/common/components/layout/images/book-blank.svg';
import bookFull from '@/common/components/layout/images/book-fill.svg';
import userBlank from '@/common/components/layout/images/user-blank.svg';
import userull from '@/common/components/layout/images/user-fill.svg';
import homeBordered from '@/common/components/layout/images/home-blank.svg';
import homeBlank from '@/common/components/layout/images/home-fill.svg';
import Icon from '@/common/icons/logo.svg';
import {
	ContentWrapper,
	LinkWrapper,
	LinkWrapperDesktop,
	Logo,
	NavigationDesktopWrapper,
	NavigationItemDesktop,
	Wrapper,
} from './Layout.styles';
import { useMutation } from 'react-query';
import { findCustomer } from '@/api/query/customer';
import FirstLogin from './productLayout/FirstLogin';
import { getNavigationPath } from '@/common/navigation/getNavigationPath';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import styled from 'styled-components';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import moment from 'moment';
import CallModePopUp from './productLayout/CallModePopUp';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import CustomerDataContextProvider from './productLayout/context/CustomerDataContext';
import {
	PAYU_OPERATOR_COUNTRIES,
	STRIPE_ONE_OFF,
} from '@/pages/checkouts/checkout/Checkout';

const NameWrapper = styled.p`
	color: ${(props) =>
		props.isActive
			? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
			: '#c0bac1'};
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 16.8px */
	margin: 0;
`;

const Border = styled.div`
	width: 30px;
	height: 3px;
	flex-shrink: 0;
	border-radius: 10px;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	margin-bottom: 5px;
`;

const { tokenManager } = api;

const Navigation = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const data = useLanguageData('navBar');

	const NavElements = [
		{
			name: '',
			navigate: '/',
			iconChecked: homeBlank,
			icon: homeBordered,
			title: data.home,
		},
		{
			name: 'learn',
			navigate: '/learn',
			iconChecked: bookFull,
			icon: bookBlank,
			title: data.learn,
			tags: 'learn, flashcard, image-mode, learning, categories, word-lesson-type, category',
		},
		{
			name: 'profile',
			navigate: '/profile',
			iconChecked: userull,
			icon: userBlank,
			title: data.profile,
		},
	];

	return (
		<LinkWrapper>
			{NavElements.map((e, i) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
						color: '#29185e',
					}}
					key={i}
				>
					{e.name === ''
						? location?.pathname.split('/')[1] === e.name && <Border />
						: location?.pathname.split('/')[1].includes(e.name) && <Border />}
					<img
						src={
							e.name === ''
								? location?.pathname.split('/')[1] === e.name
									? e.iconChecked
									: e.icon
								: location?.pathname.split('/')[1].includes(e.name)
								? e.iconChecked
								: e.icon
						}
						key={e.name}
						onClick={() => navigate(e.navigate)}
						style={{ width: 25, height: 25 }}
						alt=''
					/>
					<NameWrapper
						isActive={
							e.name === ''
								? location?.pathname.split('/')[1] === e.name
								: location?.pathname.split('/')[1].includes(e.name)
						}
					>
						{e.title}
					</NameWrapper>
				</div>
			))}
		</LinkWrapper>
	);
};

const NavigationDesktop = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const data = useLanguageData('navBar');

	const NavElements = [
		{
			name: '',
			navigate: '/',
			iconChecked: homeBlank,
			icon: homeBordered,
			title: data.home,
		},
		{
			name: 'learn',
			navigate: '/learn',
			iconChecked: bookFull,
			icon: bookBlank,
			title: data.learn,
			tags: 'learn, flashcard, image-mode, learning, categories, word-lesson-type, category',
		},
		{
			name: 'profile',
			navigate: '/profile',
			iconChecked: userull,
			icon: userBlank,
			title: data.profile,
		},
	];

	return (
		<LinkWrapperDesktop>
			{NavElements.map((e, i) => (
				<NavigationItemDesktop
					onClick={() => navigate(e.navigate)}
					isActive={
						e.name === ''
							? location?.pathname.split('/')[1] === e.name
							: location?.pathname.split('/')[1].includes(e.name)
					}
					key={i}
				>
					<div>{e.title}</div>
					<div style={{ width: '25px', overflow: 'hidden' }}>
						<img
							src={
								e.name === ''
									? location?.pathname.split('/')[1] === e.name
										? e.iconChecked
										: e.icon
									: location?.pathname.split('/')[1].includes(e.name)
									? e.iconChecked
									: e.icon
							}
							key={e.name}
							style={{ width: '100%', height: 'auto' }}
							alt=''
						/>
					</div>
				</NavigationItemDesktop>
			))}
		</LinkWrapperDesktop>
	);
};

const ProductLayout = ({ children }) => {
	const [user, setUser] = useState();
	const [payment, setPayment] = useState();
	const navigate = useNavigate();
	const { handleSendIPstats } = useContext(IpStatsContext);
	const { lang } = useContext(LanguageContext);

	const handleLoadUserMutation = useMutation({
		mutationFn: () => findCustomer(),
		onSuccess: ({ data }) => {
			if (data?.__t === 'Admin') {
				window.location.replace('https://admin.synthiaiapp.com');
				return;
			}

			if (!['Customer'].includes(data?.__t)) {
				userManager.removeUser();
				tokenManager.removeToken();
				navigate(getNavigationPath('/login'), {
					replace: true,
				});
			}

			if (payment) {
				return;
			}

			setUser(data);

			if (!data?.active) {
				if (data?.order?.expired_date) {
					window.gtag('event', 'account_expired_window_pay');
					handleSendIPstats(IP_STATS_ENUM.ACCOUNT_EXPIRED_WINDOW_PAY);
				} else {
					window.gtag('event', 'pay_in_application');
					handleSendIPstats(IP_STATS_ENUM.PAY_IN_APPLICATION);
				}
				navigate('/paywall');
				return;
			}

			const now = new Date();
			const accountExpiredDate = new Date(data.order.expired_date);

			if (data?.order?.expired_date) {
				const treshold = moment();
				const expiredDate = moment(data.order.expired_date);

				if (
					expiredDate.diff(treshold, 'day') > 0 &&
					expiredDate.diff(treshold, 'day') < 4
				) {
					const showExpired = localStorage.getItem('show_expired');
					if (!data?.order?.sub_id) {
						if (new Date(showExpired).getTime() - new Date().getTime() <= 0) {
							navigate('/paywall');
						}
					}
				}
			}

			if (now.getTime() > accountExpiredDate.getTime()) {
				window.gtag('event', 'account_expired_window_pay');
				handleSendIPstats(IP_STATS_ENUM.ACCOUNT_EXPIRED_WINDOW_PAY);

				navigate('/paywall');
			}
		},
		onError: () => {
			userManager.removeUser();
			tokenManager.removeToken();
			navigate(getNavigationPath('/login'), { replace: true });
		},
	});

	useEffect(() => {
		handleLoadUserMutation.mutate();
		const interval = setInterval(() => {
			handleLoadUserMutation.mutate();
		}, 1000 * 60 * 60 * 10);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			{/* {user?.active &&
        !user?.isCallModePopUpShow &&
        !user?.isFirstLogin &&
        lang === 'pl' && <CallModePopUp loader={handleLoadUserMutation} />} */}
			<CustomerDataContextProvider>
				{!payment && user?.isFirstLogin && (
					<FirstLogin loader={handleLoadUserMutation} />
				)}
				<Wrapper>
					<Logo src={Icon} />
					<ContentWrapper>
						<NavigationDesktopWrapper>
							<NavigationDesktop />
						</NavigationDesktopWrapper>
						{children}
					</ContentWrapper>
					<Navigation />
				</Wrapper>
			</CustomerDataContextProvider>
		</>
	);
};

export default ProductLayout;
