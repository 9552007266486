import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from './word-trainer.styles';
import TopTitleHeaderWordTrainer from './components/TopTitleHeaderWordTrainer';
import TopSectionTutor from './components/TopSectionTutor';
import { useMutation } from 'react-query';
import WordSection from './components/WordSection';
import {
  checkWordTrainer,
  generateNextWordTrainer,
  restartScoreWordTrainer,
} from '@/api/query/word-trainer';
import Loading from '@/common/components/Loading';
import { getCustomerAiPerson } from '@/api/query/ai-person';
import ActionSection from './components/ActionSection';
import StatuSection from './components/StatuSection';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { mapScoreToStatus } from './helper/mapScoreToStatus';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import WordTrainerPopUp from './components/WordTrainerPopUp';
import SessionScore from './components/SessionScore';
import EndWords from './components/EndWords';
import AudioRecordingContextProvider from '@/common/contexts/AudioRecordingContext';
import ProblemView from '@/common/components/customer/ProblemView';
import { useLanguageData } from '@/common/hooks/useLanguageData';

export const WORD_TRAINER_STATUES = {
  NONE: 'none',
  BAD: 'bad',
  OK: 'ok',
  GOOD: 'good',
};

const WordTrainer = () => {
  const translationData = useLanguageData('features.wordTrainer');

  const [wordTrainerStatus, setWordTrainerStatus] = useState(
    WORD_TRAINER_STATUES.NONE,
  );
  const [currentVoice, setCurrentVoice] = useState();
  const [word, setWord] = useState();
  const [tutorImage, setTutorImage] = useState();
  const [wordScore, setWordScore] = useState();
  const [sesionScore, setSesionScore] = useState({
    words: 0,
    points: 0,
  });
  const [showPopUpTutorial, setShowPopUpTutorial] = useState(
    !window.localStorage.getItem('dont_show_popup_word_trainer'),
  );
  const [showPopUpProblem, setShowPopUpProblem] = useState();
  const [sessionEnd, setSessionEnd] = useState();
  const [wordsEnd, setWordsEnd] = useState();

  const { addMessage } = useContext(MessageContext);

  const checkWordMutation = useMutation({
    mutationFn: (payload) => checkWordTrainer(payload),
    onSuccess: ({ data }) => {
      if (!data) {
        addMessage(translationData.errorMessage, 'error');
        return;
      }

      setSesionScore((prev) => ({
        words: prev.words + 1,
        points: prev.points + data.pronScore,
      }));
      setWordScore(() => data);
      setWordTrainerStatus(() => mapScoreToStatus(data.pronScore));
    },
  });

  const generateWordMutation = useMutation({
    mutationFn: () => generateNextWordTrainer(),
    onSuccess: ({ data }) => {
      if (data.end) {
        return setWordsEnd(() => data.knownWords);
      }

      setWord(() => data);
      setWordScore(() => null);
    },
  });
  const restartScoreWordTrainerMutation = useMutation({
    mutationFn: () => restartScoreWordTrainer(),
    onSuccess: ({ data }) => {
      setWordScore(() => null);
    },
  });

  const loadTutorMutation = useMutation({
    mutationFn: () => getCustomerAiPerson(),
    onSuccess: ({ data }) => {
      setTutorImage(() => data.image);
    },
  });

  const handleCheckWord = (voice) => {
    setWordTrainerStatus(() => WORD_TRAINER_STATUES.NONE);
    setWordScore(() => null);

    const formData = new FormData();
    formData.append('file', voice);
    formData.append('wordTrainerId', word._id);

    checkWordMutation.mutate(formData);
  };

  const handleGenerateWord = () => {
    setWordTrainerStatus(() => WORD_TRAINER_STATUES.NONE);
    generateWordMutation.mutate();
  };

  const handleLoadTutor = () => {
    loadTutorMutation.mutate();
  };

  const handleRestart = () => {
    restartScoreWordTrainerMutation.mutate();
  };

  useEffect(() => {
    handleLoadTutor();
    handleGenerateWord();
  }, []);

  return (
    <>
      <AudioRecordingContextProvider>
        {checkWordMutation.isLoading ||
        generateWordMutation.isLoading ||
        loadTutorMutation.isLoading ||
        restartScoreWordTrainerMutation.isLoading ? (
          <Loading />
        ) : (
          <PageWrapper>
            <TopTitleHeaderWordTrainer
              source={SOURCES_TYPES.WORD_TRAINER}
              setShowPopUpTutorial={setShowPopUpTutorial}
              setSessionEnd={setSessionEnd}
              forWhat={word?._id}
              title={translationData.title}
              setShowPopUpProblem={setShowPopUpProblem}
            />
            {sessionEnd ? (
              <SessionScore
                sesionScore={sesionScore}
                setClose={setSessionEnd}
              />
            ) : wordsEnd ? (
              <EndWords words={wordsEnd} handleRestart={handleRestart} />
            ) : (
              <>
                <TopSectionTutor
                  tutorImage={tutorImage}
                  status={wordTrainerStatus}
                />
                <WordSection word={word} result={wordScore?.words} />
                <StatuSection
                  status={wordTrainerStatus}
                  score={wordScore?.pronScore}
                />
                <div style={{ flex: 1 }} />
                <ActionSection
                  currentVoice={currentVoice}
                  setCurrentVoice={setCurrentVoice}
                  handleGenerateWord={handleGenerateWord}
                  handleCheckWord={handleCheckWord}
                />
              </>
            )}
          </PageWrapper>
        )}
        {showPopUpTutorial && (
          <WordTrainerPopUp setClose={setShowPopUpTutorial} />
        )}
        {showPopUpProblem && (
          <ProblemView
            forWhat={word._id}
            userVoice={currentVoice}
            setShowProblem={setShowPopUpProblem}
            type={SOURCES_TYPES.WORD_TRAINER}
            chatMessage={word.word}
          />
        )}
      </AudioRecordingContextProvider>
    </>
  );
};

export default WordTrainer;
