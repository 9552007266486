import { cleanObject } from '@/common/functions/cleanObject';
import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'price-config';
const queryString = require('query-string');

export const getCurrentPriceConfig = async (payload) => {
	const data = await api.get(`/${PREFIX}/current/${payload.country}`);
	return { data: data.data };
};

export const getCurrentPromoPriceConfig = async (payload) => {
	const data = await api.get(
		`/${PREFIX}/current-promo?${queryString.stringify(cleanObject(payload), {
			arrayFormat: 'bracket',
		})}`,
	);
	return { data: data.data };
};
