import React, { useState } from 'react';
import styled from 'styled-components';
import Megan from './images/icons/Megan.png';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const TopWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 28px;
`;

const Text = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
  max-width: 180px;
`;

const Element = styled.div`
  display: inline-flex;
  cursor: pointer;

  padding: 6px 14px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: ${(props) => (props?.isActive ? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)' : '#EEF2F5')};
  width: 360px;
`;

const Title = styled.div`
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  color: ${(props) => (props?.isActive ? 'white' : '#29185e')} !important;
`;

const Description = styled.div`
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: ${(props) => (props?.isActive ? 'white' : '#29185e')};
`;

const Left = styled.div`
  width: 80px;

  img {
    width: 100%;
    height: auto;
  }
`;

const ElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;

const SubmitButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  width: 192px;
  padding: 14px 0px 14px 0px;
  justify-content: center;
  align-items: center;
  color: white;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);
  margin-top: 44px;

  &:disabled {
    background: #d9d9d9 !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const EnglishLevel = ({ data, setPage, setData }) => {
  const { title, config, next } = useLanguageData('welcome.secondScreen');
  const languagePickerData = useLanguageData('welcome.languagePicker');

  const [selected, setSelected] = useState();

  const translateTitle = (title, learningLang, labelName = 'label') => {
    const pickedLang = languagePickerData.config.find(
      (c) => c.value === learningLang,
    );

    return title.replace('{language}', pickedLang[labelName].toLowerCase());
  };

  return (
    <>
      <TopWrapper>
        <Left>
          <img src={data.tutor?.tutorial?.base64 || Megan} />
        </Left>
        <Text>{translateTitle(title, data.learningLang)}</Text>
      </TopWrapper>
      <ElementsWrapper>
        {config.map((c) => (
          <Element
            isActive={selected === c.value}
            onClick={() => setSelected(c.value)}
          >
            <Title isActive={selected === c.value}>{c.name}</Title>
            <Description isActive={selected === c.value}>
              {translateTitle(c.description, data.learningLang, 'labelInside')}
            </Description>
          </Element>
        ))}{' '}
      </ElementsWrapper>
      <ButtonWrapper>
        <SubmitButton
          onClick={() => {
            setData((prev) => ({
              ...prev,
              level: selected,
            }));
            setPage(() => 4);
          }}
          disabled={!selected}
        >
          {next}
        </SubmitButton>
      </ButtonWrapper>
    </>
  );
};

export default EnglishLevel;
