export const MIME_TYPES = [
  'audio/webm',
  'audio/mp4',
  'audio/webm;codecs=opus',
  'audio/webm;codecs=pcm',
  'audio/ogg;codecs=opus',
  'audio/ogg;codecs=vorbis',
  'audio/ogg;codecs=pcm',
  'audio/mp4;codecs=mp4a',
  'audio/mp4;codecs=opus',
  'audio/mp4;codecs=pcm',
  'audio/x-matroska;codecs=opus',
  'audio/x-matroska;codecs=vorbis',
  'audio/x-matroska;codecs=pcm',
  'audio/aac',
  'audio/mpeg',
  'audio/wav',
  'audio/x-wav',
  'audio/x-pn-wav',
  'audio/x-m4a',
  'audio/x-flac',
  'audio/x-matroska',
  'audio/x-mpegurl',
  'audio/x-scpls',
];
