import { styled } from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 80px);
  overflow: hidden;
  text-align: center;
  margin: 0px 20px 0 20px;
  padding-top: 20px;
  gap: 10px;
  background-color: white;

  @media (min-width: 600px) {
    flex-grow: 1;
    margin: 0;
    width: 100%;
    height: fit-content;
    position: relative;
  }
`;

export const ActionWrapperMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;

  @media (min-width: 600px) {
    max-width: 460px;
    margin-top: 100px;
  }
`;

export const CurrentLevelWrapper = styled.div`
  color: #7f7c85;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const MoveLeftAction = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  display: inline-flex;
  padding: 15px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0px 40px 40px 0px;
  background: #d9d9d9;
  padding-right: 40px;
  width: 120px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active {
    transform: scale(0.9) translateY(4px);
    transition: transform 0.1s, box-shadow 0.1s;
  }

  @media (max-width: 600px) {
    &:active {
      margin-left: -9px;
    }
  }

  @media (min-width: 600px) {
    border-radius: 40px;
    padding: 15px;
    justify-content: center;
    width: 170px;
  }
`;

export const MoveRightAction = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  display: flex;
  padding: 15px;
  align-items: center;
  border-radius: 40px 0px 0px 40px;
  background: linear-gradient(271deg, #4322c6 -0.67%, #48068a 101.48%);
  padding-left: 40px;
  width: 120px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active {
    transform: scale(0.9) translateY(4px);
    transition: transform 0.1s, box-shadow 0.1s;
  }

  @media (max-width: 600px) {
    &:active {
      margin-right: -9px;
    }
  }

  @media (min-width: 600px) {
    border-radius: 40px;
    padding: 15px;
    justify-content: center;
    width: 170px;
  }
`;

export const CardsWrapper = styled.div`
  position: relative;
  width: 85%;
  height: calc(var(--vh, 1vh) * 100 - 300px);

  @media (min-width: 600px) {
    height: 500px;
  }
`;
