import routes from '@/common/routes/routes';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { Howler } from 'howler';
import { debounce } from './common/functions/debounce';
import { IpStatsContext } from './common/contexts/IpStatsContext';
import useQueryMemo from './common/hooks/urlMemo';
import userManager from './api/userManager';
import { useMutation } from 'react-query';
import { addFBStats, addIpStats } from './api/query/ipStats';
import { isMobile } from 'react-device-detect';
import { EventService } from './common/services/eventService';
import { EventNamesEnum } from './common/enums/eventNamesEnum';
import { pingUserServer } from './api/query/workflow';
import moment from 'moment';
import { getPermission } from './common/utils';
import { LanguageContext } from './common/contexts/LanguageContext';
import Seo from './common/components/Seo';
import { setupUtms } from '@/common/helpers/setupUtms';
import { getUtms } from './common/helpers/getUtms';
import { PAGES_WITH_UID } from '@/common/constants/pagesWithUid';

const INTERVAL = 30 * 1000;

function App() {
	const [, setSearchParams] = useSearchParams();
	const queryMemo = useQueryMemo();

	const { lang } = useContext(LanguageContext);
	const clearFunction = (event) => {
		event.preventDefault();
	};
	const location = useLocation();

	const unloadHowler = () => {
		Howler.unload();
	};

	const triggerResize = () => {
		window.dispatchEvent(new Event('resize'));
	};

	const handleSendIPstatsMutation = useMutation({
		mutationFn: (value: any) => addIpStats(value),
		onSuccess: () => {},
	});

	const handleSendIPstats = (type, userId = null) => {
		const utm = getUtms(queryMemo);

		window.gtag('set', { campaignName: utm.utm_campaign });
		window.gtag('set', { campaignSource: utm.utm_medium });
		window.gtag('set', { campaignMedium: utm.utm_source });
		window.gtag('set', { campaignContent: utm.utm_content });
		window.gtag('set', { campaignFbclid: utm.utm_fbclid });
		window.gtag('set', { campaignMsclid: utm.msclkid });

		const shouldShowNotyfiactionSetup = window.localStorage.getItem(
			'should_show_notyfication',
		);

		const payload = {
			user: userId || userManager?.getUser()?.id,
			lead_history: localStorage?.getItem('lead-history-id'),
			IpStatsType: type,
			utm: utm,
			device_type: isMobile ? 'mobile' : 'desktop',
			device_description: window?.navigator?.userAgent,
			lang: lang,
			shouldShowNotyfiactionSetup:
				parseInt(shouldShowNotyfiactionSetup + '') || null,
		};

		handleSendIPstatsMutation.mutate(payload);
	};

	const pingActiveUser = useMutation({
		mutationFn: (data: any) => pingUserServer(data),
		onSuccess: () => {},
	});

	const handleFbStatsMutation = useMutation({
		mutationFn: (value: any) => addFBStats(value),
		onSuccess: () => {},
	});

	const handleFbStats = (event, eventData) => {
		const utm_fbclid =
			queryMemo.get('fbclid') || localStorage.getItem('fbclid');

		let fbp: string[] | null | string = document.cookie
			.split(';')
			.filter((c) => c.includes('_fbp='))
			.map((c) => c.split('_fbp=')[1]);
		let fbc: string[] | null | string = document.cookie
			.split(';')
			.filter((c) => c.includes('_fbc='))
			.map((c) => c.split('_fbc=')[1]);

		fbp = (fbp.length && fbp[0]) || null;
		fbc = (fbc.length && fbc[0]) || null;
		if (!fbc && utm_fbclid) {
			fbc = 'fb.1.' + +new Date() + '.' + utm_fbclid;
		}

		const payload = {
			event: event,
			event_data: eventData,
			fbp: fbp,
			fbclid: fbc,
			user_agent: navigator.userAgent,
			url: window.location.origin + window.location.pathname,
		};

		handleFbStatsMutation.mutate(payload);
	};

	useLayoutEffect(() => {
		const srouce = queryMemo.get('utm_source');
		if (srouce === 'mail' || srouce === 'wp') {
			const event = queryMemo.get('event');
			if (event) {
				handleSendIPstats(event?.toLowerCase());
			}
		}
	}, []);

	useEffect(() => {
		document.addEventListener('contextmenu', clearFunction);
		return () => document.removeEventListener('contextmenu', clearFunction);
	}, []);

	useEffect(() => {
		unloadHowler();
	}, [location.pathname]);

	useEffect(() => {
		window.addEventListener('blur', unloadHowler);
		return () => window.removeEventListener('blur', unloadHowler);
	}, []);

	useEffect(() => {
		const setVh = debounce(() => {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}, 50);

		window.addEventListener('resize', setVh);

		return () => {
			window.removeEventListener('resize', setVh);
		};
	}, []);

	useEffect(() => {
		window.addEventListener('orientationchange', triggerResize);
		return () => {
			window.removeEventListener('orientationchange', triggerResize);
		};
	}, []);

	useEffect(() => {
		triggerResize();
	}, []);

	useEffect(() => {
		const utms = setupUtms(queryMemo);
		EventService.publish(EventNamesEnum.TRACING_ID_PROCESSED);

		if (
			PAGES_WITH_UID.includes(location.pathname.replaceAll('/', '')) &&
			!queryMemo.get('uid')
		) {
			setSearchParams((searchParams) => {
				searchParams.set('uid', utms.uid);
				return searchParams;
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		const interval = setInterval(() => {
			const user = userManager.getUser();
			if (user && user.role !== 'role') {
				const payload = {
					userDate: moment().utcOffset(0, true).toISOString(),
					interval: INTERVAL / 1000,
				};

				pingActiveUser.mutate(payload);
			}
		}, INTERVAL);

		return () => clearInterval(interval);
	}, []);

	const setupNotification = async () => {
		const isReady = await navigator?.serviceWorker?.ready;
		if (isReady) {
			getPermission(handleSendIPstats);
		}
	};

	useEffect(() => {
		if (window?.location?.pathname?.toLowerCase()?.includes('login')) {
			setupNotification();
		}
	}, [navigator?.serviceWorker?.ready, window.location.pathname]);

	return (
		<>
			<IpStatsContext.Provider
				value={{
					handleSendIPstats,
					handleFbStats,
				}}
			>
				<Routes>
					{routes.map((route) => (
						<Route
							key={route.path}
							path={route.path}
							element={
								<>
									<Seo />
									{route.element}
								</>
							}
						/>
					))}
				</Routes>
			</IpStatsContext.Provider>
		</>
	);
}

export default App;
