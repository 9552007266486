import {
	changeUserIcon,
	getCustomerProfiles,
} from '@/api/query/customer-profile';
import { loadUserLearningData } from '@/api/query/user';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const Title = styled.p`
	color: #29185e;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 22.4px */
	margin: 0px 0 12px 0;
`;

const ChosenProfileContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2px;
  border-radius: 4px;
	background: ${(props) =>
		props.selected
			? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
			: 'transparent'};
`;

const ProfileChange = ({ setClose }) => {
	const top = useLanguageData('profile.top');
	const [items, setItems] = useState([]);
	const [user, setUser] = useState();

	const { addMessage } = useContext(MessageContext);

	const loadDataMutation = useMutation({
		mutationFn: () => getCustomerProfiles(),
		onSuccess: ({ data }) => {
			setItems(data);
		},
	});

	const loadUserMutation = useMutation({
		mutationFn: () => loadUserLearningData(),
		onSuccess: ({ data }) => {
			setUser(data);
		},
	});

	const changeProfileMutation = useMutation({
		mutationFn: (data) => changeUserIcon(data),
		onSuccess: ({ data }) => {
			loadDataMutation.mutate();
			setItems(() => []);
			loadUserMutation.mutate();
			addMessage(top.profileChanged, 'success');
		},
		onError: () => {
			addMessage(top.error, 'error');
		},
	});

	const handlePickOne = (element) => {
		const payload = {
			profile: element._id,
		};

		changeProfileMutation.mutate(payload);
	};

	useEffect(() => {
		loadDataMutation.mutate();
		loadUserMutation.mutate();
	}, []);

	return (
		<>
			{(changeProfileMutation.isLoading ||
				loadDataMutation.isLoading ||
				loadUserMutation.isLoading) && <Loading />}
			<PopUp setClose={setClose}>
				<Title>{top.changeProfileImage}</Title>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexWrap: 'wrap',
						gap: '5px',
					}}
				>
					{user &&
						items?.map((item) => (
							<ChosenProfileContainer
								selected={user?.user_profile === item.icon._id}
							>
								<div
									id={item._id}
									onClick={() => handlePickOne(item)}
									style={{
										width: '75px',
										height: '75px',
										borderRadius: '4px',
										padding: '2px',
										cursor: 'pointer',
									}}
								>
									<img
										src={item.icon.base64}
										style={{ width: '100%', height: 'auto' }}
									/>
								</div>
							</ChosenProfileContainer>
						))}
				</div>
			</PopUp>
		</>
	);
};

export default ProfileChange;
