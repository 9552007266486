import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import OnePageStripeSubscribtionPayment from './components/stripe/OnePageStripeSubscribtionPayment';
import OnePageStripeOneTimePayment from './components/stripe/OnePageStripeOneTimePayment';
import OnePagePayuPayment from './components/payu/OnePagePayuPayment';
import { CheckoutContext } from '../../../common/contexts/checkoutContext';
import useQueryMemo from '@/common/hooks/urlMemo';

export const PAYU_OPERATOR_COUNTRIES = ['pl'];

export const STRIPE_SUBSCRIBTION = [
	'es',
	'it',
	'hu',
	'sv',
	'hr',
	'ro',
	'et',
	'bg',
	'cs',
	'el',
	'en',
	'sk',
];

export const STRIPE_ONE_OFF = ['de', 'sl'];

const Checkout = () => {
	const { lang } = useContext(LanguageContext);
	const [quizVersion, setQuizVersion] = useState();
	const [spVersion, setSpVersion] = useState();
	const queryMemo = useQueryMemo();

	const paymentPage = useMemo(() => {
		if (PAYU_OPERATOR_COUNTRIES.includes(lang)) {
			return <OnePagePayuPayment />;
		}

		if (STRIPE_SUBSCRIBTION.includes(lang)) {
			return <OnePageStripeSubscribtionPayment />;
		}

		if (STRIPE_ONE_OFF.includes(lang)) {
			return <OnePageStripeOneTimePayment />;
		}
	}, [lang]);

	useEffect(() => {
		let currentVersion =
			queryMemo.get('quiz_version') || localStorage.getItem('quiz_variant');
		let currentSpVersion =
			queryMemo.get('sp_version') || localStorage.getItem('sp_version');

		if (!currentVersion) {
			currentVersion = 'LP3A';
			localStorage.setItem('quiz_variant', currentVersion);
		}

		if (!currentSpVersion) {
			currentSpVersion = '';
			localStorage.setItem('sp_version', currentSpVersion);
		}
		setSpVersion(() => currentSpVersion);
		setQuizVersion(() => currentVersion);
	}, []);

	return (
		<div>
			<CheckoutContext.Provider value={{ quizVersion, spVersion }}>
				{paymentPage}
			</CheckoutContext.Provider>
		</div>
	);
};

export default Checkout;
