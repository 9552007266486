import React, { useContext } from 'react';
import styled from 'styled-components';
import { RegisterContext } from '../../context/register.context';
import SynthiAiSumImgage from './images/synthi_summary.png';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { useMutation } from 'react-query';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import userManager from '@/api/userManager';
import { createOneOffPayment } from '@/api/query/payment';
import { PAYMENT_OPERATORS } from '@/common/constants/paymentOperators';
import { APPLICATION_PAYWALL_UTMS } from '@/common/constants/application.utms';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 75vw;
	background: #fff;
	padding: 10px 30px;

	border-radius: 10px;

	@media (min-width: 600px) {
		display: none;
	}
`;

const SubTitle = styled.p`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;

	@media (min-width: 600px) {
		text-align: left;
		color: #05275d;
		font-family: 'Work Sans';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;

const Price = styled.p`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.4px;
`;

const Pacakge = styled.div`
	display: flex;
	padding: 10px;
	gap: 20px;

	@media (min-width: 600px) {
		padding: 10px 0px 20px 0;
	}
`;

const DataText = styled.p`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: left;
	width: 100%;
	margin: 0;
`;

const Border = styled.div`
	height: 1px;
	width: 100%;
	margin-top: 10px;
	background: #d9d9d9;
`;

const ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	border-radius: 5px;
	border: 1px solid rgba(79, 167, 180, 0.2);
	background: #fff;
	padding: 5px;
`;

const ActionWrapper = styled.form`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin: 0px 0;

	@media (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

const LetMailButton = styled.button`
	display: inline-flex;
	padding: 9px 82px 10px 82px;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	color: #fff;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
`;

const TitleMobile = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.56px;
	display: none;
	margin: 20px 0;

	@media (min-width: 600px) {
		color: #29185e;
		text-align: center;
		font-family: 'Work Sans';
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		display: flex;
		justify-content: center;
	}
`;
const TopPlanData = styled.div`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 5px;
`;

const BottomPlanData = styled.div`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const LinkWrapper = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	/* Hide the default checkbox */
	input[type='checkbox'] {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 15px;
		height: 15px;
		border: 2px solid #29185e;
		border-radius: 4px;
		outline: none;
		cursor: pointer;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}

	/* Style the checkbox when checked */
	input[type='checkbox']:checked {
		background-color: #29185e;
	}

	/* Create the checkmark using a pseudo-element */
	input[type='checkbox']:checked::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 4px;
		height: 8px;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	input[type='checkbox']:focus {
		box-shadow: 0 0 3px #29185e;
	}
`;

const MainWrapperContent = styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 600px) {
		height: calc(var(--vh, 1vh) * 100 - 100px);
	}
`;

const WrapperDesktop = styled.div`
	display: flex;
	gap: 40px;
	margin-bottom: 50px;

	@media (max-width: 600px) {
		display: none;
	}
`;

const Left = styled.div``;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`;

const AditionalInfoSub = styled.div`
	color: #868686;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 16px;
`;

const StripePaymentView = () => {
	const data = useLanguageData('register.stepThree');
	const { user, plan } = useContext(RegisterContext);
	const { lang } = useContext(LanguageContext);

	const { addMessage } = useContext(MessageContext);

	const handleSubmitStripePaymentMutation = useMutation({
		mutationFn: (payload) => createOneOffPayment(payload),
		onSuccess: async ({ data }) => {
			localStorage.setItem('user_register', JSON.stringify(data.user));
			window.location.href = data.link;
		},
		onError: () => {
			addMessage(
				'Sth went wrong. Please contact contact@synthiaiapp.com',
				'error',
			);
		},
	});

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (handleSubmitStripePaymentMutation?.isLoading) {
			return;
		}

		const payload = {};
		payload.email = user.email;
		payload.type = PAYMENT_OPERATORS.STRIPE;
		payload.paymentUtms = APPLICATION_PAYWALL_UTMS;
		payload.plan = plan._id;
		payload.lang = lang;

		handleSubmitStripePaymentMutation.mutate(payload);
	};

	return (
		<div>
			<MainWrapperContent>
				<TitleMobile>{data.title}</TitleMobile>
				<ContentWrapper>
					<Wrapper>
						<SubTitle>{data.data}</SubTitle>
						<DataText style={{ marginTop: '10px' }}>
							{user.name} {user.surname}
						</DataText>
						<DataText>{user.email}</DataText>
						<Border style={{ marginTop: '20px' }} />
						<Pacakge>
							<div style={{ width: '90px' }}>
								<img
									src={SynthiAiSumImgage}
									style={{ width: '100%', height: 'auto' }}
								/>
							</div>
							<div>
								<TopPlanData>
									{data.plan} {plan?.type?.label}
								</TopPlanData>
								<BottomPlanData>
									{data.forLong} {plan?.inAppPrice?.label}
								</BottomPlanData>
							</div>
						</Pacakge>
						<Border style={{ marginTop: '-5px' }} />
						<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							<SubTitle>{data.toPay}</SubTitle>
							<Price>{plan?.inAppPrice?.promotion_full_price_info}</Price>
						</div>
					</Wrapper>
					<WrapperDesktop>
						<Left>
							<div style={{ width: '200px' }}>
								<img
									src={SynthiAiSumImgage}
									style={{ width: '100%', height: 'auto' }}
								/>
							</div>
						</Left>
						<Right>
							<SubTitle>{data.data}</SubTitle>
							<DataText style={{ marginTop: '0px' }}>
								{user.name} {user.surname}
							</DataText>
							<DataText style={{ marginTop: '-5px' }}>{user.email}</DataText>
							<Border style={{ marginTop: '20px' }} />
							<Pacakge>
								<div>
									<TopPlanData>
										{data.plan} {plan?.type?.label}
									</TopPlanData>
									<BottomPlanData>
										{data.forLong} {plan?.inAppPrice?.label}
									</BottomPlanData>
								</div>
							</Pacakge>
							<AditionalInfoSub>
								{plan?.inAppPrice?.promotion_full_price_info}{' '}
								{plan?.inAppPrice?.nowYouWillPay}
							</AditionalInfoSub>
							<Border style={{ marginTop: '-5px' }} />
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
									marginBottom: '-10px',
								}}
							>
								<SubTitle>{data.toPay}</SubTitle>
								<Price>{plan?.inAppPrice?.promotion_full_price_info}</Price>
							</div>
						</Right>
					</WrapperDesktop>
				</ContentWrapper>
				<ActionWrapper onSubmit={handleSubmit}>
					<LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
						<input type='checkbox' id='rules_2' required defaultChecked />
						<label
							htmlFor='rules_2'
							style={{
								cursor: 'pointer',
								display: 'flex',
								gap: '3px',
								padding: '0 0 0 5px ',
							}}
						>
							{data.accept}{' '}
							<div
								onClick={() => window.open('tos')}
								style={{ textDecoration: 'underline' }}
							>
								{' '}
								{data.rules}
							</div>
							SynthiAi
						</label>
					</LinkWrapper>
					<LetMailButton type='submit'>Pay</LetMailButton>
				</ActionWrapper>
			</MainWrapperContent>
		</div>
	);
};

export default StripePaymentView;
