import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import { getCurrentPromoPriceConfig } from '@/api/query/price-config';
import { useMutation } from 'react-query';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import useQueryMemo from '@/common/hooks/urlMemo';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { registerOnePageView } from '@/api/query/order';
import SavePayment from '../SavePayment';
import { generateLinkToProductPage } from '@/common/functions/generateLinkToProductPage';
import { handleEmailChange } from '@/common/helpers/handleGuessEmail';
import { getPriceConfigPayload } from '@/common/helpers/getPriceConfigPayload';
import {
	Wrapper,
	LeftInfo,
	SynthiAiLogoWrapper,
	Title,
	PriceWrapper,
	PlanName,
	SaveWrapper,
	TextWrapper,
	Text,
	PriceInfoWrapper,
	Border,
	Text2,
	ActionWrapper,
	TitleDesktop,
	EmailBox,
	InputWrapper,
	Label,
	StyledInput,
	FormatMailCorrection,
	HintMail,
	LinkWrapper,
	LetMailButton,
} from './OnePagePayuPayment.styles';
import { getUtms } from '@/common/helpers/getUtms';
import { createOneOffPayment } from '@/api/query/payment';
import { PAYMENT_OPERATORS } from '@/common/constants/paymentOperators';

const OnePagePayuPayment = () => {
	const translations = useLanguageData('checkout');
	const queryMemo = useQueryMemo();
	const common = useLanguageData('common');

	const { lang } = useContext(LanguageContext);
	const { handleSendIPstats } = useContext(IpStatsContext);

	const [email, setEmail] = useState();
	const [promo, setPromo] = useState();
	const [correctionEmail, setCorrectionEmail] = useState();
	const [pricesData, setPricesData] = useState([]);
	const [selectedOption, setSelectedOption] = useState();

	const emailRef = useRef();

	const handleLoadPricesMutation = useMutation({
		mutationFn: (value) => getCurrentPromoPriceConfig(value),
		onSuccess: ({ data }) => {
			const prices = data.prices.sort((a, b) => a.full_price - b.full_price);
			const plan = localStorage.getItem('plan');
			const selected =
				prices?.find((p) => p?.type?.value === plan) || prices[0];

			setPricesData(() => prices);
			setSelectedOption(() => selected);
		},
	});

	const handleChangeSelect = (pd) => {
		setSelectedOption(() => pd);
	};

	const handleRegisterOnePageViewMutation = useMutation({
		mutationFn: (payload) => createOneOffPayment(payload),
		onSuccess: ({ data }) => {
			localStorage.setItem('user_register', JSON.stringify(data.user));
			window.location.href = data.link;
		},
	});

	const handleCreateOrder = (e) => {
		e?.preventDefault();

		const utm = getUtms(queryMemo);

		const payload = {};
		payload.email = emailRef?.current?.value?.toLowerCase();
		payload.paymentUtms = utm;
		payload.lang = lang;
		payload.plan = selectedOption._id;
		payload.type = PAYMENT_OPERATORS.PAYU;

		const originType = queryMemo.get('origin') ?? 'AD_SOVAL';

		if (selectedOption.type.value === 'PREMIUM') {
			handleSendIPstats(`${originType}_CHECKOUT_6MO`);
		}
		if (selectedOption.type.value === 'PRO') {
			handleSendIPstats(`${originType}_CHECKOUT_3MO`);
		}
		if (selectedOption.type.value === 'BASIC') {
			handleSendIPstats(`${originType}_CHECKOUT_1MO`);
		}

		handleRegisterOnePageViewMutation.mutate(payload);
	};

	useEffect(() => {
		const originType = queryMemo.get('origin') ?? 'AD_SOVAL';

		const pricesConfigPayload = getPriceConfigPayload(originType, lang);

		const payload = {
			country: pricesConfigPayload.country,
			type: pricesConfigPayload.pricesConfigName,
		};

		handleLoadPricesMutation.mutate(payload);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setEmail(() => localStorage.getItem('promo_email'));
		}, 100);
	}, []);

	return (
		<Wrapper>
			<LeftInfo>
				<SynthiAiLogoWrapper>
					<img src={SynthiAiLogo} />
				</SynthiAiLogoWrapper>
				<Title>{translations.leftTitle}</Title>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<div style={{ minWidth: '320px' }}>
						{[...pricesData]
							.sort((a, b) => b.full_price - a.full_price)
							.map((pd) => (
								<PriceWrapper style={{ marginBottom: '12px' }}>
									<div
										style={{
											display: 'flex',
											gap: '22px',
											width: 'fit-content',
										}}
										onClick={() => handleChangeSelect(pd)}
									>
										<input
											type='radio'
											id={pd._id}
											value={pd._id}
											name='plan'
											checked={pd._id === selectedOption?._id}
										/>
										<span
											class='custom-radio-button'
											style={{ marginTop: '5px' }}
										></span>
										<label
											htmlFor={pd._id}
											style={{
												display: 'flex',
												gap: '22px',
											}}
										>
											<PlanName
												style={{
													color:
														pd._id === selectedOption?._id
															? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
															: '#29185E',
												}}
											>
												{pd.inAppPrice.label}
											</PlanName>{' '}
											<SaveWrapper promo={promo}>
												{promo
													? pd.inAppPrice.promo_saving
													: pd.inAppPrice.saving}
											</SaveWrapper>{' '}
										</label>
									</div>
									{pd._id === selectedOption?._id && (
										<PriceInfoWrapper>
											<TextWrapper>
												<Text>{pd.inAppPrice.full_label}</Text>
												<Text>{pd.inAppPrice.orginal_full_price}</Text>
											</TextWrapper>
											<TextWrapper>
												<Text>
													{promo
														? pd.inAppPrice.promo_discount_label
														: pd.inAppPrice.discount_label}
												</Text>
												<Text
													style={{
														color: `${
															promo ? '#149069' : 'var(--pink, #E94F96)'
														}`,
													}}
												>
													{promo
														? pd.inAppPrice.promo_discount_value
														: pd.inAppPrice.discount_value}
												</Text>
											</TextWrapper>
											<Border />
											<TextWrapper>
												<Text>{translations.total}</Text>
												<Text>
													<b>
														{' '}
														{promo ? pd.promo_full_price : pd.full_price}{' '}
														{translations.currencySign}
													</b>
												</Text>
											</TextWrapper>
										</PriceInfoWrapper>
									)}
								</PriceWrapper>
							))}
					</div>
				</div>
			</LeftInfo>
			<ActionWrapper onSubmit={(e) => handleCreateOrder(e)}>
				<TitleDesktop>{translations.rightTitle}</TitleDesktop>
				<EmailBox>
					<InputWrapper style={{ zIndex: 4 }}>
						<Label>{translations.email}</Label>
						<StyledInput
							ref={emailRef}
							id='email-synthiai'
							type='email'
							required
							onChange={(e) =>
								handleEmailChange(e.target.value, lang, setCorrectionEmail)
							}
							defaultValue={email}
							placeholder={translations.placeHolderEmail}
							style={{ border: 'none', padding: '8px 0px 8px 14px' }}
						/>
					</InputWrapper>
					{correctionEmail && (
						<HintMail
							onClick={() => {
								emailRef.current.value = correctionEmail;
								setCorrectionEmail(() => null);
							}}
						>
							{translations.emailGuessText}{' '}
							<FormatMailCorrection>{correctionEmail}</FormatMailCorrection> ?
						</HintMail>
					)}
				</EmailBox>
				<LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
					<input type='checkbox' id='rules_2' required defaultChecked />
					<label
						htmlFor='rules_2'
						style={{
							cursor: 'pointer',
							display: 'flex',
							flexWrap: 'wrap',
							gap: '3px',
							maxWidth: '300px',
							padding: '0 0 0 5px ',
						}}
					>
						{translations.accept}{' '}
						<a
							href={generateLinkToProductPage(lang, common.termsLink)}
							style={{ textDecoration: 'underline' }}
						>
							{' '}
							{translations.rules}
						</a>
						{translations.space}
						<a
							href={generateLinkToProductPage(lang, common.privacyLink)}
							style={{
								textDecoration: 'underline',
							}}
						>
							{translations.tos}
						</a>
						SynthiAi
					</label>
				</LinkWrapper>
				<LetMailButton type='submit' style={{ fontSize: '18px' }}>
					{translations.pay}
				</LetMailButton>
				<SavePayment />
			</ActionWrapper>
		</Wrapper>
	);
};

export default OnePagePayuPayment;
