import React, { useRef, useState } from 'react';
import RegenerateTask from '../../icon/regenerate_task.svg';
import TranslationIcon from '../../icon/translationIcon.svg';
import { useMutation } from 'react-query';
import { getTranslationCommonWorkflow, transcript } from '@/api/query/workflow';
import {
  changeTaskInImageMode,
  generateNewTask,
  responseToTask,
} from '@/api/query/image-mode';
import Loading from '@/common/components/Loading';
import styled from 'styled-components';
import SendIcon from '../../icon/white-icon-send.svg';
import Microfon from '@/common/components/Microfon';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import AudioRecordingContextProvider from '@/common/contexts/AudioRecordingContext';

const ImageWrapper = styled.div``;

const TextAreaFotoMode = styled.textarea`
  margin-top: 20px;
  width: 80vw;
  height: 100px;
  padding: 10px 20px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f4f6f8;
  border: none;
  resize: none;

  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
  color: #29185e;
  max-width: 550px;

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #eef2f5;
    font-style: italic;
    color: #868686;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;

  margin: 10px 0;
`;
const ButtonSend = styled.button`
  display: inline-flex;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  &:disabled {
    background-color: gray;
  }

  padding: 8px 20px 8px 20px;
  margin-bottom: 10px;
`;

const TaskDescitpion = styled.p`
  color: #29185e;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 28px */
  text-align: left;
  max-width: 450px;
  margin: 0;
  min-height: 100px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: -30px;

  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: -10px;
  }
`;

const TaskTextWrapper = styled.div`
  width: 40vw;

  @media (max-width: 600px) {
    width: calc(100vw - 40px);
    overflow-x: hidden;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  @media screen {
    margin-top: 20px;
  }
`;

const ImageModeView = ({ data, setData, setConversation, setAsk }) => {
  const [translation, setTranslation] = useState();
  const [submited, setSubmited] = useState(!!data.answer);
  const responseRef = useRef();

  const { startTyping, newTask, send } = useLanguageData(
    'features.fotoMode.info',
  );

  const changeTaskMutation = useMutation({
    mutationFn: (value) => changeTaskInImageMode(value),
    onSuccess: ({ data }) => {
      setData((prev) => ({ ...prev, task: data }));
      setTranslation(() => null);
    },
  });

  const handleTranslateMutation = useMutation({
    mutationFn: (value) => getTranslationCommonWorkflow(value),
    onSuccess: ({ data }) => {
      setTranslation(data.translation);
    },
  });

  const handleResponseToTaskMutation = useMutation({
    mutationFn: (value) => responseToTask(value),
    onSuccess: () => {
      setAsk(true);
      setData((data) => ({ ...data, answer: responseRef.current.value }));
      setSubmited(true);
    },
  });

  const handleNewTaskkMutation = useMutation({
    mutationFn: (value) => generateNewTask(value),
    onSuccess: ({ data }) => {
      setData(() => data.imageModeResponse);
      setConversation(() => []);
      responseRef.current.value = '';
      setSubmited(false);
    },
  });

  const handleChangeTask = () => {
    if (submited) {
      return;
    }

    const payload = {};
    payload.imageResponseId = data._id;

    changeTaskMutation.mutate(payload);
  };

  const handleTranslate = () => {
    if (translation) {
      return setTranslation(() => null);
    }
    const payload = {};
    payload.message = data.task;
    payload.name = 'name';

    handleTranslateMutation.mutate(payload);
  };

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcript(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;
      responseRef.current.value = responseRef.current.value + ' ' + data;
    },
  });

  const handleSend = (voice) => {
    transcriptMutation.mutate(voice);
  };

  const handleReplyToTask = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.response = responseRef.current.value;
    payload.imageResponseId = data._id;

    handleResponseToTaskMutation.mutate(payload);
  };

  const handleNewTask = () => {
    const payload = {};
    payload.imageResponseId = data._id;

    handleNewTaskkMutation.mutate(payload);
  };

  return (
    <>
      {(handleTranslateMutation.isLoading ||
        changeTaskMutation.isLoading ||
        handleResponseToTaskMutation.isLoading ||
        handleNewTaskkMutation.isLoading ||
        transcriptMutation.isLoading) && <Loading />}
      <AudioRecordingContextProvider>
        <Wrapper>
          <ImageWrapper
            style={{
              width: '70vw',
              aspectRatio: 1,
              maxWidth: '330px',
              overflow: 'hidden',
              borderRadius: '10px',
            }}
          >
            <img
              src={data.image_mode.image.base64}
              style={{ width: '100%', height: 'auto' }}
              alt={data.name}
            />
          </ImageWrapper>
          <TaskTextWrapper>
            <TaskDescitpion>{translation || data.task}</TaskDescitpion>
            <IconWrapper>
              <img
                src={RegenerateTask}
                onClick={handleChangeTask}
                className="animation__grow"
                style={{ cursor: 'pointer' }}
              />
              <img
                src={TranslationIcon}
                onClick={handleTranslate}
                className="animation__grow"
                style={{ cursor: 'pointer' }}
              />
            </IconWrapper>
            <form onSubmit={handleReplyToTask} style={{ width: '100%' }}>
              <TextAreaFotoMode
                ref={responseRef}
                placeholder={startTyping}
                required
                defaultValue={data.answer || ''}
                disabled={submited}
              />
              <ButtonWrapper>
                {!submited && (
                  <>
                    <ButtonSend
                      type="submit"
                      disabled={submited}
                      className="animation__grow"
                    >
                      {send}{' '}
                      <img
                        src={SendIcon}
                        style={{ marginLeft: '10px', width: '18px' }}
                        alt="send icon"
                      />
                    </ButtonSend>
                    <Microfon handleSend={handleSend} />
                  </>
                )}
                {submited && (
                  <ButtonSend
                    type="button"
                    onClick={handleNewTask}
                    className="animation__grow"
                  >
                    {newTask}
                  </ButtonSend>
                )}
              </ButtonWrapper>
            </form>
          </TaskTextWrapper>
        </Wrapper>
      </AudioRecordingContextProvider>
    </>
  );
};

export default ImageModeView;
