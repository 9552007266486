import React, { useContext, useMemo } from 'react';
import { RegisterContext } from '../../context/register.context';
import styled from 'styled-components';
import Visa from '../../icons/Visa blue.svg';
import Master from '../../icons/mastercard.svg';
import Blik from '../../icons/blik.svg';
import Transfer from '../../icons/przelewy.svg';
import Payu from '../../svg/payou.logo.svg';
import { useMutation } from 'react-query';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import SynthiAiSumImgage from './images/synthi_summary.png';
import Lock from '../../icons/secured-lock.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import moment from 'moment';
import { generateLinkToProductPage } from '@/common/functions/generateLinkToProductPage';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { createOneOffPayment } from '@/api/query/payment';
import { PAYMENT_OPERATORS } from '@/common/constants/paymentOperators';
import { APPLICATION_PAYWALL_UTMS } from '../../../../../common/constants/application.utms';

const PaymentIcon = styled.img`
	width: 60px;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 75vw;
	background: #fff;
	padding: 10px 30px;

	border-radius: 10px;

	@media (min-width: 600px) {
		display: none;
	}
`;

const SubTitle = styled.p`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;

	@media (min-width: 600px) {
		text-align: left;
		color: #05275d;
		font-family: 'Work Sans';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;

const Price = styled.p`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.4px;
`;

const Pacakge = styled.div`
	display: flex;
	padding: 10px;
	gap: 20px;

	@media (min-width: 600px) {
		padding: 10px 0px 20px 0;
	}
`;

const DataText = styled.p`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: left;
	width: 100%;
	margin: 0;
`;

const Border = styled.div`
	height: 1px;
	width: 100%;
	margin-top: 10px;
	background: rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	border-radius: 5px;
	border: 1px solid rgba(79, 167, 180, 0.2);
	background: #fff;
	padding: 5px;
`;

const ActionWrapper = styled.form`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin: 0px 0;

	@media (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

const LetMailButton = styled.button`
	display: inline-flex;
	padding: 9px 82px 10px 82px;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	color: #fff;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
`;

const PayuLink = styled.a`
	color: #05275d;
`;

const TitleMobile = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.56px;
	margin: 20px 0;

	@media (min-width: 600px) {
		@media (min-width: 600px) {
			color: #29185e;
			text-align: center;
			font-family: 'Work Sans';
			font-size: 28px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}
`;
const TopPlanData = styled.div`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 5px;
`;

const BottomPlanData = styled.div`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const LinkWrapper = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const MainWrapperContent = styled.div`
	display: flex;
	flex-direction: column;

	@media (max-width: 600px) {
		height: calc(var(--vh, 1vh) * 100 - 100px);
	}
`;

const PaymentTitle = styled.p`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.32px;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
`;

const WrapperDesktop = styled.div`
	display: flex;
	gap: 40px;
	margin-bottom: 50px;

	@media (max-width: 600px) {
		display: none;
	}
`;

const Left = styled.div``;

const Right = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const OneTimePaymentView = () => {
	const { user, plan } = useContext(RegisterContext);
	const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);
	const data = useLanguageData('register.stepThree');
	const common = useLanguageData('common');
	const paywall = useLanguageData('paywall');

	const { lang } = useContext(LanguageContext);

	const handlePickPlanMutation = useMutation({
		mutationFn: (value) => createOneOffPayment(value),
		onSuccess: ({ data }) => {
			localStorage.setItem('user_register', JSON.stringify(data.customerId));
			window.location.href = data.link;
		},
	});

	const isGoingToExpired = useMemo(() => {
		if (!user) {
			return true;
		}

		if (!user?.active) {
			return false;
		}

		if (user?.order?.expired_date) {
			const treshold = moment();
			const expiredDate = moment(user.order.expired_date);

			if (expiredDate.diff(treshold, 'days') > 0) {
				return expiredDate.diff(treshold, 'days');
			}
		}

		return false;
	}, [user]);

	const handleCreateOrder = (e) => {
		e.preventDefault();

		handleFbStats('AddPaymentInfo', {});
		const payload = {};
		payload.email = user.email;
		payload.type = PAYMENT_OPERATORS.PAYU;
		payload.paymentUtms = APPLICATION_PAYWALL_UTMS;
		payload.plan = plan._id;
		payload.lang = lang;

		if (isGoingToExpired) {
			handleSendIPstats(IP_STATS_ENUM.APP_MAIN_SCREEN_BUY_PLAN_SUMMARY);
		} else if (user?.order?.expired_date) {
			handleSendIPstats(IP_STATS_ENUM.APP_MAIN_SCREEN_EXPIRED_BUY_PLAN_SUMMARY);
		} else {
			handleSendIPstats(IP_STATS_ENUM.PAY);
			window?.fbq('track', 'AddPaymentInfo');
			window?.gtag('event', 'add_payment_info', {});
		}

		handlePickPlanMutation.mutate(payload);
	};

	return (
		<MainWrapperContent>
			<TitleMobile>{data.paymentTitle}</TitleMobile>
			<Wrapper>
				<SubTitle>{paywall.data}</SubTitle>
				<DataText style={{ marginTop: '10px' }}>
					{user.name} {user.surname}
				</DataText>
				<DataText>{user.email}</DataText>
				<Border style={{ marginTop: '20px' }} />
				<Pacakge>
					<div style={{ width: '90px' }}>
						<img
							src={SynthiAiSumImgage}
							style={{ width: '100%', height: 'auto' }}
						/>
					</div>
					<div>
						<TopPlanData>
							{paywall.plan} {plan?.type?.label}
						</TopPlanData>
						<BottomPlanData>
							{paywall.forLong} {plan?.inAppPrice?.label}
						</BottomPlanData>
					</div>
				</Pacakge>
				<Border style={{ marginTop: '-5px' }} />
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					<SubTitle>{paywall.toPay}</SubTitle>
					<Price>{plan?.inAppPrice?.promotion_full_price_info}</Price>
				</div>
			</Wrapper>
			<WrapperDesktop>
				<Left>
					<div style={{ width: '200px' }}>
						<img
							src={SynthiAiSumImgage}
							style={{ width: '100%', height: 'auto' }}
						/>
					</div>
				</Left>
				<Right>
					<SubTitle>{paywall.data}</SubTitle>
					<DataText style={{ marginTop: '0px' }}>
						{user.name} {user.surname}
					</DataText>
					<DataText style={{ marginTop: '-5px' }}>{user.email}</DataText>
					<Border style={{ marginTop: '20px' }} />
					<Pacakge>
						<div>
							<TopPlanData>
								{paywall.plan} {plan?.type?.label}
							</TopPlanData>
							<BottomPlanData>
								{paywall.forLong} {plan?.inAppPrice?.label}
							</BottomPlanData>
						</div>
					</Pacakge>
					<Border style={{ marginTop: '-5px' }} />
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
							marginBottom: '-10px',
						}}
					>
						<SubTitle>{paywall.toPay}</SubTitle>
						<Price>{plan?.inAppPrice?.promotion_full_price_info}</Price>
					</div>
				</Right>
			</WrapperDesktop>
			<div style={{ flex: 1 }} />
			<ActionWrapper onSubmit={(e) => handleCreateOrder(e)}>
				<LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
					<input type='checkbox' id='rules_2' required defaultChecked />
					<label
						htmlFor='rules_2'
						style={{
							cursor: 'pointer',
							display: 'flex',
							gap: '3px',
							padding: '0 0 0 5px ',
						}}
					>
						{paywall.accept}{' '}
						<div
							onClick={() =>
								window.open(generateLinkToProductPage(lang, common.termsLink))
							}
							style={{ textDecoration: 'underline' }}
						>
							{' '}
							{paywall.rules}
						</div>
						SynthiAi
					</label>
				</LinkWrapper>
				<LinkWrapper
					htmlFor='rules_1'
					style={{
						marginTop: '0px',
						minWidth: '180px',
						fontSize: '12px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{paywall.payingAccept}{' '}
					<PayuLink
						href='https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf'
						target='_blank'
						style={{ paddingLeft: '5px' }}
					>
						{' '}
						{paywall.paymentRules}
					</PayuLink>
					.
				</LinkWrapper>
				<LetMailButton
					type='submit'
					style={{ fontSize: '18px', margin: '5px 0' }}
				>
					{data.buyAndPay}
				</LetMailButton>
				<PaymentTitle>
					<img src={Lock} /> {paywall.savePayment}{' '}
					<PaymentIcon src={Payu} style={{ width: '45px' }} />
				</PaymentTitle>
				<ImageWrapper style={{ marginTop: '-15px' }}>
					<PaymentIcon src={Blik} style={{ width: '30px' }} />
					<PaymentIcon
						src={Visa}
						style={{
							width: '30px',
						}}
					/>
					<PaymentIcon
						src={Master}
						style={{
							width: '20px',
						}}
					/>
					<PaymentIcon
						src={Transfer}
						style={{
							width: '45px',
						}}
					/>
				</ImageWrapper>
			</ActionWrapper>
		</MainWrapperContent>
	);
};

export default OneTimePaymentView;
