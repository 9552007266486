export const MESSAGE_DATA_REDUCER_ACTION = {
  CONVERSATION_WITH_CHAT_GPT: 'conversationWithChatGPT',
  CURRENT: 'current',
  REMOVE_SHOW_MESSAGES_TRANSLATIONS: 'removeShowMessagesTranslations',
  ADD_SHOW_MESSAGES_TRANSLATIONS: 'addShowMessagesTranslations',
  TRANSLATION_MESSAGES: 'translationMessages',
  REMOVE_LOADING_TRANSLATIONS: 'removeLoadingTranslations',
  ADD_LOADING_TRANSLATIONS: 'addLoadingTranslations',
  LOADING_VOICE: 'loadingVoice',
  RECORDINGS: 'recordings',
  VOICE_TALKING: 'voiceTalking',
  IS_HINT_LOADING: 'isHintLoading',
  HINT_DATA: 'hintData',
  MESSAGE_SCORE: 'messageScore',
  SHOW_PROBLEM: 'showProblem',
  HANDLE_SHOW_BAD_MESSAGE: 'handleShowBadMessage',
  TASK_COMPLETED: 'taskCompleted',
};
