import React from 'react';
import styled from 'styled-components';
import IconClose from '@/common/images/group.svg';
import IconRight from '@/common/images/arr-right.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(41, 24, 94, 0.41);
  backdrop-filter: blur(8px);
  z-index: 90;
`;

const WhiteBox = styled.div`
  height: fit-content;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: #f4f6f8;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 12px 0 12px;
`;

const MiddleContent = styled.div`
  padding: 12px;
  margin: 0px 12px;
  text-align: left;
  background: #fff;
`;

const Title = styled.div`
  color: #868686;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div`
  color: #29185e;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  margin-top: 12px;
`;

const TranslationText = styled.div`
  color: #868686;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
  margin-top: 4px;
`;

const TopBox = styled.div`
  border-radius: 8px;
  background: #c4c4c4;
  width: 97px;
  height: 4px;
  flex-shrink: 0;
`;

const CloseIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: -2px;
  margin-top: 8px;
`;

const Button = styled.button`
  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );
  color: #fff;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 12.35px */
  letter-spacing: 0.2px;
`;

const MiddleContentWrappper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => (props.isOkay ? '#32951B' : '#F5BC2F')};
`;

const ScoreBoxMessage = ({ data, setClose }) => {
  const { yourMessage, goodMessage, explanation } =
    useLanguageData('dailyV2.feedback');

  return (
    <>
      <Wrapper>
        <div style={{ flex: 1 }} />
        <WhiteBox>
          <TopContent>
            <div style={{ width: '12px' }} />
            <div />
            <CloseIcon src={IconClose} onClick={() => setClose(() => null)} />
          </TopContent>
          <MiddleContentWrappper>
            <MiddleContent>
              <Title>
                {yourMessage} <Circle />
              </Title>
              <Text>{data?.student_message}</Text>
            </MiddleContent>
            <MiddleContent
              style={{
                borderTop: '1px solid #EEF2F5',
              }}
            >
              <Title>
                {goodMessage} <Circle isOkay={true} />
              </Title>
              <Text>{data?.correct_message}</Text>
            </MiddleContent>
            <MiddleContent
              style={{
                borderTop: '1px solid #EEF2F5',
              }}
            >
              <Title>{explanation}</Title>
              <Text>{data?.explanation}</Text>
            </MiddleContent>
          </MiddleContentWrappper>
        </WhiteBox>
      </Wrapper>
    </>
  );
};

export default ScoreBoxMessage;
