import { TextInfomation } from '@/App.styles';
import React from 'react';
import { TextInformationWrapper } from './TalkiMessage';

const UserMessage = ({ message }) => {
	return (
		<TextInformationWrapper>
			<TextInfomation>{message}</TextInfomation>
		</TextInformationWrapper>
	);
};

export default UserMessage;
