export const getCountryFromLang = (lang) => {
	if (lang === 'sv') {
		return 'se';
	}

	if (lang === 'et') {
		return 'ee';
	}

	if (lang === 'cs') {
		return 'cz';
	}

	if (lang === 'el') {
		return 'gr';
	}

	if (lang === 'sl') {
		return 'si';
	}

	return lang;
};
