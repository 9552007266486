import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import Loading from '../Loading';
import styled from 'styled-components';
import { createHands } from '@/api/query/hands';
import PopUp from '../PopUp';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { useContext } from 'react';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';

const HAND_DECISION = {
  UP: 'HAND_UP',
  DOWN: 'HAND_DOWN',
  PROBLEM: 'PROBLEM',
  HINT_PROBLEM: 'HINT_PROBLEM',
};

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 12px;
`;

export const SOURCES_TYPES = {
  DAILY: 'DAILY',
  DAILY_V2: 'DAILY_V2',
  SCENE: 'SCENE',
  WORD: 'WORD',
  FLASH_CARD: 'FLASH_CARD',
  FLASH_CARD_NEW: 'FLASH_CARD_NEW',
  FOTO_MODE: 'FOTO_MODE',
  CALL_MODE: 'CALL_MODE',
};

const Skip = styled.button`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
  background-color: white;

  cursor: pointer;
`;

const Send = styled.button`
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: 10px 74px 10px 75px;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );
  cursor: pointer;
  @media (max-width: 600px) {
    width: 200px;
    padding: 10px 35px 10px 35px;
  }
`;

const P = styled.p`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;
  margin-top: 10px;
  margin-bottom: 18px;
`;

const TitlePopUp = styled.h2`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 0;

  @media (max-width: 600px) {
    max-width: 280px;
  }
`;

const SubTitlePopUp = styled.h3`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;

const TextArea = styled.textarea`
  width: 700px;
  height: 92px;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #eef2f5;
  outline: none;
  border: none;
  resize: none;

  color: #05275d;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;

  @media (max-width: 600px) {
    width: 300px;
    height: 140px;
    padding: 5px;
  }
`;

const LetterCounterWrapper = styled.p`
  text-align: right;
  color: #868686;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: right;
  }
`;

const PopUpWrapper = styled.form`
  padding: 50px;

  @media (max-width: 600px) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ProblemFlashcard = ({ flashcard, setShowProblem, type }) => {
  const [lengthCounter, setLengthCounter] = useState(0);
  const { title, subtitle, send, messageSend, error } =
    useLanguageData('problem');

  const { addMessage } = useContext(MessageContext);

  const messegeRef = useRef();

  const handleAddHandMutation = useMutation({
    mutationFn: (payload) => createHands(payload),
    onSuccess: () => {
      addMessage(messageSend, 'success');
      setShowProblem(null);
    },
    onError: () => {
      addMessage(error, 'error');
    },
  });

  const handleAddHand = (e) => {
    e?.preventDefault();

    const form = new FormData();

    form.set('decision', HAND_DECISION.PROBLEM);
    form.set('type', type);
    form.set('flashcard', flashcard);
    form.set('message', messegeRef?.current?.value);

    form.set("window_size", `${window?.innerWidth}x${window?.innerHeight}`);
    form.set("device_description", window?.navigator?.userAgent);

    handleAddHandMutation.mutate(form);
  };

  const handleChange = (e) => {
    const message = messegeRef.current.value;

    if (message.length > 300) {
      return (messegeRef.current.value = message.slice(0, 300));
    }

    setLengthCounter(() => message.length);
  };

  return (
    <>
      {handleAddHandMutation.isLoading && <Loading />}
      <PopUp setClose={() => setShowProblem(null)}>
        <PopUpWrapper onSubmit={handleAddHand}>
          <TitlePopUp>{title}</TitlePopUp> <P>{subtitle}</P>
          <TextArea
            ref={messegeRef}
            onChange={(e) => handleChange(e)}
            required={true}
          />
          <LetterCounterWrapper>{lengthCounter} / 300</LetterCounterWrapper>
          <ButtonsWrapper>
            <Send type="submit">{send}</Send>
          </ButtonsWrapper>
        </PopUpWrapper>
      </PopUp>
    </>
  );
};

export default ProblemFlashcard;
