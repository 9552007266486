import PopUp from '@/common/components/PopUp';
import React from 'react';
import styled from 'styled-components';
import Plus from '../images/plus-tutorial.svg';
import Heart from '../images/heart.svg';
import LeftImage from '../images/sport-category.svg 15-18-31-024.svg';
import RightImage from '../images/add-new-category.svg';
import { useMutation } from 'react-query';
import { markFlashcardTutorialPopup } from '@/api/query/customer';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';
const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin-top: 22px;
`;
const Subtitle = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 12px */
  text-align: center;
  margin: 18px 0 10px 0;
`;
const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  max-width: 290px;
  padding: 0 20px;
`;
const CloseButton = styled.button`
  padding: 14px;
  width: 200px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  border-radius: 100px;
  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);
  margin: 20px 0;
`;
const ImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-top: 22px;
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 12px;
  div {
    display: flex;
    align-items: center;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const ImageTextOne = styled.div`
  position: absolute;
  top: 42%;
  width: 100%;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
`;
const ImageTextTwo = styled.div`
  position: absolute;
  top: 52%;
  width: 100%;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;
`;
const ImageWrapperBox = styled.div`
  position: relative;
  width: 120px;
  img {
    width: 100%;
    height: auto;
  }
`;
const FlashcardCategoryTutorial = ({ setClose }) => {
  const translation = useLanguageData('learn.flashcardV2');
  const sawPopupMutation = useMutation({
    mutationFn: (payload) => markFlashcardTutorialPopup(payload),
    onSuccess: () => {
      setClose(() => false);
    },
  });
  const handleClose = () => {
    const payload = {
      type: 'category',
    };
    sawPopupMutation.mutate(payload);
  };
  return (
    <>
      {sawPopupMutation.isLoading && <Loading />}
      <PopUp setClose={() => handleClose()}>
        <Title>{translation?.howToUseFlashcard}</Title>
        <ImagesWrapper>
          <ImageWrapperBox>
            <img src={LeftImage} />
            <ImageTextOne>{translation.sport}</ImageTextOne>
          </ImageWrapperBox>
          <ImageWrapperBox>
            <img src={RightImage} style={{width: 105}} />
            <ImageTextTwo>{translation.addNewCategory}</ImageTextTwo>
          </ImageWrapperBox>
        </ImagesWrapper>
        <Text style={{ marginTop: '16px' }}>
          {translation?.clickCategoryToStart}
        </Text>
        <Text style={{ marginTop: '12px' }}>
          {translation?.lookAsProgressBar}
        </Text>
        <ContentWrapper>
          <div>
            <img src={Plus} />
          </div>
          <Text style={{ padding: 0, width: '260px' }}>
            {translation?.clickToSetupFavaroiteFlashcard}
          </Text>
        </ContentWrapper>
        <ContentWrapper>
          <div>
            <img src={Heart} />
          </div>
          <Text style={{ padding: 0, width: '260px' }}>
            {translation?.clickToCreateOwnCategory}
          </Text>
        </ContentWrapper>
        <Subtitle>{translation?.howLearningLookLike}</Subtitle>
        <Text>{translation?.restText}</Text>
        <ButtonWrapper>
          <CloseButton onClick={handleClose}>{translation?.close}</CloseButton>
        </ButtonWrapper>
      </PopUp>
    </>
  );
};
export default FlashcardCategoryTutorial;
