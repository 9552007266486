import React from "react";
import styled from "styled-components";
import Next from "./images/next.svg";
import Logo from "./images/synthiai-logo.svg";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin: 0;
  margin-top: 80px;
`;

const Continue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  display: inline-flex;
  padding: 14px 42px 13px 42px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;
  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);

  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  margin: 0;
  margin-top: 120px;
`;

const Welcome = ({ setPage }) => {
  const { welcomeInApp, start } = useLanguageData("welcome.zeroScreen");

  return (
    <>
      <div>
        <img src={Logo} />
      </div>
      <Title>{welcomeInApp}</Title>
      <Continue onClick={() => setPage(() => 1)}>{start}</Continue>
    </>
  );
};

export default Welcome;
