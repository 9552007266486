import Android1 from './images/Android_1_150_ET.png';
import Android2 from './images/Android_Dodaj_150_ET.png';
import Ios1 from './images/IOS_1_150_ET.png';
import Ios2 from './images/IOS_Dodaj_150_ET.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

import Micro from './images/wordTrainerTutorial/icon-microphone.png';
import Voice from './images/wordTrainerTutorial/volume-up.svg';
import Person from './images/wordTrainerTutorial/user.svg';

export default {
	passwordRestart: {
		title: 'Parooli taastamine',
		password: 'Parool',
		repeatPassword: 'Korrake parooli',
		restartPawssord: 'Parooli taastamine',
	},

	paywall: {
		title: 'Vali plaan, et jätkata õppimist',
		buy: 'Osta',
		savePayment: 'Turvaline makse järgmistel viisidel',
		skip: 'Vahelejätmine',
		pickPlan: 'Vali plaan',
		endPlanFirstPart: 'Sinu juurdepääs lõppeb',
		endPlanSecondPart: 'päeva pärast.',
		endPlanAditionalPart: 'päeva pärast.',
		paymentTitle: 'Kokkuvõte',
		product: 'Toode:',
		plan: 'SynthiAi Pakett',
		forLong: 'Ligipääs ajaks',
		data: 'andmed:',
		nameAndSurname: 'Ees- ja perekonnanimi:',
		paymentForm: 'Maksmisviis:',
		toPay: 'Tasuda täna:',
		accept: 'Nõustun',
		rules: 'tingimustega',
		payingAccept: 'Maksega nõustute',
		paymentRules: '"PayU maksetingimustega"',
		buyAndPay: 'OSTAN JA MAKSAN',
	},
	paymentStatus: {
		veryfication: 'Makse on veel töötlemisel...',
		title: 'Makse lõpetatud',
		success: 'Edukas!',
		failure: 'Ebaõnnestus!',
		subtitle: 'Peagi suuname sind sisselogimise lehele.',
		textInfo: `Teie makse töötleti edukalt. Nüüd saate oma parooli seadistada, minnes meie registreerimislehele. Mõne minuti jooksul saate e-kirja koos lingiga, mis võimaldab teil parooli seadistada.
Palun kontrollige oma postkasti, samuti rämpsposti kausta. Otsingut lihtsustades sisestage otsinguväljale "synthiai". Kui tekib probleeme, võtke meiega ühendust aadressil: contact@synthiaiapp.com`,
		login: 'Logi sisse',
	},
	register: {
		steps: {
			register: 'Registreerimine',
			package: 'Pakett',
			summarize: 'Kokkuvõte',
			step: 'Samm ',
		},
		stepOne: {
			password: 'Parool',
			errorGoogle: 'Logi sisse oma e-posti aadressiga',
			title: 'Registreerimine',
			nameAndSurname: 'Nimi ja perekonnanimi',
			placeholderNameAndSurname: 'XXXXXXXXXXX',
			email: 'E-posti aadress',
			placeholderEmail: 'nt. pelda@synthiaiapp.com',
			password: 'Parool',
			start: 'KONTO LOOMINE',
			subtitle:
				'Parool peab olema vähemalt 8 tähemärki pikk, sisaldama nii väikeseid kui ka suuri tähti ning ühte numbrit.',
			passwordError1: 'Parool peab sisaldama vähemalt 8 tähemärki.',
			passwordError2: 'Parool peab sisaldama vähemalt ühte suurtähte.',
			passwordError3: 'Parool peab sisaldama vähemalt ühte väiketähte.',
			passwordError4: 'Parool peab sisaldama vähemalt ühte numbrit.',
			passwordError5: 'Paroolid peavad olema identsed.',
		},
		stepTwo: {
			title: 'Vali plaan',
			month: 'kuu',
			months: 'kuud',
			months2: 'kuud',
			value: '€ / kuu',
			pick: 'vali',
			nowPay: 'Kohene makse',
			saveOne: 'Salvesta',
			buy: 'Osta',
			savePayment: 'Turvaline makse järgmistel viisidel',
		},
		stepThree: {
			title: 'Kokkuvõte',
			product: 'Toode:',
			plan: 'Synthi AI Pakett',
			forLong: 'Juurdepääs',
			data: 'kuupäev:',
			nameAndSurname: 'Nimi ja perekonnanimi:',
			paymentForm: 'Makseviis:',
			toPay: 'Makse:',
			accept: 'Nõustun',
			rules: 'tingimustega',
			payingAccept: 'Makse tegemisega nõustud',
			paymentRules: '"PayU Makse Tingimustega"',
			buyAndPay: 'OSTA JA MAKSA',
		},
	},
	succesPayment: {
		title: 'Makse õnnestus!',
		subtitle: 'Peagi suuname sind sisselogimise lehele.',
	},
	firstLogin: {
		createPasswordForAcoount: 'Loo parool oma kontole',
		typeEmail: 'Sisestage e-posti aadress',
		useSameEmail: 'Kasutage sama aadressi, mille esitasite ostmisel',
		typePassword: 'Sisestage parool',
		confirmPassword: 'Kinnitage parool',
		createPassword: 'Loo parool',
		errorLogin: 'Kontrollige, kas teie parool või e-post on õige',
		createdPassword: 'Kas olete juba parooli loonud?',
		clickToLogin: 'Klõpsake siia, et sisse logida',
	},
	login: {
		dontHavePassword: 'Kas teil pole veel parooli?',
		getPassword: 'Klõpsake siin, et saada juurdepääs',
		title: 'Sisselogimine',
		email: 'E-posti aadress',
		password: 'Parool',
		textSub: 'Unustasid parooli? Taasta',
		restart: 'siit',
		restartPasswordText:
			'Sisesta oma e-posti aadress parooli taastamiseks. Parool saadetakse sinu saabuva posti kausta.',
		placeholder: 'sisesta oma e-posti aadress...',
		resetPassword: 'parooli taastamine',
		buttonText: 'Logi sisse',
		errorLogin: 'Palun kontrolli oma e-posti aadressi ja parooli.',
		passwordResterted:
			'Sinu parool on taastatud. Kontrolli oma e-posti kontot.',
		passwordError: 'Kahjuks ei leitud sinu e-posti aadressi.',
		mobileAppBannerText: 'Avasta meie rakendus!',
	},
	navBar: {
		home: 'Avaleht',
		learn: 'Õppimine',
		profile: 'Profiil',
	},
	home: {
		hello: 'Tere',
		question: 'Millest soovid täna rääkida?',
		answer: 'Alusta vestlust',
		forYou: 'Sulle soovitatud',
		popularNow: 'Praegu populaarne',
		doYouKnow: 'Kas tead, et...?',
		funFact: 'Tänane huvitav fakt',
		new: 'Uus',
		check: 'Kontrolli',
		yourFavorite: 'Sinu lemmikud',
		newTitle: 'Sõnatreener',
		newSubtitle: 'Harjuta sõnade hääldust',
		talkWith: 'Räägi igal teemal koos',
	},
	learn: {
		wordTrainer: {
			title: 'Sõnatreener',
			subtitle: 'Harjuta sõnade hääldust',
			new: 'Uus',
		},
		flashcardV2: {
			new: 'Uus',
			sport: 'Sport',
			continue: 'jätka',
			nice: 'Suurepärane!',
			congratulation: 'Palju õnne!',
			category: 'Kategooria',
			textAfterCategory: 'pole sinu jaoks saladus!',
			pickNewCategory: 'Vali uus kategooria',
			showResult: 'Näita lahendust',
			createWordWithLetters: 'Koosta sõna segamini tähtedest',
			know: 'Tean',
			dontKnow: 'Ei tea',
			metchWords: 'Sobita paaridesse',
			addNewCategory: 'Lisa uus kategooria',
			personalizeLearning: 'Kohanda oma õppimist, luues enda kategooria.',
			typeNewCategory: 'Sisesta oma kategooria nimi',
			save: 'Salvesta',
			weAreCreatingCategoryForYou: 'Loodame teile uue kategooria:',
			start: 'ALUSTA',
			howToUseFlashcard: 'Kuidas kasutada välgukaarte?',
			knowFlashcard: 'Klõpsa „Ei tea” või „Tean”, et hinnata oma teadmisi.',
			dontKnowFlashcard: 'Kaardid, mis on märgitud „Tean”, ei kordu.',
			clickToHear: 'Klõpsa, et kuulda sõna.',
			clickForDefinition: 'Klõpsa, et näha definitsiooni',
			clickForTranslation: 'Klõpsa, et näha tõlget ja näiteid',
			close: 'Sulge',
			clickCategoryToStart: 'Klõpsa valitud kategoorial, et õppimist alustada.',
			lookAsProgressBar:
				'Edenemisriba kategooria all näitab, kui palju sõnu oled juba omandanud.',
			clickToSetupFavaroiteFlashcard:
				'Klõpsa, et määrata kategooria lemmikuks.',
			clickToCreateOwnCategory: 'Klõpsa, et luua oma välgukaartide kategooria.',
			howLearningLookLike: 'Milline õppimine välja näeb?',
			restText:
				' Õppimine seisneb kaartide läbivaatamises ja teadmiste hindamises. Lisaks lahendate lihtsaid ülesandeid.',
		},
		chat: {
			title: 'Vestlus',
			subtitle: 'Rääkige mistahes teemal.',
		},
		fotoMode: {
			title: 'Arva pilt ära',
			subtitle: 'Kirjeldage pilti nii täpselt kui võimalik.',
		},
		scene: {
			title: 'Stseenid',
			subtitle: 'Mängi AI õpetajaga.',
		},
		word: {
			title: 'Sõnad',
			subtitle: 'Rikkaenda oma sõnavara.',
		},
		flashcard: {
			title: 'Mälukaardid',
			subtitle: 'Korda ja mälukaardista sõnu.',
		},
		seeMore: 'Vaata kõiki',
	},
	common: {
		description:
			'SynthiAi - Teie nutikas inglise keele õppimise assistent. Õppige inglise keelt sujuvalt AI abiga. Avastage uus ja tõhus keeleõppe lähenemine. Alustage täna!',
		deletedFromFavorite: 'Eemaldatud lemmikutest',
		addedToFavorite: 'Lisatud lemmikutesse',
		flashcards: 'Mälukaardid',
		flashcards_v2: 'Mälukaardid',
		scenes: 'Stseenid',
		words: 'Sõnad',
		privacyLink: 'privacy',
		termsLink: 'tos',
		or: 'või',
		continueWithFB: 'Jätka Facebookiga',
	},
	profile: {
		days: [
			'Esmaspäev',
			'Teisipäev',
			'Kolmapäev',
			'Neljapäev',
			'Reede',
			'Laupäev',
			'Pühapäev',
		],
		top: {
			profile: 'Profiil',
			accessTo: 'Juurdepääs:',
			profileImage: 'Profiilipilt',
			nameAndSurname: 'Nimi ja perekonnanimi',
			password: 'Parool',
			change: 'Muutmine',
			changeProfileImage: 'Profiilipildi muutmine',
			changeNameAndSurname: 'Nime ja perekonnanime muutmine',
			changeNameAndSurnamePlaceHolder: 'Sisesta oma nimi',
			save: 'Salvesta',
			changePassword: 'Parooli muutmine',
			passwordInfo:
				'Parool peab olema vähemalt 8 tähemärki pikk, sisaldama nii väikeseid kui ka suuri tähti ning ühte numbrit.',
			repeatPassword: 'Korrake parooli',
			passwordChanged: 'Parool on muudetud',
			error: 'Ups, midagi läks valesti',
			nameChanged: 'Nimi on muudetud',
			profileChanged: 'Profiil on muudetud',
		},
		plan: {
			yourPlan: 'Sinu plaan',
			extend: 'Plaani pikendamine',
			short_extend: 'Pikendamine',
			currentPlan: 'Praegune plaan:',
			activeToPlan: 'Aktiivne kuni:',
			currentPlanLong: 'Sinu praegune plaan',
		},
		middle: {
			yourMedals: 'Teie saavutused',
			oneDay: 'päev',
			twoDays: 'päev',
			words: 'Sõnad',
			learningTime: 'Õppeaeg',
			streak: 'Järjepidevus',
			streakRecord: 'Järjepidevuse rekord',
			yourRecord: 'Sinu rekord:',
			currentRecord: 'Praegune rekord:',
			wordsInWeek: 'Nädala sõnad:',
		},
		bottom: {
			yourTutor: 'Teie AI õpetaja',
			change: 'Muuda',
			logout: 'Logi välja',
			FAQ: 'KKK',
			shareOpinion: 'Jaga oma arvamust',
			yourPlan: 'Teie plaan',
			hobby: 'Hobi',
			learningSetting: 'Õppimise seaded',
			back: 'Tagasi',
			dayTarget: 'Päevane eesmärk',
			languageLevel: 'Keele tase',
			changeYourLangLevel: 'Muuda oma keele taset',
			pickNewTutor: 'Vali uus õpetaja',
			yourDecision: 'Teie otsus mõjutab tundide stiili ja meeleolu.',
			tutroChanged: 'Õpetaja on muudetud',
			error: 'Ups, midagi läks valesti',
			englishLevelChanged: 'Keele tase on muudetud',
			levelsOptions: [
				{
					name: 'Algaja A1',
					description: 'Alusta inglise keele õppimist',
					value: 'basic',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Põhi A2',
					description: 'Mõistad põhiinfot lihtsates, igapäevastes vestlustes',
					borderRadius: '0',
					value: 'beginner',
				},
				{
					name: 'Kesktase B1',
					description:
						'Saad pidada lihtsaid, kuid arusaadavaid vestlusi tuttavatel teemadel.',
					borderRadius: '0',
					value: 'intermediate',
				},
				{
					name: 'Edasijõudnud B2',
					description: 'Enamikus olukordades suudad sujuvalt suhelda',
					borderRadius: '0',
					value: 'advanced',
				},
				{
					name: 'Ekspert C1+',
					description: 'Kasutad keelt tõhusalt keerulistes olukordades',
					value: 'expert',
					borderRadius: '0 0 8px 8px',
				},
			],
			changeDailyTarget: 'Muuda päevase õppe eesmärki.',
			deletedFromFavorite: 'Eemaldatud lemmikutest',
			addedToFavorite: 'Lisatud lemmikutesse',
			lessonTime: [
				{
					name: 'Kiire õppetund',
					dsecription: 'Ideaalne valik kiireks õppimiseks',
					time: '5 minutit',
					value: 'five_minutes',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Tavaline tund',
					dsecription: 'Tasakaalustatud õpikogemus',
					time: '10 minutit',
					value: 'ten_minutes',
					borderRadius: '0',
				},
				{
					name: 'Intensiivne harjutamine',
					dsecription: 'Parim valik entusiastlikele õppijatele',
					time: '15 minutit',
					value: 'fifteen_minutes',
					borderRadius: '0 0 8px 8px',
				},
			],
			dailyTargetChanged: 'Päevane eesmärk on muudetud!',
			pickIntresets: 'Valige vähemalt 3 hobi.',
			activeTo: 'Teie plaan on aktiivne kuni:',
			daysLeft: 'Teie plaani allesjäänud päevad:',
			opinionSubtitle:
				'Parandame pidevalt meie rakendust. Jagage oma arvamust, mida muudaksite rakenduses või mis teile seal kõige rohkem meeldib.',
			startTyping: 'Alusta kirjutamist',
			faq: 'KKK',
			send: 'Saada',
			withProblems: 'Kui sul on küsimusi/probleeme, võta meiega ühendust:',
			contactMail: 'contact@synthiaiapp.com',
			faqConfig: [
				{
					id: 1,
					question: 'Hääl ei tööta.',
					answer:
						'Kui sul on probleeme häälega, proovi järgmist:\n\nKontrolli brauseri helilube.\n\nVeendu, et sinu seadme helitugevus on sobiv.\n\nKontrolli, et kõlarid/kõrvaklapid on korralikult ühendatud.\n\nKontrolli süsteemi heliseadeid, et mõni rakendus ei oleks vaigistatud.\n\nProovi teises seadmes või võta ühendust tehnilise toega.',
				},
				{
					id: 2,
					question: 'Ei saa häält salvestada.',
					answer:
						'Kui sa ei saa häält salvestada, proovi järgmist:\n\nKontrolli mikrofonilube brauseris.\n\nVeendu, et mobiilne brauser saaks mikrofonile ligi.\n\nKontrolli, et mikrofon on korralikult ühendatud.\n\nProovi teises seadmes või võta ühendust tehnilise toega.',
				},
				{
					id: 3,
					question: 'Kas ma saan rakendust kasutada mitmes seadmes?',
					answer:
						'Jah, logi sisse igas ühilduvas seadmes, et saaksid sujuvalt õppida.',
				},
				{
					id: 4,
					question:
						'Kas ma saan Synthi AI-d kasutada ilma internetiühenduseta?',
					answer: 'Ei, Synthi AI vajab internetiühendust, et töötada.',
				},
				{
					id: 5,
					question: 'Kuidas ma saan oma AI õpetajat vahetada?',
					answer:
						"Mine 'Õpetaja' vahekaardile Synthi AI seadetes. Sirvi ja vali teine õpetaja.",
				},
				{
					id: 6,
					question: 'Kuidas ma saan oma juurdepääsu pärast aegumist uuendada?',
					answer: 'Aegumise järel saad sisselogida ja oma plaani uuendada.',
				},
				{
					id: 7,
					question: 'Kas ma saan arve?',
					answer:
						'Kirjuta aadressile contact@synthiaiapp.com ja anna oma maksunumber ja ettevõtte andmed.',
				},
				{
					id: 8,
					question: 'Kuidas ma saan tehnilise toega ühendust võtta?',
					answer:
						'Saada e-kiri aadressile contact@synthiaiapp.com, täpsustades probleem ja andmed seadme ja brauseri kohta.',
				},
			],
		},
		rules: 'tingimused',
		privacy: 'Privaatsuseeskirjad',
		span: ' ja ',
	},
	welcome: {
		languagePicker: {
			title: 'Vali keel, mida soovid õppida',
			next: 'Järgmine',
			langToLearn: 'Õpitav keel',
			successTitle: 'Õpitav keel on muudetud',
			config: [
				{
					label: 'Inglise',
					label2: 'Inglise keel',
					labelInside: 'inglise keelt',
					value: 'en',
				},
				{
					label: 'Saksa',
					label2: 'Saksa keel',
					labelInside: 'saksa keelt',
					value: 'de',
				},
				{
					label: 'Hispaania',
					label2: 'Hispaania keel',
					labelInside: 'hispaania keelt',
					value: 'es',
				},
				{
					label: 'Prantsuse',
					label2: 'Prantsuse keel',
					labelInside: 'prantsuse keelt',
					value: 'fr',
				},
				{
					label: 'Itaalia',
					label2: 'Itaalia keel',
					labelInside: 'itaalia keelt',
					value: 'it',
				},
			],
		},
		zeroScreen: {
			welcomeInApp: 'Tere tulemast Synthi AI rakendusse!',
			start: 'ALUSTA',
			welcomeMobile: 'Tere tulemast',
			config: {
				chrome: {
					title: 'Installeeri rakendus oma Android seadmesse.',
					text1: 'Chrome brauseris kliki',
					text1bold: ' Seaded',
					image1: Android1,
					text2: 'Seejärel kliki',
					text2bold: ' Lisa alguskuvale',
					image2: Android2,
					text3: 'Valmis! Synthi AI on nüüd sinu alguskuval',
					image3: Last,
				},
				safari: {
					title: 'Installeeri rakendus oma iOS seadmesse.',
					text1: 'Safari brauseris kliki',
					text1bold: ' Jaga',
					image1: Ios1,
					text2: 'Seejärel kliki',
					text2bold: ' Lisa alguskuvale',
					image2: Ios2,
					text3: 'Valmis! Synthi AI on nüüd sinu alguskuval',
					image3: Last,
				},
			},
		},
		firstScreen: {
			title: 'Vali oma õpetaja!',
			subtitle: 'Sinu valik mõjutab tundide stiili ja meeleolu.',
			next: 'TOVÁBB',
		},
		secondScreen: {
			title: 'Mis on sinu praegune {language} keele tase?',
			config: [
				{
					name: 'Alap A1',
					description: 'Alusta {language} keele õppimist.',
					value: 'basic',
				},
				{
					name: 'Põhi A2',
					description: 'Mõistad põhiinfot lihtsates vestlustes',
					value: 'beginner',
				},
				{
					name: 'Kesktase B1',
					description:
						'Suudad pidada lihtsaid, kuid arusaadavaid vestlusi tuttavatel teemadel.',
					value: 'intermediate',
				},
				{
					name: 'Edasijõudnud B2',
					description: 'Suudad sujuvalt suhelda enamikes olukordades',
					value: 'advanced',
				},
				{
					name: 'Ekspert C1+',
					description: 'Kasutad keelt tõhusalt keerulistes olukordades',
					value: 'expert',
				},
			],
			next: 'TOVÁBB',
		},
		thirdScreen: {
			title: 'Millised on sinu hobid?',
			subtitle: 'Vali vähemalt 3 hobit.',
			next: 'TOVÁBB',
		},
		fourthScreen: {
			title: 'Kui palju minuteid soovid päevas õppida?',
			subtitle: 'Vali oma päevane eesmärk.',
			next: 'ALUSTAME!',
			config: [
				{
					name: 'Kiire õppetund',
					dsecription: 'Ideaalne valik kiireks õppimiseks',
					time: '5 minutit',
					value: 'five_minutes',
				},
				{
					name: 'Tavaline tund',
					dsecription: 'Tasakaalustatud õpikogemus',
					time: '10 minutit',
					value: 'ten_minutes',
				},
				{
					name: 'Intensiivne harjutamine',
					dsecription: 'Parim valik entusiastlikele õppijatele',
					time: '15 minutit',
					value: 'fifteen_minutes',
				},
			],
		},
	},
	features: {
		wordTrainer: {
			next: 'Järgmine',
			yourSpeaking: 'Sinu hääldus',
			you: 'Sina',
			title: 'Sõnatreener',
			errorMessage:
				'Oh ei! Meil on probleeme sinu salvestuse töötlemisega. Proovi uuesti :)))',
			endScreenTitle: 'Suurepärane! 🌟🌟🌟',
			endScreenTopInfo: 'Oled juba omandanud kõik',
			endScreenMiddleInfo: 'sõnad',
			endScreenBottomInfo: 'sellel tasemel.',
			endScreenText: 'Sa võid alustada uuesti või muuta taset seadetes.',
			endScreenBottomText: 'Alusta uuesti',
			endScreenEndText: 'Lõpeta',
			sesionScoreTitle: 'Sa teed suurepärast tööd! 🎉',
			sesionScoreSubTitle: 'Parandad oma hääldust',
			sesionScoreWord: 'Sõnad',
			now: 'Praegu',
			sum: 'kokku',
			continue: 'JÄTKA',
			areYouSureToEndLesson: 'Kas oled kindel, et soovid õppetunni lõpetada?',
			speakingScore: 'Häälduse hinne',
			GOOD_ARRAY: [
				'Tubli!',
				'Täiuslik!',
				'Sa oled meister!',
				'Suurepärane!',
				'Uskumatu!',
				'Geniaalne!',
			],
			BAD_ARRAY: [
				'Ära anna alla!',
				'Sa suudad seda!',
				'Natuke veel!',
				'Harjuta edasi, sa oled peaaegu kohal!',
				'Varsti õnnestub!',
				'Pole hullu, proovi uuesti!',
			],
			OK_ARRAY: [
				'Sa oled õigel teel!',
				'Väga lähedal!',
				'Läheb järjest paremaks!',
				'Teed edusamme!',
				'Suurepärane pingutus!',
			],
			hold: 'Hoia all',
			holdText: 'mikrofoni ja ütle:',
			tutorial: {
				title: 'Kuidas kasutada Sõnatreenerit?',
				options: [
					{
						label: 'Klõpsa, et kuulata õiget hääldust',
						icon: Micro,
					},
					{
						label: 'Hoia all ja ütle sõna',
						icon: Voice,
					},
					{
						label:
							'Klõpsa ja kuula oma salvestust, et sõna täielikult omandada!',
						icon: Person,
					},
				],
				bottomText:
					'Regulaarne harjutamine Sõnatreeneriga aitab sul keelt kiiremini omandada!',
				close: 'SULGE',
			},
		},
		topbar: {
			back: 'Tagasi',
		},
		fotoMode: {
			welcome: {
				title: 'Pildi ära arvamine',
				subtitle: 'Kirjeldage, mida näete valitud teemal võimalikult täpselt.',
				start: 'Alustama!',
			},
			info: {
				startTyping: 'Alusta kirjutamist...',
				newTask: 'Uus ülesanne',
				send: 'Saada',
			},
		},
		flashcards: {
			title: 'Kaardid',
			subtitleTop: 'Õpi ja mälukaardista uusi sõnu interaktiivsete kaartidega.',
			addCategory: 'Lisa oma kategooria',
			subtitle: 'Sisesta kategooria nimi',
			type: 'sisesta kategooria...',
			add: 'Lisa',
			previous: 'Eelmine kaart',
			task: 'Ülesanne',
			changeLevel: 'Taseme vahetamine',
			dontKnow: 'Ei tea',
			know: 'Teen',
			pickPairs: 'Vali paarid',
			goodJob: 'Hea töö!',
			titleEnd: 'Su pühendumus on muljetavaldav',
			subtitleEnd: 'Kas oled valmis uutele väljakutsetele?',
			repeat: 'Kategooria kordamine',
			other: 'Vali teine kategooria',
			level: {
				title: 'Kaardi taseme vahetamine',
				beginner: 'Algaja A1-A2',
				intermediate: 'Kesktase B1-B2',
				advance: 'Edasijõudnud C1-C2',
				change: 'Vaheta',
			},
		},
		scene: {
			title: 'Stseenid',
			subtitle: 'Mängi stseeni AI õpetajaga valitud teemal.',
			suggest: 'Uue stseeni ettepanek',
			talk: 'Vestlus',
			deletedFromFavorite: 'Eemaldatud lemmikutest',
			addedToFavorite: 'Lisatud lemmikutesse',
		},
		word: {
			title: 'Sõnad',
			subtitle: 'Rikkaenda oma sõnavara peamistel aladel AI õpetajaga.',
			suggest: 'Uue sõna ettepanek',
			deletedFromFavorite: 'Eemaldatud lemmikutest',
			addedToFavorite: 'Lisatud lemmikutesse',
		},
	},
	cookie: {
		title: 'Küpsiste teave',
		subtitle:
			'See veebisait kasutab küpsiseid, et pakkuda paremat kasutajakogemust ja isikupärastatud sisu. Kui soovid selle kohta rohkem teada, loe meie privaatsuseeskirju.',
		link: 'privaatsuseeskirjad',
		accept: 'NÕUSTUN',
		denied: 'EI NÕUSTU',
	},
	talkingBar: {
		allowMicrofon: 'Palun luba mikrofoni kasutamine',
		typeMessage: 'Kirjuta sõnum...',
		record: 'Salvesta',
		recording: 'Salvestamine käib',
	},
	hands: {
		giveUsInfo: 'Jaga oma arvamust',
		thanksForGrading: 'Aitäh tagasiside eest!',
		subtitle: 'Sinu arvamus on meile oluline!',
		subtitle2: 'Räägi meile, mida arvad sellest õppetunnist:',
		skip: 'Vahelejätmine',
		send: 'Saada',
	},
	suggestConfig: {
		profile: {
			title: 'Jaga oma arvamust!',
			subtitle:
				'Parandame pidevalt meie rakendust. Jagage oma arvamust, mida muudaksite rakenduses või mis teile seal kõige rohkem meeldib.',
		},
		word: {
			title: 'Uue sõna ettepanek',
			subtitle: '',
		},
		scene: {
			title: 'Uue stseeni ettepanek',
			subtitle: '',
		},
		startTyping: 'Alusta kirjutamist',
		send: 'SAADA',
		thx: 'Arvamus saadetud! Aitäh!',
		error: 'Ups! Midagi läks valesti',
	},
	checkout: {
		emailGuessText: 'Kas tahtsid seda kirjutada?',
		leftTitle: 'Vali endale plaan',
		rightTitle: 'Täida andmed',
		subscribe: 'Telli',
		total: 'Kokku',
		currencySign: '€',
		accept: 'Nõustun ',
		space: ' ja ',
		email: 'E-post',
		placeHolderEmail: 'Sisestage oma e-posti aadress',
		rules: 'kasutustingimustega',
		tos: 'privaatsuseeskirjadega',
		currency: 'eur',
		savePayments: 'Turvalisi makseid haldab',
	},
	dailyV2: {
		conversation: {
			doYouWantContinueConversation:
				'Kas soovite vestlust jätkata või alustada uut?',
			continueConversation: 'Jätka vestlust',
			newConversation: 'Uus vestlus',
		},
		hint: {
			title: 'Nipp',
			pick: 'Valik',
		},
		feedback: {
			yourMessage: 'Sinu sõnum',
			goodMessage: 'Õige sõnum',
			explanation: 'Selgitus',
		},
		tutorial: {
			title: 'Kuidas kasutada vestlust?',
			skip: 'Vahelejätmine',
			next: 'TOVÁBB',
			start: 'Alustamine',
			screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
			config: [
				[
					{
						title: 'Kliki õpetaja hääle vaigistamiseks',
						image: VolumeUp,
					},
					{
						title: 'Kliki oma arvamuse jagamiseks',
						image: HandsUp,
					},
					{
						title: 'Kliki juhendi avamiseks',
						image: Tutorial,
					},
				],
				[
					{
						title: 'Kliki uuesti kuulamiseks',
						image: VolumeUpBlank,
					},
					{
						title: 'Kliki tõlke vaatamiseks',
						image: TranslationBlank,
					},
					{
						title: 'Kliki näpunäite vaatamiseks',
						image: HintBlank,
					},
					{
						title: 'Kliki probleemi teatamiseks',
						image: InfoBlank,
					},
				],
				[
					{
						title: 'Sinu sõnum on õige',
						image: GreenCircle,
					},
					{
						title: 'Kliki parandatud vastuse ja selgituse vaatamiseks',
						image: YellowCircle,
					},
				],
				[
					{
						title: 'Kliki sõnumi kirjutamiseks',
						image: '',
					},
					{
						title: 'Kliki sõnumi salvestamiseks',
						image: Microphone,
					},
				],
			],
		},
	},
	problem: {
		title: 'Probleemi teatamine',
		subtitle: 'Kirjeldage AI sõnumiga seotud probleemi',
		send: 'Saada',
		messageSend: 'Sõnum saadetud',
		error: 'Midagi läks valesti, kirjuta meile :)',
	},
};
