import React, { useState, useContext } from 'react';
import { Howl, Howler } from 'howler';
import styled, { keyframes } from 'styled-components';
import MicrofonWhite from '../images/microfon-white.svg';
import MicrofonColor from '../images/microfon-purple.svg';
import Next from '../images/next.png';
import MicroRedCircle from '../images/microfon-red-circle.png';
import { AudioRecordingContext } from '@/common/contexts/AudioRecordingContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { getMimeType } from '@/common/functions/getMimeType';
import NoRecordingImage from '../images/no-recording.svg';
import RecordingAvailableImage from '../images/recording-available.svg';
import PlayingRecordingImage from '../images/playing-recording.svg';
import PlayingRecordingImageDesktop from '../images/playing-recording-desktop.svg';

const rippleAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
`;

const Ripple = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: ${(props) =>
    props.background ? props.background : 'rgba(142, 68, 173, 0.8)'};
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0.8);
  animation: ${rippleAnimation} 1.5s infinite;
  animation-delay: ${(props) => props.delay || '0s'};
  z-index: 2; /* efekt będzie nad ikoną – zmień na niższy, jeśli chcesz, aby był pod nią */
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 1; /* obrazek pod efektem ripple */

  /* Opcjonalnie: wymuszamy pełne wypełnienie kontenera */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

const TextBottom = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  @media (min-width: 600px) {
    display: none;
  }
`;

const TextBottomDesktop = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  @media (max-width: 600px) {
    display: none;
  }
`;

const CustomButtonWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50% !important;
  background-color: unset;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
`;

const CustomButton = styled.div`
  width: 100px;
  height: 100px;
  position: relative; /* potrzebne do pozycjonowania Ripple */
  /* Brak overflow, by animacja nie była przycinana */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    transform: unset !important;
  }

  @media (min-width: 600px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  gap: 70px;
  margin-bottom: 80px;

  img {
    -webkit-user-drag: none !important; /* For Safari and older versions of Chrome */
    user-drag: none !important;
    -webkit-touch-callout: none !important;
  }
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`;

const ButtonWrapperElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  border-radius: 84px;
  background: #f4f6f8;
  min-width: 180px;
  width: 38px;
  height: 38px;

  @media (max-width: 600px) {
    min-width: fit-content;
  }
`;

const CustomButtonDesktop = styled.div`
  width: 100px;
  height: 100px;
  position: relative; /* potrzebne do pozycjonowania Ripple */
  /* Brak overflow, by animacja nie była przycinana */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    transform: unset !important;
  }
`;

const ActionSection = ({
  handleCheckWord,
  handleGenerateWord,
  currentVoice,
  setCurrentVoice,
}) => {
  const translationData = useLanguageData('features.wordTrainer');
  const [voiceTalking, setVoiceTalking] = useState(false);
  const { isRecording, handleStartRecording, handleStopRecording } = useContext(
    AudioRecordingContext,
  );

  const stopRecording = async () => {
    const blob = await handleStopRecording();

    if (!blob) {
      return;
    }

    const file = new File([blob], 'to_translate.mp3', {
      type: getMimeType(),
      lastModified: new Date().getTime(),
    });

    setCurrentVoice(() => blob);
    handleCheckWord(file);
    window?.audioStream?.getAudioTracks()?.forEach((track) => track?.stop());
  };

  const handleRecordingDesktop = async () => {
    if (!isRecording) {
      return handleStartRecording();
    }

    return await stopRecording();
  };

  const handlePlayMyVoice = () => {
    if (!currentVoice || voiceTalking) {
      return;
    }

    const blobUrl = URL.createObjectURL(currentVoice);

    const audio = new Howl({
      src: [blobUrl],
      html5: true,
      onplay: () => {
        setVoiceTalking(true);
      },
      onpause: () => {
        setVoiceTalking(false);
      },
      onstop: () => {
        setVoiceTalking(false);
      },
      onmute: () => {
        setVoiceTalking(false);
      },
      onfade: () => {
        setVoiceTalking(false);
      },
      onend: () => {
        setVoiceTalking(false);
      },
      onunload: () => {
        setVoiceTalking(false);
      },
    });

    audio.play();
  };

  return (
    <Wrapper>
      <Element onClick={handlePlayMyVoice}>
        <ButtonWrapperElement
          style={{
            background: voiceTalking
              ? 'linear-gradient(220deg, #4322C6 28.95%, #48068A 74.94%)'
              : '#F4F6F8',
          }}
        >
          <TextBottomDesktop
            style={{
              color: !currentVoice
                ? '#C0BAC1'
                : voiceTalking
                ? '#fff'
                : '#29185E',
            }}
          >
            {translationData?.yourSpeaking}
          </TextBottomDesktop>
          <img
            alt="User"
            src={
              !currentVoice
                ? NoRecordingImage
                : voiceTalking
                ? window.innerWidth > 600
                  ? PlayingRecordingImageDesktop
                  : PlayingRecordingImage
                : RecordingAvailableImage
            }
            draggable="false"
          />
        </ButtonWrapperElement>
        <TextBottom>{translationData?.you}</TextBottom>
      </Element>
      <CustomButtonWrapper>
        <CustomButtonDesktop onClick={() => handleRecordingDesktop()}>
          {isRecording && (
            <>
              <Ripple delay="0s" background={'rgb(255,46,1, 0.8)'} />
              <Ripple delay="0.5s" background={'rgb(255,46,1, 0.8)'} />
              <Ripple delay="1s" background={'rgb(255,46,1, 0.8)'} />
            </>
          )}
          <ImageContainer style={{ zIndex: 10 }}>
            <img
              src={isRecording ? MicroRedCircle : MicrofonWhite}
              draggable="false"
              alt="Microfon"
            />
          </ImageContainer>
        </CustomButtonDesktop>
      </CustomButtonWrapper>
      <Element
        onClick={() => {
          setCurrentVoice(() => null);
          setVoiceTalking(() => null);
          Howler.unload();
          handleGenerateWord();
        }}
      >
        <ButtonWrapperElement>
          <TextBottomDesktop>{translationData?.next}</TextBottomDesktop>
          <img alt="Next" src={Next} draggable="false" />
        </ButtonWrapperElement>
        <TextBottom>{translationData?.next}</TextBottom>
      </Element>
    </Wrapper>
  );
};

export default ActionSection;
