import { shuffleArray } from '@/common/functions/shuffleArray';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PosibleHints from './PosibleHints';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import Wrong from '../audio/wrong.mp3';
import { Howl } from 'howler';
const Title = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;
const Word = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 80px;
`;
const LetterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 30px;
  aspect-ratio: 1;
  background: #f4f5f8;
  text-transform: uppercase;
`;
const LetterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 7px;
  width: 100%;
  margin-top: 60px;
`;
const LetterTop = styled.div`
  font-size: 22px;
  width: 22px;
  text-align: center;
  color: #29185e;
  text-transform: uppercase;
`;
const LetterWrong = styled(LetterBox)`
  background: #e9574a;
  justify-content: center;
  align-items: center;
  width: 30px;
  aspect-ratio: 1;
  color: #fff;
`;
const Scatter = ({ data, handleNext }) => {
  const translation = useLanguageData('learn.flashcardV2');
  const [word, setWord] = useState();
  const [shuffledWord, setShuffledWord] = useState();
  const [currentPicked, setCurrentPicked] = useState([]);
  const [worngIndex, setWrongIndex] = useState();
  const [posibleHints, setPosibleHints] = useState(2);
  const randWord = () => {
    const randed = Math.max(Math.round(Math.random() * (data.length - 1)), 0);
    const randedWord = data[randed];
    setWord(() => randedWord);
    setCurrentPicked(() =>
      randedWord?.flashcard[0]?.word?.split('').map((d) => '_'),
    );
    setShuffledWord(() =>
      shuffleArray(randedWord?.flashcard[0]?.word?.split('')),
    );
  };
  const handlePick = (letter, index) => {
    const wordText = word?.flashcard[0]?.word;
    const findFirstIndex = currentPicked.findIndex((w) => w === '_');
    if (wordText[findFirstIndex] === letter) {
      setShuffledWord((prev) => prev.filter((p, i) => i !== index));
      return setCurrentPicked((prev) => {
        prev[findFirstIndex] = letter;
        return prev;
      });
    }
    const audio = new Howl({
      src: [Wrong],
    });
    audio?.play();
    setWrongIndex(() => index);
    setTimeout(() => setWrongIndex(() => null), 500);
  };
  const handleHint = () => {
    setPosibleHints((prev) => prev - 1);
    if (posibleHints === 0) {
      setCurrentPicked(() => word?.flashcard[0].word.split(''));
      setShuffledWord(() => []);
      return;
    }
    const findFirstIndex = currentPicked.findIndex((w) => w === '_');
    setCurrentPicked((prev) => {
      prev[findFirstIndex] = word?.flashcard[0].word[findFirstIndex];
      return prev;
    });
    const shuffledWordIndex = shuffledWord.findIndex(
      (w) => w === word?.flashcard[0].word[findFirstIndex],
    );
    setShuffledWord((prev) => prev.filter((p, i) => i !== shuffledWordIndex));
  };
  useEffect(() => {
    if (!shuffledWord && data.length) {
      randWord();
    }
  }, [data]);
  useEffect(() => {
    if (shuffledWord?.length === 0 && currentPicked[0]) {
      setTimeout(() => {
        handleNext();
      }, [1000]);
    }
  }, [shuffledWord, currentPicked]);
  return (
    <>
      <Title>{translation.createWordWithLetters} </Title>
      <Word>{word?.flashcard[0]?.flashcard_v2_translation[0]?.word}</Word>
      <LetterWrapper style={{ gap: '14px' }}>
        {currentPicked?.map((f) => (
          <LetterTop>{f}</LetterTop>
        ))}
      </LetterWrapper>
      <LetterWrapper style={{ marginTop: '18px' }}>
        {shuffledWord &&
          shuffledWord?.map((f, i) => {
            return i === worngIndex ? (
              <LetterWrong className="wiggleV2">{f}</LetterWrong>
            ) : (
              <LetterBox onClick={() => handlePick(f, i)}>{f}</LetterBox>
            );
          })}
      </LetterWrapper>
      <PosibleHints posibleHints={posibleHints} handleHint={handleHint} />
    </>
  );
};
export default Scatter;
