import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import { RegisterContext } from '../../context/register.context';
import StripeSubscriptionView from './StripeSubscriptionView';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeSubscriptionWrapper = ({}) => {
	const data = useLanguageData('checkout');
	const { lang } = useContext(LanguageContext);
	const { plan } = useContext(RegisterContext);
	const [clientSecret, setClientSecret] = useState();

	const options = {
		mode: 'subscription',
		amount: Math.floor(plan.full_price * 100),
		currency: data.currency,
		locale: lang,
		appearance: {
			theme: 'flat',
			variables: {
				borderRadius: '10px',
				fontSizeBase: '12px',
			},
			rules: {
				'.Input': {
					borderRadius: '10px',
					padding: '8px',
					fontSize: '14px',
				},
				'.Tab': {
					padding: '8px',
				},
				'.Label': {
					fontSize: '12px',
				},
			},
		},
	};

	return (
		<>
			{stripePromise && (
				<Elements stripe={stripePromise} options={options}>
					<StripeSubscriptionView />
				</Elements>
			)}
		</>
	);
};
export default StripeSubscriptionWrapper;
