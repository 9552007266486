import React from 'react';
import styled from 'styled-components';
import WordTrainerImage from '../images/wordTrainer.png';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 20px;
`;

const Box = styled(Link)`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  flex-direction: column;
  width: 90vw;
  margin-left: 5vw;
  gap: 14px;
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
`;

const Subtitle = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-align: left;
  margin-left: 5vw;
  z-index: 1;
  text-decoration: none;
`;

const TitleTop = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  width: 360px;
  margin-left: 5vw;
  margin-bottom: -3px;
  text-decoration: none;

  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
`;

const NewOne = styled.div`
  display: inline-flex;
  padding: 1px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: #03da9a;

  color: #29185e;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */

  margin-top: 2px;
`;

const WordTrainer = () => {
  const trasnaltion = useLanguageData('learn.wordTrainer');

  return (
    <Wrapper>
      <TitleTop to={'/learn/word-trainer'}>
        {trasnaltion.title}
        {trasnaltion?.new && <NewOne>{trasnaltion?.new}</NewOne>}
      </TitleTop>
      <Subtitle to={'/learn/word-trainer'}>{trasnaltion.subtitle}</Subtitle>
      <Box image={WordTrainerImage} to={'/learn/word-trainer'}></Box>
    </Wrapper>
  );
};

export default WordTrainer;
