import React, { useRef } from 'react';
import Animation1 from '@/common/images/animation-1.gif';
import { IconWrapper } from './TalkingMicrofon.styles';
import MessageBar from './MessageBar';

const SceneMicrofon = ({
  conversationWorkflowMutation,
  transcriptMessageMutation,
  handleSendMessage,
  handleSendVoice,
}) => {
  const presenceChecker = useRef();

  return (
    <>
      {transcriptMessageMutation.isLoading && (
        <div style={{ textAlign: 'right' }}>
          <img alt="" src={Animation1} style={{ width: '40px' }} />
        </div>
      )}
      {conversationWorkflowMutation.isLoading && (
        <div style={{ textAlign: 'left' }}>
          <img alt="" src={Animation1} style={{ width: '40px' }} />
        </div>
      )}
      <div ref={presenceChecker} />
      <IconWrapper>
        <MessageBar
          isBlock={false}
          sendText={handleSendMessage}
          sendVoice={handleSendVoice}
        />
      </IconWrapper>
    </>
  );
};

export default SceneMicrofon;
