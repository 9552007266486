import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import { getCurrentPriceConfig } from '@/api/query/price-config';
import { useMutation } from 'react-query';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import OneTimeStripeConf from './components/OneTimeStripeConf';
import SavePayment from '../SavePayment';
import {
	Wrapper,
	LeftInfo,
	SynthiAiLogoWrapper,
	Title,
	PriceWrapper,
	PlanName,
	SaveWrapper,
	TextWrapper,
	Text,
	PriceInfoWrapper,
	Border,
	Text2,
	ActionWrapper,
	TitleDesktop,
} from './OnePageStripeOneTimePayment.styles';

const OnePageStripeOneTimePayment = () => {
	const [email, setEmail] = useState();
	const { lang } = useContext(LanguageContext);
	const data = useLanguageData('checkout');
	const [promo, setPromo] = useState();

	const [pricesData, setPricesData] = useState([]);
	const [selectedOption, setSelectedOption] = useState();

	const handleLoadPricesMutation = useMutation({
		mutationFn: (value) => getCurrentPriceConfig(value),
		onSuccess: ({ data }) => {
			const prices = data.prices.sort((a, b) => a.full_price - b.full_price);

			setPricesData(() => prices);

			let plan = localStorage.getItem('plan');
			let selected = prices?.find((p) => p?.type?.value === plan) || prices[0];

			setSelectedOption(() => selected);
		},
	});

	const handleChangeSelect = (pd) => {
		setSelectedOption(() => pd);
	};

	useEffect(() => {
		let country = lang;

		if (lang === 'sv') {
			country = 'se';
		}

		if (lang === 'et') {
			country = 'ee';
		}

		if (lang === 'cs') {
			country = 'cz';
		}

		if (lang === 'sl') {
			country = 'si';
		}

		const payload = {
			country: country,
		};

		handleLoadPricesMutation.mutate(payload);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setEmail(() => localStorage.getItem('quiz_email'));
			const promo = localStorage.getItem('promo');
			if (promo) {
				setPromo(() => true);
			}
		}, 100);
	}, []);

	return (
		<Wrapper>
			<LeftInfo>
				<SynthiAiLogoWrapper>
					<img src={SynthiAiLogo} />
				</SynthiAiLogoWrapper>
				<Title>{data?.leftTitle}</Title>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<div style={{ minWidth: '320px' }}>
						{[...pricesData]
							.sort((a, b) => b.full_price - a.full_price)
							.map((pd) => (
								<PriceWrapper style={{ marginBottom: '12px' }}>
									<div
										style={{
											display: 'flex',
											gap: '22px',
											width: 'fit-content',
										}}
										onClick={() => handleChangeSelect(pd)}
									>
										<input
											type='radio'
											id={pd._id}
											value={pd._id}
											name='plan'
											checked={pd._id === selectedOption?._id}
										/>
										<span
											class='custom-radio-button'
											style={{ marginTop: '5px' }}
										></span>
										<label
											htmlFor={pd._id}
											style={{
												display: 'flex',
												gap: '22px',
											}}
										>
											<PlanName
												style={{
													color:
														pd._id === selectedOption?._id
															? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
															: '#29185E',
												}}
											>
												{pd.inAppPrice.label}
											</PlanName>{' '}
											<SaveWrapper promo={promo}>
												{promo
													? pd.inAppPrice.promo_saving
													: pd.inAppPrice.saving}
											</SaveWrapper>{' '}
										</label>
									</div>
									{pd._id === selectedOption?._id && (
										<PriceInfoWrapper>
											<TextWrapper>
												<Text>{pd.inAppPrice.full_label}</Text>
												<Text>{pd.inAppPrice.orginal_full_price}</Text>
											</TextWrapper>
											<TextWrapper>
												<Text>
													{promo
														? pd.inAppPrice.promo_discount_label
														: pd.inAppPrice.discount_label}
												</Text>
												<Text
													style={{
														color: `${
															promo ? '#149069' : 'var(--pink, #E94F96)'
														}`,
													}}
												>
													{promo
														? pd.inAppPrice.promo_discount_value
														: pd.inAppPrice.discount_value}
												</Text>
											</TextWrapper>
											<Border />
											<TextWrapper>
												<Text>{data?.total}</Text>
												<Text>
													<b>
														{' '}
														{promo ? pd.promo_full_price : pd.full_price}{' '}
														{data?.currencySign}
													</b>
												</Text>
											</TextWrapper>
											<TextWrapper>
												<Text2>{pd.inAppPrice.nowYouWillPay}</Text2>
											</TextWrapper>
										</PriceInfoWrapper>
									)}
								</PriceWrapper>
							))}
					</div>
				</div>
			</LeftInfo>
			<ActionWrapper>
				<TitleDesktop>{data?.rightTitle}</TitleDesktop>
				<OneTimeStripeConf
					selectedProduct={selectedOption}
					promo={promo}
					email={email}
					subscribe={data?.subscribe}
				/>
				<SavePayment />
			</ActionWrapper>
		</Wrapper>
	);
};

export default OnePageStripeOneTimePayment;
