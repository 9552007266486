import PopUp from '@/common/components/PopUp';
import { DEVICE_TYPE } from '@/common/constants/types';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 42px;
`;

const ContinuePopUpButton = styled.button`
  display: flex;
  padding: 0;
  width: 220px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);
  cursor: pointer;
  color: #fff;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const ContinuePopUpButtonMobile = styled.button`
  display: flex;
  width: 320px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 26px;
  border: 1px solid #29185e;
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  background-color: white;
`;

const MobileWrapper = styled.div`
  @media (min-width: 600px) {
    display: none;
  }

  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
  z-index: 9999;
  background: white;
  position: absolute;
  bottom: 3px;
  width: 100%;
  left: 0;
`;

const TopText = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
`;

const DesktopWrapper = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  padding: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 120px;

  @media (max-width: 600px) {
    height: fit-content;
  }
`;

const WhiteSpace = styled.div`
  height: 100%;
  width: 96vw;
  position: absolute;
  top: 0;
  background-color: white;
  opacity: 0.6;
  z-index: 9999;

  @media (min-width: 600px) {
    display: none;
  }
`;

const ContinuePopUp = ({
  handleDailyConversationMutation,
  setClose,
  setDailyConversation,
  type = DEVICE_TYPE.DESKTOP,
}) => {
  const { id } = useParams();
  const {
    doYouWantContinueConversation,
    continueConversation,
    newConversation,
  } = useLanguageData('dailyV2.conversation');

  const { handleSendIPstats } = useContext(IpStatsContext);

  const handleContinueConversation = () => {
    handleSendIPstats(IP_STATS_ENUM.APP_DAILY_CONTINUE_CONV);
    setClose(() => null);
  };

  const handleStartNewConversation = () => {
    const payload = {
      news: id,
    };
    handleSendIPstats(IP_STATS_ENUM.APP_DAILY_NEW_CONV);

    setClose(() => false);
    setDailyConversation(() => null);
    handleDailyConversationMutation.mutate(payload);
  };

  if (type === DEVICE_TYPE.DESKTOP) {
    return (
      <DesktopWrapper>
        <PopUp
          backdropFilter="blur(0px)"
          background="rgba(41, 24, 94, 0.20"
          closeIcon={false}
        >
          <Wrapper>
            <TopText>{doYouWantContinueConversation}</TopText>
            <div style={{ flex: 1 }} />
            <ButtonWrapper>
              <ContinuePopUpButton onClick={() => handleContinueConversation()}>
                {continueConversation}
              </ContinuePopUpButton>
              <ContinuePopUpButton onClick={() => handleStartNewConversation()}>
                {newConversation}
              </ContinuePopUpButton>
            </ButtonWrapper>
          </Wrapper>
        </PopUp>
      </DesktopWrapper>
    );
  }

  if (type === DEVICE_TYPE.MOBILE) {
    return (
      <>
        <WhiteSpace />
        <MobileWrapper>
          <Wrapper>
            <ContinuePopUpButtonMobile
              onClick={() => handleContinueConversation()}
            >
              {continueConversation}
            </ContinuePopUpButtonMobile>
            <ContinuePopUpButtonMobile
              onClick={() => handleStartNewConversation()}
            >
              {newConversation}
            </ContinuePopUpButtonMobile>
          </Wrapper>
        </MobileWrapper>
      </>
    );
  }
};

export default ContinuePopUp;
