import { askChatGptImageMode, transcript } from '@/api/query/workflow';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import { TALKING_ROLES } from './TalkiMessage';
import Animation1 from '@/common/images/animation-1.gif';
import { typeWriter } from '@/common/functions/typing';
import { Howl } from 'howler';
import AnimationRecording from './AnimationRecording';
import { IconWrapper } from './TalkingMicrofon.styles';
import { FROM_SOURCE_TYPES } from '@/common/constants/types';
import MessageBar from '@/common/components/chat/MessageBar';
import AudioRecordingContextProvider from '@/common/contexts/AudioRecordingContext';
import { getMimeType } from '@/common/functions/getMimeType';
import { voiceRunner } from '@/common/functions/voiceRunner';
import { VALID_PATHS } from '@/common/constants/validPaths';

const TalkingMicrofon = ({
  setCurrent,
  setConversationWithChatGPT,
  conversationWithChatGPT,
}) => {
  const [hints, setHints] = useState([]);
  const [speechLasts, setSpeechLasts] = useState();

  const { ask, setAsk, flascardData, showLoading, dailyConversation } =
    useContext(DemoConversationContext);

  const inputMessage = useRef();
  const presenceChecker = useRef();

  const [isRecording, setIsRecording] = useState(false);

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcript(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;

      setConversationWithChatGPT((prev) => [
        ...prev,
        { role: TALKING_ROLES.USER, message: data },
      ]);

      const payload = {};
      payload.message = data;
      payload._id = flascardData._id;
      payload.source = FROM_SOURCE_TYPES.SPEAK;

      getResponseForChatGptMutation.mutate(payload);
    },
  });

  const getResponseForChatGptMutation = useMutation({
    mutationFn: (value) => askChatGptImageMode(value),
    onSuccess: async ({ data }) => {
      setHints(() => data.hints);
      await voiceRunner(
        data.speaking.data,
        null,
        setSpeechLasts,
        VALID_PATHS.FOTO_MODE,
      );

      typeWriter({
        text: data.text,
        setText: setCurrent,
        typing: 45,
        onEnd: () => {
          setCurrent(null);
          setSpeechLasts(() => false);
          setConversationWithChatGPT((prev, i) => {
            if (prev.length < 2) {
              setAsk(true);
            }

            return [
              ...prev,
              { index: i, role: TALKING_ROLES.BOT, message: data.text },
            ];
          });
        },
      });
    },
  });

  const handleSendMessage = (messageInput, hint = false) => {
    if (!messageInput) return;

    setHints([]);

    const message = messageInput;

    setConversationWithChatGPT((prev) => [
      ...prev,
      { role: TALKING_ROLES.USER, message: message },
    ]);

    const payload = {};
    payload.message = message;
    payload._id = flascardData._id;
    payload.source = hint ? FROM_SOURCE_TYPES.HINT : FROM_SOURCE_TYPES.WRTIE;

    getResponseForChatGptMutation.mutate(payload);
  };

  useEffect(() => {
    if (conversationWithChatGPT.length > 3) {
      const element = document.getElementById(
        `bot-message-${conversationWithChatGPT.length - 1}`,
      );
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversationWithChatGPT]);

  useEffect(() => {
    if (ask) {
      const payload = {};
      payload._id = flascardData._id;
      payload.content = '';
      getResponseForChatGptMutation.mutate(payload);
      setAsk(false);
    }
  }, [ask]);

  return (
    <>
      {transcriptMutation.isLoading && (
        <div style={{ textAlign: 'right' }}>
          <img alt="" src={Animation1} style={{ width: '40px' }} />
        </div>
      )}
      {getResponseForChatGptMutation.isLoading && (
        <div style={{ textAlign: 'left' }}>
          <img alt="" src={Animation1} style={{ width: '40px' }} />
        </div>
      )}
      {isRecording && <AnimationRecording />}
      {showLoading && (
        <div
          style={{
            textAlign: 'center',
            marginTop: '-40px',
            marginLeft: '-80px',
          }}
        >
          <img alt="" src={Animation1} style={{ width: '120px' }} />
        </div>
      )}
      <div ref={presenceChecker} />
      <IconWrapper>
        <AudioRecordingContextProvider>
          <MessageBar
            isBlock={
              getResponseForChatGptMutation.isLoading ||
              transcriptMutation.isLoading
            }
            sendText={handleSendMessage}
            sendVoice={(data) => transcriptMutation.mutate(data)}
          />
        </AudioRecordingContextProvider>
      </IconWrapper>
    </>
  );
};

export default TalkingMicrofon;
