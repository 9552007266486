import React, { useState } from 'react';
import { TalkMessageWrapper, TranslationMessageWrapper } from '@/App.styles';
import Translation from '../Translation/Translation';

import styled from 'styled-components';
export const ContentTranslationWrapper = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	text-align: left;
	gap: 10px;
	margin-top: 10px;
`;

const BotMessage = ({
	text,
	translation,
	showTranslateIcon,
	isTutorial = false,
	id,
}) => {
	const [showTranslation, setShowTrnaslation] = useState();

	if (!text) return null;

	return (
		<>
			<ContentTranslationWrapper id={id}>
				<TalkMessageWrapper
					style={{
						borderRadius: showTranslation
							? '16px 16px 0px 0px'
							: '16px 16px 16px 0px',
					}}
				>
					{text}
				</TalkMessageWrapper>
				<Translation
					show={showTranslateIcon}
					setShowTrnaslation={setShowTrnaslation}
					demo={isTutorial}
				/>
			</ContentTranslationWrapper>
			{showTranslation && (
				<TranslationMessageWrapper className='fade__in__animation'>
					{translation}
				</TranslationMessageWrapper>
			)}
		</>
	);
};

export default BotMessage;
