import {
  checkTaskFeature,
  conversationWorkflowFeature,
  generateVoiceFeature,
  getFeedbackFeature,
  getHintFeature,
  transcirpitMessageFeature,
  translateMessageFeature,
} from '@/api/query/feature';
import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import { useMutation } from 'react-query';
import { formatInitMessages } from '../helper/formatInitMessages';
import AudioRecordingContextProvider from '@/common/contexts/AudioRecordingContext';
import SceneMicrofon from './SceneMicrofon';
import { messageDataReducer } from '../reducer/messageDataReducer';
import { messageDataInitObject } from '../reducer/messageDataInitObject';
import { messageDataWrapHandlers } from '../reducer/messageDataWrapHandlers';
import { TALKING_ROLES } from '../constants/talkingRoles';
import { Howler } from 'howler';
import { typeWriter } from '@/common/functions/typing';
import BotMessageTalking from './BotMessageTalking';
import UserMessage from './UserMessage';
import BotMessage from './Message/BotMessage';
import HintBoxMessage from './HintBoxMessage';
import ProblemView from '@/common/components/customer/ProblemView';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import { FROM_SOURCE_TYPES } from '@/common/constants/types';
import TaskPopUp from './TaskPopUp';
import ScoreBoxMessage from './ScoreBoxMessage';
import { VALID_PATHS } from '@/common/constants/validPaths';
import { voiceRunner } from '@/common/functions/voiceRunner';

const ContantSceneManager = ({ conversation }) => {
  const [messageData, dispatchMessageData] = useReducer(
    messageDataReducer,
    messageDataInitObject(formatInitMessages(conversation.customerMessages)),
  );

  const handlers = messageDataWrapHandlers(dispatchMessageData);
  const currentRef = useRef();

  const transcriptMessageMutation = useMutation({
    mutationFn: (value) => transcirpitMessageFeature(value),
    onSuccess: async ({ data }) => {
      if (!data && data?.length === 0) return;

      const userMessageId = `user-message-id-${
        messageData.conversationWithChatGPT.length + 1
      }`;

      handlers.setConversationWithChatGPT({
        role: TALKING_ROLES.USER,
        message: data,
      });

      const payload = {};
      payload.message = data;
      payload.conversationId = conversation._id;
      payload.source = FROM_SOURCE_TYPES.SPEAK;
      payload.userMessageId = userMessageId;
      payload.type = 'SCENE';

      getFeedbackMutation.mutate(payload);
      conversationWorkflowMutation.mutate(payload);
    },
  });

  const translateMessageMutation = useMutation({
    mutationFn: (value) => translateMessageFeature(value),
    onSuccess: async ({ data }) => {
      handlers.addShowMessagesTranslations(data.name);
      handlers.removeLoadingTranslations(data.name);
      handlers.setTrnaslationMessages({
        name: data.name,
        data: data.translation,
      });
    },
  });

  const getFeedbackMutation = useMutation({
    mutationFn: (value) => getFeedbackFeature(value),
    onSuccess: async ({ data }) => {
      handlers.setMessageScore(data);
    },
  });

  const checkTaskMutation = useMutation({
    mutationFn: (value) => checkTaskFeature(value),
    onSuccess: async ({ data }) => {
      handlers.setTaskCompleted(data);
    },
  });

  const getHintMutation = useMutation({
    mutationFn: (value) => getHintFeature(value),
    onSuccess: ({ data }) => {
      handlers.setIsHintLoading(false);
      handlers.setHintData(data);
    },
    onError: () => {
      handlers.setIsHintLoading(false);
    },
  });

  const getVoiceMutation = useMutation({
    mutationFn: (value) => generateVoiceFeature(value),
    onSuccess: async ({ data }) => {
      handlers.setLoadingVoice(false);
      handlers.setRecordings({
        name: data.name,
        value: data.data.data,
      });

      await voiceRunner(
        data.data.data,
        data.name,
        handlers.setVoiceTalking,
        VALID_PATHS.SCENE_V2,
      );
    },
    onError: () => {
      handlers.setLoadingVoice(false);
    },
  });

  const conversationWorkflowMutation = useMutation({
    mutationFn: (value) => conversationWorkflowFeature(value),
    onSuccess: async ({ data }) => {
      let parsedText = null;

      const payload = {};
      payload.type = 'SCENE';
      payload.conversationId = conversation?._id;

      checkTaskMutation.mutate(payload);

      await voiceRunner(
        data.speaking.data,
        '',
        handlers.setVoiceTalking,
        VALID_PATHS.SCENE_V2,
      );

      typeWriter({
        text: parsedText ?? data.text,
        setText: handlers.setCurrent,
        typing: 45,
        onEnd: () => {
          handlers.setCurrent(null);
          handlers.setConversationWithChatGPT({
            index: messageData.conversationWithChatGPT.length,
            role: TALKING_ROLES.BOT,
            message: data.text,
          });
        },
      });
    },
  });

  const handleGetVoice = async (name, message) => {
    const voice = messageData.recordings.find((tm) => tm.name === name);
    Howler?.unload();

    if (messageData.voiceTalking) {
      handlers.setVoiceTalking(false);
      return;
    }

    if (voice) {
      return await voiceRunner(
        voice.value,
        voice.name,
        handlers.setVoiceTalking,
        VALID_PATHS.SCENE_V2,
      );
    }

    handlers.setLoadingVoice(name);

    const payload = {};
    payload.message = message;
    payload.name = name;
    payload.type = 'SCENE';

    getVoiceMutation.mutate(payload);
  };

  const handleGetTranlationForBot = (name, message) => {
    const translation = messageData.translationMessages.find(
      (tm) => tm.name === name,
    );

    if (translation) {
      if (messageData.showMessagesTranslations.includes(name)) {
        handlers.removeShowMessagesTranslations(name);
      } else {
        handlers.addShowMessagesTranslations(name);
      }

      return;
    }

    const payload = {};
    payload.message = message;
    payload.name = name;
    payload.convId = conversation._id;
    payload.type = 'SCENE';

    handlers.addLoadingTranslations(name);

    translateMessageMutation.mutate(payload);
  };

  const handleGetHint = () => {
    handlers.setIsHintLoading(true);

    const payload = {
      conversationId: conversation._id,
      type: 'SCENE',
    };

    getHintMutation.mutate(payload);
  };

  const handleSendVoice = (file) => {
    const payload = {
      file,
      type: 'SCENE',
    };

    transcriptMessageMutation.mutate(payload);
  };

  const handleSendMessage = (messageInput, hint = false) => {
    if (!messageInput) return;

    handlers.setHintData(null);

    const message = messageInput;
    const userMessageId = `user-message-id-${
      messageData.conversationWithChatGPT.length + 1
    }`;

    handlers.setConversationWithChatGPT({
      role: TALKING_ROLES.USER,
      message: message,
    });

    const payload = {};
    payload.message = message;
    payload.type = 'SCENE';
    payload.conversationId = conversation?._id;
    payload.source = hint ? FROM_SOURCE_TYPES.HINT : FROM_SOURCE_TYPES.WRTIE;
    payload.userMessageId = userMessageId;

    getFeedbackMutation.mutate(payload);
    conversationWorkflowMutation.mutate(payload);
  };

  const previousMessages = useMemo(
    () =>
      messageData.conversationWithChatGPT.map((element, i) =>
        element.role === TALKING_ROLES.BOT ? (
          <BotMessageTalking
            key={i}
            id={i}
            message={element.message}
            handlers={handlers}
            messageData={messageData}
            handleGetTranlationForBot={handleGetTranlationForBot}
            handleGetVoice={handleGetVoice}
            isLastMessage={messageData.conversationWithChatGPT.length - 2 < i}
            isFirstMessage={i === 0}
            handleGetHint={handleGetHint}
          />
        ) : (
          <UserMessage
            message={element.message}
            key={i}
            userMessageId={`user-message-id-${i + 1}`}
            messageScore={messageData.messageScore}
            handleShowBadMessage={handlers.setShowBadMessage}
          />
        ),
      ),
    [
      messageData.conversationWithChatGPT,
      messageData.translationMessages,
      messageData.handleGetTranlationForBot,
      messageData.showMessagesTranslations,
      messageData.loadingTranslations,
      messageData.loadingVoice,
      messageData.voiceTalking,
      messageData.isHintLoading,
      messageData.messageScore,
    ],
  );

  useEffect(() => {
    if (conversation?.customerMessages?.length === 0) {
      const payload = {
        type: 'SCENE',
        conversationId: conversation?._id,
      };
      conversationWorkflowMutation.mutate(payload);
    }
  }, [conversation.customerMessages]);

  useEffect(() => {
    const element = document.getElementById(`bottom-info`);
    element?.scrollIntoView({ behavior: 'smooth' });
  }, [
    messageData.current,
    messageData.conversationWithChatGPT.length,
    currentRef?.current?.clientHeight,
  ]);

  return (
    <AudioRecordingContextProvider>
      {previousMessages}
      {messageData.current && (
        <div
          ref={currentRef}
          style={{
            minHeight: '60px',
          }}
        >
          <BotMessage text={messageData.current} showTranslateIcon={false} />
        </div>
      )}
      <SceneMicrofon
        conversationWorkflowMutation={conversationWorkflowMutation}
        transcriptMessageMutation={transcriptMessageMutation}
        handleSendMessage={handleSendMessage}
        handleSendVoice={handleSendVoice}
      />
      {messageData.hintData && (
        <HintBoxMessage
          data={messageData.hintData}
          setClose={() => handlers.setHintData(null)}
          handleProccessHint={() => {
            handlers.setHintData(null);
            handleSendMessage(messageData.hintData.hint_result, true);
          }}
        />
      )}
      {messageData.handleShowBadMessage && (
        <ScoreBoxMessage
          data={messageData.handleShowBadMessage}
          setClose={() => handlers.setShowBadMessage(null)}
        />
      )}
      {messageData.showProblem && (
        <ProblemView
          chatMessage={messageData.showProblem}
          setShowProblem={handlers.setShowProblem}
          type={SOURCES_TYPES.SCENE_V3}
          // userVoice={messageData.userVoice}
        />
      )}
      {messageData?.taskCompleted?.length > 0 && (
        <TaskPopUp
          close={() => handlers.setTaskCompleted(null)}
          data={messageData.taskCompleted}
        />
      )}
    </AudioRecordingContextProvider>
  );
};

export default ContantSceneManager;
