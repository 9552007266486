import { updatePassowordInside } from '@/api/query/user';
import PopUp from '@/common/components/PopUp';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useReducer, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import EyeIcon from '../images/eye.svg';
import CrossedEyeIcon from '../images/crossed-eye.svg';

const Title = styled.p`
	color: #29185e;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 22.4px */
	margin: 20px 0 12px 0;
`;

export const Label = styled.label`
	color: #29185e;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 16.8px */
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* margin: 5px; */
	/* gap: 8px; */
	position: relative;
`;

export const Input = styled.input`
	margin-top: 4px;
	display: flex;
	padding: 8px 10px 8px 10px;
	width: 260px;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #29185e;
	background: #fff;
	color: #29185e;

	@media (min-width: 600px) {
		border: 1px solid #29185e;
	}

	&:hover {
		outline: none;
	}
	&:focus {
		outline: none;
	}
`;

export const EyeIconWrapper = styled.div`
	color: #29185e;
	font-size: 16px;
	position: absolute;
	right: 18px;
	bottom: 5px;
	cursor: pointer;

	&:active {
		color: #05275d;
	}

	@media (min-width: 600px) {
	}
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const SaveButton = styled.button`
	display: flex;
	width: 189px;
	padding: 8px 0px 8px 0px;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);

	color: #fff;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.64px;
	text-transform: uppercase;

	margin: 40px 0 10px 0;
`;

export const AditionalInfo = styled.div`
	color: #868686;
	font-family: 'Work Sans';
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 14.3px */
	max-width: 280px;
	margin: 2px 0 16px 0;
`;

const LABLES = {
	PASSWORD: 'password',
	TEXT: 'text',
};

const ChangePasswordComponent = ({ setClose }) => {
	const top = useLanguageData('profile.top');
	const register = useLanguageData('register.stepOne');

	const [passwordLebal, setPasswordLabel] = useState(LABLES.PASSWORD);
	const [passwordConfirmationLebal, setPasswordConfirmationLabel] = useState(
		LABLES.PASSWORD,
	);

	const passwordRef = useRef();
	const passwordConfirmationRef = useRef();

	const { addMessage } = useContext(MessageContext);

	const handleSaveMutation = useMutation({
		mutationFn: (data) => updatePassowordInside(data),
		onSuccess: () => {
			addMessage(top.passwordChanged, 'success');
			setClose(false);
		},
		onError: () => {
			addMessage(top.error, 'error');
		},
	});

	const checkPassword = (pass, passRepeat) => {
		if (pass !== passRepeat)
			return {
				valid: false,
				message: register.passwordError5,
			};

		if (pass.length <= 8)
			return {
				valid: false,
				message: register.passwordError1,
			};

		if (!/[A-Z].*/.test(pass))
			return {
				valid: false,
				message: register.passwordError2,
			};

		if (!/[a-z].*/.test(pass))
			return {
				valid: false,
				message: register.passwordError3,
			};

		if (!/\d/.test(pass))
			return {
				valid: false,
				message: register.passwordError4,
			};

		return { valid: true, message: 'Valid Password' };
	};

	const handleSave = (e) => {
		e?.preventDefault();
		const password = passwordRef.current.value.trim();
		const repeatPassword = passwordConfirmationRef.current.value.trim();

		const checkedPassword = checkPassword(password, repeatPassword);

		if (!checkedPassword.valid) {
			return addMessage(checkedPassword.message, 'error');
		}

		const payload = {
			password,
		};

		handleSaveMutation.mutate(payload);
	};
	return (
		<PopUp setClose={setClose}>
			<Title>{top.changePassword}</Title>
			<Form onSubmit={handleSave}>
				<InputWrapper>
					<Label>{top.password}</Label>
					<Input
						ref={passwordRef}
						id='pawssword-SynthiAi-change'
						type={passwordLebal}
						required
					></Input>
					<EyeIconWrapper
						onClick={() =>
							setPasswordLabel((prev) =>
								prev === LABLES.PASSWORD ? LABLES.TEXT : LABLES.PASSWORD,
							)
						}
					>
						<img
							src={passwordLebal === LABLES.PASSWORD ? EyeIcon : CrossedEyeIcon}
							alt='eye'
						/>
					</EyeIconWrapper>
				</InputWrapper>
				<AditionalInfo style={{ color: '#868686' }}>
					{top.passwordInfo}
				</AditionalInfo>
				<InputWrapper>
					<Label>{top.repeatPassword}</Label>
					<Input
						ref={passwordConfirmationRef}
						id='password-repeat-SynthiAi-change'
						type={passwordConfirmationLebal}
						required
					></Input>
					<EyeIconWrapper
						onClick={() =>
							setPasswordConfirmationLabel((prev) =>
								prev === LABLES.PASSWORD ? LABLES.TEXT : LABLES.PASSWORD,
							)
						}
					>
						<img
							src={
								passwordConfirmationLebal === LABLES.PASSWORD
									? EyeIcon
									: CrossedEyeIcon
							}
							alt='eye'
						/>
					</EyeIconWrapper>
				</InputWrapper>
				<SaveButton type='submit'>{top.change}</SaveButton>
			</Form>
		</PopUp>
	);
};

export default ChangePasswordComponent;
