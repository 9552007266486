export const setupUtms = (queryMemo) => {
	if (queryMemo.get('quiz_version')) {
		localStorage.setItem('quiz_version', queryMemo.get('quiz_version')!);
	}
	if (queryMemo.get('sp_version')) {
		localStorage.setItem('sp_version', queryMemo.get('sp_version')!);
	}
	if (queryMemo.get('quiz_email')) {
		localStorage.setItem('quiz_email', queryMemo.get('quiz_email')!);
	}
	if (queryMemo.get('promo_email')) {
		localStorage.setItem('promo_email', queryMemo.get('promo_email')!);
	}
	if (queryMemo.get('promo')) {
		localStorage.setItem('promo', queryMemo.get('promo')!);
	}
	if (queryMemo.get('utm_campaign')) {
		localStorage.setItem('utm_campaign', queryMemo.get('utm_campaign')!);
	}
	if (queryMemo.get('utm_medium')) {
		localStorage.setItem('utm_medium', queryMemo.get('utm_medium')!);
	}
	if (queryMemo.get('utm_source')) {
		localStorage.setItem('utm_source', queryMemo.get('utm_source')!);
	}
	if (queryMemo.get('utm_content')) {
		localStorage.setItem('utm_content', queryMemo.get('utm_content')!);
	}
	if (queryMemo.get('plan')) {
		localStorage.setItem('plan', queryMemo.get('plan')!);
	}

	if (queryMemo.get('fbclid')) {
		localStorage.setItem('fbclid', queryMemo.get('fbclid')!);
	}

	if (queryMemo.get('pageName')) {
		localStorage.setItem('pageName', queryMemo.get('pageName')!);
	}
	if (queryMemo.get('msclkid')) {
		localStorage.setItem('msclkid', queryMemo.get('msclkid')!);
	}

	const trackingId = localStorage.getItem('uid') || queryMemo.get('uid');

	const SynthiAiUserIdTrack =
		(trackingId != 'undefinded' && trackingId) ||
		new Date().getTime().toString(16) + Math.floor(Math.random() * 100);
	localStorage.setItem('uid', SynthiAiUserIdTrack);

	return { uid: SynthiAiUserIdTrack };
};
