import React, { useEffect, useState } from 'react';
import { ContentWrapper, DemoSectionWrapper, MessageBox } from './scene.styles';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import NewTopTileHeader from '@/common/components/chat/NewTopTileHeader';
import Synthi from './images/synthiai-chat.svg';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import { findScenceByVersionForCustomer } from '@/api/query/scene';
import Loading from '@/common/components/Loading';
import {
  clearConversationFeature,
  loadConversationFeature,
} from '@/api/query/feature';
import ContantSceneManager from './components/ContantSceneManager';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow: hidden;
  text-align: center;
  margin: 0;
  padding: 0;

  @media (min-width: 600px) {
    flex-grow: 1;
    margin: 0;
    width: 100%;
    height: fit-content;
    position: relative;
  }
`;

const SceneV2 = () => {
  const { id } = useParams();
  const [sceneContent, setSceneContent] = useState();
  const [conversation, setConversation] = useState();

  const handleLoadLessonMutation = useMutation({
    mutationFn: (value) => findScenceByVersionForCustomer(value),
    onSuccess: ({ data }) => {
      setSceneContent(data);
    },
  });

  const handleLoadConversation = useMutation({
    mutationFn: (payload) => loadConversationFeature(payload),
    onSuccess: ({ data }) => {
      setConversation(data);
    },
  });

  const handleClearConversation = useMutation({
    mutationFn: (payload) => clearConversationFeature(payload),
    onSuccess: ({ data }) => {
      setConversation(data);
    },
  });

  const handleRefreshFunction = () => {
    const payload = {
      _id: id,
      version: 'V2',
      type: 'SCENE',
      sceneId: id,
    };

    handleClearConversation.mutate(payload);
  };

  useEffect(() => {
    if (id) {
      const payload = {
        _id: id,
        version: 'V2',
        type: 'SCENE',
        sceneId: id,
      };

      handleLoadConversation.mutate(payload);
      handleLoadLessonMutation.mutate(payload);
    }
    window.document.title = `Synthi Ai - Scene`;
  }, [id]);

  return (
    <>
      {handleLoadLessonMutation.isLoading ||
      handleLoadConversation.isLoading ||
      handleClearConversation.isLoading ? (
        <Loading />
      ) : (
        <PageWrapper>
          <NewTopTileHeader
            image={Synthi}
            name={sceneContent?.name}
            refresh={true}
            refreshFunction={() => handleRefreshFunction()}
            backTo={'/learn/scenes-v2'}
            forWhat={sceneContent?._id}
            source={SOURCES_TYPES.SCENE_V3}
          />
          <DemoSectionWrapper>
            <ContentWrapper>
              <MessageBox>
                {sceneContent && conversation && (
                  <ContantSceneManager conversation={conversation} />
                )}
                <div id="bottom-info" />
              </MessageBox>
            </ContentWrapper>
          </DemoSectionWrapper>
        </PageWrapper>
      )}
    </>
  );
};

export default SceneV2;
