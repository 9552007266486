import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from './images/arrow-left.svg';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import { useNavigate } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { loadUserPlanData } from '@/api/query/order';
import moment from 'moment';
import ProfilePayment from './ProfilePayment';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { LanguageContext } from '@/common/contexts/LanguageContext';

const IconTitleWrapper = styled.div`
	width: 20px;
	display: flex;
	align-items: center;
`;

const TopBar = styled.div`
	margin-top: 24px;
	width: 90vw;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (min-width: 600px) {
		margin: 20px;
		max-width: 96.5%;
	}
`;

const Title = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.48px;
`;

const Card = styled.div`
	margin-top: 20px;
	border-radius: 16px;
	background: #fff;
	padding: 16px;
	overflow-y: auto;
	height: fit-content;
	max-height: calc(var(--vh, 1vh) * 90 - 160px);
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media (min-width: 600px) {
		flex-direction: row;
		gap: 40px;
		margin: 0 auto;
		flex-wrap: wrap;
		overflow-y: hidden;
	}
`;

const Section = styled.div`
	width: 85vw;
	margin: -5px;
	padding: 2px 10px;
	display: flex;
	justify-content: left;
	align-items: center;

	@media (min-width: 600px) {
		flex-direction: column;
		width: 200px;
		border-radius: 16px;
		border: 1px solid rgba(79, 167, 180, 0.2);
		background: #650675;
		gap: 10px;
		box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
	}
`;

export const LevelOptionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

export const InputWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	cursor: pointer;

	label {
		cursor: pointer;
		font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
		color: #05275d;
		text-align: center;
		font-family: 'Work Sans';
		font-size: 12px;
		font-style: normal;
		line-height: 140%;
	}

	input {
		margin: 0;
	}
`;

const Left = styled.div`
	color: #29185e;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 139%; /* 16.68px */
	letter-spacing: 0.24px;
`;

const Right = styled.div`
	color: #29185e;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 139%;
	letter-spacing: 0.24px;
	margin-left: 4px;
`;

const TitlePlan = styled.h3`
	text-align: center;
	color: #05275d;
	font-family: Work Sans;
	font-size: 28px;
	margin: 20px 0;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.48px;

	@media (max-width: 600px) {
		font-size: 22px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media (max-width: 600px) {
		width: 320px;
	}
`;

const Info = styled.div`
	color: #29185e;
	width: 100%;
	font-family: Work Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%;

	@media (max-width: 600px) {
		width: 300px;
		text-align: center;
		color: #05275d;
		font-family: Work Sans;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
`;

const ActionWrapper = styled.form`
	display: flex;
	justify-content: center;
	gap: 30px;
	margin: 0px 0;

	@media (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

const LetMailButton = styled.button`
	display: flex;
	width: 200px;
	padding: 15px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 16px;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	outline: none;
	border: none;
	cursor: pointer;
	color: #fff;
	/* Button */
	font-family: Work Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.64px;
	text-transform: uppercase;

	&:hover {
		transform: scale(1.1);
	}
`;

const PricesWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 10px;

	@media (max-width: 600px) {
		display: none;
	}
`;

const FlexElement = styled.div`
	position: relative;
	width: 150px;
	border-radius: 16px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	color: #05275d;
	font-family: Work Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	background: white;
	margin-top: 10px;

	@media (min-width: 600px) {
		width: 200px;
		height: fit-content;
		border-radius: 16px;
		align-items: center;
		padding-bottom: 20px;
		gap: 10px;
		border: ${(props) =>
			props.picked
				? '1px solid linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
				: '1px solid #29185E'};
		background: ${(props) => (props.picked ? '#EEF2F5' : '#f4f6f8')};
	}
`;

const FlexElementMobile = styled.div`
	width: 85vw;
	border-radius: 16px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #05275d;
	font-family: Work Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	background: white;
	padding: 10px;
	height: 110px;
	border: ${(props) =>
		props.picked
			? '1px solid linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
			: 'none'};
	background: ${(props) => (props.picked ? '#EEF2F5' : 'white')};
`;

const TextTop = styled.div`
	color: #05275d;
	font-family: Work Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.4px;
`;

const TextPrice = styled.p`
	color: #05275d;
	font-family: Work Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.4px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin: 0;

	h3 {
		color: #05d698;
		font-family: Work Sans;
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.96px;
		margin: 0;
	}
`;

const Border = styled.div`
	height: 1px;
	width: 180px;
	background: #d9d9d9;
	margin-top: -15px;

	@media (max-width: 600px) {
		margin: 0;
		width: 100px;
	}
`;

const MonthWrapper = styled.h3`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%;
	margin: 20px 0;

	@media (max-width: 600px) {
		margin: 0px;
		font-size: 20px;
		margin-top: 25px;
	}
`;

const FullPriceWrapper = styled.p`
	color: #05275d;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	margin: 0;

	@media (max-width: 600px) {
		text-align: center;
	}
`;

const NowUwillPay = styled.p`
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.24px;
	margin: 0;
`;

const Picker = styled.div`
	display: inline-flex;
	width: 150px;
	height: 40px;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 1px solid #29185e;
	background: ${(props) =>
		props.picked
			? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'
			: 'white'};
	color: ${(props) => (props.picked ? 'white' : '#29185e')};

	text-align: center;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.64px;
	text-transform: uppercase;

	@media (max-width: 600px) {
		width: 120px;
	}

	@media (max-width: 600px) {
		margin-top: 10px;
		margin-bottom: 10px;
	}
`;

const PickerPicked = styled(Picker)`
	border-radius: 8px;
	border: 1px solid linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);

	@media (max-width: 600px) {
		margin-top: 10px;
		margin-bottom: 10px;
	}
`;

const ElementTop = styled.div`
	border-radius: 22px;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	padding: 10px;
	margin-top: -20px;
	margin-bottom: -25px;

	color: #fff;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.24px;
	text-transform: uppercase;

	@media (max-width: 600px) {
		padding: 6px 26px;
	}
`;

const FullPriceWrapperPercent = styled.p`
	color: #05275d;

	text-align: center;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 16.8px */
	text-decoration: line-through red;
	-webkit-text-decoration: line-through red;
	margin-top: -5px;
	margin-bottom: -15px;

	@media (max-width: 600px) {
		margin: 40px 0 0 0;
	}
`;

const PlanNameWrapper = styled.div`
	color: #29185e;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
	margin-top: 10px;
	margin-bottom: -25px;

	@media (min-width: 600px) {
		margin-bottom: -35px;
		margin-top: 25px;
	}
`;

const PricesWrapperMobile = styled.div`
	display: flex;
	margin-top: 30px;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	@media (min-width: 600px) {
		display: none;
	}
`;

const PlanWrapperTwo = styled.div`
	width: 300px;
	padding: 10px;
	border-radius: 16px;
	background: #eef2f5;

	@media (min-width: 600px) {
		display: none;
	}
`;

const PlanWrapperTwoDesktop = styled.div`
	width: 220px;
	padding: 10px;
	border-radius: 16px;
	background: #eef2f5;

	@media (max-width: 600px) {
		display: none;
	}
`;

const PlanTitleTwoDesktop = styled.div`
	color: #29185e;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 139%; /* 27.8px */
	letter-spacing: 0.4px;
`;

const PlanTitleTwo = styled.div`
	color: #29185e;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 139%; /* 22.24px */
	letter-spacing: 0.32px;

	@media (min-width: 600px) {
		display: none;
	}
`;

const PlanTextTwo = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
`;

const DashedText = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 15.4px */
	text-decoration: line-through #05275d;
	-webkit-text-decoration: line-through #05275d;
`;

const ActiveTo = styled.div`
	display: inline-flex;
	padding: 5px 18px 5px 19px;
	justify-content: center;
	align-items: center;
	border-radius: 22px;
	background: ${(props) => (props.active ? '#29185e' : '#EEF2F5')};
	color: ${(props) => (props.active ? '#fff' : '#29185E')};
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 139%; /* 16.68px */
	letter-spacing: 0.24px;
	text-transform: uppercase;
`;

const AditionalBottom = styled.div`
	position: absolute;
	bottom: -20px;

	display: inline-flex;
	padding: 11px 18px 12px 22px;
	justify-content: flex-end;
	align-items: center;
	border-radius: 22px;
	background: #29185e;

	color: #fff;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.24px;
	text-transform: uppercase;
`;

const Subtitle = styled.div`
	color: var(--Text-color, #29185e);
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 28px */

	@media (max-width: 600px) {
		display: none;
	}
`;

const Mobile = styled.div`
	@media (min-width: 600px) {
		display: none;
	}
`;
const Dekstop = styled.div`
	height: 600px;

	@media (max-width: 600px) {
		display: none;
	}
`;

const ActivePlanBottom = styled.div`
	position: absolute;
	bottom: -42px;

	color: #29185e;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 139%; /* 16.68px */
	letter-spacing: 0.24px;
`;

const ProfilePlan = () => {
	const bottom = useLanguageData('profile.plan');
	const [prices, setPrices] = useState([]);
	const [payments, setPayment] = useState([]);
	const [currentPlans, setCurentPlans] = useState([]);
	const [selectedOption, setSelectedOption] = useState();
	const { lang } = useContext(LanguageContext);

	const { handleSendIPstats } = useContext(IpStatsContext);

	const isSubscription = useMemo(() => {}, []);

	const navigate = useNavigate();

	const loadUserMutation = useMutation({
		mutationFn: () => loadUserPlanData(),
		onSuccess: ({ data }) => {
			const activePlans = [];

			let paymentsToCheck = [];

			data.orders.forEach((o) =>
				o.payments.forEach((p) => paymentsToCheck.push({ ...p, order: o })),
			);

			paymentsToCheck.forEach((p) => {
				const plan = data.prices.prices.find(
					(pr) =>
						pr.type.value?.toLowerCase() === p?.plan_type?.toLowerCase() ||
						pr.type.value?.toLowerCase() === p.order?.plan_type?.toLowerCase(),
				);

				const now = moment();
				const plan_to = moment(p.order.expired_date);

				if (plan_to.isAfter(now)) {
					activePlans.push({
						...p,
						plan: plan,
						plan_to: plan_to.format('DD.MM.YYYY'),
					});
				}
			});

			setCurentPlans(() => activePlans);
			setPayment(() => paymentsToCheck);
			setPrices(() =>
				data.prices.prices.filter(
					(p) => !activePlans.map((p) => p.plan_type).includes(p.type.value),
				),
			);
		},
	});

	useEffect(() => {
		loadUserMutation.mutate();
	}, []);

	useEffect(() => {
		if (currentPlans?.length === 2) {
			handleSendIPstats(IP_STATS_ENUM.APP_PROFILE_YOURPLAN2_PLAN);
		}
		if (currentPlans?.length === 1) {
			handleSendIPstats(IP_STATS_ENUM.APP_PROFILE_YOURPLAN_PLAN);
		}
	}, [currentPlans]);

	return (
		<>
			{!selectedOption ? (
				<>
					{loadUserMutation.isLoading && <Loading />}
					<TopBar>
						<IconTitleWrapper>
							<img src={ArrowLeft} onClick={() => navigate('/profile')} />
						</IconTitleWrapper>
						<Title>{bottom.yourPlan}</Title>
						<IconTitleWrapper />
					</TopBar>
					{currentPlans.length === 1 && <Subtitle>{bottom.extend}</Subtitle>}
					<Card>
						{currentPlans.length === 1 ? (
							<>
								<Mobile>
									<Section>
										<Left>{bottom.currentPlan}</Left>
										<Right>
											{currentPlans[0]?.plan?.inAppPrice?.full_label}
										</Right>
									</Section>
									<Section>
										<Left>{bottom.activeToPlan}</Left>
										<Right>{currentPlans[0]?.plan_to}</Right>
									</Section>
									<div style={{ marginTop: '20px' }}>
										<FlexElementMobile
											picked={true}
											onClick={() =>
												lang === 'pl' &&
												setSelectedOption(() => currentPlans[0]?.plan)
											}
										>
											{currentPlans[0]?.plan?.inAppPrice.saving ? (
												<ElementTop style={{ width: '120px' }}>
													{currentPlans[0]?.plan?.inAppPrice.saving}
												</ElementTop>
											) : (
												<div style={{ marginTop: '-10px' }}></div>
											)}
											<div
												style={{
													width: '100%',
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'space-between',
												}}
											>
												<div
													style={{
														width: '150px',
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<PlanNameWrapper>
														{currentPlans[0]?.plan?.type?.label}
													</PlanNameWrapper>
													<MonthWrapper>
														{currentPlans[0]?.plan?.inAppPrice?.label}
													</MonthWrapper>
													<Border />
													<NowUwillPay style={{ margin: '15px 0 0 0' }}>
														{currentPlans[0]?.plan?.inAppPrice?.nowYouWillPay}
													</NowUwillPay>
													<NowUwillPay>
														{' '}
														{
															currentPlans[0]?.plan?.inAppPrice
																?.promotion_full_price_info
														}
													</NowUwillPay>
												</div>
												<div
													style={{
														width: '150px',
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<FullPriceWrapperPercent>
														{
															currentPlans[0]?.plan?.inAppPrice
																?.original_price_info
														}
													</FullPriceWrapperPercent>
													<FullPriceWrapper>
														<b>
															{
																currentPlans[0]?.plan?.inAppPrice?.promotion_monthly_price_info.split(
																	' ',
																)[0]
															}
														</b>{' '}
														{
															currentPlans[0]?.plan?.inAppPrice?.promotion_monthly_price_info.split(
																' ',
															)[1]
														}
													</FullPriceWrapper>
													<Picker picked={true}>{bottom.short_extend}</Picker>
												</div>
											</div>
										</FlexElementMobile>
									</div>
								</Mobile>
								<Dekstop>
									<div>
										<FlexElement
											onClick={() =>
												lang === 'pl' &&
												setSelectedOption(() => currentPlans[0]?.plan)
											}
											picked={true}
										>
											{currentPlans[0]?.plan?.inAppPrice?.saving ? (
												<ElementTop style={{ width: '120px' }}>
													{currentPlans[0]?.plan?.inAppPrice?.saving}
												</ElementTop>
											) : (
												<div style={{ marginTop: '-10px' }}></div>
											)}
											<MonthWrapper>
												{currentPlans[0]?.plan?.inAppPrice?.label}{' '}
											</MonthWrapper>
											<Border style={{ marginTop: '-15px' }} />
											<FullPriceWrapperPercent
												style={{
													marginTop: !currentPlans[0]?.plan?.inAppPrice
														?.original_price_info
														? '15px'
														: '0px',
												}}
											>
												{currentPlans[0]?.plan?.inAppPrice?.original_price_info}
											</FullPriceWrapperPercent>
											<FullPriceWrapper>
												<b>
													{
														currentPlans[0]?.plan?.inAppPrice?.promotion_monthly_price_info.split(
															' ',
														)[0]
													}
												</b>{' '}
												{
													currentPlans[0]?.plan?.inAppPrice?.promotion_monthly_price_info.split(
														' ',
													)[1]
												}
											</FullPriceWrapper>
											<Picker picked={true}>{bottom?.short_extend}</Picker>
											<div style={{ marginTop: '-5px' }}></div>
											<NowUwillPay>
												{' '}
												{currentPlans[0]?.plan?.inAppPrice?.nowYouWillPay}
											</NowUwillPay>
											<NowUwillPay>
												{
													currentPlans[0]?.plan?.inAppPrice
														?.promotion_full_price_info
												}
											</NowUwillPay>
											<AditionalBottom>
												{bottom.currentPlanLong}
											</AditionalBottom>
											<ActivePlanBottom>
												{bottom?.activeToPlan}{' '}
												{moment(currentPlans[0].order.expired_date).format(
													'DD.MM.YYYY',
												)}
											</ActivePlanBottom>
										</FlexElement>
									</div>
								</Dekstop>
							</>
						) : (
							<>
								{currentPlans
									.sort(
										(a, b) =>
											new Date(b.status_complated_date).getTime() -
											new Date(a.status_complated_date).getTime(),
									)
									.map((cp, i) => (
										<>
											<PlanWrapperTwo>
												<PlanTitleTwo>
													{cp.plan?.inAppPrice.full_label}
												</PlanTitleTwo>
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
														margin: '10px 30px 16px 30px',
													}}
												>
													<PlanTextTwo>
														{' '}
														{cp.plan?.inAppPrice.label}
													</PlanTextTwo>
													<div>
														<DashedText>
															{' '}
															{cp.plan?.inAppPrice.original_price_info}
														</DashedText>
														<PlanTextTwo>
															<b>
																{
																	cp.plan?.inAppPrice.promotion_monthly_price_info?.split(
																		' ',
																	)[0]
																}
															</b>{' '}
															{
																cp.plan?.inAppPrice.promotion_monthly_price_info.split(
																	' ',
																)[1]
															}
														</PlanTextTwo>
													</div>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													<ActiveTo active={i === 0}>
														{bottom.activeToPlan}{' '}
														{i === 0
															? moment(
																	currentPlans[0].order.expired_date,
															  ).format('DD.MM.YYYY')
															: moment(
																	currentPlans[1].order.expired_date,
															  ).format('DD.MM.YYYY')}
													</ActiveTo>
												</div>
											</PlanWrapperTwo>
											<PlanWrapperTwoDesktop>
												<PlanTextTwo>Synthi AI</PlanTextTwo>
												<PlanTitleTwoDesktop>
													{cp.plan?.inAppPrice.full_label.split('-')[1]}
												</PlanTitleTwoDesktop>
												<PlanTextTwo> {cp.plan?.inAppPrice.label}</PlanTextTwo>
												<div style={{ marginTop: '46px' }}>
													<DashedText>
														{' '}
														{cp.plan?.inAppPrice.original_price_info}
													</DashedText>
													<PlanTextTwo>
														<b>
															{
																cp.plan?.inAppPrice.promotion_monthly_price_info?.split(
																	' ',
																)[0]
															}
														</b>{' '}
														{
															cp.plan?.inAppPrice.promotion_monthly_price_info.split(
																' ',
															)[1]
														}
													</PlanTextTwo>
												</div>
												<div
													style={{
														marginTop: '28px',
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													<ActiveTo active={i === 0}>
														{bottom.activeToPlan}{' '}
														{i === 0
															? moment(
																	currentPlans[0].order.expired_date,
															  ).format('DD.MM.YYYY')
															: moment(
																	currentPlans[1].order.expired_date,
															  ).format('DD.MM.YYYY')}
													</ActiveTo>
												</div>
											</PlanWrapperTwoDesktop>
										</>
									))}
							</>
						)}
						{lang === 'pl' &&
							currentPlans?.length === 1 &&
							[...(prices || [])]
								.sort((a, b) => a.per_month - b.per_month)
								.map((p) => (
									<>
										<Dekstop>
											<div>
												<FlexElement
													onClick={() =>
														lang === 'pl' && setSelectedOption(() => p)
													}
													picked={false}
												>
													{p?.inAppPrice?.saving ? (
														<ElementTop style={{ width: '120px' }}>
															{p?.inAppPrice?.saving}
														</ElementTop>
													) : (
														<div style={{ marginTop: '-10px' }}></div>
													)}
													<MonthWrapper>{p?.inAppPrice?.label} </MonthWrapper>
													<Border style={{ marginTop: '-15px' }} />
													<FullPriceWrapperPercent
														style={{
															marginTop: !p?.inAppPrice?.original_price_info
																? '15px'
																: '0px',
														}}
													>
														{p?.inAppPrice?.original_price_info}
													</FullPriceWrapperPercent>
													<FullPriceWrapper>
														<b>
															{
																p?.inAppPrice?.promotion_monthly_price_info.split(
																	' ',
																)[0]
															}
														</b>{' '}
														{
															p?.inAppPrice?.promotion_monthly_price_info.split(
																' ',
															)[1]
														}
													</FullPriceWrapper>
													<Picker picked={false}>
														{' '}
														{p?.inAppPrice?.chose}
													</Picker>

													<div style={{ marginTop: '-5px' }}></div>
													<NowUwillPay>
														{' '}
														{p?.inAppPrice?.nowYouWillPay}
													</NowUwillPay>
													<NowUwillPay>
														{p?.inAppPrice?.promotion_full_price_info}
													</NowUwillPay>
												</FlexElement>
											</div>
										</Dekstop>
									</>
								))}
					</Card>
					<PricesWrapperMobile>
						{lang === 'pl' &&
							currentPlans?.length === 1 &&
							[...(prices || [])]
								.sort((a, b) => a.per_month - b.per_month)
								.map((p) => (
									<>
										<Mobile>
											<FlexElementMobile
												onClick={() =>
													lang === 'pl' && setSelectedOption(() => p)
												}
											>
												{p.inAppPrice.saving ? (
													<ElementTop style={{ width: '120px' }}>
														{p.inAppPrice.saving}
													</ElementTop>
												) : (
													<div style={{ marginTop: '-10px' }}></div>
												)}
												<div
													style={{
														width: '100%',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'space-between',
													}}
												>
													<div
														style={{
															width: '150px',
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<PlanNameWrapper>{p.type.label}</PlanNameWrapper>
														<MonthWrapper>{p.inAppPrice.label}</MonthWrapper>
														<Border />
														<NowUwillPay style={{ margin: '15px 0 0 0' }}>
															{p.inAppPrice.nowYouWillPay}
														</NowUwillPay>
														<NowUwillPay>
															{' '}
															{p.inAppPrice.promotion_full_price_info}
														</NowUwillPay>
													</div>
													<div
														style={{
															width: '150px',
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<FullPriceWrapperPercent>
															{p.inAppPrice.original_price_info}
														</FullPriceWrapperPercent>
														<FullPriceWrapper>
															<b>
																{
																	p.inAppPrice.promotion_monthly_price_info.split(
																		' ',
																	)[0]
																}
															</b>{' '}
															{
																p.inAppPrice.promotion_monthly_price_info.split(
																	' ',
																)[1]
															}
														</FullPriceWrapper>
														<Picker>{p.inAppPrice.chose}</Picker>
													</div>
												</div>
											</FlexElementMobile>
										</Mobile>
									</>
								))}
					</PricesWrapperMobile>
					<div
						style={{
							textAlign: 'center',
							fontSize: '12px',
							color: 'grey',
						}}
					>
						{bottom.subInfo}{' '}
					</div>
				</>
			) : (
				<>
					<ProfilePayment userData={selectedOption} />
				</>
			)}
		</>
	);
};

export default ProfilePlan;
