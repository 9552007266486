import PopUp from '@/common/components/PopUp';
import React from 'react';
import styled from 'styled-components';
import InfoTop from '../images/tutorial/info_top.png';

import Translation from '../images/tutorial/translation.png';
import Microphone from '../images/tutorial/microphone.png';
import Refresh from '../images/tutorial/refresh.png';
import EndCall from '../images/tutorial/end_call.png';

const Title = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */

  margin-top: 50px;
`;
const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */

  max-width: 500px;
`;
const StartButton = styled.button`
  display: inline-flex;
  padding: 14px 42px 13px 42px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(10deg, #48068A 10%, #4322C6 65%)
  );

  border: none;
  outline: none;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  width: 670px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;
const OptionElement = styled.div`
  display: flex;
  align-items: center;
  width: 360px;
  gap: 8px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
  }
`;

const OPTIONS = [
  {
    label: 'Kliknij, aby odsłuchać ponownie wypowiedź Tutora',
    image: Refresh,
  },
  {
    label: 'Kliknij, aby mówić',
    image: Microphone,
  },
  {
    label: 'Kliknij, aby zakończyć rozmowę',
    image: EndCall,
  },
  {
    label: 'Kliknij, aby zobaczyć tłumacznie',
    image: Translation,
  },
];

const TutorialPopUp = ({ setClose }) => {
  const handleClose = () => {
    window.localStorage.setItem('show_tutorial_call_mode', 'false');
    setClose(() => false);
  };

  return (
    <PopUp setClose={() => handleClose()}>
      <Wrapper>
        <Title>Jak korzystać z trybu rozmowy?</Title>
        <div style={{ marginTop: '33px' }}>
          <img src={InfoTop} />
        </div>
        <OptionsWrapper>
          {OPTIONS.map((option) => (
            <OptionElement>
              <div>
                <img src={option.image} />
              </div>
              <Text>{option.label}</Text>
            </OptionElement>
          ))}
        </OptionsWrapper>
        <Text
          style={{
            margin: '30px 0 40px 0',
            textAlign: 'center',
          }}
        >
          Upewnij się, że wypowiadasz się wyraźnie, jesteś w cichym otoczeniu, a
          aplikacja ma udzielone wszystkie uprawnienia na dostęp do mikrofonu.
        </Text>
        <StartButton onClick={() => handleClose()}>Rozpocznij</StartButton>
      </Wrapper>
    </PopUp>
  );
};

export default TutorialPopUp;
