export const FROM_SOURCE_TYPES = {
  HINT: 'HINT',
  SPEAK: 'SPEAK',
  WRTIE: 'WRTIE',
  TOPIC: 'TOPIC',
};

export const DEVICE_TYPE = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};
