import { getCountryFromLang } from './getCountryFromLang';

export const getPriceConfigPayload = (originType, lang) => {
	let pricesConfigName = '';

	switch (originType) {
		case 'AD_SOSPRING':
			pricesConfigName = 'SOSPRING';
			break;
		case 'AD_SOVAL':
			pricesConfigName = 'SO30';
			break;
		case 'AD_SOWD':
			pricesConfigName = 'WOMANS_DAY';
			break;
		case 'AD_SOPAPRILIS':
			pricesConfigName = 'SOPAPRILIS';
			break;
		default:
			pricesConfigName = 'SO30';
	}

	let country = getCountryFromLang(lang);

	return {
		country,
		pricesConfigName,
	};
};
