export const getUtms = (queryMemo) => {
	const utm_campaign =
		queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
	const utm_medium =
		queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
	const utm_source =
		queryMemo.get('utm_source') || localStorage.getItem('utm_source');
	const utm_content =
		queryMemo.get('utm_content') || localStorage.getItem('utm_content');
	const utm_fbclid =
		queryMemo.get('utm_fbclid') || localStorage.getItem('utm_fbclid');
	const utm_term =
		queryMemo.get('utm_term') || localStorage.getItem('utm_term');
	const msclkid = queryMemo.get('msclkid') || localStorage.getItem('msclkid');
	const pageName =
		queryMemo.get('pageName') || localStorage.getItem('pageName');

	const utms = {
		utm_campaign: utm_campaign,
		utm_medium: utm_medium,
		utm_source: utm_source,
		utm_content: utm_content,
		utm_fbclid: utm_fbclid,
		utm_term: utm_term,
		payment_option: localStorage.getItem('payment_option'),
		pageName: pageName,
		uid: localStorage.getItem('uid'),
		msclkid: msclkid,
	};

	return utms;
};
