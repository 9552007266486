import Api from '../api';
const { axios: api } = Api;

const PREFIX = 'payment';

export const createOneOffPayment = async (payload) => {
  const data = await api.post(`/${PREFIX}/create-one-off-payment`, payload);
  return { data: data.data };
};

export const createSubscriptionPayment = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/create-subscription-payment`,
    payload,
  );
  return { data: data.data };
};
