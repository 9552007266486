import React from 'react';
import Score from '../images/score.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 30.8px */
`;
const ImageWrapper = styled.div``;

const ScoreText = styled.div`
  text-align: center;
  font-family: 'Work Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  background: var(
    --Gradient-2,
    linear-gradient(220deg, #4322c6 28.95%, #48068a 74.94%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const Text = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;
const ButtonStartOnceAgain = styled.button`
  display: inline-flex;
  padding: 11px 15px 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --GRADIENT-BUTTONS,
    linear-gradient(220deg, #4322c6 28.95%, #48068a 74.94%)
  );
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;
const EndLink = styled(Link)`
  display: inline-flex;
  padding: 11px 65px 10px 64px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #29185e;
  text-decoration: none;

  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

const EndWords = ({ words = 100, handleRestart }) => {
  const translationData = useLanguageData('features.wordTrainer');

  return (
    <>
      <div style={{ flex: 1 }} />
      <Title>{translationData?.endScreenTitle}</Title>
      <ImageWrapper>
        <img src={Score} />
      </ImageWrapper>
      <Text style={{ marginTop: '-30px' }}>
        {translationData?.endScreenTopInfo}
      </Text>
      <ScoreText style={{ marginTop: '-10px' }}>
        {words} {translationData?.endScreenMiddleInfo}
      </ScoreText>
      <Text style={{ marginTop: '-10px' }}>
        {translationData?.endScreenBottomInfo}
      </Text>
      <div style={{ flex: 1 }} />
      <Text style={{ maxWidth: '240px' }}>
        {translationData?.endScreenText}
      </Text>
      <ButtonStartOnceAgain onClick={handleRestart}>
        {translationData?.endScreenBottomText}
      </ButtonStartOnceAgain>
      <EndLink to="/learn"> {translationData?.endScreenEndText}</EndLink>
      <div style={{ flex: 1 }} />
    </>
  );
};

export default EndWords;
