import React, { createContext, useRef, useState } from 'react';
import { getMimeType } from '../functions/getMimeType';

export const AudioRecordingContext = createContext();

const AudioRecordingContextProvider = ({ children }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [waitingForProcess, setWaitingForProcess] = useState();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const chunksRef = useRef([]);

  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      window.audioStream = stream;

      return stream;
    } catch (err) {
      alert(
        'The microphone could not be accessed. Make sure the microphone is connected and not locked.',
      );
      return null;
    }
  };

  const handleStartRecording = async () => {
    chunksRef.current = [];
    const stream = await requestMicrophoneAccess();

    if (!stream) {
      setIsRecording(false);
      return;
    }

    const mimeType = getMimeType();
    const recorder = new MediaRecorder(stream, { mimeType });

    recorder.ondataavailable = (event) => {
      chunksRef.current.push(event.data);
    };

    recorder.start(500);
    setMediaRecorder(recorder);

    setWaitingForProcess(() => true);
    setTimeout(() => {
      setWaitingForProcess((prev) => {
        if (prev) setIsRecording(true);
        return false;
      });
    }, 500);
  };

  const handleStopRecording = () => {
    setWaitingForProcess(() => false);
    setIsRecording(() => false);
    return new Promise((resolve, _) => {
      if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.onstop = () => {
          try {
            const blob = new Blob(chunksRef.current, { type: getMimeType() });
            window.audioStream
              .getAudioTracks()
              .forEach((track) => track?.stop());
            resolve(blob);
          } catch (error) {
            resolve(null);
          }
        };

        mediaRecorder.stop();
      } else {
        if (mediaRecorder && mediaRecorder.stream) {
          window.audioStream.getAudioTracks().forEach((track) => track?.stop());
        }
        resolve(null);
      }
    });
  };

  return (
    <AudioRecordingContext.Provider
      value={{
        isRecording,
        handleStartRecording,
        handleStopRecording,
      }}
    >
      {children}
    </AudioRecordingContext.Provider>
  );
};

export default AudioRecordingContextProvider;
