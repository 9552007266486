import * as Sentry from '@sentry/react';

export const handleSetupSentry = () => {
	Sentry.init({
		dsn: 'https://b09f03755172b4aa5d04d0d7dd0418aa@o4508805200347136.ingest.de.sentry.io/4508913719443536',
		beforeSend(event) {
			if (event.level === 'info' || event.level === 'debug') {
				return null;
			}
			return event;
		},
		environment: process.env.NODE_ENV,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllInputs: false,
				maskAllText: false,
			}),
			Sentry.browserProfilingIntegration(),
			Sentry.captureConsoleIntegration({ levels: ['warn', 'error'] }),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: [
			'localhost',
			'https://app.synthiaiapp.com',
			/^\/api\//,
		],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
};
