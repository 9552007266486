import React from 'react';
import { TalkMessageWrapper, TranslationMessageWrapper } from '@/App.styles';
import TranslationBotMessage from '../TranslationBotMessage/TranslationBotMessage';
import styled from 'styled-components';

export const ContentTranslationWrapper = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	text-align: left;
	gap: 10px;
	margin-top: 10px;
`;
const BotMessageTalking = ({
	id,
	message,
	translationMessages,
	showMessagesTranslations,
	handleGetTranlationForBot,
}) => {
	const helper = () => showMessagesTranslations.includes(`bot-message-${id}`);

	return (
		<>
			<ContentTranslationWrapper id={`bot-message-${id}`}>
				<TalkMessageWrapper>{message}</TalkMessageWrapper>
				<TranslationBotMessage
					show={true}
					setShowTrnaslation={() =>
						handleGetTranlationForBot(`bot-message-${id}`, message)
					}
				/>
			</ContentTranslationWrapper>
			{translationMessages.find((f) => f.name === `bot-message-${id}`) &&
				helper() && (
					<TranslationMessageWrapper className='fade__in__animation'>
						{
							translationMessages.find((f) => f.name === `bot-message-${id}`)
								?.data
						}
					</TranslationMessageWrapper>
				)}
		</>
	);
};

export default BotMessageTalking;
