import { getClientAllScoreWordTrainer } from '@/api/query/word-trainer';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 30.8px */
`;
const Text = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const Border = styled.div`
  width: 230px;
  min-height: 1px;

  background: #eef2f5;
`;

const TextBottom = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  max-width: 260px;
`;

const ButtonStartOnceAgain = styled.button`
  display: inline-flex;
  padding: 10px;
  width: 230px;

  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --GRADIENT-BUTTONS,
    linear-gradient(220deg, #4322c6 28.95%, #48068a 74.94%)
  );
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;
const EndLink = styled(Link)`
  display: inline-flex;
  padding: 10px;
  width: 210px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #29185e;
  text-decoration: none;

  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const BorderVertial = styled.div`
  width: 1px;
  height: 65px;
  background: #eef2f5;
`;

const NumberName = styled.div`
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Work Sans';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 67.2px */

  background: var(
    --Gradient-2,
    linear-gradient(220deg, #4322c6 28.95%, #48068a 74.94%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const NumberNameSmall = styled.div`
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Work Sans';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 30.8px */

  background: var(
    --Gradient-2,
    linear-gradient(220deg, #4322c6 28.95%, #48068a 74.94%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-top: 10px;
`;

const ScoreContainer = styled.div`
  min-width: 150px;
`;

const RightScoreContainer = styled.div`
  min-width: 80px;
`;

const SessionScore = ({ sesionScore, setClose }) => {
  const translationData = useLanguageData('features.wordTrainer');

  const [allWordsScore, setAllWordsScore] = useState();

  const loadScoreMutation = useMutation({
    mutationFn: () => getClientAllScoreWordTrainer(),
    onSuccess: ({ data }) => {
      console.log(data);
      setAllWordsScore(() => data);
    },
  });

  useEffect(() => {
    loadScoreMutation.mutate();
  }, []);

  return (
    <>
      {loadScoreMutation.isLoading && <Loading />}
      <div style={{ flex: 1 }} />
      <Title>{translationData?.sesionScoreTitle}</Title>
      <Text>{translationData?.sesionScoreSubTitle}</Text>
      <Text style={{ marginTop: '5px' }}>
        {translationData?.sesionScoreWord}
      </Text>
      <Border />
      <ScoreWrapper>
        <ScoreContainer>
          <Text>{translationData?.now}</Text>
          <NumberName>{sesionScore?.words}</NumberName>
        </ScoreContainer>
        <BorderVertial />
        <RightScoreContainer>
          <Text>{translationData?.sum} </Text>
          <NumberNameSmall>{allWordsScore?.words}</NumberNameSmall>
        </RightScoreContainer>
      </ScoreWrapper>
      <Text style={{ marginTop: '5px' }}>{translationData.speakingScore}</Text>
      <Border />
      <ScoreWrapper>
        <ScoreContainer>
          <Text>{translationData?.now}</Text>
          <NumberName>
            {(sesionScore?.points / Math.max(allWordsScore?.words, 1)).toFixed(
              0,
            )}
            %
          </NumberName>
        </ScoreContainer>
        <BorderVertial />
        <RightScoreContainer>
          <Text>{translationData?.sum}</Text>
          <NumberNameSmall>
            {(
              allWordsScore?.points / Math.max(allWordsScore?.words, 1)
            ).toFixed(0)}
            %
          </NumberNameSmall>
        </RightScoreContainer>
      </ScoreWrapper>
      <TextBottom>{translationData.areYouSureToEndLesson}</TextBottom>
      <ButtonStartOnceAgain onClick={() => setClose(null)}>
        {translationData.continue}
      </ButtonStartOnceAgain>
      <EndLink to="/learn">{translationData.endScreenEndText}</EndLink>
      <div style={{ flex: 1 }} />
    </>
  );
};

export default SessionScore;
