import styled from 'styled-components';

export const Label = styled.label`
	color: #868686;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
`;

export const Input = styled.input`
	margin-top: 4px;
	display: flex;
	padding: 8px 10px 8px 10px;
	width: 260px;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #29185e;
	background: #fff;

	&:hover {
		outline: none;
	}
	&:focus {
		outline: none;
	}
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

export const LinkWrapper = styled.div`
	color: #05275d;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 10px auto;

	@media (min-width: 600px) {
		margin-top: 8px;
	}

	input[type='checkbox'] {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 15px;
		height: 15px;
		border: 2px solid #29185e;
		border-radius: 4px;
		outline: none;
		cursor: pointer;
		position: relative;
		display: inline-block;
		vertical-align: middle;
	}

	input[type='checkbox']:checked {
		background-color: #29185e;
	}

	input[type='checkbox']:checked::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 4px;
		height: 8px;
		border: solid white;
		border-width: 0 2px 2px 0;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	input[type='checkbox']:focus {
		box-shadow: 0 0 3px #29185e;
	}
`;

export const LetMailButton = styled.button`
	display: flex;
	gap: 10px;
	padding: 9px 82px 10px 82px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #fff;
	text-align: center;
	font-family: 'Work Sans';
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	margin: 5px auto;

	@media (min-width: 600px) {
		margin-top: 20px;
	}
`;

export const EmailBox = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background: #f4f6f8;
	border-radius: 12px;
	width: 320px;
	min-height: 80px;
	margin: -20px auto 20px auto;

	@media (max-width: 600px) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
`;

export const StyledInput = styled(Input)`
	@media (min-width: 600px) {
		border: 1px solid #29185e;
		color: #29185e;
		width: 300px;
	}
`;

export const HintMail = styled.div`
	float: right;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;

	margin-top: -5px;
	padding-top: 5px;
	padding-left: 10px;
	min-height: 30px;
	height: fit-content;
	width: 302px;
	flex-shrink: 0;
	border-radius: 0px 0px 16px 16px;
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	cursor: pointer;
	gap: 5px;
	text-align: left;

	color: #fff;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: left;
	z-index: 3;

	@media (max-width: 600px) {
		width: 263px;
	}
`;

export const FormatMailCorrection = styled.div`
	color: #fff;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
`;

export const TextInfo = styled.div`
	color: var(--Text-color, #29185e);
	text-align: center;
	font-family: 'Work Sans';
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
