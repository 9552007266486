import Android1 from './images/android_1.png';
import Android2 from './images/android_2.png';
import Ios1 from './images/ios_1.png';
import Ios2 from './images/ios_2.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

import Micro from './images/wordTrainerTutorial/icon-microphone.png';
import Voice from './images/wordTrainerTutorial/volume-up.svg';
import Person from './images/wordTrainerTutorial/user.svg';

export default {
	passwordRestart: {
		title: 'Reset Password',
		password: 'Password',
		repeatPassword: 'Repeat Password',
		restartPawssord: 'Reset Password',
	},

	paywall: {
		title: 'Choose a plan to continue learning',
		buy: 'Buy',
		savePayment: 'Secure payments managed by',
		skip: 'Skip',
		pickPlan: 'Pick Plan',
		endPlanFirstPart: 'Your access will end in',
		endPlanSecondPart: 'days.',
		endPlanAditionalPart: 'day.',
		paymentTitle: 'Summary',
		product: 'Product:',
		plan: 'SynthiAi Package',
		forLong: 'Access for',
		data: 'data:',
		nameAndSurname: 'Name and Surname:',
		paymentForm: 'Payment Method:',
		toPay: 'To pay today:',
		accept: 'I accept',
		rules: 'the terms',
		payingAccept: 'By paying, you accept',
		paymentRules: '"PayU Payment Terms"',
		buyAndPay: 'BUY AND PAY',
	},
	paymentStatus: {
		veryfication: 'Payment is still processing...',
		title: 'Payment completed',
		success: 'Successful!',
		failure: 'Failed!',
		subtitle: 'You will be redirected to the login page shortly.',
		textInfo: `Your payment has been successfully processed. You can now set up your password by accessing our registration page. Within a few minutes, you will receive an email with a link to set your password.
Please check your inbox, as well as the spam folder. To make searching easier, type "synthiai" in the search field. If you have any problems, please contact us at: contact@synthiaiapp.com`,
		login: 'Go to login',
	},
	register: {
		steps: {
			register: 'Register',
			package: 'Package',
			summarize: 'Summary',
			step: 'Step ',
		},
		stepOne: {
			password: 'Password',
			errorGoogle: 'Connect with your email',
			title: 'Registration',
			nameAndSurname: 'Name and Surname',
			placeholderNameAndSurname: 'XXXXXXXXXXX',
			email: 'Email',
			placeholderEmail: 'e.g. example@synthiaiapp.com',
			password: 'Password',
			start: 'CREATE ACCOUNT',
			subtitle:
				'The password must be at least 8 characters long, including lowercase and uppercase letters, and a number.',
			passwordError1: 'The password must contain at least 8 characters.',
			passwordError2: 'The password must contain at least 1 uppercase letter.',
			passwordError3: 'The password must contain at least 1 lowercase letter.',
			passwordError4: 'The password must contain at least 1 number.',
			passwordError5: 'Passwords must match.',
		},
		stepTwo: {
			title: 'Choose your plan',
			month: 'month',
			months: 'months',
			months2: 'months',
			value: '€ / month',
			pick: 'choose',
			nowPay: 'Pay Now',
			saveOne: 'Save',
			buy: 'Buy',
			savePayment: 'Secure payments processed by',
		},
		stepThree: {
			title: 'Summary',
			product: 'Product:',
			plan: 'Synthi AI Package',
			forLong: 'Access for',
			data: 'date:',
			nameAndSurname: 'Name and Surname:',
			paymentForm: 'Payment Method:',
			toPay: 'To pay:',
			accept: 'Accept',
			rules: 'terms and conditions',
			payingAccept: 'By paying you accept the',
			paymentRules: '"PayU Payment Terms"',
			buyAndPay: 'BUY AND PAY',
		},
	},
	succesPayment: {
		title: 'Payment successful!',
		subtitle: 'You will be redirected to the login page shortly.',
	},
	firstLogin: {
		createPasswordForAcoount: 'Create a password for your account',
		typeEmail: 'Enter your email address',
		useSameEmail: 'Use the same address you provided when purchasing',
		typePassword: 'Enter your password',
		confirmPassword: 'Confirm your password',
		createPassword: 'Create Password',
		errorLogin: 'Check if your password or email is correct',
		createdPassword: 'Have you already created a password?',
		clickToLogin: 'Click here to log in',
	},
	login: {
		dontHavePassword: 'Don’t have a password yet?',
		getPassword: 'Click here to gain access',
		title: 'Log in',
		email: 'Email',
		password: 'Password',
		textSub: 'Forgot your password? Reset',
		restart: 'here',
		restartPasswordText:
			'Enter your email to reset your password. The password will be sent to your inbox.',
		placeholder: 'enter your email...',
		resetPassword: 'reset password',
		buttonText: 'Log in',
		errorLogin: 'Please check if your password or email is correct',
		passwordResterted: 'Your password has been reset. Check your email.',
		passwordError: 'Sorry, we couldn’t find your email.',
		mobileAppBannerText: 'Discover our app!',
	},
	navBar: {
		home: 'Home',
		learn: 'Learn',
		profile: 'Profile',
	},
	home: {
		hello: 'Hello',
		question: 'What would you like to talk about today?',
		answer: 'Start a conversation',
		forYou: 'Recommended for you',
		popularNow: 'Popular now',
		doYouKnow: 'Did you know...?',
		funFact: 'Fun fact of the day',
		new: 'New',
		check: 'Check',
		yourFavorite: 'Your favorites',
		newTitle: 'Word Trainer',
		newSubtitle: 'Practice word pronunciation',
		talkWith: 'Talk about anything with',
	},
	learn: {
		wordTrainer: {
			title: 'Word Trainer',
			subtitle: 'Practice word pronunciation',
			new: 'New',
		},
		flashcardV2: {
			new: 'New',
			sport: 'Sport',
			continue: 'continue',
			nice: 'Great!',
			congratulation: 'Congratulations!',
			category: 'Category',
			textAfterCategory: 'has no secrets for you!',
			pickNewCategory: 'Pick a new category',
			showResult: 'Show solution',
			createWordWithLetters: 'Create a word from scrambled letters',
			know: 'I know',
			dontKnow: "I don't know",
			metchWords: 'Match words',
			addNewCategory: 'Add a new category',
			personalizeLearning:
				'Personalize your learning by creating your own category.',
			typeNewCategory: 'Type your category name',
			save: 'Save',
			weAreCreatingCategoryForYou: 'We are creating a new category for you:',
			start: 'START',
			howToUseFlashcard: 'How to use flashcards?',
			knowFlashcard:
				'Click "I don\'t know" or "I know" to assess your knowledge.',
			dontKnowFlashcard: 'Flashcards marked as "I know" won\'t be repeated.',
			clickToHear: 'Click to hear the word.',
			clickForDefinition: 'Click to see the definition',
			clickForTranslation: 'Click to see the translation and examples',
			close: 'Close',
			clickCategoryToStart: 'Click on the selected category to start learning.',
			lookAsProgressBar:
				"The progress bar under the category shows how many words you've mastered.",
			clickToSetupFavaroiteFlashcard: 'Click to mark a category as favorite.',
			clickToCreateOwnCategory: 'Click to create your own flashcard category.',
			howLearningLookLike: 'What does learning look like?',
			restText:
				" Learning involves reviewing flashcards and assessing your knowledge. Additionally, you'll solve simple tasks.",
		},
		chat: {
			title: 'Chat',
			subtitle: 'Converse on any topic.',
		},
		fotoMode: {
			title: 'Guess the Photo',
			subtitle: 'Describe the image in as much detail as possible.',
		},
		scene: {
			title: 'Scenes',
			subtitle: 'Act with an AI tutor.',
		},
		word: {
			title: 'Words',
			subtitle: 'Expand your vocabulary.',
		},
		flashcard: {
			title: 'Flashcards',
			subtitle: 'Review and memorize words.',
		},
		seeMore: 'See all',
	},
	common: {
		description:
			'SynthiAi - Your Intelligent Assistant for Learning English. Learn to speak English fluently with the help of artificial intelligence. Discover a new effective approach to language learning. Start today!',
		deletedFromFavorite: 'Removed from favorites',
		addedToFavorite: 'Added to favorites',
		flashcards: 'Flashcards',
		flashcards_v2: 'Flashcards',
		scenes: 'Scenes',
		words: 'Words',
		privacyLink: 'privacy',
		termsLink: 'tos',
		or: 'or',
		continueWithFB: 'Continue with Facebook',
	},
	profile: {
		days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
		top: {
			profile: 'Profile',
			accessTo: 'Access to:',
			profileImage: 'Profile photo',
			nameAndSurname: 'Name and Surname',
			password: 'Password',
			change: 'Change',
			changeProfileImage: 'Change profile photo',
			changeNameAndSurname: 'Change name and surname',
			changeNameAndSurnamePlaceHolder: 'Enter your name and surname',
			save: 'Save',
			changePassword: 'Change password',
			passwordInfo:
				'The password must be at least 8 characters long, including lowercase and uppercase letters, and a number',
			repeatPassword: 'Repeat password',
			passwordChanged: 'Password changed',
			error: 'Oops, something went wrong',
			nameChanged: 'Name changed',
			profileChanged: 'Profile changed',
		},
		plan: {
			yourPlan: 'Your plan',
			extend: 'Extend your plan',
			short_extend: 'Extend',
			currentPlan: 'Current plan:',
			activeToPlan: 'Active until:',
			currentPlanLong: 'Your current plan',
		},
		middle: {
			yourMedals: 'Your achievements',
			oneDay: 'day',
			twoDays: 'days',
			words: 'Words',
			learningTime: 'Learning time',
			streak: 'Streak',
			streakRecord: 'Streak record',
			yourRecord: 'Your record:',
			currentRecord: 'Current record:',
			wordsInWeek: 'Words this week:',
		},
		bottom: {
			yourTutor: 'Your AI tutor',
			change: 'Change',
			logout: 'Log out',
			FAQ: 'FAQ',
			shareOpinion: 'Share your opinion',
			yourPlan: 'Your plan',
			hobby: 'Hobbies',
			learningSetting: 'Learning settings',
			back: 'Back',
			dayTarget: 'Daily target',
			languageLevel: 'Language level',
			changeYourLangLevel: 'Change your language level.',
			pickNewTutor: 'Pick a new tutor',
			yourDecision:
				'Your decision will affect the style and atmosphere of lessons.',
			tutroChanged: 'Tutor changed',
			error: 'Oops, something went wrong',
			englishLevelChanged: 'Your level has been changed',
			levelsOptions: [
				{
					name: 'Beginner A1',
					description: 'Start your English adventure',
					value: 'basic',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Elementary A2',
					description:
						'You can understand basic information in simple everyday conversations',
					borderRadius: '0',
					value: 'beginner',
				},
				{
					name: 'Intermediate B1',
					description:
						'You can hold simple but understandable conversations on familiar topics.',
					borderRadius: '0',
					value: 'intermediate',
				},
				{
					name: 'Advanced B2',
					description: 'You can communicate fluently in most situations',
					borderRadius: '0',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description:
						'You can use the language effectively in complex contexts',
					value: 'expert',
					borderRadius: '0 0 8px 8px ',
				},
			],
			changeDailyTarget: 'Change your daily learning target.',
			deletedFromFavorite: 'Removed from favorites',
			addedToFavorite: 'Added to favorites',
			lessonTime: [
				{
					name: 'Quick lesson',
					dsecription: 'Ideal option for express learning',
					time: '5 min',
					value: 'five_minutes',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Standard session',
					dsecription: 'Great option for balanced learning',
					time: '10 min',
					value: 'ten_minutes',
					borderRadius: '0',
				},
				{
					name: 'Intensive practice',
					dsecription: 'Best option for enthusiasts',
					time: '15 min',
					value: 'fifteen_minutes',
					borderRadius: '0 0 8px 8px ',
				},
			],
			dailyTargetChanged: 'Daily target changed!',
			pickIntresets: 'Pick at least 3 hobbies.',
			activeTo: 'Plan active until:',
			daysLeft: 'Days left in plan:',
			opinionSubtitle:
				'We are constantly improving our app. Share your opinion on what we could change in the app or what you like most.',
			startTyping: 'Start typing',
			faq: 'FAQ',
			send: 'Send',
			withProblems: 'If you have questions/problems, contact us:',
			contactMail: 'contact@synthiaiapp.com',
			faqConfig: [
				{
					id: 1,
					question: 'The sound is not working.',
					answer: `If you're having sound issues, consider these tips: 
      
              Check the audio permissions in your browser.
      
              Ensure your device’s volume is set appropriately.
      
              Verify that speakers/headphones are properly connected.
      
              Check your system’s audio settings for muted apps.
      
              Try a different device or contact technical support.
          `,
				},
				{
					id: 2,
					question: 'I cannot record my voice.',
					answer: `If you're having trouble recording audio, consider these tips: 
              
              Check the microphone permissions in your browser.
      
              Ensure the mobile browser has access to the microphone.
      
              Verify that the microphone is properly connected.
      
              Consider using a different device or contact technical support.
          `,
				},
				{
					id: 3,
					question: 'Can I use the app on more than one device?',
					answer: `Absolutely, simply log in on any supported device to enjoy a seamless learning experience.`,
				},
				{
					id: 4,
					question: 'Can I use Synthi AI without an internet connection?',
					answer: `No, Synthi AI requires a web browser with internet access.`,
				},
				{
					id: 5,
					question: 'How do I change my AI tutor?',
					answer: `Go to the "Tutor" tab in Synthi AI settings. Explore and choose the tutor you prefer.`,
				},
				{
					id: 6,
					question: 'How do I renew my access to the app after it expires?',
					answer: `You can renew your plan by logging in after it expires.`,
				},
				{
					id: 7,
					question: 'Can I get an invoice?',
					answer: `Contact contact@synthiaiapp.com providing your VAT number and company details.`,
				},
				{
					id: 8,
					question: 'How can I contact technical support?',
					answer: `Send an email to contact@synthiaiapp.com with details of the issue, device information, and browser details.`,
				},
			],
		},
		rules: 'terms and conditions',
		privacy: 'Privacy Policy',
		span: ' and ',
	},
	welcome: {
		languagePicker: {
			title: 'Choose the language you want to learn',
			next: 'Next',
			langToLearn: 'Learning language',
			successTitle: 'Learning language changed',
			config: [
				{
					label: 'English',
					label2: 'English',
					labelInside: 'english',
					value: 'en',
				},
				{
					label: 'German',
					label2: 'German',
					labelInside: 'german',
					value: 'de',
				},
				{
					label: 'Spanish',
					label2: 'Spanish',
					labelInside: 'spanish',
					value: 'es',
				},
				{
					label: 'French',
					label2: 'French',
					labelInside: 'french',
					value: 'fr',
				},
				{
					label: 'Italian',
					label2: 'Italian',
					labelInside: 'italian',
					value: 'it',
				},
			],
		},
		zeroScreen: {
			welcomeInApp: 'Welcome to Synthi AI!',
			start: 'START',
			welcomeMobile: 'Welcome',
			config: {
				chrome: {
					title: 'Install the app on your Android device.',
					text1: 'In the Chrome browser, click',
					text1bold: ' Settings',
					image1: Android1,
					text2: 'Then click',
					text2bold: ' Add to home screen',
					image2: Android2,
					text3: 'Done! Synthi AI is now on your home screen',
					image3: Last,
				},
				safari: {
					title: 'Install the app on your iOS device.',
					text1: 'In the Safari browser, click',
					text1bold: ' Share',
					image1: Ios1,
					text2: 'Then click',
					text2bold: ' Add to home screen',
					image2: Ios2,
					text3: 'Done! Synthi AI is now on your home screen',
					image3: Last,
				},
			},
		},
		firstScreen: {
			title: 'Choose your tutor!',
			subtitle: 'Your choice will affect the style and atmosphere of lessons.',
			next: 'NEXT',
		},
		secondScreen: {
			title: 'What is your current {language} level?',
			config: [
				{
					name: 'Beginner A1',
					description: 'Start your adventure with {language}.',
					value: 'basic',
				},
				{
					name: 'Elementary A2',
					description:
						'You can understand basic information in simple everyday conversations',
					value: 'beginner',
				},
				{
					name: 'Intermediate B1',
					description:
						'You can hold simple but understandable conversations on familiar topics.',
					value: 'intermediate',
				},
				{
					name: 'Advanced B2',
					description: 'You can communicate fluently in most situations',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description:
						'You can use the language effectively in complex contexts',
					value: 'expert',
				},
			],
			next: 'NEXT',
		},
		thirdScreen: {
			title: 'What are your hobbies?',
			subtitle: 'Pick at least 3 hobbies.',
			next: 'NEXT',
		},
		fourthScreen: {
			title: 'How many minutes per day do you want to study?',
			subtitle: 'Choose your daily target.',
			next: 'LET’S START!',
			config: [
				{
					name: 'Quick lesson',
					dsecription: 'Ideal option for express learning',
					time: '5 min',
					value: 'five_minutes',
				},
				{
					name: 'Standard session',
					dsecription: 'Great option for balanced learning',
					time: '10 min',
					value: 'ten_minutes',
				},
				{
					name: 'Intensive practice',
					dsecription: 'Best option for enthusiasts',
					time: '15 min',
					value: 'fifteen_minutes',
				},
			],
		},
	},
	features: {
		wordTrainer: {
			next: 'Next',
			yourSpeaking: 'Your pronunciation',
			you: 'You',
			title: 'Word Trainer',
			errorMessage:
				'Oops, we have a problem processing your recording. Try again :)))',
			endScreenTitle: 'Great! 🌟🌟🌟',
			endScreenTopInfo: 'You have mastered all',
			endScreenMiddleInfo: 'words',
			endScreenBottomInfo: 'at this level.',
			endScreenText:
				'You can start learning again or change the level in the settings.',
			endScreenBottomText: 'Start over',
			endScreenEndText: 'Finish',
			sesionScoreTitle: "You're doing great! 🎉",
			sesionScoreSubTitle: "You're improving your pronunciation",
			sesionScoreWord: 'Words',
			now: 'Now',
			sum: 'total',
			continue: 'CONTINUE',
			areYouSureToEndLesson: 'Are you sure you want to end the lesson?',
			speakingScore: 'Pronunciation score',
			GOOD_ARRAY: [
				'Bravo!',
				'Perfect!',
				"You're a champion!",
				'Great!',
				'Amazing!',
				'Genius!',
			],
			BAD_ARRAY: [
				"Don't give up!",
				'You can do it!',
				'Almost there!',
				"Keep practicing, you're close!",
				"You'll get it soon!",
				'No worries, try again!',
			],
			OK_ARRAY: [
				"You're on the right track!",
				'Very close!',
				'Getting better!',
				"You're making progress!",
				'Great effort!',
			],
			hold: 'Hold',
			holdText: 'the microphone and say:',
			tutorial: {
				title: 'How to use the Word Trainer?',
				options: [
					{
						label: 'Click to listen to the correct pronunciation',
						icon: Micro,
					},
					{
						label: 'Hold and pronounce the word',
						icon: Voice,
					},
					{
						label: 'Click and listen to your recording to master the word!',
						icon: Person,
					},
				],
				bottomText:
					'Regular practice with the Word Trainer will help you master the language even faster!',
				close: 'CLOSE',
			},
		},
		topbar: {
			back: 'Back',
		},
		fotoMode: {
			welcome: {
				title: 'Photo Guess ',
				subtitle:
					'Describe what you see in the picture with as much detail as possible on the selected topic.',
				start: 'Start!',
			},
			info: {
				startTyping: 'Start typing...',
				newTask: 'New task',
				send: 'Send',
			},
		},
		flashcards: {
			title: 'Cards',
			subtitleTop:
				'Learn and memorize new words through an interactive study method with flashcards.',
			addCategory: 'Add your category',
			subtitle: 'Type the name of your category',
			type: 'type category...',
			add: 'Add',
			previous: 'Previous card',
			task: 'Task',
			changeLevel: 'Change level',
			dontKnow: 'I don’t know',
			know: 'I know',
			pickPairs: 'Match pairs',
			goodJob: 'Good job!',
			titleEnd: 'Your dedication is impressive',
			subtitleEnd: 'Are you ready for more challenges?',
			repeat: 'Repeat category',
			other: 'Choose another category',
			level: {
				title: 'Change flashcard level',
				beginner: ' Beginner A1-A2',
				intermediate: 'Intermediate B1-B2',
				advance: 'Advanced C1-C2',
				change: 'Change',
			},
		},
		scene: {
			title: 'Scenes',
			subtitle:
				'Get into character and act out a scene with an AI tutor on a selected topic.',
			suggest: 'Suggest a new scene',
			talk: 'Talk',
			deletedFromFavorite: 'Removed from favorites',
			addedToFavorite: 'Added to favorites',
		},
		word: {
			title: 'Words',
			subtitle:
				'Expand your vocabulary in key areas in conversations with an AI tutor.',
			suggest: 'Suggest a new word topic',
			deletedFromFavorite: 'Removed from favorites',
			addedToFavorite: 'Added to favorites',
		},
	},
	cookie: {
		title: 'Cookie Information',
		subtitle:
			'This website uses cookies to provide you with a better user experience and offer personalized content. For more information, consult our technical support ',
		link: 'privacy policy',
		accept: 'ACCEPT',
		denied: 'REJECT',
	},
	talkingBar: {
		allowMicrofon: 'Please allow microphone access',
		typeMessage: 'Type a message...',
		record: 'Record',
		recording: 'Recording',
	},
	hands: {
		giveUsInfo: 'Give us your feedback',
		thanksForGrading: 'Thanks for your rating!',
		subtitle: 'Your voice is important to us!',
		subtitle2: 'Tell us what you think about this lesson:',
		skip: 'SKIP',
		send: 'SEND',
	},
	suggestConfig: {
		profile: {
			title: 'Share your opinion!',
			subtitle:
				'We are constantly improving our app. Share your opinion on what we could change in the app or what you like most.',
		},
		word: {
			title: 'Suggest a new word topic',
			subtitle: '',
		},
		scene: {
			title: 'Suggest a new scene',
			subtitle: '',
		},
		startTyping: 'Start typing',
		send: 'SEND',
		thx: 'Feedback sent! Thanks!',
		error: 'Oops! Something went wrong',
	},
	checkout: {
		emailGuessText: 'Did you mean',
		leftTitle: 'Choose a plan for yourself',
		rightTitle: 'Complete the details',
		subscribe: 'subscribe',
		currencySign: '€',
		currency: 'eur',
		savePayments: 'Secure payments are managed by',
	},
	dailyV2: {
		conversation: {
			doYouWantContinueConversation:
				'Do you want to continue the conversation or start a new one?',
			continueConversation: 'Continue conversation',
			newConversation: 'New conversation',
		},
		hint: {
			title: 'Hint',
			pick: 'Pick',
		},
		feedback: {
			yourMessage: 'Your message',
			goodMessage: 'Correct message',
			explanation: 'Explanation',
		},
		tutorial: {
			title: 'How to use the chat?',
			skip: 'Skip',
			next: 'NEXT',
			start: 'Start',
			screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
			config: [
				[
					{
						title: "Click to mute the Tutor's sound",
						image: VolumeUp,
					},
					{ title: 'Click to give your feedback', image: HandsUp },
					{ title: 'Click to open instructions', image: Tutorial },
				],
				[
					{ title: 'Click to listen again', image: VolumeUpBlank },
					{
						title: 'Click to see the translation',
						image: TranslationBlank,
					},
					{ title: 'Click to see the hint', image: HintBlank },
					{ title: 'Click to report an issue', image: InfoBlank },
				],
				[
					{ title: 'Your answer is correct', image: GreenCircle },
					{
						title: 'Click to see the corrected answer and explanation',
						image: YellowCircle,
					},
				],
				[
					{ title: 'Click the text field to type a message' },
					{ title: 'Click to record a message', image: Microphone },
				],
			],
		},
	},
	problem: {
		title: 'Report a problem',
		subtitle: 'Describe the problem related to the AI message',
		send: 'Send',
		messageSend: 'Message sent',
		error: 'Something went wrong, write to us :)',
	},
};
