import React, { useContext } from 'react';
import styled from 'styled-components';
import ArrRightPurple from './images/arr-right-p.svg';
import ArrRight from './images/arr-right.svg';
import { Link } from 'react-router-dom';
import { CustomerDataContext } from '@/common/components/layout/productLayout/context/CustomerDataContext';

const WrapperWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	margin-top: 20px;
`;

const Wrapper = styled(Link)`
	height: 110px;
	text-decoration: none;
	border-radius: 10px;
	background: ${({ gradient }) =>
		gradient ? 'linear-gradient(10deg, #48068a 10%, #4322c6 65%)' : 'white'};

	margin-top: ${({ gradient }) => (gradient ? '50px' : 0)};
	display: flex;
	align-items: center;
	width: 94vw;
	margin-left: 3vw;
`;

const ImageWrapper = styled.div`
	position: relative;
	min-width: 70px;
	max-width: 70px;
	margin: 10px;

	img {
		width: 100%;
		height: auto;
	}
`;

const Point = styled.div`
	width: 12px;
	height: 12px;
	flex-shrink: 0;
	border-radius: 39px;
	background: #01b07c;
	position: absolute;
	bottom: 10px;
	right: 0px;
`;

const TopMessage = styled.div`
	display: inline-flex;
	padding: 10px 10px 10px 10px;
	align-items: center;
	border-radius: 10px 10px 10px 0px;
	background: #eef2f5;
	color: #29185e;
	font-family: Lato;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 123.5%; /* 17.29px */
	letter-spacing: 0.28px;
	float: left;
`;

const BottomMessage = styled(Link)`
	text-decoration: none;
	cursor: pointer;
	margin-top: 12px;
	float: right;
	display: flex;
	min-width: 200px;
	max-width: 240px;
	padding: 8px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	background: ${({ white }) =>
		white ? 'white' : 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)'};
	border-radius: 27px;

	color: ${({ white }) => (white ? '#29185e' : 'white')};
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;
`;

const TalkWith = ({ icon, translation }) => {
	const { userData } = useContext(CustomerDataContext);

	return (
		<WrapperWrapper>
			<Wrapper to='/learn/chat' gradient={userData?.tests?.fixedTalkWith}>
				<ImageWrapper>
					<img src={icon} alt='tutor' />
					<Point />
				</ImageWrapper>
				<div style={{ margin: '12px', width: '100%' }}>
					<TopMessage>{translation.question}</TopMessage>
					<BottomMessage to='/learn/chat' white={userData?.tests?.fixedTalkWith}>
						{translation.answer}{' '}
						<img
							src={userData?.tests?.fixedTalkWith ? ArrRightPurple : ArrRight}
							alt='arrow right'
						/>
					</BottomMessage>
				</div>
			</Wrapper>
		</WrapperWrapper>
	);
};

export default TalkWith;
