import React, { useState } from 'react';
import styled from 'styled-components';
import Megan from './images/icons/Megan.png';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const TopWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 28px;
`;

const Text = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
  max-width: 200px;
`;

const Left = styled.div`
  width: 80px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Subtitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  margin-top: 8px;
`;

const MinutesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  margin-top: 24px;
`;

const MinutesElement = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 8px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 250px;

  border-radius: 8px;
  background: ${(props) => (props.isActive ? 'linear-gradient(10deg, #48068A 10%, #4322C6 65%)' : '#EEF2F5')};
`;

const TitleBox = styled.div`
  color: ${(props) => (props.isActive ? '#F4F6F8' : '#29185E')} !important;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const Description = styled.div`
  color: ${(props) => (props.isActive ? '#F4F6F8' : '#29185E')} !important;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;

const SubmitButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  width: 192px;
  padding: 14px 0px 14px 0px;
  justify-content: center;
  align-items: center;
  color: white;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);
  margin-top: 44px;

  &:disabled {
    background: #d9d9d9 !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const DailyMinutes = ({ data, handleSend }) => {
  const { title, subtitle, config, next } = useLanguageData(
    'welcome.fourthScreen',
  );
  const [selected, setSelected] = useState(config[0].value);

  return (
    <>
      <TopWrapper>
        <Left>
          <img src={data.tutor?.tutorial?.base64 || Megan} />
        </Left>
        <Text>{title}</Text>
      </TopWrapper>
      <Subtitle>{subtitle}</Subtitle>
      <MinutesWrapper>
        {config.map((c) => (
          <MinutesElement
            isActive={selected == c.value}
            onClick={() => setSelected(c.value)}
          >
            <TopBar>
              <TitleBox isActive={selected == c.value}>{c.name}</TitleBox>
              <TitleBox isActive={selected == c.value}>{c.time}</TitleBox>
            </TopBar>
            <Description isActive={selected == c.value}>
              {c.dsecription}
            </Description>
          </MinutesElement>
        ))}
      </MinutesWrapper>
      <div style={{ height: '130px' }}></div>
      <ButtonWrapper>
        <SubmitButton
          onClick={() => {
            handleSend({
              ...data,
              dailyMinutes: selected,
            });
          }}
          disabled={!selected}
        >
          {next}
        </SubmitButton>
      </ButtonWrapper>
    </>
  );
};

export default DailyMinutes;
