import PopUp from '@/common/components/PopUp';
import React from 'react';
import styled from 'styled-components';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */

  max-width: 300px;

  @media (min-width: 600px) {
    font-size: 14px;
  }
`;

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */

  @media (min-width: 600px) {
    font-size: 24px;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;

  margin-top: 30px;
  gap: 24px;
`;

const OptionElement = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;

  div {
    display: flex;
    align-items: center;
  }
`;

const CloseButton = styled.button`
  display: inline-flex;
  padding: 14px 58px 13px 57px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 100px;
  background: var(
    --GRADIENT-BUTTONS,
    linear-gradient(220deg, #4322c6 28.95%, #48068a 74.94%)
  );

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  margin: 46px 0 22px 0;
`;

const PopUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;

  @media (min-width: 600px) {
    width: 500px;
  }
`;

const WordTrainerPopUp = ({ setClose }) => {
  const handleClose = () => {
    window.localStorage.setItem('dont_show_popup_word_trainer', 'true');
    setClose(() => false);
  };
  const translationData = useLanguageData('features.wordTrainer.tutorial');

  return (
    <PopUp setClose={() => handleClose()}>
      <PopUpWrapper>
        <Title>{translationData.title}</Title>
        <OptionWrapper>
          {translationData.options.map((t) => (
            <OptionElement>
              <div>
                <img src={t.icon} />
              </div>
              <Text>{t.label}</Text>
            </OptionElement>
          ))}
        </OptionWrapper>
        <Text style={{ marginTop: '46px' }}>{translationData.bottomText}</Text>
        <CloseButton onClick={handleClose}>{translationData.close}</CloseButton>
      </PopUpWrapper>
    </PopUp>
  );
};

export default WordTrainerPopUp;
