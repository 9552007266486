import Android1 from './images/Android_1_150_HR.png';
import Android2 from './images/Android_Dodaj_150_HR.png';
import Ios1 from './images/IOS_1_150_HR.png';
import Ios2 from './images/IOS_Dodaj_150_HR.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

import Micro from './images/wordTrainerTutorial/icon-microphone.png';
import Voice from './images/wordTrainerTutorial/volume-up.svg';
import Person from './images/wordTrainerTutorial/user.svg';

export default {
	passwordRestart: {
		title: 'Resetiraj lozinku',
		password: 'Lozinka',
		repeatPassword: 'Ponovi lozinku',
		restartPawssord: 'Resetiraj lozinku',
	},
	paywall: {
		title: 'Odaberite plan kako biste nastavili učiti',
		buy: 'Kupi',
		savePayment: 'Sigurno plaćanje podržava',
		skip: 'Preskoči',
		pickPlan: 'Odaberite plan',
		endPlanFirstPart: 'Vaš pristup završava za',
		endPlanSecondPart: 'dana.',
		endPlanAditionalPart: 'dan.',
		paymentTitle: 'Sažetak',
		product: 'Proizvod:',
		plan: 'SynthiAi Paket',
		forLong: 'Pristup na',
		data: 'podaci:',
		nameAndSurname: 'Ime i Prezime:',
		paymentForm: 'Način Plaćanja:',
		toPay: 'Za platiti danas:',
		accept: 'Prihvaćam',
		rules: 'uvjete',
		payingAccept: 'Plaćanjem prihvaćate',
		paymentRules: '"Uvjeti plaćanja PayU"',
		buyAndPay: 'KUPUJEM I PLAĆAM',
	},
	paymentStatus: {
		veryfication: 'Plaćanje se još uvijek obrađuje...',
		title: 'Plaćanje završeno',
		success: 'uspješno!',
		failure: 'neuspješno!',
		subtitle: 'Uskoro ćete biti preusmjereni na stranicu za prijavu.',
		textInfo: `Vaša uplata je uspješno obrađena. Sada možete postaviti svoju lozinku odlaskom na našu stranicu za registraciju. U roku od nekoliko minuta primit ćete e-mail s poveznicom koja vam omogućuje postavljanje lozinke.
Molimo provjerite svoju ulaznu poštu, kao i mapu za neželjenu poštu. Kako biste olakšali pretraživanje, upišite "synthiai" u polje za pretraživanje. Ako imate bilo kakvih problema, obratite nam se na: contact@synthiaiapp.com`,
		login: 'Idi na prijavu',
	},
	register: {
		steps: {
			register: 'Registracija',
			package: 'Paket',
			summarize: 'Sažetak',
			step: 'Korak ',
		},
		stepOne: {
			password: 'Lozinka',
			errorGoogle: 'Prijavite se putem svoje e-pošte',
			title: 'Registracija',
			nameAndSurname: 'Ime i prezime',
			placeholderNameAndSurname: 'npr. Adam Smith',
			email: 'E-mail',
			placeholderEmail: 'npr. primjer@synthiaiapp.com',
			password: 'Lozinka',
			start: 'KREIRAJTE RAČUN',
			subtitle:
				'Lozinka mora sadržavati najmanje 8 znakova, uključujući slova, velika slova i brojeve.',
			passwordError1: 'Lozinka mora sadržavati najmanje 8 znakova.',
			passwordError2: 'Lozinka mora sadržavati najmanje 1 veliko slovo.',
			passwordError3: 'Lozinka mora sadržavati najmanje 1 malo slovo.',
			passwordError4: 'Lozinka mora sadržavati najmanje 1 broj.',
			passwordError5: 'Lozinke moraju biti iste.',
		},
		stepTwo: {
			title: 'Odaberite svoj plan',
			month: 'Mjesec',
			months: 'Mjeseci',
			months2: 'Mjeseci',
			value: '€/Mjesec',
			pick: 'odaberite',
			nowPay: 'Plati sada',
			saveOne: 'Uštedite',
			buy: 'Kupi',
			savePayment: 'Sigurno plaćanje podržava',
		},
		stepThree: {
			title: 'Sažetak',
			product: 'Proizvod:',
			plan: 'Synthi Ai paket',
			forLong: 'Pristup za',
			data: 'Podaci:',
			nameAndSurname: 'Ime i prezime:',
			paymentForm: 'Način plaćanja:',
			toPay: 'Za platiti danas:',
			accept: 'Prihvaćam',
			rules: 'Pravila',
			payingAccept: 'Plaćanjem prihvaćate',
			paymentRules: '"PayU pravila o plaćanju"',
			buyAndPay: 'KUPI I PLATI',
		},
	},
	firstLogin: {
		createPasswordForAcoount: 'Izradite lozinku za svoj račun',
		typeEmail: 'Unesite svoju e-mail adresu',
		useSameEmail: 'Koristite istu adresu koju ste naveli prilikom kupnje',
		typePassword: 'Unesite lozinku',
		confirmPassword: 'Potvrdite lozinku',
		createPassword: 'Izradite lozinku',
		errorLogin: 'Provjerite je li vaša lozinka ili e-mail ispravna',
		createdPassword: 'Jeste li već stvorili lozinku?',
		clickToLogin: 'Kliknite ovdje za prijavu',
	},
	login: {
		dontHavePassword: 'Još nemate lozinku?',
		getPassword: 'Kliknite ovdje za pristup',
		title: 'Prijava',
		email: 'E-mail',
		password: 'Lozinka',
		textSub: 'Zaboravili ste lozinku? Resetirajte je',
		restart: 'ovdje',
		restartPasswordText:
			'Unesite svoju e-mail adresu kako biste resetirali lozinku. Lozinka će biti poslana na vašu e-mail adresu.',
		placeholder: 'Unesite svoju e-mail adresu...',
		resetPassword: 'Resetiraj lozinku',
		buttonText: 'Prijava',
		errorLogin: 'Provjerite jesu li vaša lozinka ili e-mail adresa ispravni',
		passwordResterted: 'Vaša lozinka je resetirana, provjerite svoju e-poštu.',
		passwordError: 'Nažalost, nismo uspjeli pronaći vašu e-mail adresu.',
		mobileAppBannerText: 'Otkrijte našu aplikaciju!',
	},
	navBar: {
		home: 'Start',
		learn: 'Učenje',
		profile: 'Profil',
	},
	home: {
		hello: 'Pozdrav',
		question: 'O čemu želite razgovarati danas?',
		answer: 'Započnite razgovor',
		forYou: 'Preporučeno za vas',
		popularNow: 'Trenutno popularno',
		doYouKnow: 'Jeste li znali da...?',
		funFact: 'Dnevna činjenica',
		new: 'Novo',
		check: 'Provjeri',
		yourFavorite: 'Vaši favoriti',
		newTitle: 'Trener riječi',
		newSubtitle: 'Vježbaj izgovor riječi',
		talkWith: 'Razgovaraj o bilo kojoj temi s',
	},
	learn: {
		wordTrainer: {
			title: 'Trener riječi',
			subtitle: 'Vježbaj izgovor riječi',
			new: 'Novo',
		},
		flashcardV2: {
			new: 'Novo',
			sport: 'Sport',
			continue: 'nastavi',
			nice: 'Odlično!',
			congratulation: 'Čestitke!',
			category: 'Kategorija',
			textAfterCategory: 'nema tajni za tebe!',
			pickNewCategory: 'Odaberi novu kategoriju',
			showResult: 'Prikaži rješenje',
			createWordWithLetters: 'Složi riječ iz nasumičnih slova',
			know: 'Znam',
			dontKnow: 'Ne znam',
			metchWords: 'Spoji u parove',
			addNewCategory: 'Dodaj novu kategoriju',
			personalizeLearning:
				'Prilagodi svoje učenje kreiranjem vlastite kategorije.',
			typeNewCategory: 'Unesi ime svoje kategorije',
			save: 'Spremi',
			weAreCreatingCategoryForYou: 'Stvaramo novu kategoriju za tebe:',
			start: 'ZAPOČNI',
			howToUseFlashcard: 'Kako koristiti kartice?',
			knowFlashcard:
				'Klikni na „Ne znam” ili „Znam” kako bi procijenio svoje znanje.',
			dontKnowFlashcard: 'Kartice označene kao „Znam” neće se ponavljati.',
			clickToHear: 'Klikni za slušanje riječi.',
			clickForDefinition: 'Klikni za prikaz definicije',
			clickForTranslation: 'Klikni za prikaz prijevoda i primjera',
			close: 'Zatvori',
			clickCategoryToStart: 'Klikni na odabranu kategoriju za početak učenja.',
			lookAsProgressBar:
				'Traka napretka ispod kategorije pokazuje koliko si riječi već savladao.',
			clickToSetupFavaroiteFlashcard:
				'Klikni za postavljanje kategorije kao omiljene.',
			clickToCreateOwnCategory:
				'Klikni za stvaranje vlastite kategorije kartica.',
			howLearningLookLike: 'Kako izgleda učenje?',
			restText:
				' Učenje se sastoji od pregleda kartica i procjene znanja. Osim toga, rješavat ćeš jednostavne zadatke.',
		},
		chat: {
			title: 'Chat',
			subtitle: 'Razgovarajte o bilo kojoj temi.',
		},
		fotoMode: {
			title: 'Foto-zagonetka',
			subtitle: 'Opišite sliku što je preciznije moguće.',
		},
		scene: {
			title: 'Scene',
			subtitle: 'Odigrajte ulogu s AI tutorom.',
		},
		word: {
			title: 'Riječi',
			subtitle: 'Proširite svoj vokabular.',
		},
		flashcard: {
			title: 'Kartice',
			subtitle: 'Ponovite i zapamtite riječi.',
		},
		seeMore: 'Prikaži sve',
	},
	common: {
		description:
			'Synthi AI - Vaš inteligentni asistent za učenje engleskog jezika. Naučite govoriti tečno engleski uz pomoć umjetne inteligencije. Otkrijte novi, učinkovit pristup učenju jezika. Započnite još danas!',
		deletedFromFavorite: 'Uklonjeno iz favorita',
		addedToFavorite: 'Dodano u favorite',
		flashcards: 'Kartice',
		flashcards_v2: 'Kartice',
		scenes: 'Scene',
		words: 'Riječi',
		or: 'ili',
		continueWithFB: 'Nastavite putem Facebooka',
		privacyLink: 'privacy',
		termsLink: 'tos',
	},
	profile: {
		days: ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'],
		top: {
			profile: 'Profil',
			accessTo: 'Pristup do:',
			profileImage: 'Slika profila',
			nameAndSurname: 'Ime i prezime',
			password: 'Lozinka',
			change: 'Promijeni',
			changeProfileImage: 'Promijeni sliku profila',
			changeNameAndSurname: 'Promijenite svoje ime i prezime',
			changeNameAndSurnamePlaceHolder: 'Unesite svoje ime i prezime',
			save: 'Spremi',
			changePassword: 'Promijenite lozinku',
			passwordInfo:
				'Lozinka mora sadržavati najmanje 8 znakova, uključujući slova, velika slova i brojeve.',
			repeatPassword: 'Ponovite lozinku',
			passwordChanged: 'Lozinka promijenjena',
			error: 'Ups, nešto je pošlo po zlu',
			nameChanged: 'Ime promijenjeno',
			profileChanged: 'Profil promijenjen',
		},
		plan: {
			subInfo:
				'za otkazivanje pretplate pošaljite e-mail na contact@synthiaiapp.com',
			yourPlan: 'Vaš plan',
			extend: 'Produžite svoj plan',
			short_extend: 'Produži',
			currentPlan: 'Trenutni plan:',
			activeToPlan: 'Aktivan do:',
			currentPlanLong: 'Vaš trenutni plan',
		},
		middle: {
			yourMedals: 'Vaše medalje',
			oneDay: 'Dan',
			twoDays: 'Dana',
			words: 'Riječi',
			learningTime: 'Vrijeme učenja',
			streak: 'Streak',
			streakRecord: 'Rekord u nizu',
			yourRecord: 'Vaš rekord:',
			currentRecord: 'Trenutni rekord:',
			words: 'Riječi',
			wordsInWeek: 'Broj riječi tjedno:',
		},
		bottom: {
			yourTutor: 'Vaš AI tutor',
			change: 'Promijeni',
			logout: 'Odjava',
			FAQ: 'FAQ',
			shareOpinion: 'Podijelite mišljenje',
			yourPlan: 'Vaš plan',
			hobby: 'Interesi',
			learningSetting: 'Postavke učenja',
			back: 'Natrag',
			dayTarget: 'Dnevni cilj',
			languageLevel: 'Razina jezika',
			changeYourLangLevel: 'Promijenite razinu jezika.',
			pickNewTutor: 'Odaberite novog tutora',
			yourDecision: 'Vaša odluka utječe na stil i atmosferu lekcija.',
			tutroChanged: 'Tutor promijenjen',
			error: 'Ups, nešto je pošlo po zlu',
			englishLevelChanged: 'Vaša razina je promijenjena',
			levelsOptions: [
				{
					name: 'Početnik A1',
					description: 'Započnite svoje avanturu s engleskim jezikom',
					value: 'basic',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Početnik A2',
					description:
						'Možete razumjeti osnovne izraze u jednostavnim razgovorima.',
					borderRadius: '0',
					value: 'beginner',
				},
				{
					name: 'Srednji B1',
					description:
						'Vodite jednostavne, ali razumljive razgovore o poznatim temama.',
					borderRadius: '0',
					value: 'intermediate',
				},
				{
					name: 'Napredni B2',
					description: 'Slobodno komunicirate u većini situacija',
					borderRadius: '0',
					value: 'advanced',
				},
				{
					name: 'Ekspert C1+',
					description: 'Učinkovito vladate jezikom u složenim kontekstima.',
					value: 'expert',
					borderRadius: '0 0 8px 8px ',
				},
			],
			changeDailyTarget: 'Promijenite svoj dnevni cilj učenja.',
			deletedFromFavorite: 'Uklonjeno iz favorita',
			addedToFavorite: 'Dodano u favorite',
			lessonTime: [
				{
					name: 'Brza lekcija',
					dsecription: 'Savršena opcija za brzo učenje',
					time: '5 minuta',
					value: 'five_minutes',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Standardna sesija',
					dsecription: 'Izvrsna opcija za uravnoteženo učenje',
					time: '10 minuta',
					value: 'ten_minutes',
					borderRadius: '0',
				},
				{
					name: 'Intenzivni trening',
					dsecription: 'Najbolja opcija za entuzijaste',
					time: '15 minuta',
					value: 'fifteen_minutes',
					borderRadius: '0 0 8px 8px ',
				},
			],
			dailyTargetChanged: 'Dnevni cilj promijenjen!',
			pickIntresets: 'Odaberite najmanje 3 interesa.',
			activeTo: 'Aktivan plan do:',
			daysLeft: 'Preostali dani u planu:',
			opinionSubtitle:
				'Stalno poboljšavamo našu aplikaciju. Podijelite s nama svoje mišljenje o tome što možemo promijeniti u aplikaciji ili što vam se najviše sviđa.',
			startTyping: 'Započnite pisati svoje mišljenje',
			faq: 'FAQ',
			send: 'Pošalji',
			withProblems: 'Za pitanja/probleme kontaktirajte nas na:',
			contactMail: 'contact@synthiaiapp.com',
			faqConfig: [
				{
					id: 1,
					question: 'Moj zvuk ne radi.',
					answer: `Ako imate problema sa zvukom, molimo vas da slijedite sljedeće savjete:
      
              Provjerite dozvole za zvuk u pregledniku.
      
              Provjerite je li glasnoća vašeg uređaja odgovarajuća.
      
              Provjerite ispravnu povezanost zvučnika/slušalica.
      
              Provjerite postavke zvuka sustava kako biste bili sigurni da aplikacije nisu utišane.
      
              Pokušajte s drugim uređajem ili kontaktirajte tehničku podršku.
          `,
				},
				{
					id: 2,
					question: 'Ne mogu snimiti svoj glas.',
					answer: `Ako imate problema sa snimanjem zvuka, molimo vas da slijedite sljedeće savjete:
              
              Provjerite dozvole za mikrofon u pregledniku.
      
              Provjerite ima li mobilni preglednik pristup mikrofonu.
      
              Provjerite ispravnu povezanost mikrofona.
      
              Koristite drugi uređaj ili kontaktirajte tehničku podršku.
          `,
				},
				{
					id: 3,
					question: 'Mogu li koristiti aplikaciju na više od jednog uređaja?',
					answer: `Naravno, jednostavno se prijavite na svakom kompatibilnom uređaju kako biste uživali u neprekidnom iskustvu učenja.`,
				},
				{
					id: 4,
					question: 'Mogu li koristiti Synthi Ai offline?',
					answer: `Ne, Synthi Ai zahtijeva web preglednik s pristupom internetu.`,
				},
				{
					id: 5,
					question: 'Kako mogu promijeniti AI tutora?',
					answer: `Idite na "Tutor" u postavkama Synthi Ai. Istražite i odaberite svog preferiranog tutora.`,
				},
				{
					id: 6,
					question: 'Kako mogu produžiti pristup aplikaciji nakon isteka?',
					answer: `Možete obnoviti svoj plan nakon isteka prijavom.`,
				},
				{
					id: 7,
					question: 'Mogu li dobiti račun?',
					answer:
						'Kontaktirajte nas na contact@synthiaiapp.com i navedite svoj OIB te podatke o tvrtki.',
				},
				{
					id: 8,
					question: 'Kako mogu kontaktirati tehničku podršku?',
					answer:
						'Pošaljite e-mail na contact@synthiaiapp.com i navedite detalje o problemu te informacije o uređaju i pregledniku.',
				},
			],
		},
		rules: 'Pravila',
		privacy: 'Politika privatnosti',
		span: ' i ',
	},
	welcome: {
		languagePicker: {
			title: 'Odaberi jezik koji želiš učiti',
			next: 'Dalje',
			langToLearn: 'Jezik učenja',
			successTitle: 'Jezik učenja je promijenjen',
			config: [
				{
					label: 'Engleskog',
					label2: 'Engleski',
					labelInside: 'engleskim',
					value: 'en',
				},
				{
					label: 'Njemačkog',
					label2: 'Njemački',
					labelInside: 'njemačkim',
					value: 'de',
				},
				{
					label: 'Španjolskog',
					label2: 'Španjolski',
					labelInside: 'španjolskim',
					value: 'es',
				},
				{
					label: 'Francuskog',
					label2: 'Francuski',
					labelInside: 'francuskim',
					value: 'fr',
				},
				{
					label: 'Talijanskog',
					label2: 'Talijanski',
					labelInside: 'talijanskim',
					value: 'it',
				},
			],
		},
		zeroScreen: {
			welcomeInApp: 'Dobrodošli u Synthi AI aplikaciju!',
			start: 'KRENIMO',
			welcomeMobile: 'Dobrodošli',
			config: {
				chrome: {
					title: 'Instalirajte aplikaciju na svom Android uređaju.',
					text1: 'Kliknite na',
					text1bold: ' Postavke',
					image1: Android1,
					text2: 'Zatim kliknite na',
					text2bold: ' Dodaj na početni zaslon',
					image2: Android2,
					text3: 'Gotovo! Synthi AI sada se nalazi na vašem početnom zaslonu.',
					image3: Last,
				},
				safari: {
					title: 'Instalirajte aplikaciju na svom iOS uređaju.',
					text1: 'Kliknite na gumb',
					text1bold: ' Dijeli',
					image1: Ios1,
					text2: 'Zatim kliknite na',
					text2bold: ' Dodaj na početni zaslon',
					image2: Ios2,
					text3: 'Gotovo! Synthi AI sada se nalazi na vašem početnom zaslonu.',
					image3: Last,
				},
			},
		},
		firstScreen: {
			title: 'Odaberite svog tutora!',
			subtitle: 'Vaša odluka utječe na stil i atmosferu lekcija.',
			next: 'DALJE',
		},
		secondScreen: {
			title: 'Koja je vaša trenutna razina {language} jezika?',
			config: [
				{
					name: 'Početnik A1',
					description: 'Započnite svoju avanturu s {language} jezikom.',
					value: 'basic',
				},
				{
					name: 'Početnik A2',
					description:
						'Možete razumjeti osnovne informacije u jednostavnim svakodnevnim razgovorima.',
					value: 'beginner',
				},
				{
					name: 'Srednji B1',
					description:
						'Vodite jednostavne, ali razumljive razgovore o poznatim temama.',
					value: 'intermediate',
				},
				{
					name: 'Napredni B2',
					description: 'Slobodno komunicirate u većini situacija',
					value: 'advanced',
				},
				{
					name: 'Ekspert C1+',
					description: 'Učinkovito vladate jezikom u složenim kontekstima.',
					value: 'expert',
				},
			],
			next: 'DALJE',
		},
		thirdScreen: {
			title: 'Koji su vaši interesi?',
			subtitle: 'Odaberite najmanje 3 interesa.',
			next: 'DALJE',
		},
		fourthScreen: {
			title: 'Koliko minuta dnevno želite učiti?',
			subtitle: 'Odaberite svoj dnevni cilj učenja.',
			next: 'KRENIMO!',
			config: [
				{
					name: 'Brza lekcija',
					dsecription: 'Savršena opcija za brzo učenje',
					time: '5 minuta',
					value: 'five_minutes',
				},
				{
					name: 'Standardna sesija',
					dsecription: 'Izvrsna opcija za uravnoteženo učenje',
					time: '10 minuta',
					value: 'ten_minutes',
				},
				{
					name: 'Intenzivni trening',
					dsecription: 'Najbolja opcija za entuzijaste',
					time: '15 minuta',
					value: 'fifteen_minutes',
				},
			],
		},
	},
	features: {
		wordTrainer: {
			next: 'Dalje',
			yourSpeaking: 'Tvoj izgovor',
			you: 'Ti',
			title: 'Trener riječi',
			errorMessage:
				'Ups! Imamo problem s obradom tvoje snimke. Pokušaj ponovno :)))',
			endScreenTitle: 'Odlično! 🌟🌟🌟',
			endScreenTopInfo: 'Savladao si sve',
			endScreenMiddleInfo: 'riječi',
			endScreenBottomInfo: 'na ovoj razini.',
			endScreenText: 'Možeš početi iznova ili promijeniti razinu u postavkama.',
			endScreenBottomText: 'Počni ispočetka',
			endScreenEndText: 'Završi',
			sesionScoreTitle: 'Odlično ti ide! 🎉',
			sesionScoreSubTitle: 'Poboljšavaš svoj izgovor',
			sesionScoreWord: 'Riječi',
			now: 'Sada',
			sum: 'ukupno',
			continue: 'NASTAVI',
			areYouSureToEndLesson: 'Jesi li siguran da želiš završiti lekciju?',
			speakingScore: 'Ocjena izgovora',
			GOOD_ARRAY: [
				'Bravo!',
				'Savršeno!',
				'Ti si prvak!',
				'Odlično!',
				'Nevjerojatno!',
				'Genijalno!',
			],
			BAD_ARRAY: [
				'Ne odustaj!',
				'Možeš ti to!',
				'Samo malo još!',
				'Ponovi i uspjet ćeš!',
				'Skoro si tamo!',
				'Nema veze, pokušaj ponovno!',
			],
			OK_ARRAY: [
				'Na dobrom si putu!',
				'Jako blizu!',
				'Sve bolje i bolje!',
				'Napreduješ!',
				'Bravo za trud!',
			],
			hold: 'Drži',
			holdText: 'mikrofon i izgovori:',
			tutorial: {
				title: 'Kako koristiti Trener riječi?',
				options: [
					{
						label: 'Klikni za slušanje pravilnog izgovora',
						icon: Micro,
					},
					{
						label: 'Drži i izgovori riječ',
						icon: Voice,
					},
					{
						label: 'Klikni i preslušaj svoju snimku da usavršiš izgovor!',
						icon: Person,
					},
				],
				bottomText:
					'Redovitim vježbanjem s Trenerom riječi brže ćeš savladati jezik!',
				close: 'ZATVORI',
			},
		},
		topbar: {
			back: 'Natrag',
		},
		fotoMode: {
			welcome: {
				title: 'Foto-zagonetka',
				subtitle:
					'Opišite što vidite na slici s što više detalja na odabranu temu.',
				start: 'Započni!',
			},
			info: {
				startTyping: 'Započnite pisati...',
				newTask: 'Novi zadatak',
				send: 'Pošalji',
			},
		},
		flashcards: {
			title: 'Kartice',
			subtitleTop:
				'Naučite i zapamtite nove riječi pomoću interaktivnih metoda učenja kartica.',
			addCategory: 'Dodajte svoju kategoriju',
			subtitle: 'Unesite naziv vaše kategorije',
			type: 'upišite kategoriju...',
			add: 'Dodaj',
			previous: 'Prethodna kartica',
			task: 'Zadatak',
			changeLevel: 'Promijenite razinu',
			dontKnow: 'Ne znam',
			know: 'Znam',
			pickPairs: 'Spojite parove',
			goodJob: 'Dobar posao!',
			titleEnd: 'Vaša posvećenost je impresivna',
			subtitleEnd: 'Jeste li spremni za nove izazove?',
			repeat: 'Ponovite kategoriju',
			other: 'Odaberite drugu kategoriju',
			level: {
				title: 'Promijenite razinu kartica',
				beginner: ' Početnik A1-A2',
				intermediate: 'Srednji B1-B2',
				advance: 'Ekspert C1-C2',
				change: 'Promijeni',
			},
		},
		scene: {
			title: 'Scene',
			subtitle:
				'Uživite se u ulogu i odigrajte scenu s AI tutorom na odabranu temu.',
			suggest: 'Predložite novu scenu',
			talk: 'Razgovarajte',
			deletedFromFavorite: 'Uklonjeno iz favorita',
			addedToFavorite: 'Dodano u favorite',
		},
		word: {
			title: 'Riječi',
			subtitle:
				'Proširite svoj vokabular u ključnim područjima razgovora s AI tutorom.',
			suggest: 'Predložite novu temu riječi',
			deletedFromFavorite: 'Uklonjeno iz favorita',
			addedToFavorite: 'Dodano u favorite',
		},
	},
	cookie: {
		title: 'Obavijest o kolačićima',
		subtitle:
			'Ova web stranica koristi kolačiće kako bi vam pružila bolje korisničko iskustvo i personalizirane sadržaje. Ako želite saznati više, pročitajte našu ',
		link: 'Politiku privatnosti',
		accept: 'PRIHVATI',
		denied: 'ODBIJ',
	},
	talkingBar: {
		allowMicrofon: 'Molimo omogućite pristup mikrofonu',
		typeMessage: 'Napišite poruku...',
		record: 'Snimi',
		recording: 'Snimanje',
	},
	hands: {
		giveUsInfo: 'Podijelite svoje mišljenje s nama',
		thanksForGrading: 'Hvala vam na ocjeni!',
		subtitle: 'Vaše mišljenje nam je važno!',
		subtitle2: 'Recite nam što mislite o ovoj lekciji:',
		skip: 'PRESKOČI',
		send: 'POŠALJI',
	},
	suggestConfig: {
		profile: {
			title: 'Podijelite svoje mišljenje!',
			subtitle:
				'Stalno poboljšavamo našu aplikaciju. Recite nam što možemo promijeniti u aplikaciji ili što vam se najviše sviđa.',
		},
		word: {
			title: 'Predložite novu temu riječi',
			subtitle: '',
		},
		scene: {
			title: 'Predložite novu scenu',
			subtitle: '',
		},
		startTyping: 'Započnite tipkati',
		send: 'POŠALJI',
		thx: 'Mišljenje dodano! Hvala!',
		error: 'Ups, nešto je pošlo po zlu',
	},
	checkout: {
		emailGuessText: 'Jeste li htjeli napisati ovo?',
		leftTitle: 'Odaberite plan za sebe',
		rightTitle: 'Unesite podatke',
		subscribe: 'Pretplatiti se',
		total: 'Ukupno',
		currencySign: '€',
		currency: 'eur',
		accept: 'Slažem se s ',
		space: ' i ',
		email: 'Email',
		placeHolderEmail: 'Unesite svoju email adresu',
		rules: 'pravilima',
		tos: 'politikom privatnosti',
		savePayments: 'Sigurne uplate obrađuje',
	},
	dailyV2: {
		conversation: {
			doYouWantContinueConversation:
				'Želite li nastaviti razgovor ili započeti novi?',
			continueConversation: 'Nastavi razgovor',
			newConversation: 'Novi razgovor',
		},
		hint: {
			title: 'Savjet',
			pick: 'Odaberi',
		},
		feedback: {
			yourMessage: 'Vaša poruka',
			goodMessage: 'Točna poruka',
			explanation: 'Objašnjenje',
		},
		tutorial: {
			title: 'Kako koristiti chat?',
			skip: 'Preskoči',
			next: 'DALJE',
			start: 'Započni',
			screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
			config: [
				[
					{ title: 'Kliknite za isključivanje zvuka Tutora', image: VolumeUp },
					{ title: 'Kliknite za davanje povratne informacije', image: HandsUp },
					{ title: 'Kliknite za otvaranje uputa', image: Tutorial },
				],
				[
					{ title: 'Kliknite za ponovno slušanje', image: VolumeUpBlank },
					{
						title: 'Kliknite za prikaz prijevoda',
						image: TranslationBlank,
					},
					{ title: 'Kliknite za prikaz savjeta', image: HintBlank },
					{ title: 'Kliknite za prijavu problema', image: InfoBlank },
				],
				[
					{ title: 'Vaš odgovor je točan', image: GreenCircle },
					{
						title: 'Kliknite za prikaz ispravljenog odgovora i objašnjenja',
						image: YellowCircle,
					},
				],
				[
					{ title: 'Kliknite u tekstualno polje da biste napisali poruku' },
					{ title: 'Kliknite za snimanje poruke', image: Microphone },
				],
			],
		},
	},

	problem: {
		title: 'Prijavi problem',
		subtitle: 'Opišite problem vezan uz AI poruku',
		send: 'Pošalji',
		messageSend: 'Poruka poslana',
		error: 'Nešto je pošlo po zlu, napišite nam :)',
	},
};
