import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'word-trainer';

export const generateNextWordTrainer = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-word`, payload);
  return { data: data.data };
};

export const getClientAllScoreWordTrainer = async () => {
  const data = await api.get(`/${PREFIX}/score`);
  return { data: data.data };
};

export const restartScoreWordTrainer = async () => {
  const data = await api.post(`/${PREFIX}/restart-score`);
  return { data: data.data };
};

export const checkWordTrainer = async (payload) => {
  const data = await api.post(`/${PREFIX}/check-word`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return { data: data?.data };
};
