import {
  askChatGptProductDaily,
  rateCustomerMessageChat,
  transcript,
} from '@/api/query/workflow-daily';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import { TALKING_ROLES } from './TalkiMessage';
import Animation1 from '@/common/images/animation-1.gif';
import { typeWriter } from '@/common/functions/typing';
import AnimationRecording from './AnimationRecording';
import IconCloseHints from '@/common/icons/icon-circle-chevron-down.svg';
import {
  HintsWrapper,
  IconWrapper,
  FlexWrapper,
  WrapperHint,
  HintIcon,
  HintIconWrapper,
} from './TalkingMicrofon.styles';
import { FROM_SOURCE_TYPES } from '@/common/constants/types';
import MessageBar from '@/common/components/chat/MessageBar';
import HintBoxMessage from './HintBoxMessage';
import ScoreBoxMessage from './ScoreBoxMessage';
import AudioRecordingContextProvider from '@/common/contexts/AudioRecordingContext';
import { VALID_PATHS } from '@/common/constants/validPaths';
import { voiceRunner } from '@/common/functions/voiceRunner';

const TalkingMicrofon = ({
  setCurrent,
  current,
  setConversationWithChatGPT,
  setMessageScore,
  conversationWithChatGPT,
  setShowBadMessage,
  showBadMessage,
  hintData,
  setHintData,
}) => {
  const [showHints, setShowHints] = useState(false);
  const [hints, setHints] = useState([]);
  const [speechLasts, setSpeechLasts] = useState();

  const {
    showLoading,
    dailyConversation,
    muteVoice,
    showPopUpTutorial,
    setUserVoice,
  } = useContext(DemoConversationContext);

  const presenceChecker = useRef();

  const [isRecording, setIsRecording] = useState(false);

  const transcriptMutation = useMutation({
    mutationFn: (file) => transcript(file),
    onSuccess: ({ data }) => {
      if (!data && data?.length === 0) return;

      const userMessageId = `user-message-id-${
        conversationWithChatGPT.length + 1
      }`;

      setConversationWithChatGPT({ role: TALKING_ROLES.USER, message: data });

      const payload = {};
      payload.message = data;
      payload._id = dailyConversation._id;
      payload.source = FROM_SOURCE_TYPES.SPEAK;
      payload.userMessageId = userMessageId;

      rateUserMessageMutation.mutate(payload);
      getResponseForChatGptMutation.mutate(payload);
    },
  });

  const rateUserMessageMutation = useMutation({
    mutationFn: (value) => rateCustomerMessageChat(value),
    onSuccess: ({ data }) => {
      setMessageScore(data);
    },
  });

  const getResponseForChatGptMutation = useMutation({
    mutationFn: (value) => askChatGptProductDaily(value),
    onSuccess: async ({ data }) => {
      setHints(() => data.hints);

      let parsedText = null;

      if (!muteVoice) {
        await voiceRunner(
          data.speaking.data,
          '',
          setSpeechLasts,
          VALID_PATHS.DAILY,
        );
      }

      typeWriter({
        text: parsedText ?? data.text,
        setText: setCurrent,
        typing: 45,
        onEnd: () => {
          setCurrent(null);
          setSpeechLasts(() => false);
          setConversationWithChatGPT({
            index: conversationWithChatGPT.length,
            role: TALKING_ROLES.BOT,
            message: data.text,
          });
        },
      });
    },
  });

  const handleSendMessage = (messageInput, hint = false) => {
    if (!messageInput) return;

    setHints([]);

    const message = messageInput;
    const userMessageId = `user-message-id-${
      conversationWithChatGPT.length + 1
    }`;

    setConversationWithChatGPT({ role: TALKING_ROLES.USER, message: message });

    const payload = {};
    payload.message = message;
    payload._id = dailyConversation._id;
    payload.source = hint ? FROM_SOURCE_TYPES.HINT : FROM_SOURCE_TYPES.WRTIE;
    payload.userMessageId = userMessageId;

    rateUserMessageMutation.mutate(payload);
    getResponseForChatGptMutation.mutate(payload);
  };

  const isResponding = useMemo(
    () =>
      speechLasts ||
      getResponseForChatGptMutation.isLoading ||
      transcriptMutation.isLoading,
    [
      getResponseForChatGptMutation.isLoading,
      speechLasts,
      transcriptMutation.isLoading,
    ],
  );

  const handleHints = () => {
    if (showHints) {
      return setShowHints(false);
    }

    setShowHints(true);
  };

  useEffect(() => {
    if (
      dailyConversation &&
      showPopUpTutorial === false &&
      conversationWithChatGPT.length === 0 &&
      !current
    ) {
      const payload = {};
      payload._id = dailyConversation._id;

      getResponseForChatGptMutation.mutate(payload);
    }
  }, [dailyConversation, showPopUpTutorial]);

  useEffect(() => {
    const element = document.getElementById(`bottom-info`);
    element?.scrollIntoView({ behavior: 'smooth' });
  }, [transcriptMutation?.isLoading, getResponseForChatGptMutation?.isLoading]);

  return (
    <>
      {hintData && (
        <HintBoxMessage
          data={hintData}
          setClose={() => setHintData(null)}
          handleProccessHint={() => {
            setHintData(null);
            handleSendMessage(hintData.hint_result, true);
          }}
        />
      )}
      {showBadMessage && (
        <ScoreBoxMessage
          data={showBadMessage}
          setClose={() => setShowBadMessage(null)}
        />
      )}
      {transcriptMutation.isLoading && (
        <div style={{ textAlign: 'right', minHeight: '37px' }}>
          <img
            alt=""
            src={Animation1}
            style={{ width: '37px', height: '37px' }}
          />
        </div>
      )}
      {getResponseForChatGptMutation.isLoading && (
        <div style={{ textAlign: 'left', minHeight: '37px' }}>
          <img
            alt=""
            src={Animation1}
            style={{ width: '37px', height: '37px' }}
          />
        </div>
      )}
      {isRecording && <AnimationRecording />}
      {showLoading && (
        <div
          style={{
            textAlign: 'center',
            marginTop: '-40px',
            marginLeft: '-80px',
          }}
        >
          <img alt="" src={Animation1} style={{ width: '120px' }} />
        </div>
      )}
      <div ref={presenceChecker} />
      <IconWrapper>
        <FlexWrapper>
          {showHints && !isResponding && (
            <HintsWrapper>
              {hints?.map((h) => (
                <WrapperHint onClick={() => handleSendMessage(h, true)}>
                  {h}
                </WrapperHint>
              ))}
            </HintsWrapper>
          )}
          {!!hints?.length && !isResponding && (
            <HintIconWrapper isRotated={showHints}>
              <HintIcon
                alt=""
                src={IconCloseHints}
                onClick={() => handleHints()}
              />
            </HintIconWrapper>
          )}
        </FlexWrapper>
        <AudioRecordingContextProvider>
          <MessageBar
            isBlock={
              getResponseForChatGptMutation.isLoading ||
              transcriptMutation.isLoading
            }
            sendText={handleSendMessage}
            sendVoice={(data) => {
              setUserVoice(() => data.get('file'));
              transcriptMutation.mutate(data);
            }}
          />
        </AudioRecordingContextProvider>
      </IconWrapper>
    </>
  );
};

export default TalkingMicrofon;
