import styled from 'styled-components';
import React from 'react';
import ArrRight from './images/arr-right-p.svg';
import { Link } from 'react-router-dom';

const FixedDailyContainer = styled.div`
	position: fixed;
	top: 0;
	width: 100%;
	height: 90px;
	z-index: 1;
	opacity: ${(props) => props.opacity};
	display: flex;
	justify-content: center;
	align-items: center;
	top: 55px;
`;

const Wrapper = styled.div`
	background: linear-gradient(10deg, #48068a 10%, #4322c6 65%);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0px 10px;
`;

const TopMessage = styled.div`
	color: white;
	font-family: Lato;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	text-align: left;
	padding-left: 5px;
`;

const BottomMessage = styled(Link)`
	text-decoration: none;
	cursor: pointer;
	float: right;
	display: flex;
	min-width: 200px;
	max-width: 240px;
	padding: 8px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	background-color: white;
	border-radius: 27px;

	color: #29185e;
	font-family: 'Work Sans';
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;
`;

const TalkWithFixed = ({ opacity, translation, question, icon, tutorName }) => {

	return (
		<FixedDailyContainer
			opacity={opacity}
			display={opacity === 0 ? 'none' : 'block'}
		>
			<Wrapper>
				<img
					alt='tutor'
					src={icon}
					style={{
						width: 60,
						border: '3px solid white',
						borderRadius: '100%',
            marginLeft: 15
					}}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						gap: 5,
            width: '65%',
            marginLeft: 15
					}}
				>
					<TopMessage>{translation.talkWith} {tutorName}</TopMessage>
					<BottomMessage to='/learn/chat'>
						{translation.answer} <img src={ArrRight} />
					</BottomMessage>
				</div>
			</Wrapper>
		</FixedDailyContainer>
	);
};

export default TalkWithFixed;
