import React, { useMemo } from 'react';
import styled from 'styled-components';
import { WORD_TRAINER_STATUES } from '../WordTrainer';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Wrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  font-size: 44px;
`;

const TextWrapper = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-top: 14px;
`;

const ScoreWrapper = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 36.4px */
`;

const StatuSection = ({ status, score }) => {
  const translationData = useLanguageData('features.wordTrainer');

  const config = useMemo(() => {
    if (status === WORD_TRAINER_STATUES.BAD) {
      return {
        color: '#29185E',
        icon: '🙃',
      };
    }

    if (status === WORD_TRAINER_STATUES.OK) {
      return {
        color: '#29185E',
        icon: '🤨',
      };
    }

    if (status === WORD_TRAINER_STATUES.GOOD) {
      return {
        icon: '😍',
        color: '#32951B',
      };
    }

    return { icon: '', color: '' };
  }, [status]);

  if (
    [WORD_TRAINER_STATUES.NONE, WORD_TRAINER_STATUES.RECORDING].includes(
      status,
    ) ||
    !score
  ) {
    return <></>;
  }

  return (
    <Wrapper>
      <IconWrapper>{config?.icon}</IconWrapper>
      <TextWrapper>{translationData.speakingScore}:</TextWrapper>
      <ScoreWrapper style={{ color: config?.color }}>{score}%</ScoreWrapper>
    </Wrapper>
  );
};

export default StatuSection;
