import Android1 from './images/Android_1_150_SV.png';
import Android2 from './images/Android_Dodaj_150_SV.png';
import Ios1 from './images/IOS_1_150_SV.png';
import Ios2 from './images/IOS_Dodaj_150_SV.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

import Micro from './images/wordTrainerTutorial/icon-microphone.png';
import Voice from './images/wordTrainerTutorial/volume-up.svg';
import Person from './images/wordTrainerTutorial/user.svg';

export default {
	passwordRestart: {
		title: 'Återställning av lösenord',
		password: 'Lösenord',
		repeatPassword: 'Upprepa lösenord',
		restartPawssord: 'Återställ lösenord',
	},
	paywall: {
		title: 'Välj en plan för att fortsätta studera',
		buy: 'Köp',
		savePayment: 'Säker betalning via',
		skip: 'Hoppa över',
		pickPlan: 'Välj plan',
		endPlanFirstPart: 'Ditt åtkomst slutar om',
		endPlanSecondPart: 'dagar.',
		endPlanAditionalPart: 'dagar.',
		paymentTitle: 'Sammanfattning',
		product: 'Produkt:',
		plan: 'SynthiAi Paket',
		forLong: 'Tillgång för',
		data: 'uppgifter:',
		nameAndSurname: 'Namn och Efternamn:',
		paymentForm: 'Betalningsmetod:',
		toPay: 'Att betala idag:',
		accept: 'Jag godkänner',
		rules: 'villkoren',
		payingAccept: 'Genom att betala accepterar du',
		paymentRules: '"PayUs betalningsvillkor"',
		buyAndPay: 'KÖP OCH BETALA',
	},
	paymentStatus: {
		veryfication: 'Betalningen är fortfarande under behandling...',
		title: 'Betalningen slutförd',
		success: 'Framgång!',
		failure: 'Misslyckades!',
		subtitle: 'Vi kommer snart att omdirigera dig till inloggningssidan.',
		textInfo: `Din betalning har behandlats framgångsrikt. Du kan nu ställa in ditt lösenord genom att gå till vår registreringssida. Inom några minuter kommer du att få ett e-postmeddelande med en länk för att ställa in ditt lösenord.
Kontrollera din inkorg samt skräppostmappen. För att underlätta sökningen, skriv "synthiai" i sökfältet. Om du har några problem, vänligen kontakta oss på: contact@synthiaiapp.com`,
		login: 'Logga in',
	},
	register: {
		steps: {
			register: 'Registrering',
			package: 'Paket',
			summarize: 'Sammanfattning',
			step: 'Steg ',
		},
		stepOne: {
			password: 'Lösenord',
			errorGoogle: 'Logga in med din e-postadress',
			title: 'Registrering',
			nameAndSurname: 'Förnamn och efternamn',
			placeholderNameAndSurname: 'XXXXXXXXXXX',
			email: 'E-postadress',
			placeholderEmail: 't.ex. pelda@synthiaiapp.com',
			password: 'Lösenord',
			start: 'SKAPA KONTO',
			subtitle:
				'Lösenordet måste vara minst 8 tecken långt och innehålla både små och stora bokstäver samt en siffra.',
			passwordError1: 'Lösenordet måste innehålla minst 8 tecken.',
			passwordError2: 'Lösenordet måste innehålla minst 1 versal.',
			passwordError3: 'Lösenordet måste innehålla minst 1 gemen.',
			passwordError4: 'Lösenordet måste innehålla minst 1 siffra.',
			passwordError5: 'Lösenorden måste matcha.',
		},
		stepTwo: {
			title: 'Välj en plan',
			month: 'månad',
			months: 'månader',
			months2: 'månader',
			value: '€ / månad',
			pick: 'välj',
			nowPay: 'Omedelbar betalning',
			saveOne: 'Spara',
			buy: 'Köp',
			savePayment: 'Säker betalning via',
		},
		stepThree: {
			title: 'Sammanfattning',
			product: 'Produkt:',
			plan: 'Synthi AI Paket',
			forLong: 'Tillgång',
			data: 'datum:',
			nameAndSurname: 'Förnamn och efternamn:',
			paymentForm: 'Betalningsmetod:',
			toPay: 'Att betala:',
			accept: 'Jag godkänner',
			rules: 'villkoren',
			payingAccept: 'Genom att betala godkänner du',
			paymentRules: '"PayU Betalningsvillkor"',
			buyAndPay: 'KÖP OCH BETALA',
		},
	},
	succesPayment: {
		title: 'Betalning lyckades!',
		subtitle: 'Vi kommer snart att omdirigera dig till inloggningssidan.',
	},
	firstLogin: {
		createPasswordForAcoount: 'Skapa ett lösenord för ditt konto',
		typeEmail: 'Ange din e-postadress',
		useSameEmail: 'Använd samma adress som du angav vid köpet',
		typePassword: 'Ange ditt lösenord',
		confirmPassword: 'Bekräfta ditt lösenord',
		createPassword: 'Skapa lösenord',
		errorLogin:
			'Kontrollera om ditt lösenord eller din e-postadress är korrekt',
		createdPassword: 'Har du redan skapat ett lösenord?',
		clickToLogin: 'Klicka här för att logga in',
	},
	login: {
		dontHavePassword: 'Har du inget lösenord än?',
		getPassword: 'Klicka här för att få åtkomst',
		title: 'Inloggning',
		email: 'E-postadress',
		password: 'Lösenord',
		textSub: 'Glömt lösenordet? Återställ',
		restart: 'här',
		restartPasswordText:
			'Ange din e-postadress för att återställa lösenordet. Lösenordet kommer att skickas till din inkorg.',
		placeholder: 'ange din e-postadress...',
		resetPassword: 'återställ lösenord',
		buttonText: 'Logga in',
		errorLogin: 'Kontrollera din e-postadress och lösenord.',
		passwordResterted: 'Ditt lösenord har återställts. Kontrollera din e-post.',
		passwordError: 'Tyvärr, vi kunde inte hitta din e-postadress.',
		mobileAppBannerText: 'Upptäck vår app!',
	},
	navBar: {
		home: 'Hem',
		learn: 'Lärande',
		profile: 'Profil',
	},
	home: {
		hello: 'Hej',
		question: 'Vad vill du prata om idag?',
		answer: 'Starta en konversation',
		forYou: 'Rekommenderat för dig',
		popularNow: 'Populärt nu',
		doYouKnow: 'Visste du att...?',
		funFact: 'Dagens intressanta faktum',
		new: 'Ny',
		check: 'Kontrollera',
		yourFavorite: 'Dina favoriter',
		newTitle: 'Ordtränare',
		newSubtitle: 'Öva på ords uttal',
		talkWith: 'Prata om vad som helst med',
	},
	learn: {
		wordTrainer: {
			title: 'Ordtränare',
			subtitle: 'Öva på ords uttal',
			new: 'Ny',
		},
		flashcardV2: {
			new: 'Ny',
			sport: 'Sport',
			continue: 'fortsätt',
			nice: 'Fantastiskt!',
			congratulation: 'Grattis!',
			category: 'Kategori',
			textAfterCategory: 'har inga hemligheter för dig!',
			pickNewCategory: 'Välj en ny kategori',
			showResult: 'Visa lösning',
			createWordWithLetters: 'Skapa ett ord från blandade bokstäver',
			know: 'Jag vet',
			dontKnow: 'Jag vet inte',
			metchWords: 'Matcha ord',
			addNewCategory: 'Lägg till en ny kategori',
			personalizeLearning:
				'Anpassa ditt lärande genom att skapa en egen kategori.',
			typeNewCategory: 'Skriv in namnet på din kategori',
			save: 'Spara',
			weAreCreatingCategoryForYou: 'Vi skapar en ny kategori åt dig:',
			start: 'STARTA',
			howToUseFlashcard: 'Hur använder man flashcards?',
			knowFlashcard:
				'Klicka på "Jag vet inte" eller "Jag vet" för att bedöma din kunskap.',
			dontKnowFlashcard:
				'Flashcards markerade som "Jag vet" kommer inte att upprepas.',
			clickToHear: 'Klicka för att höra ordet.',
			clickForDefinition: 'Klicka för att se definitionen',
			clickForTranslation: 'Klicka för att se översättning och exempel',
			close: 'Stäng',
			clickCategoryToStart: 'Klicka på vald kategori för att börja lära dig.',
			lookAsProgressBar:
				'Framstegsindikatorn under kategorin visar hur många ord du redan har bemästrat.',
			clickToSetupFavaroiteFlashcard:
				'Klicka för att markera en kategori som favorit.',
			clickToCreateOwnCategory:
				'Klicka för att skapa en egen kategori med flashcards.',
			howLearningLookLike: 'Hur ser lärandet ut?',
			restText:
				' Lärandet består av att granska flashcards och bedöma din kunskap. Dessutom kommer du att lösa enkla uppgifter.',
		},
		chat: {
			title: 'Chatt',
			subtitle: 'Prata om vilket ämne som helst.',
		},
		fotoMode: {
			title: 'Gissa bilden',
			subtitle: 'Beskriv bilden så exakt som möjligt.',
		},
		scene: {
			title: 'Scener',
			subtitle: 'Spela med AI-tutorn.',
		},
		word: {
			title: 'Ord',
			subtitle: 'Utöka ditt ordförråd.',
		},
		flashcard: {
			title: 'Flashcards',
			subtitle: 'Repetera och memorera ord.',
		},
		seeMore: 'Se alla',
	},
	common: {
		description:
			'SynthiAi - Din intelligenta engelska lärarassistent. Lär dig att tala engelska flytande med hjälp av AI. Upptäck en ny, effektiv metod för språkinlärning. Börja idag!',
		deletedFromFavorite: 'Borttagen från favoriter',
		addedToFavorite: 'Lagd till favoriter',
		flashcards: 'Flashcards',
		flashcards_v2: 'Flashcards',
		scenes: 'Scener',
		words: 'Ord',
		privacyLink: 'privacy',
		termsLink: 'tos',
		or: 'eller',
		continueWithFB: 'Fortsätt med Facebook',
	},
	profile: {
		days: [
			'Måndag',
			'Tisdag',
			'Onsdag',
			'Torsdag',
			'Fredag',
			'Lördag',
			'Söndag',
		],
		top: {
			profile: 'Profil',
			accessTo: 'Åtkomst:',
			profileImage: 'Profilbild',
			nameAndSurname: 'Förnamn och efternamn',
			password: 'Lösenord',
			change: 'Ändra',
			changeProfileImage: 'Ändra profilbild',
			changeNameAndSurname: 'Ändra namn och efternamn',
			changeNameAndSurnamePlaceHolder: 'Skriv ditt namn',
			save: 'Spara',
			changePassword: 'Ändra lösenord',
			passwordInfo:
				'Lösenordet måste vara minst 8 tecken långt och innehålla både små och stora bokstäver samt en siffra.',
			repeatPassword: 'Upprepa lösenord',
			passwordChanged: 'Lösenordet har ändrats',
			error: 'Oj, något gick fel',
			nameChanged: 'Namnet har ändrats',
			profileChanged: 'Profilen har ändrats',
		},
		plan: {
			yourPlan: 'Din plan',
			extend: 'Förläng plan',
			short_extend: 'Förlängning',
			currentPlan: 'Nuvarande plan:',
			activeToPlan: 'Aktiv till:',
			currentPlanLong: 'Din nuvarande plan',
		},
		middle: {
			yourMedals: 'Dina prestationer',
			oneDay: 'dag',
			twoDays: 'dagar',
			words: 'Ord',
			learningTime: 'Studietid',
			streak: 'Streak',
			streakRecord: 'Streak-rekord',
			yourRecord: 'Ditt rekord:',
			currentRecord: 'Nuvarande rekord:',
			wordsInWeek: 'Veckans ord:',
		},
		bottom: {
			yourTutor: 'Din AI-tutor',
			change: 'Ändra',
			logout: 'Logga ut',
			FAQ: 'FAQ',
			shareOpinion: 'Dela din åsikt',
			yourPlan: 'Din plan',
			hobby: 'Hobby',
			learningSetting: 'Inlärningsinställningar',
			back: 'Tillbaka',
			dayTarget: 'Dagens mål',
			languageLevel: 'Språknivå',
			changeYourLangLevel: 'Ändra din språknivå',
			pickNewTutor: 'Välj ny tutor',
			yourDecision: 'Ditt val påverkar stil och stämning i lektionerna.',
			tutroChanged: 'Tutor har ändrats',
			error: 'Oj, något gick fel',
			englishLevelChanged: 'Din språknivå har ändrats',
			levelsOptions: [
				{
					name: 'Nybörjare A1',
					description: 'Börja med att lära dig engelska',
					value: 'basic',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Grundläggande A2',
					description:
						'Förstår grundläggande information i enkla, vardagliga samtal',
					borderRadius: '0',
					value: 'beginner',
				},
				{
					name: 'Mellannivå B1',
					description:
						'Kan föra enkla, men begripliga samtal om bekanta ämnen.',
					borderRadius: '0',
					value: 'intermediate',
				},
				{
					name: 'Avancerad B2',
					description: 'Kan kommunicera flytande i de flesta situationer',
					borderRadius: '0',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description: 'Använder språket effektivt i komplexa situationer',
					value: 'expert',
					borderRadius: '0 0 8px 8px',
				},
			],
			changeDailyTarget: 'Ändra dagligt studie mål.',
			deletedFromFavorite: 'Borttagen från favoriter',
			addedToFavorite: 'Lagd till favoriter',
			lessonTime: [
				{
					name: 'Snabb lektion',
					dsecription: 'Idealisk för snabb inlärning',
					time: '5 minuter',
					value: 'five_minutes',
					borderRadius: '8px 8px 0 0',
				},
				{
					name: 'Standard lektion',
					dsecription: 'Balans mellan lärandeupplevelse',
					time: '10 minuter',
					value: 'ten_minutes',
					borderRadius: '0',
				},
				{
					name: 'Intensiv träning',
					dsecription: 'Bästa valet för entusiastiska elever',
					time: '15 minuter',
					value: 'fifteen_minutes',
					borderRadius: '0 0 8px 8px',
				},
			],
			dailyTargetChanged: 'Dagens mål har ändrats!',
			pickIntresets: 'Välj minst 3 intressen.',
			activeTo: 'Din plan är aktiv till:',
			daysLeft: 'Dagar kvar av din plan:',
			opinionSubtitle:
				'Vi förbättrar ständigt vår app. Dela din åsikt om vad du skulle ändra i appen eller vad du gillar mest med den.',
			startTyping: 'Börja skriva',
			faq: 'FAQ',
			send: 'Skicka',
			withProblems: 'Om du har frågor/problem, kontakta oss:',
			contactMail: 'contact@synthiaiapp.com',
			faqConfig: [
				{
					id: 1,
					question: 'Ljudet fungerar inte.',
					answer:
						'Om du har problem med ljudet, prova följande:\n\nKontrollera ljudbehörigheterna i webbläsaren.\n\nSe till att din enhets volym är korrekt.\n\nKontrollera att högtalarna/hörlurarna är korrekt anslutna.\n\nKolla systemets ljudinställningar för att se om någon app är avstängd.\n\nProva en annan enhet eller kontakta teknisk support.',
				},
				{
					id: 2,
					question: 'Jag kan inte spela in ljud.',
					answer:
						'Om du inte kan spela in ljud, prova följande:\n\nKontrollera mikrofonbehörigheterna i webbläsaren.\n\nSe till att mobil webbläsaren har tillgång till mikrofonen.\n\nKontrollera att mikrofonen är korrekt ansluten.\n\nProva en annan enhet eller kontakta teknisk support.',
				},
				{
					id: 3,
					question: 'Kan jag använda appen på flera enheter?',
					answer:
						'Självklart, logga in på vilken kompatibel enhet som helst för att studera sömlöst.',
				},
				{
					id: 4,
					question: 'Kan jag använda Synthi AI utan internetanslutning?',
					answer:
						'Nej, Synthi AI kräver en internetanslutning för att fungera.',
				},
				{
					id: 5,
					question: 'Hur kan jag byta min AI-tutor?',
					answer:
						"Gå till fliken 'Tutor' i Synthi AI-inställningarna. Bläddra och välj en annan tutor.",
				},
				{
					id: 6,
					question: 'Hur kan jag förnya mitt abonnemang efter utgång?',
					answer: 'Efter utgång kan du logga in och förnya din plan.',
				},
				{
					id: 7,
					question: 'Kan jag få en faktura?',
					answer:
						'Skriv till contact@synthiaiapp.com och ange ditt organisationsnummer och företagets information.',
				},
				{
					id: 8,
					question: 'Hur kontaktar jag teknisk support?',
					answer:
						'Skicka ett e-postmeddelande till contact@synthiaiapp.com med detaljer om problemet samt information om enheten och webbläsaren.',
				},
			],
		},
		rules: 'villkor',
		privacy: 'Integritetspolicy',
		span: ' och ',
	},
	welcome: {
		languagePicker: {
			title: 'Välj språket du vill lära dig',
			next: 'Nästa',
			langToLearn: 'Lärspråk',
			successTitle: 'Lärspråket har ändrats',
			config: [
				{
					label: 'Engelska',
					label2: 'Engelska',
					labelInside: 'engelska',
					value: 'en',
				},
				{
					label: 'Tyska',
					label2: 'Tyska',
					labelInside: 'tyska',
					value: 'de',
				},
				{
					label: 'Spanska',
					label2: 'Spanska',
					labelInside: 'spanska',
					value: 'es',
				},
				{
					label: 'Franska',
					label2: 'Franska',
					labelInside: 'franska',
					value: 'fr',
				},
				{
					label: 'Italienska',
					label2: 'Italienska',
					labelInside: 'italienska',
					value: 'it',
				},
			],
		},
		zeroScreen: {
			welcomeInApp: 'Välkommen till Synthi AI-appen!',
			start: 'STARTA',
			welcomeMobile: 'Välkommen',
			config: {
				chrome: {
					title: 'Installera appen på din Android-enhet.',
					text1: 'I Chrome-webbläsaren klicka på',
					text1bold: ' Inställningar',
					image1: Android1,
					text2: 'Klicka sedan på',
					text2bold: ' Lägg till på hemskärmen',
					image2: Android2,
					text3: 'Klart! Synthi AI är nu på din hemskärm',
					image3: Last,
				},
				safari: {
					title: 'Installera appen på din iOS-enhet.',
					text1: 'I Safari-webbläsaren klicka på',
					text1bold: ' Dela',
					image1: Ios1,
					text2: 'Klicka sedan på',
					text2bold: ' Lägg till på hemskärmen',
					image2: Ios2,
					text3: 'Klart! Synthi AI är nu på din hemskärm',
					image3: Last,
				},
			},
		},
		firstScreen: {
			title: 'Välj din tutor!',
			subtitle: 'Ditt val kommer att påverka stil och stämning i lektionerna.',
			next: 'TOVÁBB',
		},
		secondScreen: {
			title: 'Vad är din nuvarande {language} språknivå?',
			config: [
				{
					name: 'Grundläggande A1',
					description: 'Börja med att lära dig {language}.',
					value: 'basic',
				},
				{
					name: 'Grundläggande A2',
					description: 'Förstår grundläggande information i enkla samtal',
					value: 'beginner',
				},
				{
					name: 'Mellannivå B1',
					description: 'Kan föra enkla och begripliga samtal om bekanta ämnen.',
					value: 'intermediate',
				},
				{
					name: 'Avancerad B2',
					description: 'Kan kommunicera flytande i de flesta situationer',
					value: 'advanced',
				},
				{
					name: 'Expert C1+',
					description: 'Använder språket effektivt i komplexa situationer',
					value: 'expert',
				},
			],
			next: 'TOVÁBB',
		},
		thirdScreen: {
			title: 'Vilka är dina intressen?',
			subtitle: 'Välj minst 3 intressen.',
			next: 'TOVÁBB',
		},
		fourthScreen: {
			title: 'Hur många minuter vill du studera varje dag?',
			subtitle: 'Välj ditt dagliga mål.',
			next: 'VI BÖRJAR!',
			config: [
				{
					name: 'Snabb lektion',
					dsecription: 'Idealisk för snabb inlärning',
					time: '5 minuter',
					value: 'five_minutes',
				},
				{
					name: 'Standard lektion',
					dsecription: 'Balans mellan lärandeupplevelse',
					time: '10 minuter',
					value: 'ten_minutes',
				},
				{
					name: 'Intensiv träning',
					dsecription: 'Bästa valet för entusiastiska elever',
					time: '15 minuter',
					value: 'fifteen_minutes',
				},
			],
		},
	},
	features: {
		wordTrainer: {
			next: 'Nästa',
			yourSpeaking: 'Din uttal',
			you: 'Du',
			title: 'Ordtränare',
			errorMessage:
				'Oj då! Vi har problem med att bearbeta din inspelning. Försök igen :)))',
			endScreenTitle: 'Fantastiskt! 🌟🌟🌟',
			endScreenTopInfo: 'Du har lärt dig alla',
			endScreenMiddleInfo: 'ord',
			endScreenBottomInfo: 'på denna nivå.',
			endScreenText:
				'Du kan börja om från början eller ändra nivå i inställningarna.',
			endScreenBottomText: 'Börja om',
			endScreenEndText: 'Avsluta',
			sesionScoreTitle: 'Du gör det jättebra! 🎉',
			sesionScoreSubTitle: 'Du förbättrar ditt uttal',
			sesionScoreWord: 'Ord',
			now: 'Nu',
			sum: 'totalt',
			continue: 'FORTSÄTT',
			areYouSureToEndLesson: 'Är du säker på att du vill avsluta lektionen?',
			speakingScore: 'Uttalsbetyg',
			GOOD_ARRAY: [
				'Bra jobbat!',
				'Perfekt!',
				'Du är en mästare!',
				'Fantastiskt!',
				'Otroligt!',
				'Genialt!',
			],
			BAD_ARRAY: [
				'Ge inte upp!',
				'Du klarar det!',
				'Bara lite till!',
				'Fortsätt öva, du är nära!',
				'Snart fixar du det!',
				'Ingen fara, försök igen!',
			],
			OK_ARRAY: [
				'Du är på rätt väg!',
				'Väldigt nära!',
				'Det blir bättre och bättre!',
				'Du gör framsteg!',
				'Bra kämpat!',
			],
			hold: 'Håll in',
			holdText: 'mikrofonen och säg:',
			tutorial: {
				title: 'Hur använder man Ordtränaren?',
				options: [
					{
						label: 'Klicka för att lyssna på rätt uttal',
						icon: Micro,
					},
					{
						label: 'Håll in och säg ordet',
						icon: Voice,
					},
					{
						label:
							'Klicka och lyssna på din inspelning för att bemästra ordet!',
						icon: Person,
					},
				],
				bottomText:
					'Regelbunden träning med Ordtränaren hjälper dig att snabbare bemästra språket!',
				close: 'STÄNG',
			},
		},
		topbar: {
			back: 'Tillbaka',
		},
		fotoMode: {
			welcome: {
				title: 'Gissa bilden',
				subtitle:
					'Beskriv vad du ser på bilden om det valda ämnet så exakt som möjligt.',
				start: 'Börja!',
			},
			info: {
				startTyping: 'Börja skriva...',
				newTask: 'Ny uppgift',
				send: 'Skicka',
			},
		},
		flashcards: {
			title: 'Kort',
			subtitleTop: 'Lär och memorera nya ord med interaktiva kort.',
			addCategory: 'Lägg till egen kategori',
			subtitle: 'Skriv in kategori namn',
			type: 'Skriv in kategori...',
			add: 'Lägg till',
			previous: 'Föregående kort',
			task: 'Uppgift',
			changeLevel: 'Byt nivå',
			dontKnow: 'Jag vet inte',
			know: 'Jag vet',
			pickPairs: 'Välj par',
			goodJob: 'Bra jobbat!',
			titleEnd: 'Ditt engagemang är imponerande',
			subtitleEnd: 'Är du redo för fler utmaningar?',
			repeat: 'Upprepa kategori',
			other: 'Välj en annan kategori',
			level: {
				title: 'Byt kortnivå',
				beginner: 'Nybörjare A1-A2',
				intermediate: 'Mellannivå B1-B2',
				advance: 'Avancerad C1-C2',
				change: 'Byt',
			},
		},
		scene: {
			title: 'Scener',
			subtitle: 'Spela en scen med AI-tutorn om det valda ämnet.',
			suggest: 'Förslag på ny scen',
			talk: 'Samtal',
			deletedFromFavorite: 'Borttagen från favoriter',
			addedToFavorite: 'Lagd till favoriter',
		},
		word: {
			title: 'Ord',
			subtitle: 'Utöka ditt ordförråd på viktiga områden med AI-tutorn.',
			suggest: 'Förslag på ny ordkategori',
			deletedFromFavorite: 'Borttagen från favoriter',
			addedToFavorite: 'Lagd till favoriter',
		},
	},
	cookie: {
		title: 'Cookies information',
		subtitle:
			'Denna webbplats använder cookies för att ge en bättre användarupplevelse och erbjuda personligt innehåll. Om du vill veta mer om detta, läs vår integritetspolicy.',
		link: 'integritetspolicy',
		accept: 'JAG GODKÄNNER',
		denied: 'JAG GODKÄNNER INTE',
	},
	talkingBar: {
		allowMicrofon: 'Vänligen tillåt användning av mikrofonen',
		typeMessage: 'Skriv ett meddelande...',
		record: 'Spela in',
		recording: 'Inspelning pågår',
	},
	hands: {
		giveUsInfo: 'Dela din åsikt',
		thanksForGrading: 'Tack för din feedback!',
		subtitle: 'Din åsikt är viktig för oss!',
		subtitle2: 'Berätta vad du tycker om denna lektion:',
		skip: 'Hoppa över',
		send: 'Skicka',
	},
	suggestConfig: {
		profile: {
			title: 'Dela din åsikt!',
			subtitle:
				'Vi förbättrar ständigt vår app. Dela din åsikt om vad du skulle ändra i appen eller vad du gillar mest med den.',
		},
		word: {
			title: 'Förslag på nytt ord',
			subtitle: '',
		},
		scene: {
			title: 'Förslag på ny scen',
			subtitle: '',
		},
		startTyping: 'Börja skriva',
		send: 'SKICKA',
		thx: 'Åsikt skickad! Tack!',
		error: 'Oj! Något gick fel',
	},
	checkout: {
		emailGuessText: 'Menade du',
		leftTitle: 'Välj en plan för dig',
		rightTitle: 'Fyll i uppgifterna',
		email: 'E-post',
		placeHolderEmail: 'Ange din e-postadress',
		pay: 'KÖP OCH BETALA',
		accept: 'Acceptera',
		rules: 'reglerna',
		space: 'och',
		tos: 'sekretesspolicyn',
		payingAccept: 'Genom att betala accepterar du',
		paymentRules: 'PayU-betalningsregler',
		savePayments: 'Säkra betalningar tillhandahålls av',
		total: 'Totalt',
		currency: 'sek',
		currencySign: 'kr',
		subscribe: 'Prenumerera',
	},
	dailyV2: {
		conversation: {
			doYouWantContinueConversation:
				'Vill du fortsätta konversationen eller starta en ny?',
			continueConversation: 'Fortsätt konversation',
			newConversation: 'Ny konversation',
		},
		hint: {
			title: 'Tips',
			pick: 'Val',
		},
		feedback: {
			yourMessage: 'Ditt meddelande',
			goodMessage: 'Rätt meddelande',
			explanation: 'Förklaring',
		},
		tutorial: {
			title: 'Hur använder man chatten?',
			skip: 'Hoppa över',
			next: 'TOVÁBB',
			start: 'Börja',
			screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
			config: [
				[
					{
						title: 'Klicka för att stänga av lärarens röst',
						image: VolumeUp,
					},
					{
						title: 'Klicka för att dela din åsikt',
						image: HandsUp,
					},
					{
						title: 'Klicka för att öppna guiden',
						image: Tutorial,
					},
				],
				[
					{
						title: 'Klicka för att spela upp igen',
						image: VolumeUpBlank,
					},
					{
						title: 'Klicka för att se översättningen',
						image: TranslationBlank,
					},
					{
						title: 'Klicka för att se tipsen',
						image: HintBlank,
					},
					{
						title: 'Klicka för att rapportera problemet',
						image: InfoBlank,
					},
				],
				[
					{
						title: 'Ditt meddelande är korrekt',
						image: GreenCircle,
					},
					{
						title: 'Klicka för att se det korrigerade svaret och förklaringen',
						image: YellowCircle,
					},
				],
				[
					{
						title: 'Klicka för att skriva meddelandet',
						image: '',
					},
					{
						title: 'Klicka för att spela in meddelandet',
						image: Microphone,
					},
				],
			],
		},
	},
	problem: {
		title: 'Rapportera problem',
		subtitle: 'Beskriv problemet med AI:s meddelande',
		send: 'Skicka',
		messageSend: 'Meddelandet skickat',
		error: 'Något gick fel, skriv till oss :)',
	},
};
