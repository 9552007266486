import React, { useContext } from 'react';
import styled from 'styled-components';
import WordTrainer from '../../learn/images/wordTrainer.png';
import { Link } from 'react-router-dom';
import RightIcon from '../desktop/images/arr-right.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 18px;
`;

const Box = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;

  width: 94vw;
  margin-left: 3vw;
  height: 150px;
  gap: 14px;
  border-radius: 8px;
  text-decoration: none;
  margin-top: 8px;
`;

const Title = styled.div`
  color: #fff;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
`;

const Subtitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  max-width: 300px;
`;

const TitleTop = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  margin-left: 3vw;
`;

const CheckButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 130px;
  height: 25px;
  padding: 2px 0px;
  flex-shrink: 0;
  border-radius: 27px;
  background: #29185e;

  color: #fff;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 13.2px */
  text-decoration: none;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewSection = ({ translation }) => {
  return (
    <>
      <Wrapper>
        <TitleTop>{translation.new}</TitleTop>
        <Box image={WordTrainer} to={'/learn/word-trainer'}>
          <Title>{translation.newTitle}</Title>
          <Subtitle>{translation.newSubtitle}</Subtitle>
          <CheckButton to={'/learn/word-trainer'}>
            {translation.check}
            <Flex>
              <img src={RightIcon} />
            </Flex>
          </CheckButton>
        </Box>
      </Wrapper>
    </>
  );
};

export default NewSection;
