import { createOpinion } from '@/api/query/opinion';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import React, { useContext, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const Skip = styled.button`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
  background-color: white;

  cursor: pointer;
`;

const Send = styled.button`
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: 12px 60px 12px 60px;
  border-radius: 100px;

  background: linear-gradient(10deg, #48068A 10%, #4322C6 65%);
  cursor: pointer;

  @media (max-width: 600px) {
    width: 200px;
    padding: 12px 30px 12px 30px;
  }
`;

const P = styled.p`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;
  margin-top: 50px;
`;

const TitlePopUp = styled.h2`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 0 auto;

  @media (max-width: 600px) {
    max-width: 280px;
  }
`;

const SubTitlePopUp = styled.h3`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;
  width: 80vw;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;

const TextArea = styled.textarea`
  width: 700px;
  margin-top: 12px;
  height: 92px;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #eef2f5;
  outline: none;
  border: none;
  resize: none;

  color: #05275d;

  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;

  @media (max-width: 600px) {
    width: 300px;
    height: 140px;
    padding: 12px;
  }
`;

const LetterCounterWrapper = styled.p`
  text-align: right;
  color: #868686;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: right;
  }
`;

const PopUpWrapper = styled.form`
  padding: 50px;

  @media (max-width: 600px) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const OPINION_OPTIONS = {
  PROFILE: 'profile',
  WORD: 'word',
  SCENE: 'scene',
};

const WriteOpinion = ({ setShow, from }) => {
  const [lengthCounter, setLengthCounter] = useState(0);
  const messegeRef = useRef();
  const data = useLanguageData('suggestConfig');

  const { addMessage } = useContext(MessageContext);

  const handleAddOpinionMutation = useMutation({
    mutationFn: (payload) => createOpinion(payload),
    onSuccess: () => {
      addMessage(data.thx, 'success');
      setShow(false);
    },
    onError: () => {
      addMessage(data.error, 'error');
    },
  });

  const handleAddHand = (e) => {
    e?.preventDefault();

    const payload = {};
    payload.message = messegeRef?.current?.value || null;
    payload.type = from;
    handleAddOpinionMutation.mutate(payload);
  };

  const handleChange = (e) => {
    const message = messegeRef.current.value;

    if (message.length > 600) {
      return (messegeRef.current.value = message.slice(0, 300));
    }

    setLengthCounter(() => message.length);
  };

  return (
    <>
      {handleAddOpinionMutation.isLoading && <Loading />}
      <PopUp setClose={setShow}>
        <PopUpWrapper onSubmit={handleAddHand}>
          <TitlePopUp>{data[from].title}</TitlePopUp>{' '}
          <SubTitlePopUp>{data[from].subtitle}</SubTitlePopUp>
          <TextArea
            ref={messegeRef}
            onChange={(e) => handleChange(e)}
            required={true}
            placeholder={data.startTyping}
          />
          <LetterCounterWrapper>{lengthCounter} / 600</LetterCounterWrapper>
          <ButtonsWrapper>
            <Send type="submit">{data.send}</Send>
          </ButtonsWrapper>
        </PopUpWrapper>
      </PopUp>
    </>
  );
};

export default WriteOpinion;
